import React from 'react'

import logo from './logo.svg'
import s from './SiteLogo.module.css'

const SiteLogo = () => {
  return <img alt='site-logo' src={logo} className={s.siteLogo} />
}

export default SiteLogo
