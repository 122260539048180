import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { TextInput } from './TextInput'
import s from './TextInput.module.scss'

export const Photo = memo(({ value, onChangePhoto }) => {
  const { t } = useTranslation()
  return (
    <div className={s.photoBox} style={{ width: '100%' }}>
      <label htmlFor='photoLocker' className={s.btnBrowse}>
        {t('lockersRoute').formCreation.lockerPhotoBtnText}
        <input
          id='photoLocker'
          name='photoLocker'
          accept='.jpg, .jpeg, .png'
          type='file'
          multiple
          onChange={onChangePhoto}
          className={s.photoFile}
        />
      </label>
      <TextInput
        disabled={true}
        placeholder={t('photo').placeholder}
        label={t('photo').label}
        props={{ value }}
      />
    </div>
  )
})
