import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

const TrackingPage = lazy(() =>
  import('../TrackingRoutes' /* webpackChunkName: "TrackingPage" */),
)
const StatusPage = lazy(() =>
  import('../TrackingRoutes/StatusPage' /* webpackChunkName: "StatusPage" */),
)

export const TrackingRoute = () => {
  return (
    <>
      <Route exact path='/orders/track/:guid' render={() => <TrackingPage />} />
      <Route
        exact
        path='/orders/retrieve/status'
        render={() => <StatusPage />}
      />
    </>
  )
}
