import React, { useRef, useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useAsync } from 'react-use'
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete'
import { Icon, Switch } from '@blueprintjs/core'
import { useTranslation } from 'react-i18next'

import Header from '../../../modules/RoutesComponents/HeaderRoute'
import {
  LocationName,
  LocationType,
  Clients,
  ButtonsGroup,
  Loader,
  Select,
} from '../../../components/reusableComponents'
import GMap from '../../../components/reusableComponents/Map/Map'
import { Zone } from './Zone'
import { showErrorMessage, showWarningMessage } from '../../../modules/Toasters'
import ToogleAddressBtn from './ToogleAdressButton'

import { getLocationById } from '../../../services/api/locations'
import { defaultValues } from './formState'

import { useFetchData } from '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Locations/hooks/useFetchData'
import { useUpdateData } from '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Locations/hooks/useUpdateData'
import { usePostData } from '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Locations/hooks/usePostData'
import { useUserRole } from '../../../hooks'

import { LOCATION_TYPE_CLIENT } from '../../../data/locations'

import variables from '../../../styles/variables'
import {
  mapBtn,
  addressBlock,
  editBtn,
  zoneList,
  zoneItem,
  visibleToggleBtn,
  qrBox,
  form,
} from './LocationForm.module.scss'
import { getLogisticsCompanies } from '../../../services/api/settings.ts'

import s from '../../../styles/Form.module.scss'

const INITIAL_GEOLOCATION_GOOGLE_MAP = { lat: 24.7135517, lng: 46.6752957 }

const LOCATION_SELECT_TYPE = {
  MAP: 'MAP',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
}

export const LocationForm = ({ id, goBack = null, onSubmit = null }) => {
  const { t } = useTranslation()
  const { isAdmin } = useUserRole()
  const { register, handleSubmit, getValues, setValue, watch, control } =
    useForm({
      defaultValues,
    })
  const type = watch('type')

  const typeRef = useRef()

  const [editMode, setEditMode] = useState(!!id)

  const [client, setClient] = useState(null)
  const [zones, setZones] = useState([])

  const [googlePlace, setGooglePlace] = useState(null)
  const [address, setAddress] = useState('')
  const [geoLocation, setGeoLocation] = useState(
    () => INITIAL_GEOLOCATION_GOOGLE_MAP,
  )
  const [openCabinetsByQr, setOpenCabinetsByQr] = useState(false)

  const [logisticCompanies, setLogisticCompanies] = useState([])
  const [logisticsCompany, setLogisticsCompany] = useState(null)

  const { isLoadingData, isShowForm } = useFetchData({
    id,
    getAsyncCallback: getLocationById,
    setValue,
    setClient,
    setZones,
    setAddress,
    setGeoLocation,
    setLogisticsCompany,
    setOpenCabinetsByQr,
  })
  const { update } = useUpdateData()
  const { create } = usePostData()

  const handleFormSubmit = async () => {
    const { openCabinetsByQr, ...data } = getValues()
    if (zones.length === 0 || !zones.some((zone) => zone.isPrimary)) {
      showErrorMessage('At least should be 1 zone seletcted')
      return
    }
    if (!id) {
      await create({
        data: {
          ...data,
          ...geoLocation,
          address,
          client: client?._id || null,
          zones,
          ...((type === 'Distribution Point' || type === 'Locker') && {
            openCabinetsByQr,
          }),
          ...(type === 'Distribution Point' && { logisticsCompany }),
        },
      })
    }
    if (id) {
      await update({
        data: {
          ...data,
          id,
          ...geoLocation,
          address,
          client: client?._id || null,
          zones,
          ...((type === 'Distribution Point' || type === 'Locker') && {
            openCabinetsByQr,
          }),
          ...(type === 'Distribution Point' && { logisticsCompany }),
        },
      })
    }

    onSubmit()
  }

  const isShowClientOption = LOCATION_TYPE_CLIENT.includes(type)

  const isShowLogisticsCompanySelect = isAdmin && type === 'Distribution Point'

  const handleAddZone = (newZone) => {
    const isExistZone =
      zones.filter(
        ({ city, district }) =>
          city === newZone.city && district === newZone.district,
      ).length > 0
    if (isExistZone) {
      showWarningMessage(t('locationsRoute').popups.zoneInListWarning)
      return
    }
    setZones((prevState) => {
      if (prevState.length === 0) {
        newZone.isPrimary = true
      }
      return [...prevState, newZone]
    })
  }

  const handleRemoveZone = (zoneId) => {
    setZones((prevState) => {
      const filteredZones = prevState.filter((zone) => zone.zoneId !== zoneId)
      if (filteredZones.length === 0) {
        showWarningMessage('At least should be 1 zone seletcted')
      }
      if (!filteredZones.some((zone) => zone.isPrimary)) {
        showWarningMessage('Please define your primary zone')
      }
      return filteredZones
    })
  }

  const handleSetPrimaryZone = (city, district, isPrimary) => {
    if (isPrimary) {
      return
    }

    setZones((prevState) =>
      prevState.map((zone) => {
        if (
          zone.city === city &&
          zone.district === district &&
          !zone.isPrimary
        ) {
          zone.isPrimary = true
        } else {
          zone.isPrimary = false
        }

        return zone
      }),
    )
  }

  const [locationTypeFrom, setLocationTypeFrom] = useState(
    LOCATION_SELECT_TYPE.AUTOCOMPLETE,
  )

  const handleChangeLocationTypeFrom = (isChecked) => {
    const newType = isChecked
      ? LOCATION_SELECT_TYPE.MAP
      : LOCATION_SELECT_TYPE.AUTOCOMPLETE
    setLocationTypeFrom(newType)
  }

  useEffect(() => {
    if (type === 'Distribution Point' || (type === 'Locker' && !client)) {
      setClient(null)
    }

    if (isAdmin && logisticsCompany && type !== 'Distribution Point') {
      setLogisticsCompany(null)
    }
  }, [client, type])

  useAsync(async () => {
    if (locationTypeFrom === LOCATION_SELECT_TYPE.AUTOCOMPLETE) {
      if (!googlePlace) return
      setAddress(googlePlace?.label || '')

      try {
        const [{ geometry }] = await geocodeByPlaceId(
          googlePlace.value.place_id,
        )
        const { lat, lng } = geometry.location
        setGeoLocation({ lat: lat(), lng: lng() })
      } catch (error) {}
    }
  }, [googlePlace])

  useAsync(async () => {
    if (type === 'Distribution Point' && !logisticCompanies.length) {
      const query = {
        status: 'Active',
      }
      try {
        const { data } = await getLogisticsCompanies(query)
        setLogisticCompanies(data.rows)
      } catch (error) {
        showErrorMessage(JSON.stringify(error))
      }
    }
  }, [type])

  return (
    <div className={s.container}>
      <Header
        title={
          t('locationsRoute').formCreation[id ? 'titleEdit' : 'titleCreate']
        }
      />
      {(isShowForm || !id) && (
        <form className={form}>
          <LocationName register={register({ required: true })} />
          <Controller
            name='type'
            render={(props) => <LocationType {...props} selectRef={typeRef} />}
            control={control}
            rules={{ required: true }}
            onFocus={() => typeRef.current.focus()}
          />
          <Zone
            onChange={handleAddZone}
            selectedZonesAmount={zones.length}
            maxLimits={1}
          />
          {zones.length > 0 && (
            <div className={zoneList}>
              {zones.map(({ zoneId, city, district, isPrimary }) => (
                <div key={zoneId} className={zoneItem}>
                  <Icon
                    icon='tick-circle'
                    color={
                      isPrimary ? variables.shuttleOrange : variables.disabled
                    }
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleSetPrimaryZone(city, district, isPrimary)
                    }
                    iconSize={18}
                  />
                  <div style={{ marginLeft: 10 }}>
                    {district}, {city}{' '}
                  </div>
                  <Icon
                    icon='delete'
                    style={{
                      cursor: 'pointer',
                      marginLeft: 'auto',
                    }}
                    onClick={() => handleRemoveZone(zoneId)}
                  />
                </div>
              ))}
            </div>
          )}
          {zones.length === 0 && (
            <div style={{ color: 'red' }}>
              {t('locationsRoute').formCreation.zoneLimits}
            </div>
          )}

          <div style={{ display: 'flex' }}>
            <h4>{t('toogleAddressBtn').address}</h4>
            <ToogleAddressBtn onChange={handleChangeLocationTypeFrom} />
          </div>

          {locationTypeFrom === LOCATION_SELECT_TYPE.AUTOCOMPLETE && (
            <div className={addressBlock}>
              <div style={{ width: '100%', marginRight: 'auto' }}>
                {!editMode && (
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    selectProps={{
                      value: googlePlace,
                      onChange: setGooglePlace,
                      placeholder:
                        t('locationsRoute').formCreation
                          .addressInputPlaceholder,
                      noOptionsMessage: () =>
                        t('locationsRoute').formCreation.noOptionsMessage,
                    }}
                    debounce={300}
                  />
                )}
                {!!address && editMode && (
                  <>
                    <Icon icon='locate' color={variables.shuttleOrange} />{' '}
                    <span style={{ fontSize: 16 }}>{address}</span>
                  </>
                )}
              </div>
              {!!address && editMode && (
                <Icon
                  className={editBtn}
                  icon='edit'
                  iconSize={25}
                  onClick={() => {
                    setEditMode(false)
                    setAddress('')
                  }}
                />
              )}
              {!!address && (
                <div
                  className={mapBtn}
                  onClick={() =>
                    window.open(
                      `https://www.google.com/maps/search/?api=1&query=${geoLocation.lat},${geoLocation.lng}`,
                      '_blank',
                    )
                  }
                >
                  <Icon icon='send-to-map' color={variables.shuttleWhite} />
                </div>
              )}
            </div>
          )}

          {locationTypeFrom === LOCATION_SELECT_TYPE.MAP && (
            <GMap
              google={null}
              center={geoLocation}
              onChangeGeoCord={setGeoLocation}
              onChangeAddress={(value) => {
                setGooglePlace((prevState) => ({
                  ...prevState,
                  label: value,
                }))
                setAddress(value)
              }}
              height='300px'
              zoom={5}
            />
          )}

          {isShowClientOption && (
            <Clients
              value={client?.name || ''}
              withClient={!!client}
              onChange={setClient}
              withSwitch={true}
              switchProps={{
                label: t('locationsRoute').formCreation.clients.label,
                innerLabel: t('locationsRoute').formCreation.clients.innerLabel,
                innerLabelChecked:
                  t('locationsRoute').formCreation.clients.innerLabelChecked,
              }}
            />
          )}

          {isShowLogisticsCompanySelect && (
            <Select
              className={s.logisticCompany}
              value={logisticsCompany}
              options={logisticCompanies}
              placeholder={
                t('locationsRoute').formCreation.logisticCompany.placeholder
              }
              label={t('locationsRoute').formCreation.logisticCompany.label}
              onChange={setLogisticsCompany}
              Option={(props) => {
                const company = props[0]
                return (
                  <option key={company.name} value={company._id}>
                    {company.name}
                  </option>
                )
              }}
            />
          )}
          {(type === 'Distribution Point' || type === 'Locker') && (
            <div className={qrBox}>
              <h4>{t('locationsRoute').formCreation.qr.label}</h4>
              <Switch
                className={visibleToggleBtn}
                checked={openCabinetsByQr}
                onChange={({ target }) => setOpenCabinetsByQr(target.checked)}
                innerLabelChecked={t('locationsRoute').formCreation.qr.yes}
                innerLabel={t('locationsRoute').formCreation.qr.no}
              />
            </div>
          )}

          <ButtonsGroup
            dissmissProps={{ onGoBack: goBack }}
            actionProps={{
              disabled: zones.length === 0 || !address,
              type: 'button',
              onClick: handleFormSubmit,
            }}
          />
        </form>
      )}

      <Loader showLoader={isLoadingData} loadingText='Load location data ...' />
    </div>
  )
}
