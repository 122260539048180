import React, { useMemo, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Select } from './Select'
import { useSelector } from 'react-redux'
import { selectCities } from '../../../redux/reducers/settings-reducer/cities-reducer'

export const District = memo(
  ({
    selectRef,
    selectedCity = '',
    value = '',
    disabled = true,
    onChange = () => {},
    isRequired,
  }) => {
    const { t } = useTranslation()
    const LOCATIONS = useSelector(selectCities)?.en

    const options = useMemo(() => {
      if (LOCATIONS?.length > 0) {
        const districtOptions = []
        const city = LOCATIONS?.find((el) => el.city === selectedCity)

        if (city) {
          if (value && city.districts.includes(value)) {
            districtOptions.push(...city.districts)
          } else {
            value
              ? districtOptions.push(...city.districts, value)
              : districtOptions.push(...city.districts)
          }
        } else {
          districtOptions.push(value)
        }
        return districtOptions
      }
    }, [selectedCity, value, LOCATIONS])

    return (
      <Select
        selectRef={selectRef}
        value={value}
        options={options}
        placeholder={t('district').placeholder}
        label={t('district').label}
        disabled={disabled}
        onChange={onChange}
        Option={(props) => {
          const name = props[0]
          return (
            <option value={name}>
              {t('locations').districts[name] || name}
            </option>
          )
        }}
        isRequired={isRequired}
      />
    )
  },
)
