import React from 'react'
import { Dialog, Icon } from '@blueprintjs/core'
import { useTranslation } from 'react-i18next'
import info from '../../../assets/images/Info.svg'
import s from './SmartModals.module.scss'

export const DisclaimerDialog = ({ onApprove, onCloseDialog }) => {
  const { t } = useTranslation()

  return (
    <Dialog isOpen={true} canOutsideClickClose={true} className={s.modal}>
      <Icon
        className={s.closeBtn}
        icon='cross'
        iconSize={23}
        onClick={onCloseDialog}
      />
      <img src={info} className='d-block mx-auto mt-2 mb-4' alt='' />
      <h5 id='exampleModalLabel' className='text-center mb-5'>
        {t('trackingPage').orderPage.disclaimer}
      </h5>
      <p className='mb-5'> {t('trackingPage').orderPage.disclaimerText}</p>
      <div className='d-flex justify-content-around align-items-center'>
        <button
          type='button'
          className={`${s.btn} ${s.btnSecondary}`}
          onClick={onCloseDialog}
        >
          {t('trackingPage').orderPage.close}
        </button>
        <button
          type='button'
          className={`${s.btn} ${s.btnPrimary}`}
          onClick={onApprove}
        >
          {t('trackingPage').orderPage.approve}
        </button>
      </div>
    </Dialog>
  )
}
