import React from 'react'
import { Dialog, Icon } from '@blueprintjs/core'
import { useTranslation } from 'react-i18next'
import info from '../../../assets/images/Info.svg'
import s from './SmartModals.module.scss'

export const LockerLocationDialog = ({
  lockerLocation,
  onGoToLocation,
  onCloseDialog,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog isOpen={true} canOutsideClickClose={true} className={s.modal}>
      <Icon
        className={s.closeBtn}
        icon='cross'
        iconSize={23}
        onClick={onCloseDialog}
      />
      <img src={info} className='d-block mx-auto mt-2 mb-4' alt='' />
      <h5 className={s.title}>{t('trackingPage').orderPage.lockerLocation}</h5>
      <p className={s.text}>{t('trackingPage').orderPage.lockerLocationText}</p>
      <div>
        <div className='d-flex justify-content-around align-items-center'>
          <button
            type='button'
            className={`${s.btn} ${s.btnSecondary}`}
            onClick={onCloseDialog}
          >
            {t('trackingPage').orderPage.close}
          </button>
          <a href={lockerLocation} target='_blank' rel='noopener noreferrer'>
            <button
              type='button'
              className={`${s.btn} ${s.btnPrimary}`}
              onClick={onGoToLocation}
            >
              {t('trackingPage').orderPage.goToLocation}
            </button>
          </a>
        </div>
      </div>
    </Dialog>
  )
}
