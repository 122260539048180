import React, { Suspense, lazy, useState } from 'react'
import {
  Route,
  Switch,
  useParams,
  useLocation,
  Redirect,
} from 'react-router-dom'
import { useAsync } from 'react-use'
import moment from 'moment'
import axios from 'axios'

import { trackOrder } from '../../services/api/orders'

import { Loader } from '../reusableComponents'
import { REQUEST_TYPES } from '../../data/constans'

const Header = lazy(() => import('../Header/Header'))

const HomeRoute = lazy(() =>
  import('./HomeRoute' /* webpackChunkName: "HomeRoute" */),
)
const DeliveryNowRoute = lazy(() =>
  import('./DeliveryPartners' /* webpackChunkName: "DeliveryNowRoute" */),
)
const ShgardiRoute = lazy(() =>
  import('./ShgardiRoute' /* webpackChunkName: "ShgardiNowRoute" */),
)
const TrackRoute = lazy(() =>
  import('./TrackRoute' /* webpackChunkName: "TrackRoute" */),
)
const OrderRoute = lazy(() =>
  import('./OrderRoute' /* webpackChunkName: "OrderRoute" */),
)
const ErrorRoute = lazy(() =>
  import('./ErrorRoute' /* webpackChunkName: "ErrorRoute" */),
)
const SuccessRoute = lazy(() =>
  import('./SuccessRoute' /* webpackChunkName: "SuccessRoute" */),
)

const formatForApproxiamteDelivereingTime = 'DD/MM/YYYY - hh:mm a'

const TrackingPage = () => {
  const { guid } = useParams()
  const { pathname } = useLocation()

  const [retrieveUrl, setRetrieveUrl] = useState('')

  const [loading, setLoading] = useState(true)

  const [delivery, setDelivery] = useState(null)
  const [orderNumber, setOrderNumber] = useState('')
  const [orderId, setOrderId] = useState(null)
  const [isReadyForPickup, setIsReadyForPickup] = useState(null)
  const [orderForPuC, setOrderForPuC] = useState(null)
  const [orderStatus, setOrderStatus] = useState(null)
  const [initiateDate, setInitiateDate] = useState(null)
  const [movements, setMovements] = useState([])
  const [receiver, setReceiver] = useState({
    firstName: '',
    lastName: '',
    city: '',
    district: '',
  })
  const [store, setStore] = useState({
    name: '',
    city: '',
    district: '',
  })
  const [locker, setLocker] = useState(null)
  const [pickupDate, setPickupDate] = useState(' - ')
  const [system, setSystem] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  useAsync(async () => {
    delete axios.defaults.headers.common['Authorization']
    if (pathname.includes('success') || pathname.includes('error')) {
      setIsLoading(false)
      setLoading(false)
      return
    }

    localStorage.removeItem('token') //doesn t make sense
    setIsLoading(true)
    const retrievedOrder = await trackOrder({ id: guid })
    if (!retrievedOrder) {
      setLoading(false)
      if (pathname.includes('success') || pathname.includes('error')) return
      window.location.href = `/orders/track/${guid}/order/error`
      return
    }

    setIsLoading(false)

    const {
      _id,
      movements,
      number,
      clientCity,
      clientDistrict,
      clientName,
      receiverCity,
      receiverDistrict,
      receiverFirstName,
      receiverLastName,
      destination,
      request,
      isPaymentUponCollection,
      paymentAmount,
      isPaid,
      paymentError,
    } = retrievedOrder

    setOrderId(_id)

    if (destination) {
      setLocker({
        ...destination.locker,
        lat: destination.lat,
        lng: destination.lng,
        address: destination.address,
        lockerNumber: destination.locker.number,
      })
    }

    const date = moment(movements[0].date).format(
      formatForApproxiamteDelivereingTime,
    )
    const status = movements[movements.length - 1].status

    let approximateDeliveringTime = ' - '

    if (request?.startedAt) {
      const isIntraCity = clientCity === receiverCity
      const isInterCity = !isIntraCity

      const isOrderFromPickupRequest = request.type === REQUEST_TYPES.PICKUP
      const isOrderFromDepositRequest = request.type === REQUEST_TYPES.DEPOSIT

      if (isIntraCity && isOrderFromPickupRequest) {
        const approximateDeliveringDate = addDays(request.startedAt, 1)
        approximateDeliveringTime = moment(approximateDeliveringDate).format(
          formatForApproxiamteDelivereingTime,
        )
      }

      if (isInterCity && isOrderFromPickupRequest) {
        const approximateDeliveringDate = addDays(request.startedAt, 5)
        approximateDeliveringTime = moment(approximateDeliveringDate).format(
          formatForApproxiamteDelivereingTime,
        )
      }

      const isStartedBefore11Am =
        Number(moment(request.startedAt).format('H')) < 11

      if (isIntraCity && isOrderFromDepositRequest) {
        const days = isStartedBefore11Am ? 1 : 2
        const approximateDeliveringDate = addDays(request.startedAt, days)
        approximateDeliveringTime = moment(approximateDeliveringDate).format(
          formatForApproxiamteDelivereingTime,
        )
      }

      if (isInterCity && isOrderFromDepositRequest) {
        const days = isStartedBefore11Am ? 5 : 6
        const approximateDeliveringDate = addDays(request.startedAt, days)
        approximateDeliveringTime = moment(approximateDeliveringDate).format(
          formatForApproxiamteDelivereingTime,
        )
      }
    }

    setRetrieveUrl(retrievedOrder.retrieveUrl)
    setPickupDate(approximateDeliveringTime)
    setInitiateDate(date)
    setMovements(movements)
    setOrderNumber(number)
    setOrderForPuC({
      isPaymentUponCollection,
      paymentAmount,
      isPaid,
      paymentError,
    })
    setOrderStatus(status)
    setIsReadyForPickup(status === 'Ready for pick-up')
    retrievedOrder.delivery && setDelivery(retrievedOrder.delivery)
    setStore({
      name: clientName,
      city: clientCity,
      district: clientDistrict,
    })
    setReceiver({
      firstName: receiverFirstName,
      lastName: receiverLastName,
      city: receiverCity,
      district: receiverDistrict,
    })
    if (retrievedOrder?.system) {
      setSystem(retrievedOrder.system)
    }
    setLoading(false)
  }, [])

  const addDays = (date, days) => {
    var result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
  }

  if (loading) return <Loader showLoader={true} />

  return (
    <div>
      <Header withUserMenu={false} />
      <Suspense fallback={<Loader showLoader={true} />}>
        <Switch>
          <Route
            exact
            path='/orders/track/:guid/'
            render={() => (
              <HomeRoute
                initiateDate={initiateDate}
                orderNumber={orderNumber}
                orderStatus={orderStatus}
                receiver={receiver}
                lockerNumber={locker?.lockerNumber}
                store={store}
                pickupDate={pickupDate}
                isReadyForPickup={isReadyForPickup}
                isDeliveryRequested={
                  orderStatus === 'Delivery requested' || delivery
                }
                delivery={delivery}
                system={system}
              />
            )}
          />
          <Route
            exact
            path='/orders/track/:guid/track'
            render={() => (
              <TrackRoute
                isReadyForPickup={isReadyForPickup}
                orderStatus={orderStatus}
                movements={movements}
                currentRequestT={''}
                nextRequestT={''}
                orderNumber={orderNumber}
                delivery={delivery}
                system={system}
              />
            )}
          />
          <Route
            exact
            path='/orders/track/:guid/order'
            render={() => (
              <OrderRoute
                isReadyForPickup={isReadyForPickup}
                lockerLat={locker?.lat}
                lockerLng={locker?.lng}
                lockerDescription={locker?.description}
                lockerPhoto={locker?.photo}
                retrieveUrl={retrieveUrl}
                lockerNumber={locker?.lockerNumber}
                orderId={orderId}
                orderNumber={orderNumber}
                orderForPuC={orderForPuC}
                openCabinetsByQr={locker?.openCabinetsByQr}
                lockerDataForQrCode={locker?.lockerDataForQrCode}
                system={system}
              />
            )}
          />
          {/* <Route
            exact
            path='/orders/track/:guid/delivery'
            render={() => <DeliveryNowRoute />}
          /> */}
          {/* <Route
            exact
            path='/orders/track/:guid/delivery/shgardi'
            render={() => <ShgardiRoute orderId={orderId} />}
          /> */}
          <Route
            exact
            path='/orders/track/:guid/order/error'
            render={() => <ErrorRoute />}
          />
          <Route
            exact
            path='/orders/track/:guid/order/success/:orderId/:orderNumber'
            render={() => <SuccessRoute />}
          />
          <Redirect to='/orders/track/:guid' />
        </Switch>
      </Suspense>
    </div>
  )
}

export default TrackingPage
