import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { shuttleApi } from '../services/api'
import { useDispatch } from 'react-redux'
import authReducer from './reducers/auth-reducer'
import settingsReducer from './reducers/settings-reducer/cities-reducer'
import notificationsReducers from './reducers/notification-reducer'

export const store = configureStore({
  reducer: {
    [shuttleApi.reducerPath]: shuttleApi.reducer,
    auth: authReducer,
    settings: settingsReducer,
    notifications: notificationsReducers,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    shuttleApi.middleware,
  ],
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
