import React, { memo } from 'react'
import { Icon } from '@blueprintjs/core'
import variables from '../../../../../../styles/variables'
import s from './OrderList.module.scss'

const ListItem = ({
  _id,
  number,
  receiverCity,
  receiverDistrict,
  receiverFirstName,
  receiverLastName,
  onRemove,
  isOpenOnboarding,
}) => {
  return (
    <div className={s.listItem}>
      <div className={s.orderNumber}>{number}</div>
      <div className={s.orderReceiver}>
        {receiverFirstName} {receiverLastName.charAt(0)}.
      </div>
      <div className={s.orderLocation}>
        {receiverDistrict}, {receiverCity}
      </div>
      {!isOpenOnboarding && (
        <Icon
          icon='delete'
          color={variables.shuttle}
          iconSize={20}
          className={s.removeOrderBtn}
          onClick={() => onRemove(_id)}
        />
      )}
    </div>
  )
}

export default memo(ListItem)
