import React, { useState, useEffect, memo } from 'react'
import classnames from 'classnames'
import { Switch, Dialog, Icon } from '@blueprintjs/core'
import { useTranslation } from 'react-i18next'
import { TextInput, Button, Loader, Search } from '../'
import { useUserRole, useDialogService } from '../../../hooks'
import { getClients } from '../../../services/api/clients.js'

import s from './Clients.module.scss'

export const Clients = memo(
  ({
    value = '',
    withSwitch = false,
    withClient = false,
    withRemoveIcon = false,
    className = '',
    switchProps = {},
    onChange = () => {},
    onChangeWithClient = () => {},
    onRemove = () => {},
    labelStyle = {},
  }) => {
    const { isAdmin, isClient } = useUserRole()

    if (isClient) return null

    if (isAdmin) {
      const { t } = useTranslation()

      const [isClient, setIsClient] = useState(false)
      const {
        ref,
        isOpen,
        items,
        hasNextPage,
        pageNumber,
        isLoading,
        searchField,
        debounceSearchField,
        setSearchField,
        toggleModal,
      } = useDialogService(getClients, buildPayalod)

      function buildPayalod() {
        const payload = {
          query: {
            status: 'Active',
            name: { $regex: `.*${debounceSearchField}`, $options: 'i' },
          },
          page: pageNumber,
        }
        return payload
      }

      const handleSetItem = (item) => {
        onChange(item)
        toggleModal()
      }

      const handleToogleIsClient = ({ target }) => {
        const checked = target.checked
        if (!checked) {
          onChange(null)
        }
        onChangeWithClient(checked)
        setIsClient(checked)
      }

      useEffect(() => {
        setIsClient(withClient)
      }, [withClient])

      const isShowRemoveIcon = withRemoveIcon && !!value

      const isShowClientButtonOpenModalAndClientField = withSwitch
        ? isClient
        : true

      return (
        <div className={classnames(s.clientSelect, className)}>
          {withSwitch && (
            <Switch
              checked={isClient}
              className={s.switchClientBtn}
              onChange={handleToogleIsClient}
              {...switchProps}
            />
          )}
          {isShowClientButtonOpenModalAndClientField && (
            <>
              <Button
                text={t('modals').clients.btnOpenModalLabel}
                onClick={toggleModal}
              />

              <TextInput
                disabled={true}
                label={t('modals').clients.label}
                placeholder={t('modals').clients.placeholder}
                style={{ marginTop: 10, ...labelStyle }}
                props={{ value }}
              />

              {isShowRemoveIcon && (
                <Icon
                  className={s.removeIcon}
                  onClick={onRemove}
                  icon='cross'
                  iconSize='14'
                />
              )}

              <Dialog
                isOpen={isOpen}
                className={s.modal}
                canOutsideClickClose={true}
              >
                <header className={s.modalHeader}>
                  {t('modals').clients.title}
                </header>
                <Search
                  value={searchField}
                  onChange={setSearchField}
                  placeholder={
                    t('locationsRoute').filters.searchByNamePlaceholder
                  }
                  style={{ width: 200 }}
                  autoFocus={true}
                />
                <div className={s.itemsList}>
                  <Loader
                    showLoader={isLoading}
                    className={s.spinner}
                    size={70}
                  />
                  {items.map((item) => (
                    <div
                      ref={ref}
                      key={item._id}
                      className={s.listItem}
                      onClick={() => handleSetItem(item)}
                    >
                      {item.name}
                    </div>
                  ))}
                  {!hasNextPage && (
                    <div className={s.fullLoadedLabel}>
                      {t('modals').clients.allHitsLoaded}
                    </div>
                  )}
                </div>

                <Button
                  text={t('modals').clients.cancelBtnLabel}
                  onClick={toggleModal}
                />
              </Dialog>
            </>
          )}
        </div>
      )
    }
  },
)
