import axios from 'axios'
import qs from 'qs'
import i18n from 'i18next'

import { showSuccessMessage, showErrorMessage } from '../../modules/Toasters'

import { refreshPageIfTokenWasExpired } from '../api.ts'

export const getUsers = async ({
  status,
  client,
  filters = [],
  filtersOr = [],
  page = 1,
  isClient,
  pageSize = 10,
}) => {
  if (isClient && !client) return
  const initialQueries = { $and: [{ status }, { client }] }
  let query
  if (filters && !!filters.length) {
    query = {
      $and: [...initialQueries.$and, ...filters],
      ...(filtersOr.length && { $or: filtersOr }),
    }
  } else {
    query = initialQueries
  }

  try {
    const { data } = await axios.get(`users?page=${page}`, {
      params: { query, sort: '-_id', pageSize },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })
    return data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const getUserById = async ({ id }) => {
  try {
    const { data } = await axios.get(`/users/${id}`)
    return data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const createClientUser = async ({ data }) => {
  try {
    const response = await axios.post('/user/client', data)
    return response.data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const createAdminUser = async ({ data }) => {
  try {
    await axios.post('/user/admin', data)
    return true
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const updateClientUser = async ({ data }) => {
  try {
    await axios.put('/user/client', data)
    showSuccessMessage(i18n.t('services').users.updateSucces)
    return true
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const updateAdminUser = async ({ data }) => {
  try {
    const item = await axios.put('/user/admin', data)
    showSuccessMessage(i18n.t('services').users.updateSucces)
    return item.data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const resetClientPassword = async ({ data }) => {
  try {
    await axios.post('/user/password/reset', data)
    showSuccessMessage(i18n.t('services').users.resetSuccess)
    showSuccessMessage(i18n.t('services').users.emailNotify)
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const changeUserStatus = async ({ data }) => {
  try {
    await axios.put('/user/status', data)
    document.location.reload()
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const inactivateAllUserStatuses = async ({ data }) => {
  try {
    await axios.patch('/users/status', data)
    document.location.reload()
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const verifyUserEmailByAdmin = async (paylaod) => {
  try {
    await axios.post('/user/admin/email/verify', paylaod)
    document.location.reload()
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const changeProviderStatus = async (paylaod) => {
  return await axios.put('user/provider/status', paylaod)
}
