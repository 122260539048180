import React from 'react'
import { useTranslation } from 'react-i18next'

import s from './styles.module.scss'

import learnIcon from '../../../../assets/images/LearnIcon.svg'

export const UsingShuttle = () => {
  const { t, i18n } = useTranslation()
  return (
    <div className={s.cards} data-toggle='modal' data-target='#exampleModal'>
      <img className='ml-1' src={learnIcon} alt='' />
      <div
        className='my-auto ml-2'
        style={
          i18n.language === 'ar'
            ? { marginRight: '6px', marginLeft: '0px' }
            : {}
        }
      >
        <p className={s.subtitle}>
          {t('trackingPage').homePage.optionCards.useShuttle1}
        </p>
        <p className={s.title}>
          {t('trackingPage').homePage.optionCards.useShuttle2}
        </p>
      </div>{' '}
    </div>
  )
}
