import axios from 'axios'
import qs from 'qs'
import fileDownload from 'js-file-download'
import i18n from 'i18next'

import { showSuccessMessage, showErrorMessage } from '../../modules/Toasters'

import { refreshPageIfTokenWasExpired } from '../api.ts'

export const getOrders = (params, signal) => {
  return axios.get('orders', {
    signal,
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const getOrdersAWB = async (params) => {
  try {
    const { data } = await axios.get('/orders', {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })
    return data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const getOrderId = async ({ id }) => {
  try {
    const { data } = await axios.get(`/orders/${id}`)
    return data
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
  }
}

export const getOrdersById = async ({ ids }) => {
  try {
    const promises = ids.map(async (id) => {
      const { data } = await axios.get(`/orders/${id}`)
      return data
    })

    const result = await Promise.all(promises)
    return result
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const getOrderSuggestById = async ({ number }) => {
  const text = number.split(' ').join('.')
  try {
    const { data } = await axios.get('/orders', {
      params: {
        query: {
          number: {
            $regex: `.*${text}`,
            $options: 'i',
          },
          status: 'Initiated',
        },
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })
    return data.rows
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
  }
}

export const removeOrder = async ({ id }) => {
  try {
    await axios.delete(`/orders/${id}`)
    showSuccessMessage(i18n.t('services').orders.orderRemoveSuccess)
    document.location.reload()
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
  }
}

export const markOrderAsDelivered = async ({ id }) => {
  try {
    await axios.post(`/orders/finalize`, { orderIds: [id] })
    showSuccessMessage(i18n.t('services').orders.orderRemoveSuccess)
    document.location.reload()
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
  }
}

export const markOrderAsLost = async ({ id }) => {
  try {
    await axios.post(`/orders/lost`, { orderIds: [id] })
    //showSuccessMessage(i18n.t('services').orders.orderRemoveSuccess)
    document.location.reload()
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
  }
}
export const trackOrder = async ({ id }) => {
  try {
    const { data } = await axios.get(`/orders/track/${id}`)
    return data
  } catch (error) {
    return false
    // refreshPageIfTokenWasExpired(error.response.data.data)
  }
}

export const exportOrders = async () => {
  try {
    const { data } = await axios.get('/orders?export=true')
    fileDownload(data, 'Orders.csv')
    return data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const importOrders = async ({ data }) => {
  try {
    await axios.post('/orders/import', data, {
      headers: { 'Content-type': 'multipart/form-data;' },
    })
    showSuccessMessage(i18n.t('services').orders.importSuccess)
    return true
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
    return false
  }
}

export const createClientOrder = async ({ data }) => {
  try {
    const response = await axios.post('/order', data)
    return response.data
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
  }
}

export const createAdminOrder = async ({ data }) => {
  try {
    const response = await axios.post('/order/admin', data)
    return response.data
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
  }
}

export const updateClientOrder = async ({ data }) => {
  try {
    const response = await axios.put('/order', data)
    return response.data
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
  }
}

export const updateAdminOrder = async ({ data }) => {
  try {
    const response = await axios.put('/order/admin', data)
    return response.data
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
  }
}

export const getAvaliableOrdersForRoute = async ({ routeId, tripId }) => {
  let url = `/orders/route?routeId=${routeId}`
  if (tripId) {
    url += `&tripId=${tripId}`
  }
  try {
    const response = await axios.get(url)
    return response.data
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
  }
}

export const getAvaliableOrdersForLocation = async ({ data, tripId }) => {
  let url = '/orders/locations'
  if (tripId) {
    url += `?tripId=${tripId}`
  }
  try {
    const response = await axios.post(url, data)
    return response.data
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
  }
}

export const resendSms = async ({ id }) => {
  try {
    const { data } = await axios.get(`/orders/track/sms/${id}`)
    return data
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
  }
}

export const postPaymentOrder = async (forPayment) => {
  try {
    const data = await axios.post(`/payment`, forPayment)
    return data
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
    return []
  }
}

export const getOrdersForLocker = async ({ locker, cabinet }) => {
  try {
    const { data } = await axios.get(
      `/locker/orders?lockerNumber=${locker}&cabinetNumber=${cabinet}`,
    )
    return data
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
    return []
  }
}

export const getOrdersForStatisticsJournal = async ({ ...data }) => {
  try {
    const res = await axios.post('/analytics/orders', data)
    if ('export' in data) return res.data

    const hasMore = res.data.page < res.data.totalPages
    return { ...res.data, hasMore }
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
    return []
  }
}

export const returnDeliveredOrder = async (payload) => {
  try {
    await axios.post('/order/return/delivered', payload)
    showSuccessMessage('Order was returned')
    setTimeout(() => {
      window.location.reload()
    }, 1500)
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const changeOrderStatus = async (payload) => {
  try {
    await axios.put('/order/status', payload)
    showSuccessMessage('Order was cancelled')
    setTimeout(() => {
      window.location.reload()
    }, 1500)
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const cancelOrder = async ({ orderId }) => {
  try {
    await axios.delete(`/order/cancel/${orderId}`)
    showSuccessMessage('Order was cancelled')
    setTimeout(() => {
      window.location.reload()
    }, 1500)
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const updateOrderLocation = async ({ orderId, payload }) => {
  try {
    await axios.put(`/order/${orderId}/location`, payload)
    showSuccessMessage('Order location was updated')
    setTimeout(() => {
      window.location.reload()
    }, 1500)
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const updateTrackingLinks = async (payload) => {
  try {
    await axios.post('orders/track/update', payload)
    showSuccessMessage('')
    return true
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}
