import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { Dialog } from '@blueprintjs/core'
import BarcodeScannerComponent from 'react-qr-barcode-scanner'
import { Button } from '../../../reusableComponents'
import { BackBtn } from '../BackBtn/BackBtn'
import { EnterShipmentAWB } from '../EnterShipmentAWB/EnterShipmentAWB'
import { openCabinet } from '../../../../services/api/lockers'
import {
  verifyShipment,
  depositShipment,
} from '../../../../services/api/integrations'
import s from './DepositScreen.module.scss'

export const DepositScreen: React.FC = ({
  locker,
  setAction,
}: any): JSX.Element => {
  const { t }: any = useTranslation()

  const [shipmentAWB, setShipmentAWB] = useState('')
  const [isScanShipmentAWB, setIsScanShipmentAWB] = useState(false)
  const [isVerifyShipmentAWB, setIsVerifyShipmentAWB] = useState(false)
  const [isEditShipmentAWB, setIsEditShipmentAWB] = useState(true)
  const [verifiedShipmentAWB, setVerifiedShipmentAWB] = useState(null)
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [isOpenDepositDialog, setIsOpenDepositDialog] = useState(false)
  const [selectedSize, selectSize] = useState('')
  const [cabinetOpening, setOpeningCabinet] = useState(false)

  const verifyShipmentAWB = async () => {
    const payload = { awb: shipmentAWB }
    const verifiedData = await verifyShipment(payload)
    if (verifiedData) {
      setIsScanShipmentAWB(false)
      setIsVerifyShipmentAWB(false)
      setVerifiedShipmentAWB(verifiedData)
    }
  }

  const handleToogleConfirmModal = () => {
    setIsOpenDialog((prevState) => !prevState)
  }

  const handleClickScan = () => {
    setShipmentAWB('')
    setIsVerifyShipmentAWB(false)
    setIsScanShipmentAWB(true)
    setIsEditShipmentAWB(true)
  }

  const handleClickEnter = () => {
    setShipmentAWB('')
    setIsEditShipmentAWB(false)
    setIsVerifyShipmentAWB(true)
    setIsScanShipmentAWB(false)
    setIsScanShipmentAWB(false)
  }

  const openCabinetWithSelectedSize = async () => {
    setOpeningCabinet(true)
    const cabinetBySeletedSize = locker.cabinets.find(
      (cabinet) => cabinet.status === 'Free' && cabinet.size === selectedSize,
    )
    try {
      const open = await openCabinet({
        data: {
          lockerNumber: locker.number,
          cabinetNumber: cabinetBySeletedSize.number,
        },
      })
      if (open) {
        setIsOpenDepositDialog(true)
      }
    } catch (error) {
      selectSize('')
    } finally {
      setOpeningCabinet(false)
      handleToogleConfirmModal()
    }
  }

  const onDeposit = async () => {
    const payload = {
      awb: shipmentAWB,
      shipmentSize: selectedSize,
    }
    await depositShipment(payload)
    setVerifiedShipmentAWB(null)
    setShipmentAWB('')
    setIsOpenDepositDialog(false)
  }

  const sizes = locker.sizes.filter((size) =>
    size.cabinets.some((cabinet) => cabinet.status === 'Free'),
  )

  useEffect(() => {
    if (isScanShipmentAWB && shipmentAWB) {
      verifyShipmentAWB()
    }
  }, [isScanShipmentAWB, shipmentAWB])

  return (
    <div className={s.container}>
      <BackBtn setAction={setAction} />
      <h5 className={s.title}>Deposit shipment</h5>
      <div className={s.shipmentVerifyContainer}>
        {!verifiedShipmentAWB && (
          <>
            <p>Scan or Enter AWB Shipment</p>
            <Button
              withIcon={true}
              iconProps={{ icon: 'barcode', iconSize: 20 }}
              className={`${s.scanBtn} ${isScanShipmentAWB && s.btnActive}`}
              onClick={handleClickScan}
            />
            <Button
              withIcon={true}
              iconProps={{ icon: 'manually-entered-data', iconSize: 20 }}
              className={`${s.scanBtn} ${!isEditShipmentAWB && s.btnActive}`}
              onClick={handleClickEnter}
            />
            {isScanShipmentAWB && !shipmentAWB && (
              <BarcodeScannerComponent
                width={320}
                height={320}
                onUpdate={(err, result) => {
                  if (result) {
                    setShipmentAWB(result.text)
                  }
                }}
              />
            )}
            {isVerifyShipmentAWB && (
              <EnterShipmentAWB
                isActiveEdit={isEditShipmentAWB}
                onVerifyShipmentAWB={verifyShipmentAWB}
                shipmentAWB={shipmentAWB}
                setShipmentAWB={setShipmentAWB}
              />
            )}
          </>
        )}
      </div>

      {verifiedShipmentAWB && (
        <div className={s.sizesContainer}>
          <div className={s.header}>
            Select size for {verifiedShipmentAWB.awb}
          </div>
          <div className={s.sizeList}>
            {sizes.map((size) => {
              const sizeName = size.size
              return (
                <div
                  onClick={() => selectSize(sizeName)}
                  className={cn(
                    s.sizeItem,
                    selectedSize === sizeName && s.selected,
                  )}
                  key={sizeName}
                >
                  {sizeName}
                </div>
              )
            })}
          </div>

          {selectedSize && (
            <div className={s.openBtn}>
              <Button
                {...{
                  text: t('providerRoute').trip.view.openBtnText,
                  onClick: () => handleToogleConfirmModal(),
                  disabled: cabinetOpening,
                }}
              />
            </div>
          )}
        </div>
      )}

      <Dialog
        isOpen={isOpenDialog}
        style={{
          padding: 20,
        }}
      >
        <h2 style={{ textAlign: 'center' }}>
          Are you want to open {selectSize} cabinet?
        </h2>

        <div
          style={{
            display: 'flex',
            marginTop: 10,
            justifyContent: 'center',
          }}
        >
          <Button
            {...{
              text: t('providerRoute').trip.modals.confirmDropLastStep
                .yesBtnText,
              onClick: openCabinetWithSelectedSize,
              style: { marginRight: 20 },
              disabled: cabinetOpening,
            }}
          />

          <Button
            {...{
              classNameType: 'dismiss',
              text: t('providerRoute').trip.modals.confirmDropLastStep
                .notBtnText,
              disabled: cabinetOpening,
              onClick: () => {
                handleToogleConfirmModal()
              },
            }}
          />
        </div>
      </Dialog>

      <Dialog
        isOpen={isOpenDepositDialog && !cabinetOpening}
        style={{
          padding: 20,
        }}
      >
        <h2 style={{ textAlign: 'center' }}>
          Are you want to deposit order in cabinet?
        </h2>

        <div
          style={{
            display: 'flex',
            marginTop: 10,
            justifyContent: 'center',
          }}
        >
          <Button
            {...{
              text: t('providerRoute').trip.modals.confirmDropLastStep
                .yesBtnText,
              onClick: onDeposit,
              style: { marginRight: 20 },
            }}
          />

          <Button
            {...{
              classNameType: 'dismiss',
              text: t('providerRoute').trip.modals.confirmDropLastStep
                .notBtnText,
              onClick: () => {
                setIsOpenDepositDialog(false)
              },
            }}
          />
        </div>
      </Dialog>
    </div>
  )
}
