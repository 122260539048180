import React, { useEffect, useState } from 'react'
import { components, useTour } from '@reactour/tour'
import { ProgressBar } from '@blueprintjs/core'
import i18n from 'i18next'
import { Button } from '../../reusableComponents'
import connection from './connection.png'
import welcomeImg from './welcome.png'
import variables from '../../../styles/variables'
import s from './StepsContent.module.scss'

export const WelcomeStep = () => {
  return (
    <div className={s.welcome}>
      <div className={s.logo}>
        <img src={welcomeImg} alt='welcome' />
      </div>
      <h2 className={s.welcomeTitle}>
        <span className={s.welcomeAccent}>
          {i18n.t('onboarding').steps.welcomeStep.title.welcome}
        </span>
        {i18n.t('onboarding').steps.welcomeStep.title.shttle}
      </h2>
      <p>{i18n.t('onboarding').steps.welcomeStep.text}</p>
    </div>
  )
}

export const Step1Title = () => {
  return <p className={s.stepTitle}>{i18n.t('onboarding').titles.title1}</p>
}

export const Step2Title = () => {
  return <p className={s.stepTitle}>{i18n.t('onboarding').titles.title2}</p>
}

export const Step3Title = () => {
  return <p className={s.stepTitle}>{i18n.t('onboarding').titles.title3}</p>
}

export const Step4Title = () => {
  return <p className={s.stepTitle}>{i18n.t('onboarding').titles.title4}</p>
}

export const Step5Title = () => {
  return <p className={s.stepTitle}>{i18n.t('onboarding').titles.title5}</p>
}

export const Step6Title = () => {
  return <p className={s.stepTitle}>{i18n.t('onboarding').titles.title6}</p>
}

export const Step7Title = () => {
  return <p className={s.stepTitle}>{i18n.t('onboarding').titles.title7}</p>
}

export const Step8Title = () => {
  return <p className={s.stepTitle}>{i18n.t('onboarding').titles.title8}</p>
}

export const Step9Title = () => {
  return <p className={s.stepTitle}>{i18n.t('onboarding').titles.title8}</p>
}

export const Step1 = () => {
  return (
    <div className={s.step}>
      <Step1Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step1}</p>
    </div>
  )
}

export const Step2 = () => {
  return (
    <div className={s.step}>
      <Step1Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step2}</p>
    </div>
  )
}

export const Step3 = () => {
  return (
    <div className={s.step}>
      <Step2Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step3}</p>
    </div>
  )
}

export const Step4 = () => {
  return (
    <div className={s.step}>
      <Step3Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step4}</p>
    </div>
  )
}

export const Step5 = () => {
  return (
    <div className={s.step}>
      <Step3Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step5}</p>
    </div>
  )
}

export const Step6 = () => {
  return (
    <div className={s.step}>
      <Step3Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step6}</p>
    </div>
  )
}

export const Step7 = () => {
  return (
    <div className={s.step}>
      <Step4Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step7}</p>
    </div>
  )
}

export const Step8 = () => {
  return (
    <div className={s.step}>
      <Step4Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step8}</p>
    </div>
  )
}

export const Step9 = () => {
  return (
    <div className={s.step}>
      <Step5Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step9}</p>
    </div>
  )
}

export const Step10 = () => {
  return (
    <div className={s.step}>
      <Step5Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step10}</p>
    </div>
  )
}

export const Step11 = () => {
  return (
    <div className={s.step}>
      <Step5Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step11}</p>
    </div>
  )
}

export const Step12 = () => {
  return (
    <div className={s.step}>
      <Step5Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step12}</p>
    </div>
  )
}

export const Step13 = () => {
  return (
    <div className={s.step}>
      <Step5Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step13}</p>
    </div>
  )
}

export const Step14 = () => {
  return (
    <div className={s.step}>
      <Step5Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step14}</p>
    </div>
  )
}

export const Step15 = () => {
  return (
    <div className={s.step}>
      <Step6Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step15}</p>
    </div>
  )
}

export const Step16 = () => {
  return (
    <div className={s.step}>
      <Step6Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step16}</p>
    </div>
  )
}

export const Step17 = () => {
  return (
    <div className={s.step}>
      <Step7Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step17.title}</p>
      <ol style={{ paddingLeft: '10px' }}>
        <li>
          <p className={s.stepContent}>
            {i18n.t('onboarding').steps.step17.item1}
          </p>
        </li>
        <li>
          <p className={s.stepContent}>
            {i18n.t('onboarding').steps.step17.item2}
          </p>
        </li>
      </ol>
    </div>
  )
}

export const CongratulationsDepositStep = () => {
  return (
    <div className={s.welcome}>
      <div className={s.logo}>
        <img src={welcomeImg} alt='congratulations' />
      </div>

      <h2 className={s.welcomeTitle}>
        {i18n.t('onboarding').steps.congratulationsDepositStep.title}
      </h2>
      <p>{i18n.t('onboarding').steps.congratulationsDepositStep.text}</p>
    </div>
  )
}

export const Step19 = () => {
  return (
    <div className={s.step}>
      <Step8Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step19}</p>
    </div>
  )
}

export const Step20 = () => {
  return (
    <div className={s.step}>
      <Step8Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step20}</p>
    </div>
  )
}

export const Step21 = () => {
  return (
    <div className={s.step}>
      <Step9Title />
      <p className={s.stepContent}>{i18n.t('onboarding').steps.step21}</p>
    </div>
  )
}

export const CongratulationsPickupStep = () => {
  return (
    <div className={s.welcome}>
      <div className={s.logo}>
        <img src={welcomeImg} alt='congratulations' />
      </div>

      <h2 className={s.welcomeTitle}>
        {i18n.t('onboarding').steps.congratulationsPickupStep.title}
      </h2>
      <p>{i18n.t('onboarding').steps.congratulationsPickupStep.text}</p>
    </div>
  )
}

export const Badge = () => {
  const { currentStep } = useTour()
  const styleRightTop = {
    backgroundColor: 'transparent',
    opacity: 1,
    position: 'absolute',
    width: '50px',
    height: '50px',
    top: '-20px',
    left: '-65px',
  }
  const styleLeftTop = {
    backgroundColor: 'transparent',
    opacity: 1,
    position: 'absolute',
    width: '50px',
    height: '50px',
    top: '-20px',
    right: '-70px',
  }
  const styleRightDown = {
    backgroundColor: 'transparent',
    opacity: 1,
    position: 'absolute',
    width: '50px',
    height: '50px',
    right: '-70px',
    bottom: '-20px',
  }
  const styleTopLeft = {
    backgroundColor: 'transparent',
    opacity: 1,
    position: 'absolute',
    width: '50px',
    height: '50px',
    top: '-60px',
    left: 0,
  }

  return (
    <components.Badge
      styles={{
        badge: () => ({
          ...(((currentStep === 1 || currentStep === 3 || currentStep === 16) &&
            styleRightTop) ||
            ((currentStep === 2 ||
              currentStep === 4 ||
              currentStep === 7 ||
              currentStep === 15 ||
              currentStep === 17 ||
              currentStep === 22) &&
              styleLeftTop) ||
            ((currentStep === 5 || currentStep === 8) && styleRightDown) ||
            ((currentStep === 6 ||
              currentStep === 9 ||
              currentStep === 10 ||
              currentStep === 13 ||
              currentStep === 14 ||
              currentStep === 20 ||
              currentStep === 21 ||
              currentStep === 19) &&
              styleTopLeft)),
        }),
      }}
    >
      <img src={connection} alt='connection' className={s.icon} />
    </components.Badge>
  )
}

export const Navigation = ({
  currentStep,
  setCurrentStep,
  setIsOpen,
  steps,
}) => {
  const [progress, setProgress] = useState(0)

  const handleClickStart = async () => {
    setCurrentStep((prev) => prev + 1)
  }

  useEffect(() => {
    setProgress(((currentStep + 1) / steps.length).toFixed(1))
  }, [currentStep])

  return (
    <>
      {currentStep === 15 && (
        <Button
          className={s.acknolwedge}
          onClick={handleClickStart}
          text={i18n.t('onboarding').buttons.acknolwedge}
        />
      )}
      <div className={s.navigation}>
        {currentStep > 0 && currentStep !== 18 && currentStep !== 23 && (
          <div className={s.dots}>
            <ProgressBar
              animate={false}
              intent={variables.shuttleWhite}
              value={currentStep !== 0 ? progress : null}
              stripes={false}
            />
          </div>
        )}

        {currentStep === 0 && (
          <Button
            className={s.start}
            onClick={handleClickStart}
            text={i18n.t('onboarding').buttons.start}
          />
        )}

        {currentStep === 17 || currentStep === 22 ? (
          <Button
            className={`${s.cancel} ${s.end}`}
            onClick={handleClickStart}
            text={i18n.t('onboarding').buttons.end}
          />
        ) : (
          <button
            onClick={() => setIsOpen(false)}
            className={`
            ${currentStep === 0 && s.cancel} ${
              (currentStep === 18 || currentStep === 23) && s.ok
            } ${
              currentStep !== 0 &&
              currentStep !== 18 &&
              currentStep !== 17 &&
              currentStep !== 23 &&
              s.skip
            }`}
          >
            {currentStep === 0 && i18n.t('onboarding').buttons.later}
            {(currentStep === 18 || currentStep === 23) &&
              i18n.t('onboarding').buttons.ok}
            {currentStep !== 0 &&
              currentStep !== 18 &&
              currentStep !== 23 &&
              currentStep !== 17 &&
              i18n.t('onboarding').buttons.skip}
          </button>
        )}
      </div>
    </>
  )
}
