import { useMemo } from 'react'
import { useAuth } from './useAuth.ts'

import { ROLE } from '../data/constans'

const useUserRole = () => {
  const { user } = useAuth()

  return useMemo(
    () => ({
      isAdmin: user?.role ? user.role === ROLE.ADMIN : null,
      isClient: user?.role ? user.role === ROLE.CLIENT : null,
      isProvider: user?.role ? user.role === ROLE.PROVIDER : null,
      isTenant: user?.role ? user.role === 'Tenant' : null,
    }),
    [user],
  )
}

export { useUserRole }
