import axios from 'axios'
import qs from 'qs'
import i18n from 'i18next'

import { showSuccessMessage, showErrorMessage } from '../../modules/Toasters'

import { refreshPageIfTokenWasExpired } from '../api.ts'

export const getLockerById = async ({ id }) => {
  try {
    const { data } = await axios.get(`/lockers/${id}`)
    return data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const getLockerByNumber = async ({ number }) => {
  try {
    const { data } = await axios.get(`/lockers/number/${number}`)
    return data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const createLocker = async ({ data }) => {
  try {
    await axios.post('/locker', data)
    return true
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const getLockers = async ({
  status = 'Active',
  pageSize = 100,
  page = 1,
  filters = {},
}) => {
  const initialQueries = {
    $and: [{ status }],
  }
  let query
  if (!!filters.length && Array.isArray(filters)) {
    query = {
      $and: [
        ...initialQueries.$and,
        ...filters.filter((el) => {
          if ('name' in el) {
            return false
          } else {
            return true
          }
        }),
      ],
    }
    const isNameProp = filters.filter((filter) => 'name' in filter).length > 0
    if (isNameProp) {
      const { name } = filters.find((filter) => 'name' in filter)
      query.$and[0].name = {
        $regex: `.*${name}`,
        $options: 'i',
      }
    }
  } else {
    query = initialQueries
  }

  try {
    const { data } = await axios.get('/lockers', {
      params: { query, sort: '-_id', pageSize, page },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })

    const hasMore = data.page < data.totalPages
    return { ...data, hasMore }
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return []
  }
}

export const getLockerByLocation = async ({ location }) => {
  try {
    const { data } = await axios.get('/lockers', {
      params: {
        query: { $and: [{ status: 'Active', location }] },
        sort: '-_id',
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })
    return data.rows
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return []
  }
}

export const changeLockerStatus = async ({ data }) => {
  try {
    await axios.post('/locker/status', data)
    window.location.reload()
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const updateLocker = async ({ data }) => {
  try {
    await axios.put('/locker', data)
    return true
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const changeCabinetStatus = async ({ data }) => {
  try {
    const res = await axios.post('/locker/cabinet/status', data)
    showSuccessMessage(i18n.t('services').lockers.statusCabinetChangeSuccess)
    return res.data.status
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const openCabinet = async ({ data }) => {
  try {
    await axios.post('/locker/cabinet/open', data)
    showSuccessMessage(i18n.t('services').lockers.openCabinetSuccess)
    return true
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const openLocker = async ({ data }) => {
  try {
    await axios.post('/locker/open', data)
    showSuccessMessage(i18n.t('services').lockers.openLockerSuccess)
    return true
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const putIntoOriginLocker = async ({ data }) => {
  try {
    await axios.get('locker/cabinet/reserve/deposit', { params: data })
    showSuccessMessage(i18n.t('services').lockers.depositOrderSuccess)
    return true
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const checkCabinetState = async ({ data }) => {
  try {
    await axios.post('/locker/cabinet/state', data)
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const getLockersWithOpenCabinets = async ({
  page,
  onlyOpen,
  lockerNumber,
  onlyStuck,
  pageSize,
}) => {
  try {
    const { data } = await axios.get('/lockers/cabinets', {
      params: {
        onlyOpen,
        page,
        lastOpenOrder: 'desc',
        lockerNumber,
        onlyStuck,
        pageSize,
      },
    })
    const hasMore = data.page < data.totalPages
    return { ...data, hasMore }
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const getCabinetsForExport = async () => {
  try {
    const { data } = await axios.get('/lockers/cabinets-export')
    return data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const getLockerCabinetHistory = async ({
  page,
  lockerNumber,
  cabinetNumber,
}) => {
  try {
    const { data } = await axios.post('/locker/cabinet/movements', {
      page,
      lockerNumber,
      cabinetNumber,
      dateOrder: 'desc',
    })
    const hasMore = data.page < data.totalPages
    return { ...data, hasMore }
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const returnOrder = async ({
  orderIds,
  lockerNumber,
  cabinetNumber,
}) => {
  try {
    await axios.post('/locker/cabinet/return', {
      orderIds,
      lockerNumber,
      cabinetNumber,
    })
    showSuccessMessage('Order was returned')
    setTimeout(() => {
      window.location.reload()
    }, 1500)
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}
