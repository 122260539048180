import {
  WelcomeStep,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
  Step9,
  Step10,
  Step11,
  Step12,
  Step13,
  Step14,
  Step15,
  Step16,
  Step17,
  Step19,
  Step20,
  Step21,
  CongratulationsDepositStep,
  CongratulationsPickupStep,
} from './StepsContent'
import { disableBody, enableBody } from '../../../utils/bodyScroll'
import variables from '../../../styles/variables'

export const stepsForClientsOnboarding = () => [
  {
    selector: 'body',
    disableActions: true,
    content: WelcomeStep(),
    position: 'center',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleWhite,
        opacity: 1,
      }),
      maskArea: (base) => ({ top: 0 }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        maxWidth: '700px',
        top: -200,
      }),
    },
  },
  {
    selector: '[data-tour="1"]',
    disableActions: true,
    resizeObservables: ['[data-tour="1"]'],
    content: Step1(),
    position: 'right',
    padding: { mask: 0 },
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
        border: `2px solid ${variables.shuttleWhite}`,
      }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        marginLeft: '80px',
        top: 30,
      }),
    },
  },
  {
    selector: '[data-tour="2"]',
    disableActions: true,
    content: Step2(),
    position: 'left',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        left: '-80px',
      }),
    },
  },
  {
    selector: '[data-tour="3"]',
    disableActions: true,
    resizeObservables: ['[data-tour="3"]'],
    content: Step3(),
    position: 'right',
    action: enableBody,
    actionAfter: disableBody,
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      maskArea: (base, { x, y }) => ({ ...base, y: 70 }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        marginLeft: '80px',
        top: 70,
      }),
    },
  },
  {
    selector: '.dataTour4',
    disableActions: true,
    content: Step4(),
    mutationObservables: ['.dataTour4_1'],
    highlightedSelectors: [
      '.dataTour4',
      '.dataTour4_2',
      '[data-tab-id="Pending"]',
    ],
    position: 'bottom',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        right: 0,
        marginRight: '240px',
        left: null,
      }),
    },
  },
  {
    selector: '.dataTour5',
    highlightedSelectors: ['.dataTour5', '.dataTour5_1'],
    disableActions: true,
    content: Step5(),
    position: 'left',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        left: '-110px',
        top: '-30px',
      }),
    },
  },
  {
    selector: '[data-tour="6"]',
    disableActions: true,
    content: Step6(),
    position: 'right',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
        borderRadius: '30px',
      }),
      maskArea: (base, { x, y }) => ({ ...base, width: '700px', y: 30 }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        marginTop: '60px',
        marginLeft: '10px',
      }),
    },
  },
  {
    selector: '.dataTour7',
    disableActions: true,
    content: Step7(),
    mutationObservables: ['.dataTour4_1'],
    highlightedSelectors: [
      '.dataTour7',
      '.dataTour7_2',
      '[data-tab-id="Pending"]',
    ],
    position: 'bottom',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        right: 0,
        marginRight: '240px',
        left: null,
      }),
    },
  },
  {
    selector: '.dataTour8',
    highlightedSelectors: ['.dataTour8', '.dataTour8_1'],
    disableActions: true,
    content: Step8(),
    position: 'left',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        left: '-110px',
        top: '-60px',
      }),
    },
  },
]

export const stepsDepositForClientsOnboarding = () => [
  {
    selector: '[data-tour="9"]',
    disableActions: true,
    resizeObservables: ['[data-tour="9"]'],
    content: Step9(),
    position: 'right',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        marginTop: '60px',
        marginLeft: '30px',
        maxWidth: '250px',
      }),
    },
  },
  {
    selector: '.dataTour20',
    disableActions: true,
    content: Step10(),
    position: 'right',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        marginTop: '60px',
        marginLeft: '30px',
        maxWidth: '250px',
      }),
    },
  },
  {
    selector: '[data-tour="9"]',
    disableActions: true,
    resizeObservables: ['[data-tour="9"]'],
    content: Step11(),
    position: 'right',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        marginTop: '60px',
        marginLeft: '30px',
        maxWidth: '250px',
      }),
    },
  },
  {
    selector: '[data-tour="9"]',
    disableActions: true,
    resizeObservables: ['[data-tour="9"]'],
    content: Step12(),
    position: 'right',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        marginTop: '60px',
        marginLeft: '30px',
        maxWidth: '250px',
      }),
    },
  },
  {
    selector: '[data-tour="9"]',
    disableActions: true,
    resizeObservables: ['[data-tour="9"]'],
    content: Step13(),
    position: 'right',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      maskArea: (base, { x, y }) => ({ ...base, width: '800px' }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        marginTop: '60px',
        marginLeft: '30px',
      }),
    },
  },
  {
    selector: '.dataTour14',
    disableActions: true,
    content: Step14(),
    position: 'right',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      maskArea: (base, { x, y }) => ({ ...base, width: '800px' }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        marginTop: '60px',
        marginLeft: '30px',
      }),
    },
  },
  {
    selector: '[data-tour="15"]',
    disableActions: true,
    content: Step15(),
    position: 'left',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      maskArea: (base, { x, y }) => ({ ...base, width: '800px' }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        left: '-110px',
      }),
    },
  },
  {
    selector: '[data-tour="16"]',
    disableActions: true,
    resizeObservables: ['[data-tour="16"]'],
    content: Step16(),
    position: 'right',
    padding: { mask: 0 },
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        marginLeft: '80px',
        top: 30,
      }),
    },
  },
  {
    selector: '.dataTour17',
    disableActions: true,
    content: Step17(),
    mutationObservables: ['.dataTour17_1'],
    resizeObservables: ['.dataTour16_1', '.dataTour17_1', '.dataTour17_2'],
    highlightedSelectors: [
      '.dataTour17',
      '.dataTour17_2',
      '[data-tab-id="Unassigned"]',
    ],
    position: 'top',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        right: 0,
        marginRight: '240px',
        left: null,
      }),
    },
  },
  {
    selector: 'body',
    disableActions: true,
    content: CongratulationsDepositStep(),
    position: 'center',
    actionAfter: () => window.location.reload(),
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleWhite,
        opacity: 1,
      }),
      maskArea: (base) => ({ top: 0 }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        maxWidth: '700px',
        top: -200,
      }),
    },
  },
]

export const stepsPickupForClientsOnboarding = () => [
  {
    selector: '.dataTour19',
    disableActions: true,
    content: Step19(),
    position: 'right',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        marginTop: '60px',
        marginLeft: '30px',
        maxWidth: '280px',
      }),
    },
  },
  {
    selector: '.dataTour20',
    disableActions: true,
    content: Step20(),
    position: 'right',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        marginTop: '60px',
        marginLeft: '30px',
        maxWidth: '280px',
      }),
    },
  },
  {
    selector: '.dataTour19',
    disableActions: true,
    content: Step21(),
    position: 'right',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      maskArea: (base, { x, y }) => ({ ...base, width: '800px' }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        marginTop: '60px',
        marginLeft: '30px',
        maxWidth: '280px',
      }),
    },
  },
  {
    selector: '[data-tour="15"]',
    disableActions: true,
    content: Step15(),
    position: 'left',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleBlue,
        opacity: 0.8,
      }),
      maskArea: (base, { x, y }) => ({ ...base, width: '800px' }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: variables.shuttleBlue,
        opacity: 1,
        border: `2px solid ${variables.shuttleWhite}`,
        borderRadius: '7px',
        left: '-110px',
      }),
    },
  },
  {
    selector: 'body',
    disableActions: true,
    content: CongratulationsPickupStep(),
    actionAfter: () => window.location.reload(),
    position: 'center',
    styles: {
      maskWrapper: (base) => ({
        ...base,
        color: variables.shuttleWhite,
        opacity: 1,
      }),
      maskArea: (base) => ({ top: 0 }),
      popover: (base) => ({
        ...base,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        maxWidth: '700px',
        top: -200,
      }),
    },
  },
]
