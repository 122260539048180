import React from 'react'
import { Icon } from '@blueprintjs/core'
import variables from '../../../../../styles/variables'
import s from './LocationLockersMarker.module.scss'

interface LockerMarkerProps {
  lat?: number
  lng?: number
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

export const LocationLockersMarker: React.FC<LockerMarkerProps> = ({
  onClick,
}) => {
  return (
    <div onClick={onClick} className={s.marker}>
      <Icon icon='box' color={variables.shuttleWhite} iconSize={18} />
    </div>
  )
}
