import React, { useState, useCallback, memo } from 'react'
import { Dialog, Icon } from '@blueprintjs/core'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Button } from '../'
import { mapBtn, fullscreen } from './TripsMap.module.scss'
import { MapWithRoutes } from '../../reusableComponents'
import variables from '../../../styles/variables'
import s from './Clients.module.scss'

export const TripsMap = memo(({ locations = [] }) => {
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(false)

  const handleToogleModal = useCallback(
    () => setOpen((prevState) => !prevState),
    [],
  )

  const createLink = (locations) => {
    const baseLink = 'https://www.google.com/maps/dir/'
    const points = locations.map((l) => `${l.lat}+${l.lng}`).join('/')
    return baseLink + points
  }
  return (
    <div className={classnames(s.clientSelect)}>
      <div className={mapBtn} onClick={handleToogleModal}>
        <Icon icon='send-to-map' color={variables.shuttleWhite} />
      </div>

      <Dialog
        isOpen={isOpen}
        className={s.modal}
        canOutsideClickClose={true}
        style={{ position: 'relative' }}
      >
        <Button
          withIcon={true}
          iconProps={{ icon: 'cross', iconSize: 16 }}
          onClick={handleToogleModal}
          style={{
            backgroundColor: 'transparent',
            color: variables.text,
            position: 'absolute',
            minWidth: 'auto',
            padding: '5px',
            right: '5px',
            top: '5px',
          }}
        />
        <a href={createLink(locations)} className={fullscreen} target='_blank'>
          <Icon icon='fullscreen' iconSize={18} style={{ display: 'block' }} />
        </a>
        <header className={s.modalHeader}>{t('modals').tripsMap.title}</header>
        <div style={{ height: '100%', width: '100%' }}>
          <MapWithRoutes
            places={locations}
            mapElement={<div style={{ height: `100%` }} />}
            defaultCenter={{
              lat: 22.848295,
              lng: 45.725533,
            }}
            defaultZoom={5}
          />
        </div>
      </Dialog>
    </div>
  )
})
