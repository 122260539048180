import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../'

import s from './LoadMoreBtn.module.scss'

const LoadMoreBtn = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <Button
      text={t('loadMoreBtn').label}
      onClick={onClick}
      className={s.loadMoreBtn}
    />
  )
}

export default memo(LoadMoreBtn)
