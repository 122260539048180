import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from './Select'
import { useSelector } from 'react-redux'
import {
  selectCities,
  selectVisibleCities,
} from '../../../redux/reducers/settings-reducer/cities-reducer'

export const City = memo(
  ({ selectRef, value = '', hideUnvisible = false, onChange, isRequired }) => {
    const { t } = useTranslation()
    const LOCATIONS = hideUnvisible
      ? useSelector(selectVisibleCities)?.en
      : useSelector(selectCities)?.en

    const options = useMemo(() => {
      if (LOCATIONS?.length > 0) {
        const cityOptions = LOCATIONS?.map(({ city }) => city)
        if (value && !LOCATIONS?.includes(value)) {
          cityOptions.push(value)
        }
        return cityOptions.filter((c, i, arr) => arr.indexOf(c) === i).sort()
      }
    }, [value, LOCATIONS])

    return (
      <Select
        selectRef={selectRef}
        value={value}
        options={options}
        placeholder={t('city').placeholder}
        label={t('city').label}
        onChange={onChange}
        Option={(props) => {
          const name = props[0]
          return (
            <option value={name}>{t('locations').cities[name] || name}</option>
          )
        }}
        isRequired={isRequired}
      />
    )
  },
)
