import React, { memo } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Button } from './Button'
import TrackLinkButton from './TrackLinkButton/TrackLinkButton'

import s from './ButtonsGroup.module.scss'

export const ButtonsOrderGroup = memo(
  ({
    onExportOrders = () => {},
    onOpenImport = () => {},
    onOpenRequest = () => {},
    onOpenDeposit = () => {},
    onPrintAWBS = () => {},
    className = '',
    style = {},
    isShowDepositAndPickupBtns,
  }) => {
    const { t } = useTranslation()
    return (
      <div className={classnames(s.buttonsGroup, className)} style={style}>
        <Button
          onClick={onOpenImport}
          text={t('ordersRoute').tabs.btnsGroup.upload}
          classNameType='actionBorder'
          withIcon={true}
          iconProps={{
            icon: 'export',
            iconSize: 14,
          }}
        />
        <Button
          onClick={onExportOrders}
          text={t('ordersRoute').tabs.btnsGroup.download}
          classNameType='actionBorder'
          withIcon={true}
          iconProps={{
            icon: 'import',
            iconSize: 14,
          }}
        />

        <Button
          onClick={onPrintAWBS}
          text={t('ordersRoute').tabs.btnsGroup.printAWBS}
          className={s.btnPrintAWBS}
          classNameType='actionBorder'
          withIcon={true}
          iconProps={{
            icon: 'print',
            iconSize: 14,
          }}
        />

        {isShowDepositAndPickupBtns && (
          <>
            <Button
              onClick={onOpenRequest}
              classNameType='actionBorder'
              text={t('ordersRoute').tabs.btnsGroup.pickup}
            />
            <Button
              onClick={onOpenDeposit}
              classNameType='actionBorder'
              text={t('ordersRoute').tabs.btnsGroup.deposit}
            />
          </>
        )}

        <TrackLinkButton />
      </div>
    )
  },
)
