import axios from 'axios'
import { showErrorMessage } from '../../modules/Toasters'
import { refreshPageIfTokenWasExpired } from '../api'

const handleDataCities = (response) => {
  const cities = response.reduce(
    (acc, el) => {
      const cityEnglish = el.translations.find((t) => t.language === 'en')
        ? el.translations.find((t) => t.language === 'en').value
        : el.translations.find((t) => t.language === 'ar').value
      const cityArabic = el.translations.find((t) => t.language === 'ar')
        ? el.translations.find((t) => t.language === 'ar').value
        : el.translations.find((t) => t.language === 'en').value

      const districtsEnglish = el.districts.map((d) =>
        d.translations.find((t) => t.language === 'en')
          ? d.translations.find((t) => t.language === 'en').value
          : d.translations.find((t) => t.language === 'ar').value,
      )
      const districtsArabic = el.districts.map((d) =>
        d.translations.find((t) => t.language === 'ar')
          ? d.translations.find((t) => t.language === 'ar').value
          : d.translations.find((t) => t.language === 'en').value,
      )

      acc.en.push({
        city: cityEnglish,
        visible: el.visible,
        districts: districtsEnglish,
      })
      acc.ar.push({
        city: cityArabic,
        visible: el.visible,
        districts: districtsArabic,
      })
      return acc
    },
    { en: [], ar: [] },
  )
  return cities
}

export const settingsEndpoints = (builder) => ({
  getCities: builder.query({
    query: () => `/cities?format=false`,
    providesTags: ['Cities'],
    transformResponse: async (response) => {
      return handleDataCities(response)
    },
  }),
  getCitiesForRoute: builder.query({
    query: () => `/cities?format=false`,
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ id }) => ({
              type: 'Cities' as const,
              id,
            })),
            { type: 'Cities', id: 'LIST' },
          ]
        : [{ type: 'Cities', id: 'LIST' }],
  }),
  getCityById: builder.query({
    query: (id) => id && `/city/${id}?format=false`,
    providesTags: (result, error, id) => [{ type: 'Cities', id }],
  }),
  createCity: builder.mutation({
    query: (body) => ({
      url: '/city',
      method: 'POST',
      body,
    }),
    invalidatesTags: ['Cities'],
  }),
  updateCity: builder.mutation({
    query: (body) => ({
      url: '/city',
      method: 'PUT',
      body,
    }),
    invalidatesTags: ['Cities'],
  }),
  removeCity: builder.mutation({
    query: (id) => ({
      url: `/city/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: ['Cities'],
  }),
})

export const getLogisticsCompanies = async (query = {}) => {
  return await axios
    .get('/logistics_companies', {
      params: {
        query,
        page: 1,
        pageSize: 10000,
      },
    })
    .catch((error) => {
      refreshPageIfTokenWasExpired(error.response.data.data)
      showErrorMessage(error.response.data.message)
    })
}

export const createLogisticsCompany = async ({ data }) => {
  return await axios.post('/logistics_company', data).catch((error) => {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  })
}

export const updateLogisticsCompany = async ({ data }) => {
  return await axios.put('/logistics_company', data).catch((error) => {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  })
}

export const changeLogisticsCompaniesStatus = async ({ data }) => {
  return await axios.put('/logistics_company/status', data).catch((error) => {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  })
}

export const getApplicationList = async (query = {}) => {
  return await axios
    .get('/applications', {
      params: {
        query,
        page: 1,
        pageSize: 10000,
      },
    })
    .catch((error) => {
      refreshPageIfTokenWasExpired(error.response.data.data)
      showErrorMessage(error.response.data.message)
    })
}

export const createApplication = async (data: { name: string }) => {
  return await axios.post('/application', data).catch((error) => {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  })
}

export const updateApplicationToken = async (data: {
  id: string
  name: string
  status: string
  updateToken?: boolean
}) => {
  return await axios.put('/application', data).catch((error) => {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  })
}

export const changeApplicationStatus = async ({ data }) => {
  return await axios.put('/application/status', data).catch((error) => {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  })
}

export const getGenerateTripsSettings = async () => {
  return await axios.get('/settings').catch((error) => {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  })
}
export const updateGenerateTripsSettings = async ({ id, data }) => {
  return await axios
    .put(`/settings/routingSchedule/${id}`, data)
    .catch((error) => {
      refreshPageIfTokenWasExpired(error.response.data.data)
      showErrorMessage(error.response.data.message)
    })
}

export const getSettingsByName = (settingsName: string) => {
  return axios
    .get('/settings/name', {
      params: { settingsName },
    })
    .catch((error) => {
      refreshPageIfTokenWasExpired(error.response.data.data)
      showErrorMessage(error.response.data.message)
    })
}

export const updateSettingsByName = async (payload: {
  settingsName: string
  settingsData: any
}) => {
  return await axios.put('/settings/name', payload).catch((error) => {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  })
}
