import axios from 'axios'
import qs from 'qs'

import { showErrorMessage, showSuccessMessage } from '../../modules/Toasters'

import { refreshPageIfTokenWasExpired } from '../api.ts'

export const getClients = async (payload) => {
  try {
    const { data } = await axios.get('/clients', {
      params: {
        ...payload,
        sort: payload?.sort || '-_id',
        pageSize: payload?.pageSize || 10,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })
    return data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const getClientById = async ({ id }) => {
  try {
    const { data } = await axios.get(`/clients/${id}`)
    return { ...data }
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const createClient = async ({ data }) => {
  try {
    const response = await axios.post('/client', data)
    return response.data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const updateClient = async ({ data }) => {
  try {
    const item = await axios.put('/client', data)
    return item.data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const changeClientStatus = async ({ data }) => {
  try {
    await axios.put('/client/status', data)
    document.location.reload()
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}
