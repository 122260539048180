import React, { lazy, useState } from 'react'
import { useAsync } from 'react-use'
import jwt from 'jwt-decode'
import { useAuth } from '../../../context/auth'
import { ProviderActions } from './ActionButtons/Actions'
import { DepositScreen } from './DepositScreen/DepositScreen'
import { PickupScreen } from './PickupScreen/PickupScreen'
import { getLockerByNumber } from '../../../services/api/lockers'
import { showErrorMessage } from '../../../modules/Toasters'

const Header = lazy(() => import('../../Header/Header'))

export const ProviderExternalRoute = () => {
  const { onSignOut } = useAuth()
  const [currentAction, setAction] = useState('')
  const [locker, setLocker] = useState(null)

  useAsync(async () => {
    try {
      const token = window.localStorage.getItem('token')
      const { lockerNumber } = jwt(token)
      if (lockerNumber) {
        const retrievedLocker = await getLockerByNumber({
          number: lockerNumber,
        })
        setLocker(retrievedLocker)
      } else {
        onSignOut()
      }
    } catch (error) {
      showErrorMessage('Something went wrong')
    }
  }, [])

  return (
    <div
      style={{
        width: 320,
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        height: '100vh',
      }}
    >
      <Header />
      <div>
        {!currentAction && locker && (
          <ProviderActions
            actionClick={setAction}
            selectedAction={currentAction}
          />
        )}
        {currentAction === 'Deposit' && (
          <DepositScreen locker={locker} setAction={setAction} />
        )}
        {currentAction === 'Pickup' && <PickupScreen setAction={setAction} />}
      </div>
    </div>
  )
}
