import React from 'react'
import GoogleMapReact from 'google-map-react'

import { LocationLockersMarker } from './Points/LocationLockersMarker.tsx'
import { Location } from '../../../Routes/SmartPagesRoutes/Consignee/ConsigneeSmartPage.tsx'

interface LocationLockersOnTheMapProps {
  locations: Location[]
  onClick: (location: Location) => void
}

export const LocationLockersOnTheMap: React.FC<LocationLockersOnTheMapProps> =
  ({ locations, onClick }) => {
    return (
      <div style={{ height: '500px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_API_KEY,
          }}
          defaultCenter={{
            lat: 22.848295,
            lng: 45.725533,
          }}
          defaultZoom={5}
        >
          {locations.length > 0 &&
            locations.map((location) => {
              const { _id, lat, lng } = location
              return (
                <LocationLockersMarker
                  key={_id}
                  lat={lat}
                  lng={lng}
                  onClick={() => onClick(location)}
                />
              )
            })}
        </GoogleMapReact>
      </div>
    )
  }
