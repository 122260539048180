import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { shuttleApi } from '../../../services/api'
import { Cities, Dictionary, CitiesRoute } from './settings.interfaces'

export const citiesSlice = createSlice({
  name: 'settings',
  initialState: {
    cities: {
      en: [],
      ar: [],
    },
  } as Cities,
  reducers: {
    selectCities(state, { payload }) {
      state.cities = payload as Dictionary
    },
    selectCitiesRoute(state, { payload }) {
      state.citiesRoute = payload as CitiesRoute
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      shuttleApi.endpoints.getCities.matchFulfilled,
      (state, { payload }) => {
        state.cities = payload as Dictionary
      },
    )
    builder.addMatcher(
      shuttleApi.endpoints.getCitiesForRoute.matchFulfilled,
      (state, { payload }) => {
        state.citiesRoute = payload as CitiesRoute
      },
    )
  },
})

export default citiesSlice.reducer

export const selectCities = (state: RootState) => state.settings.cities

export const selectCitiesRoute = (state: RootState) =>
  state.settings.citiesRoute

export const selectVisibleCities = (state: RootState) => ({
  en: state.settings.cities.en.filter((city) => city.visible),
  ar: state.settings.cities.ar.filter((city) => city.visible),
})
