import React, { useState, useCallback, memo } from 'react'
import { Icon } from '@blueprintjs/core'

import { SuggestItems } from './SuggestItems'
import { OrderList } from '../../../components/NavigationPanel/NavigationRoutes/CommonsRoutes/Orders/OrdersList/OrdersList'

import { getOrderSuggestById } from '../../../services/api/orders'

import s from './OrdersSuggest.module.scss'

export const OrdersSuggest = memo(
  ({ data, onAdd, onRemove, suggestBy = 'number', withToggle = true }) => {
    const [showInput, setShowInput] = useState(false)
    const handleToogleShowInput = useCallback(
      () => setShowInput((prevState) => !prevState),
      [],
    )

    return (
      <div className={s.container}>
        {withToggle && (
          <div className={s.label} onClick={handleToogleShowInput}>
            <Icon
              icon={showInput ? 'chevron-down' : 'chevron-right'}
              iconSize={16}
              style={{ marginRight: 10 }}
            />
            No. of Orders
          </div>
        )}
        {showInput && (
          <>
            <SuggestItems
              data={data}
              itemKey={suggestBy}
              onAdd={onAdd}
              apiGetRuquest={getOrderSuggestById}
            />
            {data.length > 0 && <OrderList items={data} onRemove={onRemove} />}
          </>
        )}
      </div>
    )
  },
)
