import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import s from './styles.module.scss'

import orderTIcon from '../../../../assets/images/OrderTIcon.svg'

export const OrderTracking = () => {
  const { t, i18n } = useTranslation()
  const { guid } = useParams()

  const path = `/orders/track/${guid}/track`
  return (
    <div className={s.cards}>
      <Link to={path} className={s.customerCard}>
        <img className='ml-1' src={orderTIcon} alt='' />
        <div
          className='my-auto ml-2'
          style={
            i18n.language === 'ar'
              ? { marginRight: '6px', marginLeft: '0px' }
              : {}
          }
        >
          <p className={s.subtitle}>
            {t('trackingPage').homePage.optionCards.orderTracking1}
          </p>
          <p className={s.title}>
            {t('trackingPage').homePage.optionCards.orderTracking2}
          </p>
        </div>
      </Link>{' '}
    </div>
  )
}
