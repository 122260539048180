import React from 'react'
import { Dialog } from '@blueprintjs/core'
import { Icon } from '@blueprintjs/core'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { Button } from '../'

import MounterModal from '../../../modules/MounterModal'

import s from './Confirm.module.scss'

const Confirm = ({
  icon = null,
  title,
  text = null,
  text2 = null,
  cancelText,
  confirmText,
  onCancel = () => {},
  onConfirm = () => {},
  onDismiss = () => {},
  className = '',
}) => {
  const { t } = useTranslation()
  const handleCancel = () => {
    onCancel()
    onDismiss()
  }

  const handleConfirm = () => {
    onConfirm()
    onDismiss()
  }

  return (
    <Dialog className={cn(s.modal, className)} isOpen={true}>
      {icon && (
        <div className={s.boxIcon}>
          <Icon icon={icon} iconSize='30' className={s.icon} />
        </div>
      )}
      <h2 className={s.title}>{title || t('modals').confirm.title}</h2>
      {text && <p className={s.text}>{text}</p>}
      {text2 && <p className={s.text}>{text2}</p>}
      <div className={s.btnGroup}>
        <Button
          text={cancelText || t('modals').confirm.cancel}
          classNameType='dismiss'
          onClick={handleCancel}
        />
        <Button
          text={confirmText || t('modals').confirm.yes}
          onClick={handleConfirm}
        />
      </div>
    </Dialog>
  )
}

export const ConfirmModal = ({ contextValue, ...data }) =>
  MounterModal({ data, contextValue, Component: Confirm })
