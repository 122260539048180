import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { TextInput } from './TextInput'

export const Email = memo(({ register, name, isRequired, props }) => {
  const { t } = useTranslation()
  return (
    <TextInput
      register={register}
      name={name}
      type='email'
      placeholder={t('email').placeholder}
      label={t('email').label}
      options={{ pattern: '@' }}
      isRequired={isRequired}
      props={props}
    />
  )
})
