import React from 'react'
import { Dialog } from '@blueprintjs/core'
import { Icon } from '@blueprintjs/core'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { Button } from '../'
import MounterModal from '../../../modules/MounterModal'
import s from './Warning.module.scss'

const Warning = ({
  icon = null,
  title,
  text = null,
  confirmText,
  onConfirm = () => {},
  onDismiss = () => {},
  className = '',
  isHtml = false,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const handleConfirm = () => {
    onConfirm()
    onDismiss()
  }

  return (
    <Dialog className={cn(s.modal, className)} isOpen={true}>
      {icon && (
        <div className={s.boxIcon}>
          <Icon icon={icon} iconSize='30' className={s.icon} />
          <h2 className={s.title}>{title || t('modals').warning.title}</h2>
        </div>
      )}
      {text && (
        <>
          {language !== 'ar' && isHtml ? (
            <p
              className={s.text}
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            ></p>
          ) : (
            <p className={s.text}>{text}</p>
          )}
        </>
      )}
      <div>
        <Button
          text={confirmText || t('modals').warning.yes}
          onClick={handleConfirm}
        />
      </div>
    </Dialog>
  )
}

export const WarningModal = ({ contextValue, ...data }) =>
  MounterModal({ data, contextValue, Component: Warning })
