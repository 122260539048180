import React, { memo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsync } from 'react-use'
import { Select } from './Select'
import { getLogisticsCompanies } from '../../../services/api/settings'
import { showErrorMessage } from '../../../modules/Toasters'

export const LogisticsCompany = memo(
  ({
    selectRef,
    value = '',
    onChange,
    isRequired,
    disabled,
    valueId = true,
    placeholder,
    style = { maxWidth: 300 },
  }) => {
    const { t } = useTranslation()
    const [logisticsCompanies, setLogisticsCompanies] = useState()

    useAsync(async () => {
      try {
        const { data } = await getLogisticsCompanies({ status: 'Active' })
        setLogisticsCompanies(data.rows)
      } catch (error) {
        showErrorMessage(JSON.stringify(error))
      }
    }, [])

    return (
      <Select
        selectRef={selectRef}
        value={value}
        valueId={valueId}
        options={logisticsCompanies}
        placeholder={placeholder}
        label={t('logisticsCompany').label}
        onChange={onChange}
        disabled={disabled}
        Option={(props) => {
          const logisticCompany = props[0]
          return (
            <option
              value={
                valueId ? logisticCompany._id : JSON.stringify(logisticCompany)
              }
              key={logisticCompany._id}
            >
              {logisticCompany.name}
            </option>
          )
        }}
        isRequired={isRequired}
        style={style}
      />
    )
  },
)
