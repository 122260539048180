import React from 'react'
import classnames from 'classnames'
import moment from 'moment'
import { QRCode } from 'react-qr-svg'
import SiteLogo from '../../../modules/SiteLogo/SiteLogo'
import Header from '../../../modules/RoutesComponents/HeaderRoute'
import s from './MasterAWBCode.module.scss'

export const MasterAWBCode = ({ parcel }) => {
  return (
    <div className={s.container}>
      <div>
        <style type='text/css' media='print'>
          {'\
   @page { size: landscape; }\
'}
        </style>

        <Header>Shttle Master AWB - Parcel</Header>
        <div className={s.headerBlock}>
          <SiteLogo />

          <QRCode
            level='M'
            style={{ width: 120 }}
            value={`${parcel._id}=${parcel.groupTrackingId}`}
          />
        </div>
        <div className={s.infoBlock}>
          <table className={s.infoTable}>
            <tbody>
              <tr>
                <td className={classnames(s.cell, s.info)}>Shipper</td>
                <td className={s.cell}>
                  {parcel.destinationDP.logisticsCompany.name}
                </td>
              </tr>
              <tr>
                <td className={classnames(s.cell, s.info)}>Shuttle Parcel #</td>
                <td className={s.cell}>{parcel.masterAWBCode}</td>
              </tr>
              <tr>
                <td className={classnames(s.cell, s.info)}>Shipper #</td>
                <td className={s.cell}>{parcel.groupTrackingId}</td>
              </tr>
            </tbody>
          </table>

          <table className={s.infoTable}>
            <tbody>
              <tr>
                <td className={classnames(s.cell, s.empty)}></td>
                <td className={classnames(s.cell, s.info)}>Shipper Schedule</td>
              </tr>
              <tr>
                <td className={classnames(s.cell, s.info)}>Date</td>
                <td className={s.cell}></td>
              </tr>
              <tr>
                <td className={classnames(s.cell, s.info)}>Departure Time</td>
                <td className={s.cell}></td>
              </tr>
            </tbody>
          </table>
        </div>
        <table className={s.infoTable}>
          <tbody>
            <tr>
              <td className={classnames(s.cell, s.info)}>Date</td>
              <td className={s.cell}>
                {moment(parcel.createdAt).format('DD/MM/YYYY')}
              </td>
            </tr>
          </tbody>
        </table>

        <table className={s.table}>
          <thead>
            <tr>
              <th className={s.tableHead}>#</th>
              <th className={s.tableHead}>Order ID</th>
              <th className={s.tableHead}>Origin</th>
              <th className={s.tableHead}>Destination</th>
            </tr>
          </thead>

          <tbody className={s.body}>
            {parcel.orders.map((o, idx) => (
              <tr className={s.row} key={o._id}>
                <td className={classnames(s.cell, s.shortInfo)}>{idx + 1}</td>
                <td className={s.cell}>{o.number}</td>
                <td className={s.cell}>{parcel.originDP.name}</td>
                <td className={s.cell}>{parcel.destinationDP.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
