import React from 'react'
import Geocode from 'react-geocode'

import MapView from './MapView'

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY)
Geocode.enableDebug()

class Map extends React.Component {
  state = {
    address: '',
    city: '',
    area: '',
    state: '',
    mapPosition: {
      lat: this.props.center.lat,
      lng: this.props.center.lng,
    },
    markerPosition: {
      lat: this.props.center.lat,
      lng: this.props.center.lng,
    },
  }

  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    const { onChangeAddress } = this.props
    Geocode.fromLatLng(
      this.state.mapPosition.lat,
      this.state.mapPosition.lng,
    ).then(
      (response) => {
        const address = response.results[0].formatted_address
        const addressArray = response.results[0].address_components

        onChangeAddress(address)

        const city = this.getCity(addressArray)
        const area = this.getArea(addressArray)
        const state = this.getState(addressArray)

        this.setState({
          address: address ? address : '',
          area: area ? area : '',
          city: city ? city : '',
          state: state ? state : '',
        })
      },
      (error) => {},
    )
  }
  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.markerPosition.lat !== this.props.center.lat ||
      this.state.address !== nextState.address ||
      this.state.city !== nextState.city ||
      this.state.area !== nextState.area ||
      this.state.state !== nextState.state
    ) {
      return true
    } else if (this.props.center.lat === nextProps.center.lat) {
      return false
    }
  }
  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let city = ''
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        'administrative_area_level_2' === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name
        return city
      }
    }
  }
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = (addressArray) => {
    let area = ''
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            'sublocality_level_1' === addressArray[i].types[j] ||
            'locality' === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name
            return area
          }
        }
      }
    }
  }
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray) => {
    let state = ''
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          'administrative_area_level_1' === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name
          return state
        }
      }
    }
  }
  /**
   * And function for city,state and address input
   * @param event
   */
  onChange = ({ target }) => {
    const { name, value } = target
    this.setState({ [name]: value })
  }
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = (event) => {}

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = (e) => {
    const newLat = e.latLng.lat()
    const newLng = e.latLng.lng()

    const markerPosition = { lat: newLat, lng: newLng }

    const { onChangeGeoCord } = this.props

    onChangeGeoCord(markerPosition)

    this.setState({ markerPosition }, () => {
      const { lat, lng } = this.state.markerPosition
      const { onChangeAddress } = this.props

      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0].formatted_address
          const addressArray = response.results[0].address_components

          onChangeAddress(address)

          const city = this.getCity(addressArray)
          const area = this.getArea(addressArray)
          const state = this.getState(addressArray)

          this.setState({
            address: address ? address : '',
            area: area ? area : '',
            city: city ? city : '',
            state: state ? state : '',
          })
        },
        (error) => {},
      )
    })
  }

  render() {
    const { google, zoom, center, height } = this.props
    const { mapPosition, markerPosition, city, area, address, state } =
      this.state
    const { lat, lng } = mapPosition

    const isShowMapView = center.lat !== undefined

    const asyncMapProps = {
      address,
      google,
      zoom,
      lat,
      lng,
      markerPosition,
      onCloseInfo: this.onInfoWindowClose,
      onMarkerDragEnd: this.onMarkerDragEnd,
    }

    return (
      <>
        {isShowMapView && (
          <MapView
            city={city}
            area={area}
            state={state}
            address={address}
            height={height}
            onChange={this.onChange}
            asyncMapProps={asyncMapProps}
          />
        )}
        {!isShowMapView && <div style={{ height }} />}
      </>
    )
  }
}
export default Map
