import React, { memo } from 'react'

import { TextInput } from './TextInput'

export const Search = memo(
  ({ value, placeholder = 'Search', style, onChange, autoFocus }) => {
    return (
      <TextInput
        props={{ value, onChange: ({ target }) => onChange(target.value) }}
        placeholder={placeholder}
        autoFocus={autoFocus}
        style={style}
        iconName='search'
      />
    )
  },
)
