export const ROLE = {
  ADMIN: 'Admin',
  CLIENT: 'Client',
  PROVIDER: 'Provider',
  TENANT_ADMIN: 'Tenant Admin',
}

export const TOKEN_LOCALSTORAGE_KEY = 'token'

export const ORDERS_STATUS = [
  'Initiated',
  'Assigned to request',
  'Pick-up scheduled',
  'On way to origin locker',
  'On way to intermediate locker',
  'On way to destination locker',
  'Deposited at origin locker',
  'Deposited at intermediate locker',
  'Deposited at destination locker',
  'On way to origin DP',
  'On way to destination DP',
  'Arrived at origin DP',
  'Arrived at destination DP',
  'Ready for pick-up',
  'Delivered',
  'Not delivered',
  'Under investigation',
  'Delivery requested',
  'Picked up by delivery company',
  'Cancelled',
]

export const PICKUP_OPTIONS = ['Today', 'Tomorrow', 'Later']
export const PREFERRED_TIME = ['1pm - 3pm', '3pm - 5pm']

export const REQUEST_TYPES = {
  DEPOSIT: 'Deposit',
  PICKUP: 'Pickup',
}

export const TRIP_STATUS = {
  UPCOMING: 'Upcoming',
  IN_PROGRESS: 'In-Progress',
  COMPLETED: 'Completed',
}

export const REPORT_ISSUES = [
  {
    id: 'orderNotReady',
    types: ['Warehouse', 'Store'],
    label: 'Order not ready',
  },
  {
    id: 'orderCancelledByClient',
    types: ['Warehouse', 'Store'],
    label: 'Order cancelled by Client',
  },
  {
    id: 'cabinetNotOpen',
    types: ['Locker'],
    label: 'Cabinet Not Open',
  },
  {
    id: 'orderNotFound',
    types: ['Locker'],
    label: 'Order not found',
  },
  {
    id: 'notDelivered3PL',
    types: ['Distribution Point'],
    label: 'Not delivered 3PL',
  },
]

export const GENERATION_TRIPS_MODES = [
  {
    id: 'Manual',
    label: 'Manual',
  },
  {
    id: 'Automated',
    label: 'Automated',
  },
]

export const WEEK = {
  Sunday: false,
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
}

export const USER_TYPES = Object.values(ROLE)

export const pageSizes = [20, 50, 100]
export const initialPageSize = pageSizes[0]
export const orderTypes = ['Forward', 'Reverse']

export const INTEGRATION_SERVICES = { MAGENTO: 'Magento' }
