import React, { createContext, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from '../hooks/useLocalStorage'

export const LanguageContext = createContext()

export function useLanguage() {
  return useContext(LanguageContext)
}

export const LANGUAGE_STORAGE_KEY = 'SHUTTLE_LANGUAGE_INTERFACE'

export const INTERFACE_LANGUAGES = {
  ARABIC: 'ar',
  ENGLISH: 'en',
}

export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const [language, setLanguage] = useLocalStorage(
    LANGUAGE_STORAGE_KEY,
    INTERFACE_LANGUAGES.ARABIC,
  )

  const toogle = (lng) => {
    i18n.changeLanguage(lng)
    setLanguage(lng)
  }

  return (
    <LanguageContext.Provider value={[language, toogle]}>
      {children}
    </LanguageContext.Provider>
  )
}
