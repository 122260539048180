export const authEndpoints = (builder) => ({
  login: builder.mutation({
    query: (credentials) => ({
      url: '/user/login',
      method: 'POST',
      body: {
        ...credentials,
      },
    }),
  }),
})
