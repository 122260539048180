import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { TextInput } from './TextInput'

export const PhoneNumber = memo(({ register, name, isRequired, ...props }) => {
  const { t } = useTranslation()
  return (
    <TextInput
      register={register}
      name={name}
      type='tel'
      placeholder={t('phoneNumber').placeholder}
      label={t('phoneNumber').label}
      isRequired={isRequired}
      props={props}
    />
  )
})
