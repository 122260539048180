import React from 'react'
import { Button } from '../../../reusableComponents'
import s from './BackBtn.module.scss'

export const BackBtn: React.FC = ({ setAction }: any): JSX.Element => {
  const handleClickBack = () => {
    setAction('')
  }

  return (
    <div className={s.backBox}>
      <Button
        withIcon={true}
        iconProps={{ icon: 'key-enter', iconSize: 10 }}
        text={'Back'}
        className={s.backBtn}
        onClick={handleClickBack}
        iconStyle={{ marginLeft: 0, marginRight: '5px' }}
      />
    </div>
  )
}
