import React, { memo } from 'react'

import { Select } from './Select'

import { LOCATION_OWNER } from '../../../data/locations'

export const LocationOwner = memo(({ value = '', onChange = () => {} }) => {
  return (
    <Select
      value={value}
      options={LOCATION_OWNER}
      placeholder='Select Location Owner'
      label='Location Owner'
      onChange={onChange}
      Option={(props) => {
        const { id, title } = props[0]
        return (
          <option key={id} value={title}>
            {title}
          </option>
        )
      }}
    />
  )
})
