import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { shuttleApi } from '../../services/api'
import { TOKEN_LOCALSTORAGE_KEY } from '../../data/constans'
import axios from 'axios'

interface AuthState {
  user: UserLoginResponse | null
  token: string | null
  isAuth: boolean
}

enum UserRole {
  Admin,
  Provider,
  Client,
}

enum UserStatus {
  Active,
  Inactive,
}

interface UserLoginResponse {
  _id: string
  firstName: string
  lastName: string
  email: string
  phone: string
  image: string
  role: UserRole
  client: string | null
  status: UserStatus
  access_token?: string
  expires_in: number
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, token: null, isAuth: false } as AuthState,
  reducers: {
    setCurrentUser(state, { payload }) {
      state.token = payload.token
      state.user = payload.user
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      shuttleApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        const { access_token, ...userData } = payload as UserLoginResponse
        state.token = access_token
        state.user = userData
        window.localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, access_token)
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${access_token}`
      },
    )
  },
})

export default authSlice.reducer

export const selectCurrentUser = (state: RootState) => state.auth.user
export const selectCurrentToken = (state: RootState) => state.auth.token
