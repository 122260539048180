import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'

export enum NOTIFICATION_TYPE {
  DECLINED_TRIPS = 'declinedTrips',
  OVERDUE_ACCEPTANCE_TRIPS = 'overdueAcceptanceTrips',
  ASSIGNED_TRIPS = 'assignedTrips',
}

export const notificationType = {
  DECLINED_TRIPS: 'declinedTrips',
  OVERDUE_ACCEPTANCE_TRIPS: 'overdueAcceptanceTrips',
  ASSIGNED_TRIPS: 'assignedTrips',
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    [NOTIFICATION_TYPE.DECLINED_TRIPS]: {
      entities: [],
      newEntitiesCount: 0,
    },
    [NOTIFICATION_TYPE.OVERDUE_ACCEPTANCE_TRIPS]: {
      entities: [],
      newEntitiesCount: 0,
    },
    [NOTIFICATION_TYPE.ASSIGNED_TRIPS]: {
      entities: [],
      newEntitiesCount: 0,
    },
  },
  reducers: {
    updateNotification(state, { payload }) {
      state[payload.notificationType].entities.push(payload.entity)
      state[payload.notificationType].newEntitiesCount += 1
    },

    clearAdminNotifications(state, { payload }) {
      state[payload.notificationType] = {
        entities: [],
        newEntitiesCount: 0,
      }
    },

    clearProviderNotifications(state) {
      state[NOTIFICATION_TYPE.ASSIGNED_TRIPS] = {
        entities: [],
        newEntitiesCount: 0,
      }
    },
  },
})

export default notificationsSlice.reducer

export const selectNotificationType = (
  state: RootState,
  notificationType: NOTIFICATION_TYPE,
) => state.notifications[notificationType]

export const selectAdminNotifications = (state: RootState) => ({
  declinedTrips: state.notifications.declinedTrips,
  overdueAcceptanceTrips: state.notifications.overdueAcceptanceTrips,
})

export const selectAmountAdminNotifications = (state: RootState) =>
  state.notifications.declinedTrips.newEntitiesCount +
  state.notifications.overdueAcceptanceTrips.newEntitiesCount

export const selectProviderNotifications = (state: RootState) => ({
  assignedTrips: state.notifications.assignedTrips,
})

export const selectAmountProviderNotifications = (state: RootState) =>
  state.notifications.assignedTrips.newEntitiesCount
