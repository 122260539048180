import React, { useState, memo, useEffect } from 'react'
import { Dialog, Icon } from '@blueprintjs/core'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { TextInput, Button, Loader, Search, CreateLocation } from '..'

import { useDialogService } from '../../../hooks'
import { getLocations } from '../../../services/api/locations'

import s from './Clients.module.scss'

export const Locations = memo(
  ({
    value = '',
    className = '',
    onChange = () => {},
    onRemove = () => {},
    label,
    isShowAddLocation = false,
    labelStyle = {},
    filters = {},
    withRemoveIcon = false,
    Component = null,
    includeLockers = false,
    type = null,
    setCurrentStep,
    isOpenOnboarding = false,
  }) => {
    const { t } = useTranslation()
    const {
      ref,
      isOpen,
      items,
      getItems,
      hasNextPage,
      pageNumber,
      isLoading,
      searchField,
      debounceSearchField,
      setSearchField,
      toggleModal,
    } = useDialogService(getLocations, buildPayalod)

    const [expandedList, setExpandedList] = useState({})

    function buildPayalod() {
      const payload = {
        query: {
          status: 'Active',
          name: { $regex: `.*${debounceSearchField}`, $options: 'i' },
          ...(filters?.type && { type: filters.type }),
          ...(filters?.client && { client: filters.client }),
        },
        page: pageNumber,
        includeLockers,
      }
      return payload
    }

    const handleSetItem = async (item) => {
      onChange(item)
      toggleModal()
      if (isOpenOnboarding) {
        setTimeout(() => {
          type === 'pickup' ? setCurrentStep(21) : setCurrentStep(11)
        }, 400)
      }
    }

    const handleClickButton = async () => {
      toggleModal()

      if (isOpenOnboarding) {
        setTimeout(() => {
          type === 'pickup' ? setCurrentStep(20) : setCurrentStep(10)
        }, 400)
      }
    }

    const handleCancel = () => {
      if (isOpenOnboarding) {
        type === 'pickup' ? setCurrentStep(19) : setCurrentStep(9)
      }

      toggleModal()
    }

    const refreshItems = () => {
      const payload = buildPayalod()
      payload.page = 1
      getItems(payload, isOpen)
    }

    useEffect(() => {
      setExpandedList({})
    }, [debounceSearchField])

    useEffect(() => {
      if (isOpen) {
        refreshItems()
      }
    }, [isOpen])

    const isShowRemoveIcon = withRemoveIcon && !!value

    return (
      <div className={classnames(s.clientSelect, className)}>
        <div style={{ display: 'flex' }}>
          <Button
            text={t('modals').locations.btnOpenModalLabel}
            onClick={handleClickButton}
          />
          {Component && <Component />}
        </div>

        <div style={{ display: 'flex', position: 'relative' }}>
          <TextInput
            disabled={true}
            label={t('modals').locations.label}
            placeholder={t('modals').locations.placeholder}
            style={{ marginTop: 10, ...labelStyle }}
            props={{ value }}
          />

          {isShowRemoveIcon && (
            <Icon
              className={s.removeIcon}
              onClick={onRemove}
              icon='cross'
              iconSize='14'
            />
          )}
        </div>

        <Dialog
          isOpen={isOpen}
          className={`${s.modal} dataTour20`}
          canOutsideClickClose={true}
        >
          <header className={s.modalHeader}>
            {t('modals').locations.title}
          </header>

          <div className={s.panel}>
            {(items.length > 0 || searchField) && (
              <Search
                value={searchField}
                onChange={setSearchField}
                placeholder={
                  t('locationsRoute').filters.searchByNamePlaceholder
                }
                style={{ width: 200 }}
                autoFocus={true}
              />
            )}
            {isShowAddLocation && (
              <CreateLocation getItems={() => refreshItems()} />
            )}
          </div>

          <div className={s.itemsList}>
            <Loader showLoader={isLoading} className={s.spinner} size={70} />

            {!isLoading && items.length === 0 && (
              <div style={{ textAlign: 'center' }}>
                {t('modals').locations.notFound}
              </div>
            )}
            {items.map((item) => (
              <div
                ref={ref}
                key={`${item._id}_item`}
                className={s.listItem}
                style={{ display: 'block', maxHeight: 'none' }}
                onClick={() => handleSetItem(item)}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      marginRight: 'auto',
                      maxWidth: '50%',
                      width: '100%',
                    }}
                  >
                    {item.name}
                  </div>
                  <div
                    style={{
                      marginLeft: 'auto',
                      maxWidth: '50%',
                      width: '100%',
                      textAlign: 'right',
                    }}
                  >
                    ({item.client?.name || 'Type:' + item.type})
                  </div>
                </div>
                {includeLockers && item.type === 'Locker' && (
                  <div>
                    <span
                      onClick={(event) => {
                        event.stopPropagation()
                        setExpandedList((prevState) => ({
                          ...prevState,
                          [item._id]: !prevState[item._id],
                        }))
                      }}
                    >
                      {t('tripsRoute').columnsHeadersDnd.avaliableLockers}{' '}
                      <Icon
                        icon={
                          expandedList[item._id]
                            ? 'chevron-down'
                            : 'chevron-right'
                        }
                      />
                    </span>
                    {expandedList[item._id] && (
                      <div style={{ fontSize: 13 }}>
                        {item.lockers.map((locker) => (
                          <div>
                            {locker.name} -{' '}
                            {String(locker.utilization).substr(0, 4)}%
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
            {!hasNextPage && items.length > 0 && (
              <div className={s.fullLoadedLabel}>
                {t('modals').locations.allHitsLoaded}
              </div>
            )}
          </div>

          <Button
            text={t('modals').locations.cancelBtnLabel}
            onClick={handleCancel}
          />
        </Dialog>
      </div>
    )
  },
)
