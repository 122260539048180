import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Select } from './Select'

import { ROLE } from '../../../data/constans'

import { useUserRole } from '../../../hooks'

export const Role = memo(({ selectRef, value, onChange }) => {
  const { t } = useTranslation()
  const { isAdmin, isClient } = useUserRole()
  if (isClient) return null
  if (isAdmin) {
    const options = useMemo(() => Object.values(ROLE), [])

    return (
      <Select
        selectRef={selectRef}
        value={value}
        options={options}
        placeholder={t('role').placeholder}
        label={t('role').label}
        onChange={onChange}
        Option={(props) => {
          const name = props[0]
          return (
            <option value={name} key={name}>
              {name}
            </option>
          )
        }}
      />
    )
  }
})
