import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Select } from './Select'

import { PREFERRED_TIME } from '../../../data/constans'
import moment from 'moment'

export const PreferredTime = memo(
  ({
    value = '',
    placeholder,
    withTimeRestriction = false,
    onChange = () => {},
  }) => {
    const { t } = useTranslation()

    let options = PREFERRED_TIME
    if (withTimeRestriction) {
      const isAppliedTodayTime = moment().isBefore(
        moment({ hour: 13, minute: 0 }),
      )
      if (!isAppliedTodayTime) {
        options = PREFERRED_TIME.filter((o) => o !== '1pm - 3pm')
      }
    }

    return (
      <Select
        value={value}
        options={options}
        placeholder={placeholder}
        label={t('preferredTime').label}
        onChange={onChange}
        Option={(props) => {
          const name = props[0]
          return <option value={name}>{name}</option>
        }}
      />
    )
  },
)
