import React, { useMemo, memo } from 'react'
import { Icon } from '@blueprintjs/core'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import s from './TextInput.module.scss'

interface NumberInputProps {
  name: string
  type: string
  disabled: boolean
  autoFocus: boolean
  register: null | any
  label: string
  placeholder: string
  iconName: string
  style: any
  styleLabel: any
  props: any
  isRequired: boolean
  subInput: boolean
  unit: string
  pattern: null | any
  helperText: null | string
}

export const NumberInput: React.FC<NumberInputProps> = memo(
  ({
    name = '',
    type = 'text',
    disabled = false,
    autoFocus = false,
    register = null,
    label = '',
    placeholder = '',
    iconName = '',
    style = {},
    styleLabel = {},
    props = {},
    isRequired = false,
    subInput = false,
    unit = '',
    pattern = null,
    helperText = null,
  }) => {
    const { t } = useTranslation()
    const isShowLabel = useMemo(() => !!label && !!props.value, [label, props])

    const isShowHelperText = useMemo(() => !!helperText, [helperText])
    const isUnit = useMemo(() => !!unit, [unit])
    const isShowIcon = useMemo(() => !!iconName, [iconName])

    return (
      <div>
        <div
          className={cn(s.inputBlock, subInput && s.subInputBlock, s.price)}
          style={style}
        >
          <input
            name={name}
            ref={register}
            type={type}
            disabled={disabled}
            autoFocus={autoFocus}
            pattern={pattern}
            placeholder={!!placeholder ? placeholder : label}
            {...props}
          />
          {isShowLabel && <label style={styleLabel}>{label}</label>}
          {isRequired && (
            <span className={s.requiredLabel}>{t('isReuqiredLabel')}</span>
          )}
          {isShowIcon && (
            <Icon className={s.icon} icon={iconName} iconSize='15' />
          )}
          {isUnit && <p>{unit}</p>}
        </div>
        <p className={helperText ? s.helperTextError : s.helperText}>
          {helperText ? helperText : '0.00'}
        </p>
      </div>
    )
  },
)
