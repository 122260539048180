import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { TourProvider } from '@reactour/tour'
import { store } from './redux/store'
import i18n from './localization/i18n.ts'
import App from './components/App'
import { LanguageProvider } from './context/language'
import { stepsForClientsOnboarding } from './components/NavigationPanel/OnboardingTour/Steps'
import {
  Navigation,
  Badge,
} from './components/NavigationPanel/OnboardingTour/StepsContent'
import { disableBody, enableBody } from './utils/bodyScroll'
const queryClient = new QueryClient()

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <LanguageProvider>
          <TourProvider
            steps={stepsForClientsOnboarding}
            components={{ Navigation, Badge }}
            showCloseButton={false}
            showDots={true}
            afterOpen={disableBody}
            beforeClose={enableBody}
          >
            <App />
          </TourProvider>
        </LanguageProvider>
      </I18nextProvider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root'),
)
