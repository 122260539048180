import React, { useState, useEffect } from 'react'
import { TimePicker } from '@blueprintjs/datetime'

export const TimePickerComponent = ({
  value,
  onChange,
  showArrowButtons = false,
  useAmPm = true,
}) => {
  const [time, setTime] = useState(value ? value : new Date())

  useEffect(() => {
    onChange(time)
  }, [time])

  return (
    <TimePicker
      value={time}
      onChange={setTime}
      showArrowButtons={showArrowButtons}
      useAmPm={useAmPm}
    />
  )
}
