import React, { lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { routes } from '../../../routes'

const Trips = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/Trips/Trips' /* webpackChunkName: "Trips" */
  ),
)
const TripForm = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/Trips/form/TripForm' /* webpackChunkName: "TripForm" */
  ),
)
const TripDetails = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/Trips/details/TripDetails' /* webpackChunkName: "TripDetails" */
  ),
)
const Hosts = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/Hosts/Hosts' /* webpackChunkName: "Hosts" */
  ),
)
const HostForm = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/Hosts/form/HostForm' /* webpackChunkName: "HostForm" */
  ),
)
const Clients = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/Clients/Clients' /* webpackChunkName: "Clients" */
  ),
)
const ClientForm = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/Clients/form/ClientForm' /* webpackChunkName: "ClientForm" */
  ),
)
const Routes = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/Routes/Routes' /* webpackChunkName: "Routes" */
  ),
)
const RouteForm = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/Routes/form/RouteForm' /* webpackChunkName: "RouteForm" */
  ),
)
const LockerForm = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/LockersDashboard/form/LockerForm' /* webpackChunkName: "LockerForm" */
  ),
)
const LockerDetails = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/LockersDashboard/LockerDetails' /* webpackChunkName: "LockerDetails" */
  ),
)
const LockersDashboardAdmin = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/LockersDashboard/Admin/' /* webpackChunkName: "LockersDashboardAdmin" */
  ),
)
const LockerStatistics = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/LockersStatisticsDashboards' /* webpackChunkName: "LockerStatistics" */
  ),
)
const Profile = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Profile/Profile' /* webpackChunkName: "Profile" */
  ),
)
const Requests = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Requests/Requests' /* webpackChunkName: "Requests" */
  ),
)
const Orders = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Orders/Orders' /* webpackChunkName: "Orders" */
  ),
)
const OrderForm = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Orders/form/OrderForm' /* webpackChunkName: "OrderForm" */
  ),
)
const Payments = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Payments/Payments' /* webpackChunkName: "Payments" */
  ),
)
const Users = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Users/Users' /* webpackChunkName: "Users" */
  ),
)
const UserForm = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Users/form/UserForm' /* webpackChunkName: "UserForm" */
  ),
)
const Locations = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Locations/Locations' /* webpackChunkName: "Locations" */
  ),
)
const LocationFormPage = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Locations/form/LocationFormPage' /* webpackChunkName: "LocationFormPage" */
  ),
)

const OrdersStatistics = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/Analytics/orders/OrdersAnalytics' /* webpackChunkName: "OrdersStatistics" */
  ),
)
const CycleAnalysis = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/Analytics/cycleAnalysis/CycleAnalysis' /* webpackChunkName: "CycleAnalysis" */
  ),
)

const ProviderReport = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/Analytics/reports/providerReport/ProviderReport' /* webpackChunkName: "ProviderReport" */
  ),
)
const TripsReport = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/Analytics/reports/tripsReport/TripsReport' /* webpackChunkName: "TripsReport" */
  ),
)
const HostsAnalytics = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/Analytics/hosts/HostsAnalytics' /* webpackChunkName: "HostsAnalytics" */
  ),
)
const IntercityParcels = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/IntercityParcels/IntercityParcels' /* webpackChunkName: "IntercityParcels" */
  ),
)
const Settings = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/Settings/Settings' /* webpackChunkName: "Settings" */
  ),
)
const SettingsCityForm = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/Settings/tabs/Cities/formCity/CityForm' /* webpackChunkName: "SettingsCityForm" */
  ),
)

export const AdminRoutes = () => {
  return (
    <Switch>
      <Route exact path={routes.PROFILE} render={() => <Profile />} />
      <Route exact path={routes.REQUESTS} render={() => <Requests />} />
      <Route exact path={routes.ORDERS} render={() => <Orders />} />
      <Route exact path={routes.ORDERS_ADD} render={() => <OrderForm />} />
      <Route exact path={routes.ORDERS_EDIT} render={() => <OrderForm />} />
      <Route exact path={routes.PAYMENTS} render={() => <Payments />} />
      <Route exact path={routes.USERS} render={() => <Users />} />
      <Route exact path={routes.USERS_ADD} render={() => <UserForm />} />
      <Route exact path={routes.USERS_EDIT} render={() => <UserForm />} />
      <Route exact path={routes.LOCATIONS} render={() => <Locations />} />
      <Route
        exact
        path={routes.LOCATIONS_ADD}
        render={() => <LocationFormPage />}
      />
      <Route
        exact
        path={routes.LOCATIONS_EDIT}
        render={() => <LocationFormPage />}
      />
      <Route exact path={routes.TRIPS} render={() => <Trips />} />
      <Route exact path={routes.TRIPS_ADD} render={() => <TripForm />} />
      <Route exact path={routes.TRIPS_EDIT} render={() => <TripForm />} />
      <Route exact path={routes.TRIP_DETAILS} render={() => <TripDetails />} />
      <Route exact path={routes.HOSTS} render={() => <Hosts />} />
      <Route exact path={routes.HOSTS_ADD} render={() => <HostForm />} />
      <Route exact path={routes.HOSTS_EDIT} render={() => <HostForm />} />
      <Route exact path={routes.CLIENTS} render={() => <Clients />} />
      <Route exact path={routes.CLIENTS_ADD} render={() => <ClientForm />} />
      <Route exact path={routes.CLIENTS_EDIT} render={() => <ClientForm />} />
      <Route exact path={routes.ROUTES} render={() => <Routes />} />
      <Route exact path={routes.ROUTES_ADD} render={() => <RouteForm />} />
      <Route exact path={routes.ROUTES_EDIT} render={() => <RouteForm />} />
      <Route
        exact
        path={routes.LOCKER_DETAILS}
        render={() => <LockerDetails />}
      />
      <Route
        exact
        path={routes.LOCKERS}
        render={() => <LockersDashboardAdmin />}
      />
      <Route exact path={routes.LOCKERS_ADD} render={() => <LockerForm />} />
      <Route exact path={routes.LOCKERS_EDIT} render={() => <LockerForm />} />
      <Route
        exact
        path={routes.LOCKER_STATISTICS}
        render={() => <LockerStatistics />}
      />
      <Route
        exact
        path={routes.ORDERS_STATISTICS}
        render={() => <OrdersStatistics />}
      />
      <Route
        exact
        path={routes.CYCLE_ANALYSIS}
        render={() => <CycleAnalysis />}
      />
      <Route
        exact
        path={routes.PROVIDER_REPORT}
        render={() => <ProviderReport />}
      />
      <Route exact path={routes.TRIPS_REPORT} render={() => <TripsReport />} />
      <Route
        exact
        path={routes.HOSTS_ANALYTICS}
        render={() => <HostsAnalytics />}
      />
      <Route
        exact
        path={routes.INTERCITY_PARCELS}
        render={() => <IntercityParcels />}
      />
      <Route exact path={routes.SETTINGS} render={() => <Settings />} />
      <Route
        exact
        path={routes.SETTINGS_CITY_ADD}
        render={() => <SettingsCityForm />}
      />
      <Route
        exact
        path={routes.SETTINGS_CITY_EDIT}
        render={() => <SettingsCityForm />}
      />
      <Redirect to={routes.REQUESTS} />
    </Switch>
  )
}
