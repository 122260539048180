import React, { lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

const Header = lazy(() => import('../Header/Header'))

// Provider Routes
const Provider = lazy(() =>
  import(
    '../NavigationPanel/NavigationRoutes/ProviderRoutes/Trips/ProviderTripsTabs' /* webpackChunkName: "Provider" */
  ),
)
const TripView = lazy(() =>
  import(
    '../NavigationPanel/NavigationRoutes/ProviderRoutes/Trips/Trip/TripView' /* webpackChunkName: "TripView" */
  ),
)
const TripStops = lazy(() =>
  import(
    '../NavigationPanel/NavigationRoutes/ProviderRoutes/Trips/Trip/Stops/TripStops' /* webpackChunkName: "TripStops" */
  ),
)
const TripLocation = lazy(() =>
  import(
    '../NavigationPanel/NavigationRoutes/ProviderRoutes/Trips/Trip/Stops/LocationStops/TripLocation' /* webpackChunkName: "TripLocation" */
  ),
)
const TripLocationLockers = lazy(() =>
  import(
    '../NavigationPanel/NavigationRoutes/ProviderRoutes/Trips/TripLocationLockers' /* webpackChunkName: "TripLocationLockers" */
  ),
)
const TripLocationLockerCabinets = lazy(() =>
  import(
    '../NavigationPanel/NavigationRoutes/ProviderRoutes/Trips/TripLocationLockerCabinets' /* webpackChunkName: "TripLocationLockerCabinets" */
  ),
)

export const ProviderRoute = () => {
  return (
    <div
      style={{
        width: 320,
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        height: '100vh',
      }}
    >
      <Header />
      <Switch>
        <Route exact path='/provider' render={() => <Provider />} />
        <Route
          exact
          path='/provider/trip/:tripid'
          render={() => <TripView />}
        />
        <Route
          exact
          path='/provider/trip/:tripid/stops'
          render={() => <TripStops />}
        />
        <Route
          exact
          path='/provider/trip/:tripid/stops/location'
          render={() => <TripLocation />}
        />
        <Route
          exact
          path='/provider/trip/:tripid/stops/location/lockers'
          render={() => <TripLocationLockers />}
        />
        <Route
          exact
          path='/provider/trip/:tripid/stops/location/lockers/:id/cabinets'
          render={() => <TripLocationLockerCabinets />}
        />
        <Redirect to='/provider' />
      </Switch>
    </div>
  )
}
