import React, { useState } from 'react'
import { Dialog, TextArea } from '@blueprintjs/core'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import Header from '../../../../modules/RoutesComponents/HeaderRoute'
import { Button, ButtonsGroup } from '../..'

import s from './TrackLinkButton.module.scss'
import { updateTrackingLinks } from '../../../../services/api/orders'
import { showErrorMessage } from '../../../../modules/Toasters'

const TrackLinkButton = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [ordersText, setOrdersText] = useState('')
  const [processing, setProcessing] = useState(false)

  const toggleOpenModal = () => {
    setIsOpen((prevState) => !prevState)
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    setProcessing(true)

    const orders = ordersText.match(/\d+/gm)

    if (!orders) {
      showErrorMessage('No orders')
      setProcessing(false)
      return
    }

    const payload = { orders }

    const res = await updateTrackingLinks(payload)

    if (res) {
      setOrdersText('')
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    } else {
      setProcessing(false)
    }
  }

  return (
    <div>
      <Button
        text={t('changeTrackLink')}
        onClick={toggleOpenModal}
        classNameType='actionBorder'
      />

      <Dialog isOpen={isOpen} className={s.modal} usePortal={true}>
        <Header title={t('modals').upload.title} />
        <form className={classnames(s.form)} onSubmit={handleFormSubmit}>
          <TextArea
            fill={true}
            onChange={({ target }) => setOrdersText(target.value)}
            value={ordersText}
          />

          <ButtonsGroup
            dissmissProps={{ onGoBack: toggleOpenModal, disabled: processing }}
            actionProps={{
              disabled: !ordersText || processing,
              text: t('update'),
            }}
          />
        </form>
      </Dialog>
    </div>
  )
}

export default TrackLinkButton
