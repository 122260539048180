import React, { lazy } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { routes } from '../../../routes'

const LockersDashboardClient = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/LockersDashboard/Client/LockersDashboardClient' /* webpackChunkName: "LockersDashboardClient" */
  ),
)
const HostsAnalytics = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/Analytics/hosts/HostsAnalytics' /* webpackChunkName: "HostsAnalytics" */
  ),
)

export const TenantRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={routes.LOCKERS}
        render={() => <LockersDashboardClient />}
      />
      <Route
        exact
        path={routes.HOSTS_ANALYTICS}
        render={() => <HostsAnalytics />}
      />
      <Redirect to={routes.LOCKERS} />
    </Switch>
  )
}
