import axios from 'axios'
import qs from 'qs'

import { showErrorMessage } from '../../modules/Toasters'

import { refreshPageIfTokenWasExpired } from '../api.ts'

export const getHosts = async ({
  status,
  page = '',
  name = '',
  pageSize = 10,
}) => {
  let url = '/hosts'
  if (page) {
    url = `${url}?page=${page}`
  }
  try {
    const { data } = await axios.get(url, {
      params: {
        query: {
          $and: [{ status }, { name: { $regex: `.*${name}`, $options: 'i' } }],
        },
        sort: '-_id',
        pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })
    return data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}
export const getHostById = async ({ id }) => {
  try {
    const { data } = await axios.get(`/hosts/${id}`)
    return data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const createHost = async ({ data }) => {
  try {
    const response = await axios.post('/host', data)
    return response.data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const updateHost = async ({ data }) => {
  try {
    const item = await axios.put('/host', data)
    return item.data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const changeHostStatus = async ({ data }) => {
  try {
    await axios.put('/hosts/status', data)
    document.location.reload()
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}
