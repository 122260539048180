import React, { useCallback, memo } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { Select } from './Select'

import { PICKUP_OPTIONS } from '../../../data/constans'

export const PickupTime = memo(
  ({
    value = '',
    label,
    placeholder,
    withTimeRestriction = false,
    onChange = () => {},
    onChangeDate = () => {},
  }) => {
    const { t } = useTranslation()
    const handleChange = useCallback(
      (newValue) => {
        switch (newValue) {
          case 'Later':
            const afterTommorrow = moment().add(2, 'days')._d
            onChangeDate(afterTommorrow)
            break
          case 'Tomorrow':
            const tomorrow = moment().add(1, 'days')._d
            onChangeDate(tomorrow)
            break
          case 'Today':
            const today = moment()._d
            onChangeDate(today)
            break
          default:
            onChangeDate('')
            break
        }

        onChange(newValue)
      },
      [onChangeDate, onChange],
    )

    let options = PICKUP_OPTIONS
    if (withTimeRestriction) {
      const isAppliedTodayTime = moment().isBefore(
        moment({ hour: 15, minute: 0 }),
      )
      if (!isAppliedTodayTime) {
        options = PICKUP_OPTIONS.filter((o) => o !== 'Today')
      }
    }

    return (
      <Select
        value={value}
        options={options}
        placeholder={placeholder || t('pickupTime').label}
        label={label || t('pickupTime').placeholder}
        onChange={handleChange}
        Option={(props) => {
          const name = props[0]
          return (
            <option value={name}>
              {t('pickupTime').selectOptions[name.toLowerCase()]}
            </option>
          )
        }}
      />
    )
  },
)
