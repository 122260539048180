import React, { useState, useEffect, useCallback, useRef, memo } from 'react'
import { useAsync } from 'react-use'
import { Checkbox, Dialog, Icon } from '@blueprintjs/core'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { Button, Loader, Search } from '../'

import { useIntersect } from '../../../hooks/useIntersect'
import { getOrders, getOrdersAWB } from '../../../services/api/orders'

import s from './Clients.module.scss'
import { showErrorMessage } from '../../../modules/Toasters'
import { DateRangeInput } from '@blueprintjs/datetime'

const initialState = [moment().startOf('day')._d, moment().endOf('day')._d]

export const Orders = memo(
  ({
    className = '',
    onChange = () => {},
    filters,
    isAWB = false,
    selectedItems,
  }) => {
    const { t } = useTranslation()

    const [needReload, setNeedReload] = useState(false)
    const [dateRange, setDateRange] = useState(initialState)
    const [isOpen, setOpen] = useState(false)
    const [items, setItems] = useState([])
    const [currentItems, setCurrentItems] = useState([])
    const [hasNextPage, setHasNextPage] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [totalOrders, setTotalOrders] = useState(0)
    const [hasBeenLoadedAll, setHasBeenLoadedAll] = useState(false)
    const [searchNumber, setSearchNumber] = useState()

    const loadItems = async (isLoadAll = false) => {
      setIsLoading(true)
      try {
        let queryData = {
          query: {
            $and: [
              { client: filters?.client || null },
              { status: 'Initiated' },
            ],
          },
          sort: '-_id',
          page: hasBeenLoadedAll || isLoadAll ? 1 : pageNumber,
          pageSize: 100,
          dateFrom: dateRange[0],
          dateTo: dateRange[1],
          ...((isLoadAll || hasBeenLoadedAll) && { pageSize: totalOrders }),
        }

        const { data } = await getOrders(queryData)
        const { rows, page, totalPages, total } = data

        setTotalOrders(total)
        setHasNextPage(page < totalPages)
        setPageNumber(page !== totalPages ? page + 1 : totalPages)

        const filteredSelectedItems = rows
          .filter(({ _id }) => {
            return !selectedItems.find((order) => _id === order._id)
          })
          .map((item) => ({ ...item, isChecked: isLoadAll }))
        setItems((prevState) => [
          ...(isLoadAll ? [] : prevState),
          ...filteredSelectedItems,
        ])
      } catch (error) {
        showErrorMessage(error?.response?.data?.message)
      } finally {
        setIsLoading(false)
      }
    }

    const loadItemsAWB = async () => {
      const params = {
        page: pageNumber,
        sort: '-_id',
        dateFrom: dateRange[0],
        dateTo: dateRange[1],
      }

      if (filters?.client) {
        params.query = {
          $and: { client: { $in: [null, filters.client] } },
        }
      }

      const { rows, page, totalPages } = await getOrdersAWB(params)
      setHasNextPage(page < totalPages)
      setPageNumber(page !== totalPages ? page + 1 : totalPages)

      const filteredSelectedItems = rows.filter(({ _id }) => {
        return !selectedItems.find((order) => _id === order._id)
      })
      setItems((prevState) => [...prevState, ...filteredSelectedItems])
    }

    const handleToogleModal = useCallback(
      () => setOpen((prevState) => !prevState),
      [],
    )

    const handleGrabItems = useCallback(() => {
      const selectedItems = items.filter(({ isChecked }) => isChecked)
      onChange(selectedItems)
      handleToogleModal()
    }, [handleToogleModal, onChange, items])

    const myRef = useRef()
    const [ref, entry] = useIntersect({ root: myRef.current })
    const [selectedAll, setSelectedAll] = useState(false)

    useAsync(async () => {
      if (isLoading) return
      setIsLoading(true)
      try {
        isOpen &&
          hasNextPage &&
          (isAWB ? await loadItemsAWB() : await loadItems())
      } catch (error) {}
      setImmediate(false)
      setIsLoading(false)
    }, [entry, isOpen])

    useEffect(() => {
      if (searchNumber) {
        const newCurrentItems = items.filter((item) =>
          item.number.includes(searchNumber),
        )
        setCurrentItems(newCurrentItems)
      } else {
        setCurrentItems(items)
        setSelectedAll(false)
      }
    }, [searchNumber, items])

    useEffect(() => {
      if (!isOpen) return
      setSelectedAll(false)
      setImmediate(false)
      setIsLoading(false)
      setItems([])
      setCurrentItems([])
      setSearchNumber('')
      setPageNumber(1)
      setNeedReload(true)
    }, [dateRange])

    useAsync(async () => {
      if (needReload && !isLoading) {
        try {
          isAWB ? await loadItemsAWB() : await loadItems()
        } catch (error) {
        } finally {
          setNeedReload(false)
        }
      }
    }, [needReload])

    const handleSelectItem = (id) => {
      setItems((prevState) =>
        prevState.map((item) => {
          if (item._id === id) {
            return { ...item, isChecked: !item.isChecked }
          }
          return item
        }),
      )
    }

    const handleCloseModal = () => {
      setItems([])
      setPageNumber(1)
      setCurrentItems([])
      setSearchNumber('')
      setHasNextPage(true)
      handleToogleModal()
      setSelectedAll(false)
    }

    const handleSelectAll = ({ target }) => {
      setSelectedAll(target.checked)
      setItems((prevState) => [
        ...prevState.map((item) => {
          if (currentItems.find((i) => i._id === item._id)) {
            return { ...item, isChecked: target.checked }
          } else {
            return item
          }
        }),
      ])
    }

    const handleSearchNumber = (value) => {
      setSearchNumber(value)
    }

    useEffect(() => {
      const selectedItems = items.filter(({ isChecked }) => isChecked).length
      if (selectedItems && selectedItems === items.length) {
        setSelectedAll(true)
      }
    }, [items])

    return (
      <div className={classnames(s.clientSelect, className)}>
        <Button
          text={t('modals').orders.btnOpenModalLabel}
          onClick={handleCloseModal}
        />

        <Dialog isOpen={isOpen} className={s.modal} canOutsideClickClose={true}>
          <header className={s.modalHeader}>{t('modals').orders.title}</header>

          <div className={s.dateRangeFilterContainer}>
            <DateRangeInput
              formatDate={(date) => moment(date).format('YYYY-MM-DD')}
              onChange={setDateRange}
              parseDate={(str) => moment(str)}
              value={dateRange}
              className={s.dateRangeInput}
              highlightCurrentDay={true}
              shortcuts={false}
              allowSingleDayRange={true}
            />
          </div>
          <div className={s.panel}>
            <Search
              value={searchNumber}
              onChange={handleSearchNumber}
              placeholder={t('ordersRoute').filters.searchId}
              style={{ width: 200 }}
            />
            {!isAWB && !!currentItems.length && (
              <Checkbox
                label={t('modals').orders.selectAll}
                checked={items.length ? selectedAll : false}
                onChange={handleSelectAll}
              />
            )}
          </div>
          <div className={s.itemsList}>
            <Loader showLoader={isLoading} className={s.spinner} size={70} />
            {currentItems.map((item) => (
              <div
                ref={ref}
                key={item._id}
                className={s.listItem}
                onClick={() => handleSelectItem(item._id)}
              >
                <div>
                  <Icon
                    icon={item.isChecked ? 'tick-circle' : 'circle'}
                    style={{ marginRight: 20 }}
                  />{' '}
                  {item.number} {item.receiverFirstName} {item.receiverLastName}
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  {(item.extId || item.extNumber) && (
                    <span>
                      {`#${item?.extNumber || ''}`} |{' '}
                      {item?.extId && `Ext. ID - ${item.extId}`}
                    </span>
                  )}
                </div>
              </div>
            ))}
            {!hasNextPage && (
              <div className={s.fullLoadedLabel}>
                {t('modals').orders.allHitsLoaded}
              </div>
            )}
          </div>

          <div style={{ display: 'flex' }}>
            <Button
              text={t('modals').orders.cancelBtnLabel}
              classNameType='dismiss'
              style={{ marginRight: 20 }}
              onClick={handleToogleModal}
            />
            <Button
              text={t('modals').orders.okBtnLabel}
              onClick={handleGrabItems}
            />
          </div>
        </Dialog>
      </div>
    )
  },
)
