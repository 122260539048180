import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../reusableComponents'
import s from './SubHeader.module.scss'

export const SubHeader = ({
  boxStyle = null,
  title,
  isRoute = true,
  route,
  onClick,
}) => {
  return (
    <div className={s.headerBlock} style={{ ...boxStyle }}>
      <h5 className={s.header}>{title.text}</h5>
      {isRoute && (
        <>
          {route.type === 'button' ? (
            <Button onClick={onClick} text={route.title} className={s.btn} />
          ) : (
            <Link
              to={{
                pathname: route.path,
              }}
              className={`${s.btn} ${s.link}`}
            >
              <div>{route.title}</div>
            </Link>
          )}
        </>
      )}
    </div>
  )
}
