import React, { lazy, useEffect, useMemo } from 'react'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import { useTour } from '@reactour/tour'
import { useWindowSize } from 'react-use'
import { updateProfile } from '../../../services/api.ts'
import { useAuth } from '../../../hooks/useAuth.ts'
import { routes } from '../../../routes'
import { useAsync } from 'react-use'

const Profile = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Profile/Profile' /* webpackChunkName: "Profile" */
  ),
)
const Requests = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Requests/Requests' /* webpackChunkName: "Requests" */
  ),
)
const Orders = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Orders/Orders' /* webpackChunkName: "Orders" */
  ),
)
const OrderForm = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Orders/form/OrderForm' /* webpackChunkName: "OrderForm" */
  ),
)
const Payments = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Payments/Payments' /* webpackChunkName: "Payments" */
  ),
)
const Users = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Users/Users' /* webpackChunkName: "Users" */
  ),
)
const UserForm = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Users/form/UserForm' /* webpackChunkName: "UserForm" */
  ),
)
const Locations = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Locations/Locations' /* webpackChunkName: "Locations" */
  ),
)
const LocationFormPage = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/CommonsRoutes/Locations/form/LocationFormPage' /* webpackChunkName: "LocationFormPage" */
  ),
)
const LockersDashboardClient = lazy(() =>
  import(
    '../../NavigationPanel/NavigationRoutes/OperatorRoutes/LockersDashboard/Client/LockersDashboardClient' /* webpackChunkName: "LockersDashboardClient" */
  ),
)

export const ClientRoutes = () => {
  const { user, setAuthUser } = useAuth()
  const { currentStep, isOpen, setIsOpen } = useTour()
  const { push } = useHistory()
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width < 950, [width])

  useEffect(() => {
    if (isOpen && currentStep === 0) {
      push('/requests')
    }
  }, [isOpen, currentStep])

  useEffect(() => {
    if (user && user.client) {
      !isMobile && !user.isShowOnboarding && setIsOpen(true)
    }
  }, [user])

  useAsync(async () => {
    if (isOpen) {
      if (user && user.client) {
        const { firstName, lastName, phone } = user

        if (!user.isShowOnboarding) {
          const newDataUser = {
            firstName,
            lastName,
            phone,
            isShowOnboarding: true,
          }
          try {
            await updateProfile(newDataUser, setAuthUser)
          } catch (err) {
            console.log('err', err)
          }
        }
      }
    } else {
      document.body.style.overflow = 'scroll'
    }
  }, [isOpen])

  return (
    <Switch>
      <Route exact path={routes.PROFILE} render={() => <Profile />} />
      <Route exact path={routes.REQUESTS} render={() => <Requests />} />
      <Route exact path={routes.ORDERS} render={() => <Orders />} />
      <Route exact path={routes.ORDERS_ADD} render={() => <OrderForm />} />
      <Route exact path={routes.ORDERS_EDIT} render={() => <OrderForm />} />
      <Route exact path={routes.PAYMENTS} render={() => <Payments />} />
      <Route exact path={routes.USERS} render={() => <Users />} />
      <Route exact path={routes.USERS_ADD} render={() => <UserForm />} />
      <Route exact path={routes.USERS_EDIT} render={() => <UserForm />} />
      <Route exact path={routes.LOCATIONS} render={() => <Locations />} />
      <Route
        exact
        path={routes.LOCATIONS_ADD}
        render={() => <LocationFormPage />}
      />
      <Route
        exact
        path={routes.LOCATIONS_EDIT}
        render={() => <LocationFormPage />}
      />
      <Route
        exact
        path={routes.LOCKERS}
        render={() => <LockersDashboardClient />}
      />
      <Redirect to={routes.REQUESTS} />
    </Switch>
  )
}
