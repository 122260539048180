import { useState, useEffect } from 'react'

export const useLocalStorage = (key, defaultValue = null) => {
  const [state, setState] = useState(() => {
    return window.localStorage.getItem(key) ?? defaultValue
  })

  const removeKey = (keyForRemove) => {
    window.localStorage.removeItem(keyForRemove)
  }

  useEffect(() => {
    state && window.localStorage.setItem(key, state)
  }, [key, state])

  return [state, setState, removeKey]
}
