import React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import { Provider } from 'react-redux'
import { store } from 'src/redux/store'

import { AuthContext } from '../context/auth'

const MounterModal = ({ data, contextValue, Component }) => {
  let containerElement, pickupElement

  const mount = () => {
    const root = document.querySelector('#requestContainer')
    containerElement = document.createElement('div')

    pickupElement = (
      <Provider store={store}>
        <AuthContext.Provider value={contextValue}>
          <Component {...data} onDismiss={unmount} />
        </AuthContext.Provider>
      </Provider>
    )

    root.appendChild(containerElement)
    render(pickupElement, containerElement)
  }

  const unmount = () => {
    if (containerElement) {
      unmountComponentAtNode(containerElement)
      containerElement.remove()
      containerElement = null
      pickupElement = null
    }
  }

  mount()
}

export default MounterModal
