import React, { useState } from 'react'
import { Button } from '../../../reusableComponents'
import s from './EnterShipmentAWB.module.scss'

export const EnterShipmentAWB: React.FC = ({
  isActiveEdit = false,
  onVerifyShipmentAWB,
  shipmentAWB,
  setShipmentAWB,
}: any): JSX.Element => {
  const [isEditShipmentAWB, setIsEditShipmentAWB] = useState(isActiveEdit)

  return (
    <div className={s.verifyBox}>
      <input
        value={shipmentAWB}
        onChange={(e) => setShipmentAWB(e.target.value)}
        disabled={isEditShipmentAWB}
        placeholder='Enter Shipment AWB'
        className={s.verifyInput}
      />
      {isEditShipmentAWB && (
        <Button
          withIcon={true}
          iconProps={{ icon: 'edit', iconSize: 20 }}
          className={s.verifyBtn}
          onClick={() => setIsEditShipmentAWB(false)}
          iconStyle={{ margin: '0 auto' }}
        />
      )}
      <Button
        withIcon={true}
        iconProps={{ icon: 'tick', iconSize: 20 }}
        className={s.verifyBtn}
        onClick={onVerifyShipmentAWB}
        iconStyle={{ margin: '0 auto' }}
      />
    </div>
  )
}
