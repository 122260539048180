import React from 'react'
import { Dialog, Icon } from '@blueprintjs/core'
import QRCode from 'qrcode.react'
import printJS from 'print-js'
import { useTranslation } from 'react-i18next'
import Header from '../../../modules/RoutesComponents/HeaderRoute'
import variables from '../../../styles/variables'
import logo from '../../../modules/SiteLogo/logo.svg'
import s from './QRcode.module.scss'

export const QRcode = ({
  isOpenModal,
  onDismiss,
  title,
  fileName,
  valueQR,
}) => {
  const { t } = useTranslation()

  const downloadQR = () => {
    const canvas = document.getElementById('qrcode-object')

    const newCanvas = document.createElement('canvas')
    const context = newCanvas.getContext('2d')

    newCanvas.width = 330
    newCanvas.height = 330

    // create white background and center the text
    context.font = '40px Bahij TheSansArabic'
    context.fillStyle = 'white'
    context.textBaseline = 'middle'
    context.textAlign = 'center'
    context.fillRect(0, 0, 330, 330)

    // create style for text inside canvas
    context.fillStyle = variables.shuttleBlack
    // Shadow
    context.shadowColor = '#000'
    context.shadowBlur = 0.5
    context.shadowOffsetX = 0
    context.shadowOffsetY = 0
    context.fillText(fileName, newCanvas.width / 2.1, 30)
    // copy oldCanvas to newCanvas
    context.drawImage(canvas, 40, 60, 250, 250)

    const pngUrl = newCanvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')
    let downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.setAttribute('download', `QRCode of '${fileName}'.png`)

    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const printQR = () => {
    const canvas = document.getElementById('qrcode-object')

    const newCanvas = document.createElement('canvas')
    const context = newCanvas.getContext('2d')

    newCanvas.width = 330
    newCanvas.height = 330

    // create white background and center the text
    context.font = '40px Bahij TheSansArabic'
    context.fillStyle = 'white'
    context.textBaseline = 'middle'
    context.textAlign = 'center'
    context.fillRect(0, 0, 330, 330)

    // create style for text inside canvas
    context.fillStyle = variables.shuttleBlack
    // Shadow
    context.shadowColor = '#000'
    context.shadowBlur = 0.5
    context.shadowOffsetX = 0
    context.shadowOffsetY = 0
    context.fillText(fileName, newCanvas.width / 2.1, 30)
    // copy oldCanvas to newCanvas
    context.drawImage(canvas, 40, 60, 250, 250)

    printJS({
      printable: newCanvas.toDataURL(),
      type: 'image',
      imageStyle: 'width:100%',
    })
  }

  return (
    <Dialog
      isOpen={isOpenModal}
      className={s.modal}
      canOutsideClickClose={true}
    >
      <div className={s.qrcodeModal}>
        <div className={s.qrcodeHeader}>
          <Header title={title} />
          <Icon
            icon='delete'
            onClick={onDismiss}
            iconSize={20}
            className={s.iconClose}
          />
        </div>
        <div className={s.qrcodeBox}>
          <QRCode
            id='qrcode-object'
            value={valueQR}
            size={300}
            fgColor={variables.shuttleBlack}
            bgColor={variables.lightDarkGray}
            level={'M'}
            includeMargin={true}
          />
          <div>
            <span className={s.qrcodeBtn} onClick={downloadQR}>
              <Icon icon='cloud-download' color={variables.shuttleWhite} />
            </span>
            <span className={s.qrcodeBtn} onClick={printQR}>
              <Icon icon='print' color={variables.shuttleWhite} />
            </span>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
