import React from 'react'
import customerIcon from '../../../../assets/images/CustomerIcon.svg'
import { useTranslation } from 'react-i18next'

import s from './styles.module.scss'

export const CustomersSupport = ({ styles = null }) => {
  const { t, i18n } = useTranslation()
  return (
    <a
      href={`tel: ${process.env.REACT_APP_CUSTOMER_SUPPORT_PHONE}`}
      target='_blank'
      rel='noopener noreferrer'
      className={`${s.cards} ${styles}`}
      data-toggle='modal'
      data-target='#exampleModal2'
      style={{ minWidth: 170, height: 60 }}
    >
      <img className='ml-1' src={customerIcon} alt='' />
      <div
        className='my-auto ml-2'
        style={
          i18n.language === 'ar'
            ? { marginRight: '4px', marginLeft: '0px' }
            : {}
        }
      >
        <p className={s.subtitle}>
          {t('trackingPage').homePage.optionCards.customersSupport1}
        </p>
        <p className={s.title}>
          {t('trackingPage').homePage.optionCards.customersSupport2}
        </p>
      </div>
    </a>
  )
}
