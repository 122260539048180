import React from 'react'
import { useTranslation } from 'react-i18next'

import AsyncMap from './AsyncMap'

const MapView = ({ city, area, state, address, height, asyncMapProps }) => {
  const { t } = useTranslation()
  return (
    <div>
      <div>
        <div className='form-group'>
          <span style={{ fontWeight: 900 }}>{t('gmap').city}:</span>
          <span> {city}</span>
        </div>
        <div className='form-group'>
          <span style={{ fontWeight: 900 }}>{t('gmap').area}:</span>
          <span> {area}</span>
        </div>
        <div className='form-group'>
          <span style={{ fontWeight: 900 }}>{t('gmap').state}:</span>
          <span> {state}</span>
        </div>
        <div className='form-group'>
          <span style={{ fontWeight: 900 }}>{t('gmap').address}:</span>
          <span> {address}</span>
        </div>
      </div>
      <AsyncMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height }} />}
        mapElement={<div style={{ height: `100%` }} />}
        asyncMapProps={asyncMapProps}
      />
    </div>
  )
}

export default MapView
