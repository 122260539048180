import React, { useState, useCallback, useRef, useMemo, memo } from 'react'
import { useAsync } from 'react-use'
import { Dialog, Icon } from '@blueprintjs/core'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { TextInput, Button, Loader } from '../'

import { useIntersect } from '../../../hooks/useIntersect'
import { getUsers } from '../../../services/api/users'

import s from './Clients.module.scss'

export const ProvidersMulti = memo(
  ({
    value = [],
    className = '',
    onChange = () => {},
    onRemove = () => {},
    labelStyle = {},
    filters,
    withRemoveIcon = false,
  }) => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false)
    const [items, setItems] = useState([])
    const [hasNextPage, setHasNextPage] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const loadItems = async () => {
      const { rows, page, totalPages } = await getUsers({
        page: pageNumber,
        client: filters?.client || {},
        filters: [{ role: 'Provider' }],
      })
      setHasNextPage(page < totalPages)
      setPageNumber(page !== totalPages ? page + 1 : totalPages)
      setItems((prevState) => [...prevState, ...rows])
    }

    const handleToogleModal = useCallback(
      () => setOpen((prevState) => !prevState),
      [],
    )

    const handleSetItem = useCallback(
      (item) => {
        onChange(item)
        handleToogleModal()
      },
      [handleToogleModal, onChange],
    )

    const myRef = useRef()
    const [ref, entry] = useIntersect({ root: myRef.current })

    useAsync(async () => {
      setIsLoading(true)
      try {
        isOpen && hasNextPage && (await loadItems())
      } catch (error) {}
      setIsLoading(false)
    }, [entry, isOpen])

    const isShowRemoveIcon = useMemo(
      () => withRemoveIcon && !!value,
      [withRemoveIcon, value],
    )

    return (
      <div className={classnames(s.clientSelect, className)}>
        <Button
          text={t('modals').providers.openModalBtnText}
          onClick={handleToogleModal}
        />
        <div style={{ position: 'relative', flexDirection: 'column' }}>
          {value.length > 0 ? (
            value.map((p) => (
              <div style={{ position: 'relative' }}>
                <TextInput
                  disabled={true}
                  label={t('modals').providers.label}
                  placeholder={t('modals').providers.placeholder}
                  style={{ marginTop: 10, ...labelStyle }}
                  props={{ value: `${p.firstName} ${p.lastName}` }}
                />

                {isShowRemoveIcon && (
                  <Icon
                    className={s.removeIcon}
                    onClick={() => onRemove(p)}
                    icon='cross'
                    iconSize='14'
                  />
                )}
              </div>
            ))
          ) : (
            <>
              <TextInput
                disabled={true}
                label={t('modals').providers.label}
                placeholder={t('modals').providers.placeholder}
                style={{ marginTop: 10, ...labelStyle }}
                // props={{ p }}
              />

              {/* {isShowRemoveIcon && (
                            <Icon className={s.removeIcon} onClick={() => onRemove(p)} icon='cross' iconSize='14' />
                        )} */}
            </>
          )}
        </div>

        <Dialog isOpen={isOpen} className={s.modal} canOutsideClickClose={true}>
          <header className={s.modalHeader}>
            {t('modals').providers.header}
          </header>
          <div className={s.itemsList}>
            <Loader showLoader={isLoading} className={s.spinner} size={70} />
            {items.map((item) => (
              <div
                ref={ref}
                key={item._id}
                className={s.listItem}
                onClick={() => handleSetItem(item)}
              >
                {item.firstName} {item.lastName}
              </div>
            ))}
            {!hasNextPage && (
              <div className={s.fullLoadedLabel}>
                {t('modals').providers.loadCompleteMsg}
              </div>
            )}
          </div>

          <Button
            text={t('modals').providers.cancelBtnText}
            onClick={handleToogleModal}
          />
        </Dialog>
      </div>
    )
  },
)
