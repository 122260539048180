import { useState } from 'react'
import { useAsync } from 'react-use'

import { generate } from 'shortid'
const formatingData = (data, onSave) => {
  const keys = Object.keys(data)
  keys.forEach((objKey) => {
    const key = objKey === '_id' ? 'id' : objKey
    onSave(key, data[objKey])
  })
}

export const useFetchData = ({
  id,
  getAsyncCallback,
  setValue,
  setClient,
  setGeoLocation,
  setAddress,
  setZones,
  setLogisticsCompany,
  setOpenCabinetsByQr,
}) => {
  const [isShowForm, setShowForm] = useState(false)
  const [isLoadingData, setIsLoading] = useState(false)

  useAsync(async () => {
    if (id) {
      setIsLoading(true)
      const retrievedData = await getAsyncCallback({ id })
      setIsLoading(false)
      setShowForm(true)
      setClient(retrievedData.client)
      setGeoLocation({ lat: retrievedData.lat, lng: retrievedData.lng })
      setAddress(retrievedData.address)
      setOpenCabinetsByQr(retrievedData.openCabinetsByQr)
      const zones = retrievedData.zones.map((zone) => ({
        ...zone,
        zoneId: generate(),
      }))
      if (zones.length === 1) {
        zones[0].isPrimary = true
      }
      setZones(zones)
      formatingData(retrievedData, setValue)
      setLogisticsCompany(retrievedData?.logisticsCompany._id || null)
    }
  }, [id, getAsyncCallback, setValue])
  return { isLoadingData, isShowForm }
}
