import axios from 'axios'
import i18n from 'i18next'
import { refreshPageIfTokenWasExpired } from '../api'
import { showSuccessMessage, showErrorMessage } from '../../modules/Toasters'

export const verifyShipment = async (payload) => {
  try {
    const { data } = await axios.post(
      '/fodel/integration/shipment/verify',
      payload,
    )
    return data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const depositShipment = async (payload) => {
  try {
    const { data } = await axios.post(
      '/fodel/integration/shipment/deposit',
      payload,
    )
    showSuccessMessage(i18n.t('services').lockers.depositOrderSuccess)
    return data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const getShipmentByAwb = async (payload) => {
  try {
    const { data } = await axios.post(
      '/fodel/integration/shipment/awb',
      payload,
    )
    return data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const pickupShipment = async (payload) => {
  try {
    await axios.post('/fodel/integration/shipment/retrieve/rto', payload)
    showSuccessMessage(i18n.t('services').trips.pickupSuccess)
    return true
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}
