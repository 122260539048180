import React from 'react'
import cn from 'classnames'

import s from './Actions.module.scss'

export const ProviderActions: React.FC = ({
  actionClick,
  selectedAction,
}: any): JSX.Element => {
  return (
    <div className={s.actionsContainer}>
      <div
        className={cn(
          s.actionButton,
          selectedAction === 'Deposit' && s.selected,
        )}
        onClick={() => actionClick('Deposit')}
      >
        Deposit
      </div>
      <div
        className={cn(
          s.actionButton,
          selectedAction === 'Pickup' && s.selected,
        )}
        onClick={() => actionClick('Pickup')}
      >
        Pickup return
      </div>
    </div>
  )
}
