import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@blueprintjs/core'

import s from './styles.module.scss'

export const DropInCabinet = ({ onDrop }) => {
  const { t, i18n } = useTranslation()
  return (
    <div
      className={s.cards}
      style={{ width: 170, height: 60 }}
      onClick={onDrop}
    >
      <Icon className='ml-1' icon='send-to' />
      <div
        className='my-auto ml-2'
        style={
          i18n.language === 'ar'
            ? { marginRight: '4px', marginLeft: '0px' }
            : {}
        }
      >
        <p>{t('trackingPage').homePage.optionCards.dropIntoCabinet}</p>
      </div>
    </div>
  )
}
