import React, { useState, useCallback, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Switch } from '@blueprintjs/core'

import s from './ToogleAddressButton.module.scss'

const ToogleAddressBtn = ({ onChange }) => {
  const { t } = useTranslation()
  const [isCkecked, setIsChecked] = useState(false)

  const toogle = useCallback(
    ({ target }) => {
      const { checked } = target
      setIsChecked(checked)
      onChange(checked)
    },
    [onChange],
  )

  return (
    <Switch
      className={s.toogleAddressBtn}
      checked={isCkecked}
      onChange={toogle}
      innerLabelChecked={t('toogleAddressBtn').map}
      innerLabel={t('toogleAddressBtn').autocomplete}
    />
  )
}

export default memo(ToogleAddressBtn)
