import React, { useState } from 'react'
import { Dialog, Icon } from '@blueprintjs/core'
import { useTranslation } from 'react-i18next'
import Scanner from 'react-webcam-qr-scanner'
import s from './SmartModals.module.scss'

export const ScannerDialog = ({ onCloseDialog, onDecode }) => {
  const { t, i18n } = useTranslation()
  const [statusScan, setStatusScan] = useState()
  const [resScan, setResScan] = useState()

  const handleDecode = (res) => {
    if (res.data === resScan) {
      return
    } else {
      setResScan(res.data)
      onDecode(res.data)
    }
  }
  const handleScannerLoad = (mode) => {
    setStatusScan(mode)
  }

  return (
    <Dialog isOpen={true} canOutsideClickClose={true} className={s.modal}>
      <Icon
        className={s.closeBtn}
        icon='cross'
        iconSize={23}
        onClick={onCloseDialog}
      />
      <h5
        className={s.title}
        style={i18n.language === 'ar' ? { direction: 'rtl' } : {}}
      >
        {t('trackingPage').orderPage.scanQR}
      </h5>
      <div className={s.box}>
        <p className={s.warning}>{t('trackingPage').orderPage.scanQRWarning}</p>
        <div className={s.scanBox}>
          <Scanner
            className={s.scan}
            onDecode={(res) => {
              handleDecode(res)
            }}
            onScannerLoad={handleScannerLoad}
            constraints={{
              audio: false,
              video: {
                facingMode: 'environment',
              },
            }}
            captureSize={{ width: 1280, height: 720 }}
          />
        </div>

        <p className={`${s.scanLoader} ${!statusScan && resScan && s.white}`}>
          {t('trackingPage').orderPage.scanQRLoader}
        </p>
      </div>
    </Dialog>
  )
}
