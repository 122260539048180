import React, { useState, useCallback, useRef, useMemo, memo } from 'react'
import { useAsync } from 'react-use'
import { Checkbox, Dialog, Icon } from '@blueprintjs/core'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { TextInput, Button, Loader, Search } from '..'

import { useDebounce } from '../../../hooks'
import { useIntersect } from '../../../hooks/useIntersect'
import { getUsers } from '../../../services/api/users'

import s from './Clients.module.scss'

export const Providers = memo(
  ({
    value = '',
    className = '',
    onChange = () => {},
    onRemove = () => {},
    labelStyle = {},
    filters,
    withRemoveIcon = false,
  }) => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false)
    const [items, setItems] = useState([])
    const [hasNextPage, setHasNextPage] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const [showOnlineOnly, setShowOnlineOnly] = useState(false)
    const [isComponentInitiated, setComponentInitiated] = useState(false)
    const [needReloadData, setNeedReloadData] = useState(false)
    const [searchField, setSearchField] = useState('')

    const debounceSearchField = useDebounce(searchField, 500)

    const loadItems = async () => {
      const { rows, page, totalPages } = await getUsers({
        pageSize: 100,
        page: pageNumber,
        client: filters?.client || {},
        filters: [
          {
            status: 'Active',
          },
          {
            role: 'Provider',
          },
          {
            ...(showOnlineOnly && { providerStatus: 'Online' }),
          },
        ],
        filtersOr: [
          {
            firstName: { $regex: `.*${debounceSearchField}`, $options: 'i' },
          },
          {
            lastName: { $regex: `.*${debounceSearchField}`, $options: 'i' },
          },
        ],
      })
      setHasNextPage(page < totalPages)
      setPageNumber(page !== totalPages ? page + 1 : totalPages)
      setItems((prevState) => [
        ...prevState,
        ...rows.filter((row) => !row?.externalProviderCompany),
      ])
    }

    const handleToggleModal = useCallback(
      () => setOpen((prevState) => !prevState),
      [],
    )

    const handleSetItem = useCallback(
      (item) => {
        onChange(item)
        handleToggleModal()
      },
      [handleToggleModal, onChange],
    )

    const myRef = useRef()
    const [ref, entry] = useIntersect({ root: myRef.current })

    const isShowRemoveIcon = useMemo(
      () => withRemoveIcon && !!value,
      [withRemoveIcon, value],
    )

    const reset = () => {
      setItems([])
      setHasNextPage(true)
      setPageNumber(1)
    }

    const fetchUsers = async () => {
      if (isLoading) return
      setIsLoading(true)
      try {
        hasNextPage && (await loadItems())
      } catch (error) {}
      setIsLoading(false)
      if (!isComponentInitiated) {
        setComponentInitiated(true)
      }
      setNeedReloadData(false)
    }

    useAsync(async () => {
      if (isOpen) {
        fetchUsers()
      } else {
        reset()
      }
    }, [isOpen, entry])

    useAsync(async () => {
      if (isLoading || !isComponentInitiated) return
      reset()
      setNeedReloadData(true)
    }, [showOnlineOnly, debounceSearchField])

    useAsync(async () => {
      if (needReloadData) {
        await fetchUsers()
      }
    }, [needReloadData])

    return (
      <div className={classnames(s.clientSelect, className)}>
        <Button
          text={t('modals').providers.openModalBtnText}
          onClick={handleToggleModal}
        />

        <TextInput
          disabled={true}
          label={t('modals').providers.label}
          placeholder={t('modals').providers.placeholder}
          style={{ marginTop: 10, ...labelStyle }}
          props={{ value }}
        />

        {isShowRemoveIcon && (
          <Icon
            className={s.removeIcon}
            onClick={onRemove}
            icon='cross'
            iconSize='14'
          />
        )}

        <Dialog isOpen={isOpen} className={s.modal} canOutsideClickClose={true}>
          <header className={s.modalHeader}>
            {t('modals').providers.header}
          </header>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: 10,
            }}
          >
            <Search
              value={searchField}
              onChange={setSearchField}
              placeholder={t('locationsRoute').filters.searchByNamePlaceholder}
              style={{ width: 200, marginBottom: 15 }}
              autoFocus={true}
            />

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 10,
              }}
            >
              <Checkbox
                onChange={({ target }) => setShowOnlineOnly(target.checked)}
                checked={showOnlineOnly}
                style={{ marginBottom: 0 }}
              />
              Show Online Only
            </div>
          </div>

          <div className={s.itemsList}>
            <Loader showLoader={isLoading} className={s.spinner} size={70} />
            {items.map((item) => (
              <div
                ref={ref}
                key={item._id}
                className={s.listItem}
                onClick={() => handleSetItem(item)}
              >
                {item.firstName} {item.lastName}
              </div>
            ))}
            {!hasNextPage && (
              <div className={s.fullLoadedLabel}>
                {t('modals').providers.loadCompleteMsg}
              </div>
            )}
          </div>

          <Button
            text={t('modals').providers.cancelBtnText}
            onClick={handleToggleModal}
          />
        </Dialog>
      </div>
    )
  },
)
