import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Select } from './Select'

import {
  LOCATION_TYPE_CLIENT,
  LOCATION_TYPE_ADMIN,
} from '../../../data/locations'
import { useUserRole } from '../../../hooks'

export const LocationType = memo(
  ({ selectRef, value, onChange, customOptions = null }) => {
    const { t } = useTranslation()
    const { isAdmin, isClient } = useUserRole()
    let options = []
    if (isAdmin) {
      options = LOCATION_TYPE_ADMIN
    }
    if (isClient) {
      options = LOCATION_TYPE_CLIENT
    }
    if (customOptions && isClient) {
      options = customOptions
    }
    return (
      <Select
        selectRef={selectRef}
        value={value}
        options={options}
        placeholder={t('locationTypes').placeholder}
        label={t('locationTypes').label}
        onChange={onChange}
        Option={(props) => {
          const name = props[0]
          return <option value={name}>{name}</option>
        }}
      />
    )
  },
)
