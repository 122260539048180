import variables from '../../styles/variables'

export const en = {
  translation: {
    authForm: {
      logInPage: {
        title: 'Log In',
        loginPlaceholder: 'Email',
        PasswordPlaceholder: 'Password',
        forgotPasswordLinkLabel: 'Forgot Password',
        loginBtn: 'Log In',
        signupLinkLabel: 'Don’t have account? Sign up',
        privacyPolicy: 'Privacy Policy',
        tooltips: {
          email: 'Enter your email',
          hidePassword: 'Hide Password',
          showPassword: 'Show Password',
        },
      },
      forgotPasswordPage: {
        title: 'Restore Password',
        subtitle: 'Enter your email and we’ll send you a reset link.',
        successMessage:
          'We have sent you an email to verify your account. Please follow the instructions from there in order to login.',
        emailPlaceholder: 'Email',
        resetPasswordBtn: 'Reset password',
        loginLinkLabel: 'Have an account? Log In',
        tooltips: {
          email: 'Enter your email',
        },
      },
      signUpPage: {
        title: 'Sign Up',
        firstNamePlaceholder: 'Your First Name',
        lastNamePlaceholder: 'Your Last Name',
        emailPlaceholder: 'Your Business Email',
        phonePlaceholder: 'Your Phone Number +966',
        clientNamePlaceholder: 'Your Store’s Web Address',
        citySelectPlaceholder: 'Your Store’s City',
        districtSelectPlaceholder: 'Your Store’s District',
        passwordPlaceholder: 'Choose your password',
        confirmPasswordPlaceholder: 'Confirm Password',
        privacyPolicyText: 'By signing up you agree to Shttle’s',
        privacyPolicyLink: 'Terms of Service and Privacy Policy.',
        signupBtn: 'Finish',
        backStepBtn: 'Back',
        continueBtn: 'Continue',
        signupStepBtn: 'Get Started Free',
        moreAbout:
          'Tell us more about your business to personalize your experience',
        loginLinkLabel: 'Have an account? Log In',
        joinBlock: {
          title: 'Join Shttle lockers network.',
          text: 'Let your customers pickup their orders from more than 200 lockers around Saudi Arabia.',
          paragraph1: 'Create shipment orders in a few steps.',
          paragraph2:
            'Deposit orders to the nearest locker or let us pick up orders from your store or warehouse.',
          paragraph3: 'Start with 2 free orders, they are on us.',
        },
        tooltips: {
          hidePassword: 'Hide Password',
          showPassword: 'Show Password',
        },
        popups: {
          confirmRegistration:
            'We have sent you an email to verify your account. Please follow the instructions from there in order to login',
        },
      },
    },
    onboarding: {
      titles: {
        title1: 'Create new order',
        title2: 'Add order information',
        title3: 'Print AWB',
        title4: 'Create a request',
        title5: 'Configure Deposit',
        title6: 'Review',
        title7: 'Complete Deposit',
        title8: 'Select Location',
        title9: 'Select pickup time',
      },
      buttons: {
        acknolwedge: 'Acknolwedge',
        start: 'Start your tour',
        later: 'Take the tour later',
        skip: 'Skip tour',
        end: 'End tour',
        ok: 'OK',
      },
      steps: {
        welcomeStep: {
          title: {
            welcome: 'Welcome',
            shttle: 'to shttle!',
          },
          text: 'Shttle allows you to ship orders to your customers’ nearest lockers. You just need to create an order and deposit it, or ask us to pick it up from your store or warehouse. Let’s create an order together.',
        },
        step1: 'Click on the orders page to check your orders.',
        step2:
          'Every time a customer submits an order to you, you can ask us to handle the shipment by creating a new order.',
        step3:
          'Fill all the required data about the consignee. Finally click on “Save”',
        step4:
          'Your consignee has received an SMS and the order is now in “pending” state. Once your shipment is ready, click on “Actions”.',
        step5:
          'Before doing any action, click on “Print AWB” in order to print your shipping label.',
        step6:
          'Print your AWB, stick it on the parcel, and close the popup window.',
        step7:
          'Once your AWB is attached on the shipment, click on “Actions” again.',
        step8:
          'If you want to deposit the parcel to your nearest locker click on “Deposit”, otherwise if you want to request a pickup from your store, click on “Request pickup”.',
        step9: 'Click on “Select Location”',
        step10:
          'Add a new location or choose an existing one if you have already created one.',
        step11: 'Select a locker and click “Next” ',
        step12:
          'Select the cabinet size that suits you order dimensions, then click “Next”.',
        step13: 'Review everything and confirm',
        step14: 'Since you are not at the locker, select “Later”',
        step15:
          'Since you have just created a request, the amount of orders on your account has now decreased.',
        step16:
          'Go to “Requests” in order to complete the deposit at the locker.',
        step17: {
          title: 'When ready to deposit, please',
          item1: 'go to the locker',
          item2:
            'open the Shttle platform again, go to "Requests" and open the “Actions” menu to complete the deposit tour',
        },
        step19: 'Click on “Select Location”',
        step20:
          'Add a new location or choose an existing one if you have already created one.',
        step21:
          'Choose when Shttle can come to your location to pick up the order',
        congratulationsDepositStep: {
          title: 'Congratulations',
          text: 'You have successfully requested a deposit. Now, prepare your order and go to the locker to deposit it.',
        },
        congratulationsPickupStep: {
          title: 'Congratulations',
          text: 'You have successfully requested a pickup. Now make sure your order is ready, while Shttle comes to pickup.',
        },
      },
    },
    profile: {
      header: 'My Account',
      remainingOrders: 'Remaining Orders:',
      firstNamePlaceholderAndLabel: 'First Name',
      lastNamePlaceholderAndLabel: 'Last Name',
      phoneNumberPlaceholderAndLabel: 'Phone Number',
      changePasswordText: 'Change Password',
      oldPasswordPlaceholderAndLabel: 'Old Password',
      newPasswordPlaceholderAndLabel: 'New Password',
      newConfirmPasswordPlaceholderAndLabel: 'Confirm Password',
      resetPasswordBtnText: 'Reset Password',
      popups: {
        largeImageError: 'Image is large then 15MB',
      },
    },
    header: {
      remainingOrders: 'Remaining Orders',
      onboarding: {
        button: 'Onboarding Tour',
        confirmText:
          'Are you sure you want to start the tour? You will exit any current activity.',
      },
      userLogoPopover: {
        myAccount: 'My Account',
        editLogo: 'Edit Logo',
        editPassword: 'Edit Password',
        logout: 'Log Out',
      },
    },
    navigationsPanel: {
      hosts: 'Hosts',
      requests: 'Requests',
      orders: 'Orders',
      payments: 'Payments',
      users: 'Users',
      locations: 'Locations',
      trips: 'Trips',
      clients: 'Clients',
      routes: 'Routes',
      lockers: 'Lockers',
      lockersStatistics: 'Lockers Statistics',
      ordersStatistics: 'Orders Statistics',
      cycleAnalysis: 'Cycle Analysis',
      providerReport: 'Provider Report',
      tripsReport: 'Trips Report',
      settings: 'Settings',
      intercityParcels: 'Intercity Parcels',
      hostAnalytics: 'Hosts Analytics',
    },
    providerRoute: {
      assigned: {
        title: 'Assigned Trips',
        emptyMessage: 'No Assigned Trips',
      },
      inProgressTrips: {
        title: 'In-Progress Trips',
        emptyMessage: 'No trips in Progress',
      },
      upcomingTrips: {
        title: 'Upcoming Trips',
        emptyMessage: 'No trips scheduled',
      },
      trip: {
        detailsBtnText: 'View Details',
        view: {
          backToListBtnText: 'Go Back To List',
          pausedByAdmin: 'Trip is Paused, to resume trip contact Admin',
          paused: 'Trip is Paused, to continue with trip press Resume button',
          goBackToStopsBtnText: 'Go Back To Stops',
          goBackBtnText: 'Go Back',
          stopsText: 'Stops',
          pickupText: 'Pickup',
          dropText: 'Drop',
          startTripBtnText: 'Start Trip',
          viewTripBtnText: 'View Trip',
          endTripBtnText: 'End Trip',
          noOrdersToPickup: 'No orders to pickup',
          setOrderToService: 'Select a order to service',
          orderId: 'Order ID',
          shipmentAWB: 'Shipment AWB',
          lockerId: 'Locker ID',
          cabinet: 'Cabinet',
          openBtnText: 'Open',
          pickupBtnText: 'Pickup',
          dropOrdersAsText: 'Drop orders as',
          multy: 'Multy',
          single: 'Single',
          noOrdersToDrop: 'No orders to drop',
          orders: 'order(s)',
          client: 'Client:',
          selectCabinetBtnText: 'Select Cabinet',
          dropBtnText: 'Drop',
          selectLockerText: 'Select a locker',
          sizes: 'Sizes',
          locker: 'Locker',
          viewCabinetsBtnText: 'View Cabinets',
          avaliableCabinetsText: 'Available Cabinets',
          size: 'Size',
          width: 'Width',
          height: 'Height',
          parcel: 'Parcel',
        },
        modals: {
          confirmPickup: {
            firstTitleOpen: 'Are you really want to open locker?',
            secondTitleOpen: 'Are you approve that you picked-up order?',
            firstTitlePickup: 'Are you really want to pick-up order?',
            secondTitlePickup: 'Are you approve that you picked-up order?',
            yesBtnText: 'Yes',
            notBtnText: 'Not',
          },
          confrmDrop: {
            title: 'Are you really want to drop order(s)?',
            yesBtnText: 'Yes',
            notBtnText: 'Not',
          },
          cofirmDropSingle: {
            firstTitle: 'Are you really want to drop order?',
            secondTitle: 'Are you approve that order is dropped?',
            yesBtnText: 'Yes',
            notBtnText: 'Not',
          },
          confirmDropLastStep: {
            firstTitle: 'Are you really want to open cabinet?',
            secondTitle: 'Are you approve that order(s) in the cabinet?',
            yesBtnText: 'Yes',
            notBtnText: 'Not',
            dropBtnText: 'Drop',
          },
          cofirmAcceptTripTitle: 'Are you confirm that you ACCEPT trip?',
          cofirmDeclineTripTitle: 'Are you confirm that you DECLINE trip?',
        },
      },
      emptyMessage: 'No Trips',
    },
    requestRoute: {
      header: 'Requests',
      tabs: {
        Unassigned: 'Unassigned',
        Assigned: 'Assigned',
        Completed: 'Completed',
        Cancelled: 'Cancelled',
        countLabel: 'Request(s)',
      },
      filters: {
        searchByIdPlaceholder: 'Search by ID',
        filterByLabel: 'Filter By:',
      },
      columnsHeaders: {
        requestId: 'Request ID',
        location: 'Location',
        locker: 'Locker',
        orders: 'Orders',
        date: 'Date',
        noOfOrders: 'No. Of Orders',
        type: 'Type',
        preferredTime: 'Preferred Time',
        types: {
          Deposit: 'Deposit',
          Pickup: 'Pickup',
        },
        client: 'Client',
        actions: 'Actions',
      },
      actionPopover: {
        deposit: 'Deposit',
        editRequest: 'Edit Request',
        cancelRequest: 'Cancel Request',
      },
      notFoundText: 'Uh Oh! No Requests found.',
    },
    paymentsRoute: {
      header: 'Payments upon Collection',
      btnAsAvailable: 'Mark as Available',
      btnAsRemitted: 'Mark as Remitted',
      total: 'Total',
      remittanceIdLabel: 'Remittance ID',
      notFoundText: 'Uh Oh! No Payments found.',
      tabs: {
        'To Pay': 'To Pay',
        Processing: 'Processing',
        Available: 'Available',
        Remitted: 'Remitted',
        countLabel: 'Payment(s)',
      },
      warnings: {
        admin: {
          'To Pay': 'Payments not yet captured from consignees.',
          Processing:
            'Payments already captured from consignees and being processed by the payment service provider.',
          Available:
            'Payments currently available on Shttle account to be remitted to clients according to contractual agreements.',
          Remitted: 'Amounts already remitted to clients',
        },
        client: {
          'To Pay': 'Payments not yet captured from consignees.',
          Processing:
            'Payments already captured from consignees and being processed by the payment service provider.',
          Available:
            'Payments currently available on Shttle account. The total amount will be remitted to you according to contractual agreements',
          Remitted:
            'Total amounts already remitted. It may take 1-3 business days until the payment is visible on your bank transactions statement.',
        },
      },
      columnsHeaders: {
        remittanceId: 'Remittance ID',
        totalRemittedAmount: 'Total remitted amount (SAR)',
        remittedOn: 'Remitted on',
        orderId: 'Order ID',
        client: 'Client',
        status: 'Order Status',
        lastChanged: 'Last Changed on',
        requestId: 'Request ID',
        paymentAmount: 'Payment amount (SAR)',
        paidOn: 'Paid on',
        paidWith: 'Paid with',
        remittance: 'Remittance amount (SAR)',
        availableSince: 'Available since',
      },
      modals: {
        confirmMarkAsAvailable: {
          title: 'Confirmation',
          text: 'Are you sure you want to mark these amounts as now available on Shttle bank account?',
          text2: 'This action is irreversible',
        },
        confirmMarkAsRemitted: {
          title: 'Confirmation',
          text: 'The selected amount(s) will be marked as transferred to the client(s).',
          text2: 'This action is irreversible. Do you confirm?',
        },
      },
    },
    ordersRoute: {
      header: 'Orders',
      newOrder: 'New Order',
      filters: {
        label: 'Sort By',
        options: {
          updatedAt: 'Updated At',
          createdAt: 'Created At',
        },
        searchId: 'Search by Id',
      },
      tabs: {
        Pending: 'Pending',
        Scheduled: 'Scheduled',
        'Returns Ready for Pickup': 'Returns Ready for Pickup',
        Delivered: 'Delivered',
        'Under investigation': 'Under investigation',
        Processed: 'Processed',
        Cancelled: 'Cancelled',
        'Locker SLA Broken': 'Locker SLA Broken',
        countLabel: 'Order(s)',
        btnsGroup: {
          upload: 'Upload',
          download: 'Download',
          printAWBS: ' Print AWBS',
          pickup: 'Request Pickup',
          deposit: 'Deposit',
        },
      },
      columnsHeaders: {
        orderId: 'Order ID',
        orderNumber: 'Order Number',
        originOrderId: 'Origin Order ID',
        returnOrderId: 'Return Order ID',
        extId: 'Ext. ID',
        receiver: 'Consignee Name',
        phone: 'Phone',
        location: 'Location',
        lockerInfo: 'Locker Info',
        status: 'Status',
        lockerTiming: 'Locker Timing',
        requestId: 'Request ID',
        client: 'Client',
        type: 'Type',
        types: {
          Deposit: 'Deposit',
          Pickup: 'Pickup',
        },
        issueType: 'Issue type',
        actions: 'Actions',
      },
      formCreation: {
        titleCreate: 'Add Order',
        titleEdit: 'Edit Order',
        senderBlockTitle: 'Sender Info',
        trackingLinkLabel: 'Open Tracking Page',
        receiverAddress: 'Receiver Address',
        customOrderNumber: 'Custom Order Number',
        loadText: 'Loading order data ...',
        extId: 'external identifier',
        errorClientMessage: 'Please, select a client',
        address: 'Address',
        paymentLabel: 'Payment upon Collection',
        paymentText: 'Let the customer pay when collecting the order',
        amountToCollect: 'Amount to collect (SAR)',
        amountToCollectHelperText: 'For example, 1.00',
        validateAmountToCollect:
          'Value must be numeric with max 2 decimal digits',
        next: 'Next',
      },
      actionPopover: {
        markAsDelivered: 'Mark as Delivered',
        markAsDeliveredText:
          'Are you really want to mark the order as Delivered?',
        edit: 'Edit',
        track: 'Track',
        cancel: 'Cancel',
        delete: 'Delete',
        markAsLost: 'Mark as Lost',
        putIntoLocker: 'Put into origin locker',
        resendSms: 'Re-send SMS',
        deposit: 'Deposit',
        depositBySender: 'Deposit (by Sender)',
        pickup: 'Request Pickup',
        printAWB: 'Print AWB',
        trackLinkCopy: 'Track Link (Copy)',
        depositLinkCopy: 'Deposit link (copy)',
        return: 'Return order',
        markAsLostText: 'Are you really want to mark as lost order?',
        cancelText: 'Are you really want to cancel order?',
        deleteText: 'Are you really want to delete order?',
        returnText: 'It will set all Orders in the cabinet as Not Delevered',
        returnDeliverdText: 'Are you really want to request return order?',
      },
      tooltips: {
        locationIsNotServedText: 'Location is not served!',
        cityIsNotServedText: 'City is not served!',
        districtIsNotServedText: 'District is not served!',
        returnedOrder: 'Returned Order',
      },
      popups: {
        refillBalanceError: 'Please refill balance first',
        copiedLinkSuccess: 'Copied!',
      },
      notFoundText: 'Uh Oh! No Orders found.',
    },
    usersRoute: {
      header: 'Users',
      newUser: 'New User',
      deactivateAll: 'Deactivate all',
      tabs: {
        Active: 'Active',
        Inactive: 'Inactive',
        countLabel: 'User(s)',
      },
      columnsHeaders: {
        name: 'Name',
        type: 'Type',
        client: 'Client',
        host: 'Host',
        email: 'Email',
        actions: 'Actions',
        externalCourier: {
          externalCourier: 'External Courier',
          courierCompany: 'Courier Company',
          yesText: 'Yes',
          noText: 'No',
        },
      },
      formCreation: {
        titleCreate: 'Add User',
        titleEdit: 'Edit User',
        loadText: 'Loading user data ...',
        providersId: 'Provider`s ID',
        providersDate: 'Joining date',
        fixedAmount: 'Fixed amount',
        tripRate: 'Trip Rate',
        externalCourier: {
          externalCourier: 'External Courier',
          courierCompany: 'Courier Company',
          courierCompanyToken: 'Courier Company Token',
        },
        integration: {
          label: 'Integration',
          checkbox: 'Enable/disable integration with services',
        },
        validate: {
          invalid: 'Invalid value',
          negativeValue:
            'Negative value is not allowed. Please enter 0.00 or bigger number.',
        },
      },
      actionPopover: {
        edit: 'Edit',
        Activate: 'Activate',
        Deactivate: 'Deactivate',
        resetPassword: 'Reset Password',
        verifyEmail: 'Verify Email',
      },
      popups: {
        refillBalanceError: 'Please refill balance first',
      },
      searchByEmailPlaceholder: 'Search Email',
      notFoundText: 'Uh Oh! No Users found.',
      emailNotVerified: 'Not Verified',
    },
    locationsRoute: {
      header: 'Locations',
      newLocation: 'New Location',
      tabs: {
        Active: 'Active',
        Inactive: 'Inactive',
        countLabel: 'Location(s)',
      },
      filters: {
        filterByText: 'Filter by:',
        searchByNamePlaceholder: 'Search by name',
      },
      columnsHeaders: {
        name: 'Name',
        type: 'Type',
        address: 'Address',
        location: 'Location',
        zone: 'Primary Zone',
        client: 'Client',
        logisticsCompany: 'Logistics company',
        actions: 'Actions',
      },
      formCreation: {
        titleCreate: 'Create Location',
        titleEdit: 'Edit Location',
        loadText: 'Loading location data ...',
        zoneLimits: 'Should be at least one zone',
        addressInputPlaceholder: 'Type address ...',
        noOptionsMessage: 'No Options',
        clients: {
          label: 'This is a client location?',
          innerLabel: 'No',
          innerLabelChecked: 'Yes',
        },
        logisticCompany: {
          placeholder: 'Select Logistic Company',
          label: 'Logistic Company',
        },
        qr: {
          label: 'Before open cabinet',
          yes: 'Scan QR code',
          no: 'Do not scan QR',
        },
      },
      actionPopover: {
        edit: 'Edit',
        Activate: 'Activate',
        Deactivate: 'Deactivate',
        qrcode: 'QR-code',
      },
      popups: {
        zoneInListWarning: 'Zone is already exist',
      },
      notFoundText: 'Uh Oh! No Locations found.',
    },
    tripsRoute: {
      header: 'Trips',
      newTrip: 'New Trip',
      generateTrips: {
        generateTripsButton: 'Generate Trips',
        approveGenerateTripTitle:
          'Are you really want to start generate trips?',
        startGenerate: 'Start',
        selectLogisticsCompany: {
          title: 'Select Logistics Company for Intercity Orders',
          or: 'OR',
          subtitle:
            'Select Distribution Points to Drop Off intercity orders at Origin Cities',
          orderId: 'ORDER ID',
          orders: 'Orders',
          btnApply: 'Apply to All Dest Cities',
          monoSelectOriginDP: 'Select Origin DP',
          multySelectOriginDP: 'Different companies selected',
        },
        btnProceed: 'Proceed with Trip Generation',
        ordersNotFound: 'Orders not found',
        intercityOrdersNotFound:
          'Intercity orders not found. You can proceed with trips generation for Intracity orders',
      },
      generateTripsHistory: {
        columnsHeadersRouting: {
          startTime: 'Start Time',
          finishTime: 'Finish Time',
          status: 'Status',
          cancelBtn: 'Cancel',
          created: 'Created',
        },
        generateTripsHistoryNotFound: 'Not found',
        generateTripsHistoryButton: 'Generate Trips History',
        currentGenerateTripsTitle: 'Current Generate Trips',
      },
      tabs: {
        Unassigned: 'Unassigned',
        All: 'All',
        Upcoming: 'Upcoming',
        'In-Progress': 'In-Progress',
        'Waiting Response': 'Waiting Response',
        Completed: 'Completed',
        countLabel: 'Trip(s)',
      },
      filters: {
        searchByIdPlaceholder: 'Search by ID',
      },
      columnsHeaders: {
        tripId: 'Trip ID',
        map: 'Map',
        startLocation: 'Start Location',
        endLocation: 'End Location',
        scheduledDate: 'Scheduled Date',
        startTime: 'Start Time',
        endTime: 'End Time',
        duration: 'Duration (Hs)',
        paused: 'Paused',
        assignedProvider: 'Assigned Provider',
        status: 'Status',
        actions: 'Actions',
      },
      columnsHeadersDnd: {
        stop: 'Stop',
        location: 'Location',
        distance: 'Distance',
        type: 'Type',
        pickups: 'Pickups',
        drops: 'Drops',
        lockers: 'Lockers',
        avaliableLockers: 'Available Lockers',
        actions: 'Actions',
      },
      formCreation: {
        titleCreate: 'Create Trip',
        titleEdit: 'Edit Trip',
        scheduledLabel: 'Schedule At',
        loadText: 'Loading trip data ...',
        locationsLimits: 'Add at least 2 locations to create a trip',
      },
      detailsPage: {
        backBtnText: 'Back',
        tripId: 'Trip ID',
        assignedProvider: 'Assigned Provider',
        startTime: 'Start Time',
        endTime: 'End Time',
        duration: 'Duration',
        assignmentHistory: 'Assignment History',
        columns: {
          stop: 'Stop',
          time: 'Time',
          location: 'Location',
          type: 'Type',
          pickup: 'Pick up Orders',
          drop: 'Drop off Orders',
          cabinet: 'Cabinet',
          orders: 'Orders',
          action: 'Action',
          date: 'Date',
          provider: 'Provider',
          author: 'Author',
        },
      },
      actionPopover: {
        edit: 'Edit',
        assignProvider: 'Assign Provider',
        details: 'Details',
        updateProvider: 'Update Provider',
        delete: 'Delete',
        pauseTrip: 'Pause Trip',
        resumeTrip: 'Resume Trip',
      },
      popups: {
        locationInTripError: 'Location is already exist in the Trips',
        emptyDropsOrPickupsWarning:
          'You dont have locations with pickups and drops orders',
      },
      notFoundText: 'Uh Oh! No Trips found.',
    },
    intercityParcelsRoute: {
      header: 'Intercity Parcels',
      tabs: {
        'Dropped at the Origin DP': 'Dropped at the Origin DP',
        'In Shipment': 'In Shipment',
        'Arrived at Destination DP': 'Arrived at Destination DP',
        Completed: 'Completed',
        countLabel: 'Intercity Parcel(s)',
      },
      filters: {
        filterByLabel: 'Filter By:',
        searchByExterIDPlaceholder: 'Search By Ext. ID',
        searchByCompanyPlaceholder: 'Search By Company',
        markBtn: 'Mark as Arrived at Destination DP',
        confirm: {
          askAboutAllIntercityParcels:
            'Are you sure that you want to mark selected intercity parcels on this page as Arrived at Destination DP?',
        },
      },
      columnsHeaders: {
        externalID: 'External ID',
        originDP: 'Origin DP',
        destinationDP: 'Destination DP',
        date: 'Date',
        masterAWBCode: 'Master AWB Code',
        logisticsCompany: 'Logistics Company',
        received3pl: 'Received by 3PL',
        actions: 'Actions',
      },
      actionPopover: {
        details: 'Details',
        markAsArrivedAtDestinationDP: 'Mark as Arrived at Destination DP',
        markOrdersAsArrivedAtDestinationDP:
          'Mark Orders as Arrived at Destination DP',
        printMasterAWBCode: 'Print Master AWB',
      },
      intercityParcelDetails: {
        title: 'Intercity Parcel Details',
        requestID: 'Request ID',
        externalNumber: 'External Number',
        logisticsCompany: 'Logistics Company',
        pickupLocation: 'Pickup Location',
        columnsHeaders: {
          orderID: 'Order ID',
          orderNumber: 'Order Number',
          extID: 'Ext ID',
          client: 'Client',
          consignee: 'Consignee',
        },
      },
      notFoundText: 'Uh Oh! No Intercity Parcels found.',
    },
    hostsRoute: {
      header: 'Hosts',
      newHost: 'New Host',
      tabs: {
        Active: 'Active',
        Inactive: 'Inactive',
        countLabel: 'Host(s)',
      },
      columnsHeaders: {
        name: 'Name',
        status: 'Status',
        actions: 'Actions',
      },
      formCreation: {
        titleCreate: 'Create Host',
        titleEdit: 'Edit Host',
        nameLabel: 'Name',
        loadText: 'Loading host data ...',
      },
      actionPopover: {
        edit: 'Edit',
        Activate: 'Activate',
        Deactivate: 'Deactivate',
      },
      notFoundText: 'Uh Oh! No Hosts found.',
    },
    settingsRoute: {
      header: 'Settings',
      tabs: {
        Cities: 'Cities',
        'Logistics Companies': 'Logistics Companies',
        Application: 'Application',
        'Generate Trips': 'Generate Trips',
        'Providers Settings': 'Providers',
      },
      logistics: {
        title: 'Logistics Companies',
        newLogCompany: 'New Logistics Company',
        formCreationLogisticsCompany: {
          titleCreate: 'Create Logistics Company',
          titleEdit: 'Edit Logistics Company',
          logisticsCompanyName: 'Logistics Company Name',
          loadText: 'Loading logistics company data ...',
        },
        columnsHeaders: {
          name: 'Name',
          status: 'Status',
          actions: 'Actions',
        },
        actionPopover: {
          edit: 'Edit',
          Activate: 'Activate',
          Deactivate: 'Deactivate',
        },
        tabs: {
          Active: 'Active',
          Inactive: 'Inactive',
          countLabel: 'Logistics Company(ies)',
        },
        notFoundText: 'Uh Oh! No Logistics Companies found.',
      },
      cities: {
        title: 'Cities',
        subtitle: 'Districts',
        newCity: 'New City',
        newDistrict: 'New District',
        tabs: {
          Active: 'Active',
          Inactive: 'Inactive',
        },
        columnsHeaders: {
          number: 'Number',
          nameEnglish: 'English name',
          nameArabic: 'Arabic name',
          districts: 'Districts',
          actions: 'Actions',
        },
        filtersCities: 'Search by number',
        filtersDistricts: 'Search by number',
        formCreationCity: {
          titleCreate: 'Create City',
          titleEdit: 'Edit City',
          numberCityLabel: 'City Number',
          englishCityName: 'English City Name',
          arabicCityName: 'Arabic City Name',
          regionCode: 'Region Code',
          helperTextForRegionCode: 'Maximum 5 characters',
          addDistrictBtn: 'Add District',
          titleCreateDistrict: 'New District',
          numberDistrictLabel: 'District Number',
          englishDistrictName: 'English District Name',
          arabicDistrictName: 'Arabic District Name',
          statusDistrictLabel: 'District Status',
          editBtn: 'Edit',
          deleteBtn: 'Delete',
          deactivateBtn: 'Deactivate',
          activateBtn: 'Activate',
          saveDistrictBtn: 'Save',
          cancelDistrictBtn: 'Cancel',
          visible: 'Visible in Orders',
          loadText: 'Loading city data ...',
        },
        actionPopover: {
          edit: 'Edit',
          delete: 'Delete',
        },
        notFoundCities: 'Uh Oh! No Cities found.',
        notFoundDistricts: 'Uh Oh! No Districts found.',
      },
      application: {
        title: 'Applications',
        newApplication: 'New Application',
        emptyList: 'Applications list is empty',
        columnsHeaders: {
          name: 'Name',
          token: 'Token',
        },
        tabs: {
          Active: 'Active',
          Inactive: 'Inactive',
        },
        actionPopover: {
          copyToken: 'Copy token',
          update: 'Update',
          Activate: 'Activate',
          Deactivate: 'Deactivate',
        },
        applicationDialog: {
          createNewApp: 'Create new application & generate token',
          change: 'Change',
          set: 'Set',
          applicationName: 'application name',
          attention:
            'Attention! The Token will be changed. You will need to provide new Token to Application.',
          tokenCreated: 'Token was created',
          update: 'Update',
          create: 'Create',
          copyToken: 'Copy token',
        },
        notFoundText: 'Uh Oh! No Applications found.',
      },
      generateTrips: {
        title: 'Configure Generation Trips',
        info: {
          mode: 'Mode:',
          logisticsCompany: 'Logistics Company:',
          days: 'Days:',
          time: 'Time:',
        },
        mode: {
          placeholderText: 'Select Generate Trips Mode',
          labelText: 'Generate Trips Mode',
          types: {
            Manual: 'Manual',
            Automated: 'Automated',
          },
        },
        week: {
          title: 'Select the days to automatically trigger Generate trips',
          Sunday: 'Sunday',
          Monday: 'Monday',
          Tuesday: 'Tuesday',
          Wednesday: 'Wednesday',
          Thursday: 'Thursday',
          Friday: 'Friday',
          Saturday: 'Saturday',
        },
        time: {
          title: 'Select time to automatically trigger Generate trips',
        },
        warning: `Distribution points for logisticsCompanyName have not been found for cities listed below: <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">listCities</span>. Generate trips will fail in case intercity orders for these cities found.`,
        submit: 'Submit',
      },
      providers: {
        title: 'Pending accept time (min)',
        save: 'Save',
      },
    },
    clientsRoute: {
      header: 'Clients',
      newClient: 'New Client',
      tabs: {
        Active: 'Active',
        Inactive: 'Inactive',
        countLabel: 'Client(s)',
      },
      columnsHeaders: {
        name: 'Name',
        remainingOrders: 'Remaining Orders',
        location: 'Location',
        actions: 'Actions',
      },
      formCreation: {
        titleCreate: 'Create Client',
        titleEdit: 'Edit Client',
        nameLabel: 'Name',
        loadText: 'Loading client data ...',
        warning: 'Contact Information (required)',
        optional: 'Optional',
        paymentLabel: 'Payment upon Collection',
        paymentText:
          'Enable/disable this client to create orders with "Payment upon Collection"',
      },
      actionPopover: {
        edit: 'Edit',
        Activate: 'Activate',
        Deactivate: 'Deactivate',
        refillBalance: 'Refill balance',
      },
      notFoundText: 'Uh Oh! No Clients found.',
    },
    routesRoute: {
      header: 'Routes',
      newRoute: 'New Route',
      tabs: {
        Active: 'Active',
        Inactive: 'Inactive',
        countLabel: 'Route(s)',
      },
      filters: {
        searchByNamePlaceholder: 'Search by name',
      },
      columnsHeaders: {
        routeId: 'Route ID',
        routeName: 'Route Name',
        startLocation: 'Start Location',
        endLocation: 'End Location',
        noOfStops: 'No. of Stops',
        actions: 'Actions',
      },
      columnsHeadersRoute: {
        stop: 'Stop',
        location: 'Location',
        distance: 'Distance',
        actions: 'Actions',
      },
      formCreation: {
        titleCreate: 'Create Route',
        titleEdit: 'Edit Route',
        nameLabel: 'Route Name',
        addBtnText: 'Add',
        loadText: 'Loading Route data ...',
      },
      actionPopover: {
        edit: 'Edit',
        Activate: 'Activate',
        Deactivate: 'Deactivate',
        createTrip: 'Create Trip',
      },
      popups: {
        locationLimitsWarining: 'At least should be 1 location in the Route',
      },
      notFoundText: 'Uh Oh! No Routes found.',
    },
    lockersRoute: {
      header: 'Lockers',
      newLocker: 'New Locker',
      toggleBtn: {
        all: 'All',
        onlyOpened: 'Only Opened',
      },
      tabs: {
        Active: 'Active',
        Inactive: 'Inactive',
        countLabel: 'Locker(s)',
      },
      columnsHeaders: {
        visible: 'Visible',
        number: 'Number',
        name: 'Name',
        locationName: 'Location Name',
        address: 'Address',
        location: 'Location',
        host: 'Host',
        utilization: 'Utilization',
        sizes: 'Sizes',
        actions: 'Actions',
        lockerTiming: 'Locker Timing',
        cabinetNumber: 'Cabinet Number',
        state: 'State',
        lastStatusRequestDate: 'Last Status Request',
        lastStatusResponseDate: 'Last Status Response',
        dateTime: 'Date / Time',
        action: 'Action',
        actor: 'Actor',
      },
      formCreation: {
        titleCreate: 'Create Locker',
        titleEdit: 'Edit Locker',
        cabinetsNumberingDirection: 'Cabinets numbering direction',
        numerationDirectionLTR: 'Left To Right (row)',
        numerationDirectionTTB: 'Top To Bottom (column)',
        visible: 'Available for Clients',
        notVisible: 'Non-Available for Clients',
        scan: 'Scan QR code before open Cabinet',
        notScan: 'Do not scan QR code before open Cabinet',
        nameLabel: 'Locker Name',
        numberLabel: 'Locker Number',
        rowsNumberLabel: 'Rows Number',
        columnsNumberLabel: 'Columns Number',
        lockerDescriptionLabel: 'Locker Description',
        lockerPhotoBtnText: 'Browse Photo',
        nextBtnText: 'Next',
        cancelBtnText: 'Cancel',
        backBtnText: 'Back',
        positionText: 'Position',
        rowText: 'Row',
        columnText: 'Column',
        numberText: 'Number',
        sizeText: 'Size',
        widthText: 'Width',
        heightText: 'Heigth',
        loadText: 'Loading Locker data ...',
      },
      detailsPage: {
        backBtnText: 'Back',
        lockerInfo: {
          visible: 'Visible',
          number: 'Number',
          rowsNumber: 'Rows number',
          columnsNumber: 'Columns number',
          sizes: 'Sizes',
          location: 'Location',
          host: 'Host',
          ltd: 'Latitude',
          lng: 'Longitude',
          utilization: 'Utilization',
          description: 'Description',
          photo: 'Photo',
          downloadQR: 'Download QR-code of locker',
          printQR: 'Print QR-code of locker',
        },
        cabinetsGrid: {
          title: 'Cabinets Grid',
          cabinetInfo: {
            status: 'Status',
            state: 'State',
            number: 'Number',
            size: 'Size',
            width: 'Width',
            height: 'Height',
            row: 'Row',
            column: 'Column',
            hideBtnsText: 'Hide Cabinet Info',
            openCabinetBtnText: 'Open Cabinet',
            openAllCabinetsBtnText: 'Open ALL Cabinets',
          },
          columnsHeaders: {
            number: 'Number',
            receiver: 'Consignee Name',
            phone: 'Phone',
            client: 'Client',
          },
          confirmOpenCabinetModal: {
            title: 'Are you sure to open Cabinet {{number}}?',
            notBtnText: 'Not',
            yesBtnText: 'Yes',
          },
          confirmOpenAllCabinetsModal: {
            title: 'Are you sure to open All Cabinets?',
            notBtnText: 'Not',
            yesBtnText: 'Yes',
            openingCabinets: 'Opening Cabinets ...',
          },
          confirmStatusChangeModal: {
            title:
              'Attention! It will clear the assigned Orders! Are you sure?',
            notBtnText: 'Not',
            yesBtnText: 'Yes',
          },
        },
        loadText: 'Loading Locker data ...',
      },
      actionPopover: {
        edit: 'Edit',
        details: 'Details',
        Activate: 'Activate',
        Deactivate: 'Deactivate',
        qrcode: 'QR-code',
      },
      // popups: {
      //     locationLimitsWarining: 'At least should be 1 location in the Route',
      // },
      notFoundText: 'Uh Oh! No Lockers found.',
    },
    lockersStatisticsRoute: {
      header: 'Lockers Statistics',
      stuckLockerLabel: 'Show lockers which not responding',
      cabinetHistoryHeader: 'Cabinet History',
      lockerResponseFailedText: 'Maintenance is required',
      exportCabinetsStatus: 'Export Cabinets Status',
    },
    ordersStatisticsRoute: {
      header: 'Orders Statistics Journal',
      filters: {
        requestTypes: {
          All: 'All',
          Deposit: 'Deposit',
          Pickup: 'Pickup',
        },
        exportBtnLabel: 'Export',
        exportPausesBtnLabel: 'Export Pauses',
      },
      columnsHeaders: {
        orderId: 'Order ID',
        createdAt: 'Created',
        deliveryLocker: 'Delivery Locker',
        deliveryTime: 'Delivery Time',
        status: 'Status',
        client: 'Client',
        requestId: 'Request ID',
        requestType: 'Request Type',
      },
    },
    operationCycleAnalysisRoute: {
      header: 'Operation Cycle Analysis',
      filters: {
        exportBtnLabel: 'Export',
      },
      columnsHeaders: {
        orderId: 'Order ID',
        storeName: 'Store Name',
        origin: 'Origin',
        destinationCity: 'Destination (City)',
        pickupType: 'Pickup Type',
        orderType: 'Type of Order',
        lockerNumber: 'Locker Number',
        cabinetNumber: 'Cabinet Number',
        awb: 'AWB',
      },
    },
    reportProviderRoute: {
      header: 'Provider Report',
    },
    reportTripsRoute: {
      header: 'Trips Report',
    },
    hostsAnalyticsRoute: {
      header: 'Hosts Analytics',
      columnsHeaders: {
        number: 'Number',
        name: 'Name',
        locationName: 'Location',
        hostName: 'Host',
        status: 'Status',
        deposited: 'Deposited',
        retrieved: 'Retrieved',
      },
      selectHost: 'Please, select a host',
      notFoundText: 'Uh Oh! No founds.',
      filters: {
        exportBtnLabel: 'Export',
      },
    },
    depositOrderInstructionsForConsignee: {
      title: 'Deposit Order Instructions',
      text1: `Please deposit your <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">Order #orderNumber</span> into reserved cabinet.`,
      text2: `Cabinet <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">reservation time limit is “24 hours”.</span>`,
      text3: 'For instructions, Read below',
      openCabinetText: `By clicking on <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">Open</span>, the cabinet reserved for your <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">order</span> will open <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">IMMEDIATELY.</span> Please make sure to <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">close the cabinet</span> once you have <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">deposited your order.</span>`,
      errorPage: {
        notFound: 'NOT FOUND!',
        notFoundList: {
          title:
            'It seems that you are facing a problem with depositing your order, the issue may be one of the following:',
          subTitle: 'Please reach us if you need any help',
          first: '1. The period for depositing the order has expired',
          second: '2. There was a problem with opening the cabinet ',
        },
        customersSupport: 'Customers Support',
      },
      successPage: {
        thankYou: 'THANK YOU!',
        successText: `Your order #orderNumber has been <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">Deposited</span>. Thank you for choosing Shuttle.`,
        rateText: 'Please rate your experience with us',
        commentText: 'Help us service you better! Tell us what you think',
        submitText: 'Submit',
        feedbackSent: 'Thank you, your feedback was sent!',
        serveyText: 'click here to take a simple survey',
      },
    },
    trackingPage: {
      homePage: {
        title: 'Order Tracking',
        orderOnWayText: `Your <span  style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">Order</span> is being <span  style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">Delivered</span> to <span  style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">SMART LOCKER</span> available for pick up. <span  style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">Order Status</span> will change to <span  style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">“Ready for Pick Up”</span> once your order reached the locker.`,
        readyForPickupText1: `Your order is <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">"Ready for Pick Up"</span>`,
        readyForPickupText2: `Select <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">"Self Pick Up"</span> for pick up instructions`,
        customerDetailsBlock: {
          origin: 'Origin',
          store: 'Store',
          destination: 'Destination',
          smartLocker: 'Smart locker',
          nearestSmartLocker: 'Nearest smart locker',
          orderStatus: 'Order Status',
          willBeReady: 'Will be ready for Pick-up by',
          willBeDelivered: 'Will be delivered by',
          deliveryRequested: 'Delivery requested',
        },
        optionCards: {
          useShuttle1: 'Learn How to',
          useShuttle2: 'Use Shuttle',
          orderPickup1: 'Order Pick-up',
          orderPickup2: 'Instructions',
          orderTracking1: 'Order',
          orderTracking2: 'Tracking',
          customersSupport1: 'Customers',
          customersSupport2: 'Support',
          deliveryNow1: 'Get it delivered',
          deliveryNow2: 'to your home',
          deliveryNow3: 'Now',
          pickupYourself1: 'Pickup order by',
          pickupYourself2: 'Yourself',
          useShuttleModal: 'How to use Shuttle',
          close: 'Close',
          customerSupport1:
            'You can contact us, noting that the working hours are from 9 am to 5 pm',
          customerSupport2:
            'If there is no response, please raise your inquiry here, and we will contact you as soon as possible',
          phoneCall: 'Phone call',
          whatsUp: "What's up",
          typeForm: 'Fill out the form',
          request: 'Request',
          shgardiNow: 'Shgardi Now',
          openCabinet: 'Open',
          back: 'Back',
          dropIntoCabinet: 'Drop into cabinet',
          slaaskChatOpen: 'Please contact us through live chat for help',
        },
      },
      trackPage: {
        title: 'Order Tracking',
        text1:
          'You will be able to pick-up your order orderNumber from locker when the order status is Ready for pick-up. You will have 48 hours to pick-up your order',
        text2:
          'Your order #orderNumber is Ready for pick-up. You have 48 hours to pick it up to avoid return. Please follow Pick-up instructions by clicking on Pick-up Button',
        text1Ups:
          'You will be able to pick-up your order orderNumber from locker when the order status is Ready for pick-up. You will have 5 days to pick-up your order',
        text2Ups:
          'Your order #orderNumber is Ready for pick-up. You have 5 days to pick it up to avoid return. Please follow Pick-up instructions by clicking on Pick-up Button',
        initiated: 'Initiated',
        initiatedSubText: 'We are waiting for the store to prepare your order',
        onProgress: 'On Progress',
        onProgressSubText:
          'We are picking up your order and ship it to your nearst locker',
        onWayToLocker: 'On-way to Locker',
        onWayToLockerSubText:
          'We Picked up your order, heading your nearest locker',
        readyForPickup: 'Ready for Pick-up',
        readyForPickupSubText:
          '"Please Pick-up your order to avoid returing the order",',
        waitForPickupByDeliveryCompany: 'Wait for Pickup by',
        delivering: 'Delivering by',
        delivered: 'Delivered',
        willBeDeliveryAt: 'Will be delivered at',
      },
      orderPage: {
        pickUpInstructions: 'Pick-up instructions',
        text1: `Your <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">Order #orderNumber</span> is “Ready for Pick Up”.`,
        text2: `Order Pick Up time limit is “48 hours”`,
        text2Ups: `Order Pick Up time limit is 5 days`,
        text3: `For <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">Order Pick Up</span> instructions, Read Below`,
        getDirections: 'Get Directions',
        infoAboutPayment:
          'Please pay for your order now in order to open the cabinet and collect your order',
        pay: 'Pay',
        paySuccessful: 'Your payment was successful!',
        payUnsuccessful:
          'Unfortunately, your payment was unsuccessful, please, pay for your order again in order to open the cabinet and collect your order',
        lockerLocation: 'Click here for locker directions',
        lockerLocationText:
          '"After opening the map and arriving at the location, open the Pick-up Instructions page back to activate the next step"',
        scanQR: 'Scan QR Code',
        scanQRBtn: 'Click here to scan QR',
        scanQRWarning:
          'If the camera didn’t work, please make shure you open the link in Safari or google chrome browsers',
        scanQRDescription: `Scan QR code on the locker using your <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">Mobile Phone Camera</span> to activate <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">“Open Cabinet”</span> button`,
        scanQRText: 'Place QR code inside the frame to scan please',
        scanQRLoader: 'Scanning Code...',
        scanQRSuccessful: 'Your scan QR code was successful!',
        scanQRUnsuccessful:
          'Unfortunately, you scanned wrong QR code. Please check Get Directions section for correct locker information',
        disclaimer: 'Disclaimer',
        disclaimerText:
          'Please click on the button to approve that you are in the front of locker and to active open cabinet step.',
        youHaveApproved: 'You have approved',
        yourApproval: 'Your Approval',
        disclaimerOpenCabinetText: `For you security, <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">ONLY “Open Cabinet”</span> once you are <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">physically present at the locker.</span>`,
        openCabinet: 'Open Cabinet',
        openCabinetText:
          'By clicking on open, you will open the cabinet immediately ! Please close the cabinet once you have picked up your order',
        open: 'Open',
        close: 'Close',
        goToLocation: 'Go to location',
        approve: 'Approve',
        popupTexts: {
          errorOpenCabinet: 'Cabinet has not been opened',
        },
        notFound: 'The information not found',
      },
      deliveryPage: {
        header: 'Fill the form for Shgardi',
        description: '',
        pickupDate: 'Pickup Date',
        deliveryDate: 'Delivery Date',
        recipientName: 'Recipient Name',
        recipientMobile: 'Recipient Mobile',
        recipientAddress: 'Recipient Address',
        recipientLatitude: 'Recipient Latitude',
        recipientLongitude: 'Recipient Longitude',
        selectFromMap: 'Select address from Google Map',
        selectFromField: 'Select address from Search Field',
        p1Shgardi:
          "Your order <b>will be delivered</b> to your <b>doorstep</b> in fastest time throagh Shgardi's driver.",
        p2Shgardi: 'Please determine your location from the map:',
        create: 'Submit',
      },
      errorPage: {
        notFound: 'NOT FOUND!',
        notFoundList: {
          title:
            'It seems that you are facing a problem with picking-up your order, the issue may be one of the following:',
          subTitle: 'Please reach us if you need any help',
          first: '1. The order has already been picked-up',
          second: '2. The period for picking-up the order has expired',
          third: '3. There was a problem with opening the cabinet',
        },
        customersSupport: 'Customers Support',
      },
      successPage: {
        thankYou: 'THANK YOU!',
        successText:
          'Your order #orderNumber is Picked up. Thank you for choosing Shuttle',
        rateText: 'Please rate your experience',
        commentText: 'Do you have any comment ?',
        submitText: 'Submit',
        feedbackSent: 'Thank you, your feedback was sent!',
        serveyText: 'click here to take a simple survey',
      },
      dropViaSmartLinkPage: {
        successDrop: 'Order has been dropped successfully',
        origin: 'Origin',
        destination: 'Destination',
      },
      orderStatus: {
        Initiated: 'Initiated',
        'Assigned to request': 'Assigned to request',
        'Pick-up scheduled': 'Pick-up scheduled',
        'On way to origin locker': 'On way to origin locker',
        'Deposited at origin locker': 'Deposited at origin locker',
        'Picked-up at origin locker': 'Picked-up at origin locker',
        'On way to intermediate locker': 'On way to intermediate locker',
        'Deposited at intermediate locker': 'Deposited at intermediate locker',
        'On way to destination locker': 'On way to destination locker',
        'On way to origin DP': 'On way to origin DP',
        'Arrived at origin DP': 'Arrived at origin DP',
        'On way to destination DP': 'On way to destination DP',
        'Arrived at destination DP': 'Arrived at destination DP',
        'Deposited at destination locker': 'Deposited at destination locker',
        'Ready for pick-up': 'Ready for pick-up',
        'Delivery requested by Consignee': 'Delivery requested by Consignee',
        'Picked-up at destination locker': 'Picked-up at destination locker',
        'Out for delivery': 'Out for delivery',
        Delivered: 'Delivered',
        'Delivery requested': 'Delivery requested',
        'Picked up by delivery company': 'Picked up by delivery company',
        'Not delivered': 'Not delivered',
      },
    },
    shgardiDriverTackPage: {
      receiver: 'Receiver',
      address: 'Address',
      lockerDetails: 'Locker details',
      locker: 'Locker',
      cabinet: 'Cabinet',
      receiverTitle: 'Receiver',
      popupTexts: {
        successfullOpendedCabinet: 'Cabinet has been opened',
        errorPickupedOrder: 'Order has not been picked up',
      },
      infoStep: {
        title: 'Welcome, Shgardi!',
        subTitle:
          'You have accepted the delivery of the order, and you will deliver it to the customer',
        listHeader: 'Important information',
        item1:
          '1- You will not receive the order from a store or person at the receiving site',
        item2:
          '2- You will receive the order from a smart safe at the delivery location',
        item3:
          '3- The shape of the safe from which you will receive the order:',
        agree: 'Agree',
      },
      mainPage: {
        title1: 'Pick up and delivery method:',
        item1: '1- Go to the receiving location shown in the Shgardi app.',
        item2:
          '2- When you arrive at the delivery site, you will find a smart safe on the site.',
        item3: '3- Here is the safe information + button to open the cabin',
        lockerInfoTitle: 'Locker details',
        lockerNumber: 'Locker Number',
        cabinetNumber: 'Cabinet Number',
        address: 'Locker address',
        direction: 'Locker direction',
        lockerDescription: 'Locker description',
        item4:
          '4- When you are standing in front of the safe, press to open the cabinet',
        item5: '5- Receive the order from the booth',
        item6:
          '6- If you encounter a problem with opening the booth, contact technical support',
        item7:
          '7- After receiving the order, you will deliver it to the customer. The customer’s information is shown to you in the Shgardi app.',
        item8: '8- Go to the delivery location shown in the Shgardi app.',
        item9: '9- Deliver the order to the recipient',
        modalHeader: 'Did you picked up Order?',
        confirmBtnYes: 'Yes',
        confirmBtnNot: 'Not',
      },
    },
    deliveryPartnersPage: {
      title: 'Delivery Options',
      descriptionP1:
        'Home delivery service requires an <u><b>additional fees.</b></u>',
      descriptionP2: 'Service prices differ based on service provider',
      select: 'Select',
    },
    smartRoutePages: {
      consigneePage: {
        title: 'We started to plan the delivery of your order.',
        subTitle: 'Please select location you want order to be delivered.',
        confirmLocationText: 'Do you confirm to select {location} location?',
        successText: 'Order will be delivered to your location!',
      },
    },
    modals: {
      warning: {
        title: 'Warning',
        yes: 'Yes',
      },
      createLocation: {
        btnOpenModalLabel: 'Add new location',
      },
      confirm: {
        title: 'Are you confirm action?',
        cancel: 'Cancel',
        yes: 'Yes',
      },
      locations: {
        btnOpenModalLabel: 'Select Location',
        label: 'Location',
        placeholder: 'Click to choose location',
        title: 'Locations List',
        allHitsLoaded: 'All Locations are loaded',
        cancelBtnLabel: 'Cancel',
        notFound: 'Not Found',
      },
      hosts: {
        btnOpenModalLabel: 'Select Host',
        label: 'Host',
        placeholder: 'Click to choose host',
        title: 'Hosts List',
        searchByNamePlaceholder: 'Search by name',
        allHitsLoaded: 'All Hosts are loaded',
        cancelBtnLabel: 'Cancel',
      },
      clients: {
        btnOpenModalLabel: 'Select Client',
        label: 'Client',
        placeholder: 'Click to choose client',
        title: 'Clients List',
        allHitsLoaded: 'All Clients are loaded',
        cancelBtnLabel: 'Cancel',
      },
      clientsMulti: {
        btnOpenModalLabel: 'Select Clients',
      },
      orders: {
        btnOpenModalLabel: 'Select Orders',
        selectAll: 'Select all',
        label: 'Orders',
        placeholder: 'Click to choose order',
        title: 'Orders List',
        allHitsLoaded: 'All Orders are loaded',
        cancelBtnLabel: 'Cancel',
        okBtnLabel: 'Ok',
      },
      pickups: {
        title: 'Request Pickup',
        locationsPlaceholder: 'Where do you want the pickup from?',
        ordersWarningMessage: 'Should be at least one order.',
        preferredTimePlaceholder: 'Preferred pickup time',
      },
      depositSplitting: {
        title: 'Deposit Splitting Orders',
        ordersList: {
          orderLabel: 'Order:',
          nameLabel: 'Name:',
        },
        openBtn: 'Open',
        cabinetNoLabel: 'Cabinet NO.:',
        cabinetText: 'Cabinet',
        instructionsList: {
          first: 'Add as many Orders as you can.',
          second:
            'Then Select Book another cabinet if you need to add more orders:',
        },
        anotherBtn: 'Another Cabinet',
        finishDepositBtn: 'Finish Deposit',
        confirmFinishModal: {
          title: 'Are you really sure that you want to finish deposit?',
          yes: 'Yes',
          not: 'Not',
        },
        depositHasBeenFinished: 'Depositing has been finished',
        popups: {
          disableOpenAndSplitWarning:
            'You selected all orders. At least should be 1 order is not selected',
          refillBalanceError: 'Please refill balance first for {{name}}',
          finishDepositSuccess: 'Depositing has been finished',
          someOrdersNotDepositerError: 'Some orders has not been deposited',
          allOrdersInCabinetsWarning:
            'It seems all orders were put into the cabinets.',
          notEnoughFreeCabinetsWarning: 'Not enough FREE cabients',
          depositCancelError: 'Deposit has not been cancelled',
          atLeastNeedOneOrderToFinishDepositWarning:
            'Please put at least one order to finish the deposit',
        },
      },
      upload: {
        title: 'Upload Bulk Orders',
        brief: 'You can download Sample CSV File here.',
        label: 'Select File',
        placeholder: 'Choose file...',
        selectFileBtnText: 'Browse',
        uploadBtnText: 'Upload CSV',
      },
      deposit: {
        title: 'Deposit Orders',
        stepsLabels: {
          time: 'Set time',
          locker: 'Select locker',
          cabinet: 'Select Cabinet',
          confirm: 'Review & Confirm',
        },
        step1: {
          depositTimeLabel: 'Deposit Time',
          depositTimePlaceholder: 'When do you want the deposit?',
          preferredTimeLabel: 'Preferred deposit time',
          dateLabel: 'Date',
          popups: {
            refillBalanceError: 'Please refill balance first for {{name}}',
          },
        },
        step2: {
          notLockerFoundMsg:
            'Sorry no locker found. Please select a different location',
          avaliableLockerTitle: 'Available Lockers! Please select one of them.',
        },
        step3: {
          title: 'Locker # {{name}}',
          cabinetSizeTitle: 'Select a cabinet size',
          notAvaliableMsg: '(Not available)',
          cabinetLabel: 'Cabinet #',
          heightLabel: 'Height',
          widthLabel: 'Width',
          selectCabinetMsg: 'Please, select a cabinet size',
        },
        step4: {
          title: `Locker #{{name}} Cabinet # {{number}}`,
          ordersToDeposit: 'Depositing {{count}} Orders',
        },
        warnings: {
          differenteLocations:
            'Deposit Location is Destination location for orders [listOrders]. Please Deposit these orders separately.',
          differentReceivers:
            'Not allowed to deposit together orders for different Consignees',
        },
        footerBtns: {
          cancel: 'Cancel',
          back: 'Back',
          next: 'Next',
          confirm: 'Confirm',
          save: 'Save',
        },
        confirmDeposit: {
          title: 'When do you want to deposit order?',
          nowBtnText: 'Now',
          laterBtnText: 'Later',
        },
      },
      lockersOnMap: {
        title: 'Lockers',
        quantity: 'Quantity {{quantity}}',
        cancelBtn: 'Cancel',
      },
      tripsMap: {
        title: 'Trip`s route',
        cancelBtn: 'Cancel',
        error: 'Unfortunately, we could not get the route',
      },
      zone: {
        openModalBtn: 'Add Zone',
        title: 'Zone',
        closeBtnText: 'Close',
        addBtnText: 'Add',
      },
      qrcode: {
        titleLocation: 'QR-code of location locationName',
        titleLocker: 'QR-code of locker lockerName',
      },
      routes: {
        openModalBtnText: 'Select Route',
        label: 'Route',
        placeholder: 'Click to choose route',
        header: 'Routes List',
        loadCompleteMsg: 'Full Routes are loaded',
        cancelBtnText: 'Cancel',
      },
      providers: {
        openModalBtnText: 'Select Provider',
        label: 'Provider',
        placeholder: 'Click to choose provider',
        header: 'Providers List',
        loadCompleteMsg: 'Full Providers are loaded',
        cancelBtnText: 'Cancel',
      },
      intercityParcels: {
        openModalBtnText: 'Select Logistics Company',
        label: 'Logistics Company',
        placeholder: 'Click to choose logistics company',
        header: 'Logistics Companies List',
        loadCompleteMsg: 'Full Logistics Companies are loaded',
        cancelBtnText: 'Cancel',
      },
      awbPrinting: {
        title: 'AWB Label',
        note: 'Note: Please set A5 page size for printing',
        hideList: 'Hide List',
        showList: 'Show List',
      },
      reportIssue: {
        label: 'Report Issue',
        title: 'Please, select the issue',
        reportBtnConfirm: 'Report',
      },
      cityDistrict: {
        header: 'Order Location',
      },
    },
    reactTable: {
      previousText: 'Previous',
      nextText: 'Next',
      pageText: 'Page',
      ofText: 'of',
    },
    buttonsGroup: {
      cancelBtnLabel: 'Cancel',
      saveBtnLabel: 'Save',
    },
    pickupTime: {
      label: 'Pickup Time',
      placeholder: 'When do you want the pickup?',
      selectOptions: {
        today: 'Today',
        tomorrow: 'Tomorrow',
        later: 'Later',
      },
    },
    date: {
      label: 'Date',
    },
    preferredTime: {
      label: 'Preferred Time',
    },
    firstName: {
      placeholder: 'First Name',
      label: 'First Name',
    },
    lastName: {
      placeholder: 'Last Name',
      label: 'Last Name',
    },
    phoneNumber: {
      placeholder: 'Phone Number (+9665xxxxxxxx)',
      label: 'Phone Number',
    },
    email: {
      placeholder: 'Email Address',
      label: 'Email Address',
    },
    photo: {
      placeholder: 'Click to choose photo',
      label: 'Photo',
    },
    city: {
      placeholder: 'Select City',
      label: 'City',
    },
    district: {
      placeholder: 'Select District',
      label: 'District',
    },
    integration: {
      placeholder: 'Select Service',
      label: 'Service',
    },
    logisticsCompany: {
      placeholder: {
        mono: 'Select Logistics Company',
        multy: 'Different companies selected',
      },
      label: 'Logistics Company',
    },
    role: {
      placeholder: 'Select Role',
      label: 'Role',
    },
    orderType: {
      placeholder: 'Select Order Type',
      label: 'Type',
    },
    locationTypes: {
      placeholder: 'Select Location Type',
      label: 'Location Type',
    },
    userTypes: {
      placeholder: 'Select User Type',
      label: 'User Type',
    },
    locationName: {
      placeholder: 'Location Name',
      label: 'Location Name',
    },
    datePicker: {
      todayText: 'Today',
    },
    isReuqiredLabel: 'required',
    loadMoreBtn: {
      label: 'Load More',
    },
    locations: {
      cities: {
        Riyadh: 'Riyadh',
        Dammam: 'Dammam',
        Jeddah: 'Jeddah',
        Kharj: 'Kharj',
        Taif: 'Taif',
        Hofuf: 'Hofuf',
        Buraydah: 'Buraydah',
        Unaizah: 'Unaizah',
        'Khamis Mushait': 'Khamis Mushait',
        Jubail: 'Jubail',
        Medinah: 'Medinah',
        Abha: 'Abha',
        'Al-Badaya': 'Al-Badaya',
        الرياض: 'Riyadh',
        الدمام: 'Dammam',
        جدة: 'Jeddah',
        الخرج: 'Kharj',
        الطائف: 'Taif',
        الهفوف: 'Hofuf',
        بريدة: 'Buraydah',
        عنيزة: 'Unaizah',
        'خميس مشيط': 'Khamis Mushait',
        الجبيل: 'Jubail',
        'المدينة المنورة': 'Medinah',
        أبها: 'Abha',
        البدائع: 'Al-Badaya',
      },
      districts: {
        'Ibn Khaldun': 'Ibn Khaldun',
        Uhud: 'Uhud',
        'Al Itisalat': 'Al Itisalat',
        'Al Iskan': 'Al Iskan',
        'Al Iskan (Dammam)': 'Al Iskan (Dammam)',
        'Al Iskan (Khobar)': 'Al Iskan (Khobar)',
        'Al Amir Muhammed Ibn Saud': 'Al Amir Muhammed Ibn Saud',
        'Al Athir': 'Al Athir',
        'Al Amanah': 'Al Amanah',
        'Al Amal': 'Al Amal',
        'Al Anwar': 'Al Anwar',
        'Al Badiyah': 'Al Badiyah',
        'Al Buhayrah (Dammam)': 'Al Buhayrah (Dammam)',
        'Al Buhayrah (Khobar)': 'Al Buhayrah (Khobar)',
        'Al Badi': 'Al Badi',
        'Al Basatin': 'Al Basatin',
        'Al Jamiyin': 'Al Jamiyin',
        'Al Jalawiyah': 'Al Jalawiyah',
        'Al Jawharah': 'Al Jawharah',
        'Al Jawharah (Dammam)': 'Al Jawharah (Dammam)',
        'Al Jawharah (Khobar)': 'Al Jawharah (Khobar)',
        'Al Hussam': 'Al Hussam',
        'Al Hamra': 'Al Hamra',
        'Al Hamra (Dammam)': 'Al Hamra (Dammam)',
        'Al Hamra (Khobar)': 'Al Hamra (Khobar)',
        'Al Khalidiyah Al Janubiyah': 'Al Khalidiyah Al Janubiyah',
        'Al Khalidiyah Ash Shamaliyah': 'Al Khalidiyah Ash Shamaliyah',
        'Al Kuthriah': 'Al Kuthriah',
        'Al Khalij': 'Al Khalij',
        'Ad Danah': 'Ad Danah',
        'Ad Dawasir': 'Ad Dawasir',
        'Ar Rabiyah': 'Ar Rabiyah',
        'Ar Rakah Ash Shamaliyah': 'Ar Rakah Ash Shamaliyah',
        'Ar Rabi': 'Ar Rabi',
        'Ar Rawdah': 'Ar Rawdah',
        'Ar Rayyan': 'Ar Rayyan',
        'Az Zuhur': 'Az Zuhur',
        'As Salam': 'As Salam',
        'As Suq': 'As Suq',
        'As Saif': 'As Saif',
        'Ash Shati Ash Sharqi': 'Ash Shati Ash Sharqi',
        'Ash Shati Al Gharbi': 'Ash Shati Al Gharbi',
        'Ash Sharq': 'Ash Sharq',
        'Ash Shulah': 'Ash Shulah',
        'Ash Shifa': 'Ash Shifa',
        'As Sadafah': 'As Sadafah',
        'As Safa': 'As Safa',
        Industrial: 'Industrial',
        'Industrial Area No1': 'Industrial Area No1',
        'Ad Dabab': 'Ad Dabab',
        'At Tubayshi': 'At Tubayshi',
        'Al Adamah': 'Al Adamah',
        'Al Aziziyah': 'Al Aziziyah',
        'Al Amamrah': 'Al Amamrah',
        'Al Anud': 'Al Anud',
        'Al Firdaws': 'Al Firdaws',
        'Al Fursan': 'Al Fursan',
        'Al Fanar': 'Al Fanar',
        'Al Faiha': 'Al Faiha',
        'Al Faisaliyah': 'Al Faisaliyah',
        'Al Qadisiyah': 'Al Qadisiyah',
        'Al Qazaz': 'Al Qazaz',
        'Al Muhammadiyah': 'Al Muhammadiyah',
        'Industrial City 2': 'Industrial City 2',
        'Al Muraikabat': 'Al Muraikabat',
        'Al Mazruiyah': 'Al Mazruiyah',
        'Al Matar': 'Al Matar',
        'Al Manar': 'Al Manar',
        'Al Muntazah': 'Al Muntazah',
        'Al Maha (Dammam)': 'Al Maha (Dammam)',
        'Al Maha (Khobar)': 'Al Maha (Khobar)',
        'An Nasriyah': 'An Nasriyah',
        'An Nakhil': 'An Nakhil',
        'An Nada': 'An Nada',
        'An Nuzhah': 'An Nuzhah',
        'An Nasim': 'An Nasim',
        'An Nahdah': 'An Nahdah',
        'An Nur': 'An Nur',
        'An Nawras': 'An Nawras',
        'Al Hadabah': 'Al Hadabah',
        'Al Wahah': 'Al Wahah',
        Badr: 'Badr',
        'Dahiyat Al Malik Fahd': 'Dahiyat Al Malik Fahd',
        Taybah: 'Taybah',
        Ghirnatah: 'Ghirnatah',
        'Qasr Al Khalij': 'Qasr Al Khalij',
        'Madinat Al Umal (Dammam)': 'Madinat Al Umal (Dammam)',
        'Madinat Al Ummal (Khobar)': 'Madinat Al Ummal (Khobar)',
        'King Faysal University': 'King Faysal University',
        'King Fahd International Airport': 'King Fahd International Airport',
        'King Abdul Aziz Seaport': 'King Abdul Aziz Seaport',
        'Ibn Sina': 'Ibn Sina',
        Ishbiliya: 'Ishbiliya',
        'Al Amwaj': 'Al Amwaj',
        'Al Andalus': 'Al Andalus',
        'Al Bahar': 'Al Bahar',
        'Al Bustan': 'Al Bustan',
        'Al Bandariyah': 'Al Bandariyah',
        'At Tahliyah': 'At Tahliyah',
        'At Taawn': 'At Taawn',
        'Ath Thuqbah': 'Ath Thuqbah',
        'Al Jisr': 'Al Jisr',
        'Al Hizam Al Akhdar': 'Al Hizam Al Akhdar',
        'Al Hizam Adh Dhahabi': 'Al Hizam Adh Dhahabi',
        'Al Khubar Al Janubiyah': 'Al Khubar Al Janubiyah',
        'Al Khubar Ash Shamaliyah': 'Al Khubar Ash Shamaliyah',
        'Al Khuzama': 'Al Khuzama',
        'Al Khawr': 'Al Khawr',
        'Ar Rakah Al Janubiyah': 'Ar Rakah Al Janubiyah',
        'Ar Raja': 'Ar Raja',
        'Ar Rawabi': 'Ar Rawabi',
        'As Sahil': 'As Sahil',
        'As Sufun': 'As Sufun',
        'Ash Sheraa': 'Ash Sheraa',
        'As Sawari': 'As Sawari',
        'Al Aqrabiyah': 'Al Aqrabiyah',
        'Al Aqiq': 'Al Aqiq',
        'Al Ulaya': 'Al Ulaya',
        'Al Kawthar': 'Al Kawthar',
        'Al Kurnaish': 'Al Kurnaish',
        'Al Lulu': 'Al Lulu',
        'Sporting City': 'Sporting City',
        'Al Murjan': 'Al Murjan',
        'Al Hada': 'Al Hada',
        'Al Yarmok': 'Al Yarmok',
        'Sinaiyah Ath Thuqbah': 'Sinaiyah Ath Thuqbah',
        'Sinaiyah Al Foaziah': 'Sinaiyah Al Foaziah',
        Qurtubah: 'Qurtubah',
        'Ad Dawhah Al Janubiyah': 'Ad Dawhah Al Janubiyah',
        'Ad Danah Ash Shamaliyah': 'Ad Danah Ash Shamaliyah',
        'Al Qusur': 'Al Qusur',
        'Ad Dawhah Ash Shamaliyah': 'Ad Dawhah Ash Shamaliyah',
        'Ad Danah Al Janubiyah': 'Ad Danah Al Janubiyah',
        'Al Jamiah': 'Al Jamiah',
        'Al Haras Al Watani': 'Al Haras Al Watani',
        Tihamah: 'Tihamah',
        'Al Qashlah': 'Al Qashlah',
        Hajr: 'Hajr',
        'Gharb Adh Dhahran': 'Gharb Adh Dhahran',
        Kfupm: 'Kfupm',
        'Az Zomorod': 'Az Zomorod',
        'Al Loaloa': 'Al Loaloa',
        'Al Yaqoot': 'Al Yaqoot',
        'As Swaryee': 'As Swaryee',
        'Al Ferdous': 'Al Ferdous',
        'Al Falah': 'Al Falah',
        'Al Asalah': 'Al Asalah',
        Mraykh: 'Mraykh',
        'Ash Shrouk': 'Ash Shrouk',
        'Al Amir Fawaz Ash Shamaly': 'Al Amir Fawaz Ash Shamaly',
        'Ar Riyadh': 'Ar Riyadh',
        'Al Frosyah': 'Al Frosyah',
        'Ar Rahmanyah': 'Ar Rahmanyah',
        'As Salhiyah': 'As Salhiyah',
        'Al Hamadaniyah': 'Al Hamadaniyah',
        'Ar Rayaan': 'Ar Rayaan',
        'Um Hableen': 'Um Hableen',
        Bryman: 'Bryman',
        'Al Montazah': 'Al Montazah',
        'Ath Thaalibah': 'Ath Thaalibah',
        'Al Balad': 'Al Balad',
        'Al Farouk': 'Al Farouk',
        'Al Adel': 'Al Adel',
        'Al Hindawiyah': 'Al Hindawiyah',
        'Al Mahjar': 'Al Mahjar',
        'Al Khalidiyah': 'Al Khalidiyah',
        'King Abdul Aziz University': 'King Abdul Aziz University',
        'Al Baghdadiyah Ash Sharqiyah': 'Al Baghdadiyah Ash Sharqiyah',
        'An Nazlah Ash Sharqiyah': 'An Nazlah Ash Sharqiyah',
        'Al Bawadi': 'Al Bawadi',
        'As Salamah': 'As Salamah',
        'Ath Thaghr': 'Ath Thaghr',
        'Ash Sharafiyah': 'Ash Sharafiyah',
        'King Faisal Naval Base': 'King Faisal Naval Base',
        'Ash Shefaa': 'Ash Shefaa',
        'As Sanabel': 'As Sanabel',
        'At Tadamon': 'At Tadamon',
        'Al Karamah': 'Al Karamah',
        'Ar Rahmah': 'Ar Rahmah',
        'Al Barakah': 'Al Barakah',
        'Al Masarah': 'Al Masarah',
        'Al Moulysaa': 'Al Moulysaa',
        'Al Qouzeen': 'Al Qouzeen',
        'Al Wadi': 'Al Wadi',
        'Al Fadeylah': 'Al Fadeylah',
        'At Taawon': 'At Taawon',
        'As Sarawat': 'As Sarawat',
        'Al Khomrah': 'Al Khomrah',
        'Ad Dahiah': 'Ad Dahiah',
        'Al Qryniah': 'Al Qryniah',
        'An Nazlah Al Yamaniyah': 'An Nazlah Al Yamaniyah',
        'Al Quraiyat': 'Al Quraiyat',
        Ghulail: 'Ghulail',
        'Al Kandarah': 'Al Kandarah',
        'Al Ammariyah': 'Al Ammariyah',
        'As Sahifah': 'As Sahifah',
        'As Sabil': 'As Sabil',
        'Bani Malik': 'Bani Malik',
        'Ar Rihab': 'Ar Rihab',
        Mishrifah: 'Mishrifah',
        'Al Wurud': 'Al Wurud',
        'Abruq Ar Rughamah': 'Abruq Ar Rughamah',
        'As Sulimaniyah': 'As Sulimaniyah',
        'Al Bashaer': 'Al Bashaer',
        Taibah: 'Taibah',
        'An Naim': 'An Naim',
        'Ar Rabwah': 'Ar Rabwah',
        'Al Marwah': 'Al Marwah',
        'As Samir': 'As Samir',
        'Al Ajwad': 'Al Ajwad',
        'King Abdul Aziz International Airport':
          'King Abdul Aziz International Airport',
        'Industrial Area': 'Industrial Area',
        'Al Amir Abdoulmajed': 'Al Amir Abdoulmajed',
        'Al Waziriyah': 'Al Waziriyah',
        'Al Amir Fawaz Al Janouby': 'Al Amir Fawaz Al Janouby',
        'Al Mutanazahat': 'Al Mutanazahat',
        'Al Fadel': 'Al Fadel',
        'Um Asalam': 'Um Asalam',
        'King Abdul Aziz Medical City': 'King Abdul Aziz Medical City',
        'Al Baghdadiyah Al Gharbiyah': 'Al Baghdadiyah Al Gharbiyah',
        'Abhur Ash Shamaliyah': 'Abhur Ash Shamaliyah',
        'Ar Ruwais': 'Ar Ruwais',
        Petromin: 'Petromin',
        'Abhur Al Janubiyah': 'Abhur Al Janubiyah',
        'As Sororyah': 'As Sororyah',
        'Al Ajaweed': 'Al Ajaweed',
        'Ash Shati': 'Ash Shati',
        'Madain Al Fahd': 'Madain Al Fahd',
        'Az Zahra': 'Az Zahra',
        Bahrah: 'Bahrah',
        'Jeddah Eslamic Seaport': 'Jeddah Eslamic Seaport',
        'An Namudhajiyah': 'An Namudhajiyah',
        'Al Jaradiyah': 'Al Jaradiyah',
        'As Sinaiyah': 'As Sinaiyah',
        'Manfuhah Al Jadidah': 'Manfuhah Al Jadidah',
        'Al Fakhriyah': 'Al Fakhriyah',
        'Ad Dirah': 'Ad Dirah',
        'Umm Al Hamam Ash Sharqi': 'Umm Al Hamam Ash Sharqi',
        'Al Madhar Ash Shamali': 'Al Madhar Ash Shamali',
        'Umm Al Hamam Al Gharbi': 'Umm Al Hamam Al Gharbi',
        'Ar Rahmaniyah': 'Ar Rahmaniyah',
        Laban: 'Laban',
        'Ar Rafiah': 'Ar Rafiah',
        'Al Shohada': 'Al Shohada',
        'King Fahd': 'King Fahd',
        'As Suwaidi': 'As Suwaidi',
        'Al Hazm': 'Al Hazm',
        Utaiqah: 'Utaiqah',
        'Al Murabba': 'Al Murabba',
        'Al Mursalat': 'Al Mursalat',
        'King Faisal': 'King Faisal',
        'New Industrial Area': 'New Industrial Area',
        'Al Mansurah': 'Al Mansurah',
        Ghubairah: 'Ghubairah',
        'Al Faruq': 'Al Faruq',
        'Al Jazirah': 'Al Jazirah',
        'As Saadah': 'As Saadah',
        'Al Manakh': 'Al Manakh',
        'Ad Difa': 'Ad Difa',
        'King Abdullah': 'King Abdullah',
        'Salah Ad Din': 'Salah Ad Din',
        'King Abdul Aziz': 'King Abdul Aziz',
        'Al Wazarat': 'Al Wazarat',
        Skirinah: 'Skirinah',
        Jarir: 'Jarir',
        'Al Madhar': 'Al Madhar',
        'Al Malaz': 'Al Malaz',
        Manfuhah: 'Manfuhah',
        Ilaishah: 'Ilaishah',
        'Adh Dhubbat': 'Adh Dhubbat',
        'As Suwaidi Al Gharbi': 'As Suwaidi Al Gharbi',
        Dirab: 'Dirab',
        Namar: 'Namar',
        Uqaz: 'Uqaz',
        Shubra: 'Shubra',
        'Az Zahrah': 'Az Zahrah',
        Siyah: 'Siyah',
        Sultanah: 'Sultanah',
        'Al Yamamah': 'Al Yamamah',
        'Al Badiah': 'Al Badiah',
        'Al Masani': 'Al Masani',
        'Ad Duraihimiyah': 'Ad Duraihimiyah',
        'An Nasim Ash Sharqi': 'An Nasim Ash Sharqi',
        'Al Quds': 'Al Quds',
        'An Nafl': 'An Nafl',
        'Al Masif': 'Al Masif',
        'Al Izdihar': 'Al Izdihar',
        'Ar Rayan': 'Ar Rayan',
        'Dhahrat Al Badiah': 'Dhahrat Al Badiah',
        'An Nazim': 'An Nazim',
        'Ar Rimayah': 'Ar Rimayah',
        'Al Bariah': 'Al Bariah',
        Mansuriyah: 'Mansuriyah',
        'Dahiat Namar': 'Dahiat Namar',
        'Al Misfat': 'Al Misfat',
        'As Safarat': 'As Safarat',
        'Khashm Al An': 'Khashm Al An',
        Tuwaiq: 'Tuwaiq',
        'Dhahrat Namar': 'Dhahrat Namar',
        'Al Mughrizat': 'Al Mughrizat',
        'As Sulai': 'As Sulai',
        'Al Ghadir': 'Al Ghadir',
        'Al Muruj': 'Al Muruj',
        'Al Ud': 'Al Ud',
        Thulaim: 'Thulaim',
        'Ash Shimaisi': 'Ash Shimaisi',
        'Al Wisham': 'Al Wisham',
        'Ad Dubiyah': 'Ad Dubiyah',
        'Al Mikal': 'Al Mikal',
        Jabrah: 'Jabrah',
        'Al Qura': 'Al Qura',
        'Al Marqab': 'Al Marqab',
        'Al Futah': 'Al Futah',
        'Umm Salim': 'Umm Salim',
        'As Sahafah': 'As Sahafah',
        'Ar Raid': 'Ar Raid',
        'Al Uraija Al Gharbiyah': 'Al Uraija Al Gharbiyah',
        'Al Uraija': 'Al Uraija',
        'Al Uraija Al Wusta': 'Al Uraija Al Wusta',
        'Ad Dar Al Baida': 'Ad Dar Al Baida',
        'Al Batha': 'Al Batha',
        'Al Mutamarat': 'Al Mutamarat',
        'Al Wusayta': 'Al Wusayta',
        'Al Janadriyah': 'Al Janadriyah',
        Ishbiliyah: 'Ishbiliyah',
        'Al Maizilah': 'Al Maizilah',
        'Al Yarmuk': 'Al Yarmuk',
        'Al Munsiyah': 'Al Munsiyah',
        Irqah: 'Irqah',
        'Dhahrat Laban': 'Dhahrat Laban',
        Hittin: 'Hittin',
        'Al Malqa': 'Al Malqa',
        'Al Qirawan': 'Al Qirawan',
        'Al Yasmin': 'Al Yasmin',
        'Al Arid': 'Al Arid',
        'King Khalid Airport': 'King Khalid Airport',
        'An Narjis': 'An Narjis',
        'Al Imam University': 'Al Imam University',
        Banban: 'Banban',
        'Ar Rimal': 'Ar Rimal',
        'Al Dho': 'Al Dho',
        'Al Umjiah': 'Al Umjiah',
        Hyt: 'Hyt',
        'Al Haeer': 'Al Haeer',
        'Um Alshal': 'Um Alshal',
        'Al Ghnamiah': 'Al Ghnamiah',
        Areed: 'Areed',
        'Al Mahdiyah': 'Al Mahdiyah',
        'King Saud University': 'King Saud University',
        'An Nasim Al Gharbi': 'An Nasim Al Gharbi',
        'Al Mishal': 'Al Mishal',
        'ابن خلدون': 'Ibn Khaldun',
        احد: 'Uhud',
        الاتصالات: 'Al Itisalat',
        الاسكان: 'Al Iskan',
        'الإسكان (الدمام)': 'Al Iskan (Dammam)',
        'الاسكان (الخبر)': 'Al Iskan (Khobar)',
        'الامير محمد بن سعود': 'Al Amir Muhammed Ibn Saud',
        الأثير: 'Al Athir',
        الأمانة: 'Al Amanah',
        العمل: 'Al Amal',
        الأنوار: 'Al Anwar',
        البادية: 'Al Badiyah',
        'البحيرة (الدمام)': 'Al Buhayrah (Dammam)',
        'البحيرة (الخبر)': 'Al Buhayrah (Khobar)',
        البديع: 'Al Badi',
        البساتين: 'Al Basatin',
        الجامعيين: 'Al Jamiyin',
        الجلوية: 'Al Jalawiyah',
        الجوهرة: 'Al Jawharah',
        'الجوهرة (الدمام)': 'Al Jawharah (Dammam)',
        'الجوهرة (الخبر)': 'Al Jawharah (Khobar)',
        الحسام: 'Al Hussam',
        الحمراء: 'Al Hamra',
        'الحمراء (الدمام)': 'Al Hamra (Dammam)',
        'الحمراء (الخبر)': 'Al Hamra (Khobar)',
        'الخالدية الجنوبية': 'Al Khalidiyah Al Janubiyah',
        'الخالدية الشمالية': 'Al Khalidiyah Ash Shamaliyah',
        الخضرية: 'Al Kuthriah',
        الخليج: 'Al Khalij',
        الدانة: 'Ad Danah',
        الدواسر: 'Ad Dawasir',
        الرابية: 'Ar Rabiyah',
        'الراكة الشمالية': 'Ar Rakah Ash Shamaliyah',
        الربيع: 'Ar Rabi',
        الروضة: 'Ar Rawdah',
        الريان: 'Ar Rayyan',
        الزهور: 'Az Zuhur',
        السلام: 'As Salam',
        السوق: 'As Suq',
        السيف: 'As Saif',
        'الشاطئ الشرقي': 'Ash Shati Ash Sharqi',
        'الشاطئ الغربي': 'Ash Shati Al Gharbi',
        الشرق: 'Ash Sharq',
        الشعلة: 'Ash Shulah',
        الشفا: 'Ash Shifa',
        الصدفة: 'As Sadafah',
        الصفا: 'As Safa',
        الصناعية: 'Industrial',
        'الصناعية الاولى': 'Industrial Area No1',
        الضباب: 'Ad Dabab',
        الطبيشي: 'At Tubayshi',
        العدامة: 'Al Adamah',
        العزيزية: 'Al Aziziyah',
        العمامرة: 'Al Amamrah',
        العنود: 'Al Anud',
        الفردوس: 'Al Firdaws',
        الفرسان: 'Al Fursan',
        الفنار: 'Al Fanar',
        الفيحاء: 'Al Faiha',
        الفيصلية: 'Al Faisaliyah',
        القادسية: 'Al Qadisiyah',
        القزاز: 'Al Qazaz',
        المحمدية: 'Al Muhammadiyah',
        'المدينة الصناعية الثانية': 'Industrial City 2',
        المريكبات: 'Al Muraikabat',
        المزروعية: 'Al Mazruiyah',
        المطار: 'Al Matar',
        المنار: 'Al Manar',
        المنتزه: 'Al Muntazah',
        'المها (الدمام)': 'Al Maha (Dammam)',
        'المها (الخبر)': 'Al Maha (Khobar)',
        الناصرية: 'An Nasriyah',
        النخيل: 'An Nakhil',
        الندى: 'An Nada',
        النزهة: 'An Nuzhah',
        النسيم: 'An Nasim',
        النهضة: 'An Nahdah',
        النور: 'An Nur',
        النورس: 'An Nawras',
        الهضبة: 'Al Hadabah',
        الواحة: 'Al Wahah',
        بدر: 'Badr',
        'ضاحية الملك فهد': 'Dahiyat Al Malik Fahd',
        طيبة: 'Taybah',
        غرناطة: 'Ghirnatah',

        'قصر الخليج': 'Qasr Al Khalij',
        'مدينة العمال (الدمام)': 'Madinat Al Umal (Dammam)',
        'مدينة العمال(الخبر)': 'Madinat Al Ummal (Khobar)',
        'مدينة الملك فيصل الجامعية': 'King Faysal University',
        'مطار الملك فهد الدولي': 'King Fahd International Airport',
        'ميناء الملك عبدالعزيز': 'King Abdul Aziz Seaport',
        'ابن سيناء': 'Ibn Sina',
        اشبيليا: 'Ishbiliya',
        الامواج: 'Al Amwaj',
        الاندلس: 'Al Andalus',
        البحر: 'Al Bahar',
        البستان: 'Al Bustan',
        البندرية: 'Al Bandariyah',
        التحلية: 'At Tahliyah',
        التعاون: 'At Taawn',
        الثقبة: 'Ath Thuqbah',
        الجسر: 'Al Jisr',
        'الحزام الاخضر': 'Al Hizam Al Akhdar',
        'الحزام الذهبي': 'Al Hizam Adh Dhahabi',
        'الخبر الجنوبية': 'Al Khubar Al Janubiyah',
        'الخبر الشمالية': 'Al Khubar Ash Shamaliyah',
        الخزامى: 'Al Khuzama',
        الخور: 'Al Khawr',
        'الراكة الجنوبية': 'Ar Rakah Al Janubiyah',
        الرجاء: 'Ar Raja',
        الروابي: 'Ar Rawabi',
        الساحل: 'As Sahil',
        السفن: 'As Sufun',
        الشراع: 'Ash Sheraa',
        الصواري: 'As Sawari',
        العقربية: 'Al Aqrabiyah',
        العقيق: 'Al Aqiq',
        العليا: 'Al Ulaya',
        الكوثر: 'Al Kawthar',
        الكورنيش: 'Al Kurnaish',
        اللؤلؤ: 'Al Lulu',
        'المدينة الرياضية': 'Sporting City',
        المرجان: 'Al Murjan',
        الهدا: 'Al Hada',
        اليرموك: 'Al Yarmok',
        'صناعية الثقبة': 'Sinaiyah Ath Thuqbah',
        'صناعية الفوازية': 'Sinaiyah Al Foaziah',
        قرطبة: 'Qurtubah',
        'الدوحة الجنوبية': 'Ad Dawhah Al Janubiyah',
        'الدانة الشمالية': 'Ad Danah Ash Shamaliyah',
        القصور: 'Al Qusur',
        'الدوحة الشمالية': 'Ad Dawhah Ash Shamaliyah',
        'الدانة الجنوبية': 'Ad Danah Al Janubiyah',
        الجامعة: 'Al Jamiah',
        'الحرس الوطني': 'Al Haras Al Watani',
        تهامة: 'Tihamah',
        القشلة: 'Al Qashlah',
        هجر: 'Hajr',
        'غرب الظهران': 'Gharb Adh Dhahran',
        'جامعة الملك فهد للبترول والمعادن': 'Kfupm',
        الزمرد: 'Az Zomorod',

        الياقوت: 'Al Yaqoot',
        الصوارى: 'As Swaryee',

        الفلاح: 'Al Falah',
        الاصالة: 'Al Asalah',
        مريخ: 'Mraykh',
        الشروق: 'Ash Shrouk',
        'الامير فواز الشمالى': 'Al Amir Fawaz Ash Shamaly',
        الرياض: 'Ar Riyadh',
        الفروسية: 'Al Frosyah',
        الرحمانية: 'Ar Rahmanyah',
        الصالحية: 'As Salhiyah',
        الحمدانية: 'Al Hamadaniyah',

        'ام حبلين': 'Um Hableen',
        بريمان: 'Bryman',
        المنتزة: 'Al Montazah',
        الثعالبة: 'Ath Thaalibah',
        البلد: 'Al Balad',
        الفاروق: 'Al Farouk',
        العدل: 'Al Adel',
        الهنداوية: 'Al Hindawiyah',
        المحجر: 'Al Mahjar',
        الخالدية: 'Al Khalidiyah',
        'جامعة الملك عبدالعزيز': 'King Abdul Aziz University',
        'البغدادية الشرقية': 'Al Baghdadiyah Ash Sharqiyah',
        'النزلة الشرقية': 'An Nazlah Ash Sharqiyah',
        البوادي: 'Al Bawadi',
        السلامة: 'As Salamah',
        الثغر: 'Ath Thaghr',
        الشرفية: 'Ash Sharafiyah',
        'قاعدة الملك فيصل البحرية': 'King Faisal Naval Base',

        السنابل: 'As Sanabel',
        التضامن: 'At Tadamon',
        الكرامة: 'Al Karamah',
        الرحمة: 'Ar Rahmah',
        البركة: 'Al Barakah',
        المسرة: 'Al Masarah',
        المليساء: 'Al Moulysaa',
        القوزين: 'Al Qouzeen',
        الوادي: 'Al Wadi',
        الفضيلة: 'Al Fadeylah',

        السروات: 'As Sarawat',
        الخمرة: 'Al Khomrah',
        الضاحية: 'Ad Dahiah',
        القرينية: 'Al Qryniah',
        'النزلة اليمانية': 'An Nazlah Al Yamaniyah',
        القريات: 'Al Quraiyat',
        غليل: 'Ghulail',
        الكندرة: 'Al Kandarah',
        العمارية: 'Al Ammariyah',
        الصحيفة: 'As Sahifah',
        السبيل: 'As Sabil',
        'بنى مالك': 'Bani Malik',
        الرحاب: 'Ar Rihab',
        مشرفة: 'Mishrifah',
        الورود: 'Al Wurud',
        'ابرق الرغامة': 'Abruq Ar Rughamah',
        السليمانية: 'As Sulimaniyah',
        البشائر: 'Al Bashaer',

        النعيم: 'An Naim',
        الربوة: 'Ar Rabwah',
        المروة: 'Al Marwah',
        السامر: 'As Samir',
        الاجواد: 'Al Ajwad',
        'مطار الملك عبدالعزيز الدولي': 'King Abdul Aziz International Airport',
        'المنطقة الصناعية': 'Industrial Area',
        'الامير عبدالمجيد': 'Al Amir Abdoulmajed',
        الوزيريه: 'Al Waziriyah',
        'الامير فواز الجنوبى': 'Al Amir Fawaz Al Janouby',
        المتنزهات: 'Al Mutanazahat',
        الفضل: 'Al Fadel',
        'ام السلم': 'Um Asalam',
        'مدينة الملك عبدالعزيز الطبية': 'King Abdul Aziz Medical City',
        'البغدادية الغربية': 'Al Baghdadiyah Al Gharbiyah',
        'ابحر الشمالية': 'Abhur Ash Shamaliyah',
        الرويس: 'Ar Ruwais',
        بترومين: 'Petromin',
        'ابحر الجنوبية': 'Abhur Al Janubiyah',
        السرورية: 'As Sororyah',
        الاجاويد: 'Al Ajaweed',
        الشاطئ: 'Ash Shati',
        'مدائن الفهد': 'Madain Al Fahd',
        الزهراء: 'Az Zahra',
        بحرة: 'Bahrah',
        'ميناء جدة الاسلامى': 'Jeddah Eslamic Seaport',
        النموذجية: 'An Namudhajiyah',
        الجرادية: 'Al Jaradiyah',

        'منفوحة الجديدة': 'Manfuhah Al Jadidah',
        الفاخرية: 'Al Fakhriyah',
        الديرة: 'Ad Dirah',
        'ام الحمام الشرقي': 'Umm Al Hamam Ash Sharqi',
        'المعذر الشمالي': 'Al Madhar Ash Shamali',
        'ام الحمام الغربي': 'Umm Al Hamam Al Gharbi',

        لبن: 'Laban',
        الرفيعة: 'Ar Rafiah',
        الشهداء: 'Al Shohada',
        'الملك فهد': 'King Fahd',
        السويدي: 'As Suwaidi',
        الحزم: 'Al Hazm',
        عتيقة: 'Utaiqah',
        المربع: 'Al Murabba',
        المرسلات: 'Al Mursalat',
        'الملك فيصل': 'King Faisal',
        'المدينة الصناعية الجديدة': 'New Industrial Area',
        المنصورة: 'Al Mansurah',
        غبيرة: 'Ghubairah',

        الجزيرة: 'Al Jazirah',
        السعادة: 'As Saadah',
        المناخ: 'Al Manakh',
        الدفاع: 'Ad Difa',
        'الملك عبدالله': 'King Abdullah',
        'صلاح الدين': 'Salah Ad Din',
        'الملك عبدالعزيز': 'King Abdul Aziz',
        الوزارات: 'Al Wazarat',
        سكيرينة: 'Skirinah',
        جرير: 'Jarir',
        المعذر: 'Al Madhar',
        الملز: 'Al Malaz',
        منفوحة: 'Manfuhah',
        عليشة: 'Ilaishah',
        الضباط: 'Adh Dhubbat',
        'السويدي الغربي': 'As Suwaidi Al Gharbi',
        ديراب: 'Dirab',
        نمار: 'Namar',
        عكاظ: 'Uqaz',
        شبرا: 'Shubra',
        الزهرة: 'Az Zahrah',
        صياح: 'Siyah',
        سلطانة: 'Sultanah',
        اليمامة: 'Al Yamamah',
        البديعة: 'Al Badiah',
        المصانع: 'Al Masani',
        الدريهمية: 'Ad Duraihimiyah',
        'النسيم الشرقي': 'An Nasim Ash Sharqi',
        القدس: 'Al Quds',
        النفل: 'An Nafl',
        المصيف: 'Al Masif',
        الازدهار: 'Al Izdihar',
        'ظهرة البديعة': 'Dhahrat Al Badiah',
        النظيم: 'An Nazim',
        الرماية: 'Ar Rimayah',
        البرية: 'Al Bariah',
        المنصورية: 'Mansuriyah',
        'ضاحية نمار': 'Dahiat Namar',
        المصفاة: 'Al Misfat',
        السفارات: 'As Safarat',
        'خشم العان': 'Khashm Al An',
        طويق: 'Tuwaiq',
        'ظهرة نمار': 'Dhahrat Namar',
        المغرزات: 'Al Mughrizat',
        السلي: 'As Sulai',
        الغدير: 'Al Ghadir',
        المروج: 'Al Muruj',
        العود: 'Al Ud',
        ثليم: 'Thulaim',
        الشميسي: 'Ash Shimaisi',
        الوشام: 'Al Wisham',
        الدوبية: 'Ad Dubiyah',
        المعكال: 'Al Mikal',
        جبرة: 'Jabrah',
        القرى: 'Al Qura',
        المرقب: 'Al Marqab',
        الفوطة: 'Al Futah',
        'ام سليم': 'Umm Salim',
        الصحافة: 'As Sahafah',
        الرائد: 'Ar Raid',
        'العريجاء الغربية': 'Al Uraija Al Gharbiyah',
        العريجاء: 'Al Uraija',
        'العريجاء الوسطى': 'Al Uraija Al Wusta',
        'الدار البيضاء': 'Ad Dar Al Baida',
        البطحاء: 'Al Batha',
        المؤتمرات: 'Al Mutamarat',
        الوسيطاء: 'Al Wusayta',
        الجنادرية: 'Al Janadriyah',
        اشبيلية: 'Ishbiliyah',
        المعيزلة: 'Al Maizilah',
        المونسية: 'Al Munsiyah',
        عرقة: 'Irqah',
        'ظهرة لبن': 'Dhahrat Laban',
        حطين: 'Hittin',
        الملقا: 'Al Malqa',
        القيروان: 'Al Qirawan',
        الياسمين: 'Al Yasmin',
        العارض: 'Al Arid',
        'مطار الملك خالد': 'King Khalid Airport',
        النرجس: 'An Narjis',
        'جامعة الامام': 'Al Imam University',
        بنبان: 'Banban',
        الرمال: 'Ar Rimal',
        الدحو: 'Al Dho',
        العماجية: 'Al Umjiah',
        هيت: 'Hyt',
        الحائر: 'Al Haeer',
        'ام الشعال': 'Um Alshal',
        ' الغنامية': 'Al Ghnamiah',
        عريض: 'Areed',
        المهدية: 'Al Mahdiyah',
        'جامعة الملك سعود': 'King Saud University',
        'النسيم الغربي': 'An Nasim Al Gharbi',
        // المشاعل: 'Al Mishal',
        المشاعل: 'المشاعل',
        القطبية: 'القطبية',
        'ام العراد': 'ام العراد',
        الوكف: 'الوكف',
        نخب: 'نخب',
        الشرقية: 'الشرقية',
        البيعة: 'البيعة',
        الدهاس: 'الدهاس',
        'الامام تركي بن عبدالله': 'الامام تركي بن عبدالله',
        جليل: 'جليل',
        قروى: 'قروى',
        الوسام: 'الوسام',
        الجال: 'الجال',
        الرميدة: 'الرميدة',
        'مخطط البيعة': 'مخطط البيعة',
        حوايا: 'حوايا',
        'مخطط البساتين': 'مخطط البساتين',
        لقطبية: 'لقطبية',
        الوكيف: 'الوكيف',
        'الشهداء الجنوبية': 'الشهداء الجنوبية',
        الوشحاء: 'الوشحاء',
        شهار: 'شهار',
        معشي: 'معشي',
        'مخطط بن باين': 'مخطط بن باين',
        'المهلب بن صفرة': 'المهلب بن صفرة',
        الطويرات: 'الطويرات',
        السداد: 'السداد',
        مسره: 'مسره',
        المثناه: 'المثناه',
        عودة: 'عودة',
        الوهط: 'الوهط',
        القمرية: 'القمرية',
        'مخطط بدر': 'مخطط بدر',
        'بن باين': 'بن باين',
        'مخطط الدهاس': 'مخطط الدهاس',
        'وادي ديرة': 'وادي ديرة',
        الحمادين: 'الحمادين',
        الشهابية: 'الشهابية',
        المربدية: 'المربدية',
        'منسوبي التعليم': 'منسوبي التعليم',
        جوبا: 'جوبا',
        'المعلمين الشرقية': 'المعلمين الشرقية',
        النايفية: 'النايفية',
        'مجمع الدوائر الحكومية': 'مجمع الدوائر الحكومية',
        البصيرة: 'البصيرة',
        المثلث: 'المثلث',
        الحوراء: 'الحوراء',
        المرقاب: 'المرقاب',
        السلمانية: 'السلمانية',
        المهندسين: 'المهندسين',
        المباركية: 'المباركية',
        الجامعين: 'الجامعين',
        الحفيرة: 'الحفيرة',
        الهدى: 'الهدى',
        السنيدية: 'السنيدية',
        المزروع: 'المزروع',
        'الإسكان الأول': 'الإسكان الأول',
        الصيهد: 'الصيهد',
        الكوت: 'الكوت',
        'عين موسى': 'عين موسى',
        الثليثية: 'الثليثية',
        الفاضلية: 'الفاضلية',
        النعاثل: 'النعاثل',
        العسيلة: 'العسيلة',
        الرفعة: 'الرفعة',
        العويمرية: 'العويمرية',
        'السلمانية القديمة': 'السلمانية القديمة',
        'عين علي': 'عين علي',
        الرقيقة: 'الرقيقة',
        الأندلس: 'الأندلس',
        خضراء: 'خضراء',
        الجنوب: 'الجنوب',
        السادة: 'السادة',
        الجردة: 'الجردة',
        'خب الثنيان': 'خب الثنيان',
        الخبيب: 'الخبيب',
        الزرقاء: 'الزرقاء',
        'القاع البارد': 'القاع البارد',
        'الشقة السفلى': 'الشقة السفلى',
        الافق: 'الافق',
        النقل: 'النقل',
        المرواج: 'المرواج',
        الخضر: 'الخضر',
        السالمية: 'السالمية',
        الصباخ: 'الصباخ',
        الهلال: 'الهلال',
        الشماس: 'الشماس',
        الصفراء: 'الصفراء',
        الروضه: 'الروضه',
        رواق: 'رواق',
        اللسيب: 'اللسيب',
        واسط: 'واسط',
        الضاحي: 'الضاحي',
        العجيبة: 'العجيبة',
        الموطاء: 'الموطاء',
        المريدسية: 'المريدسية',
        الشفق: 'الشفق',
        الفايزية: 'الفايزية',
        الإسكان: 'الإسكان',
        الخزامي: 'الخزامي',
        القضيعة: 'القضيعة',
        حويلان: 'حويلان',
        'خب البريدي': 'خب البريدي',
        العريضي: 'العريضي',
        التعليم: 'التعليم',
        الصفاء: 'الصفاء',
        الحمر: 'الحمر',
        الوسيطي: 'الوسيطي',
        النقع: 'النقع',
        الأخضر: 'الأخضر',
        الاشرفية: 'الاشرفية',
        الروغاني: 'الروغاني',
        السمية: 'السمية',
        الشريمية: 'الشريمية',
        الصالة: 'الصالة',
        الضليعة: 'الضليعة',
        العونية: 'العونية',
        الفيضة: 'الفيضة',
        الملكخالد: 'الملكخالد',
        المنطقةالصناعية: 'المنطقةالصناعية',
        الوسطى: 'الوسطى',
        شيخة: 'شيخة',
        غربالديرة: 'غربالديرة',
        مشرف: 'مشرف',
        مليحة: 'مليحة',
        هلالة: 'هلالة',
        الراقي: 'الراقي',
        شباعة: 'شباعة',
        شكر: 'شكر',
        المعزاب: 'المعزاب',
        الشفاء: 'الشفاء',
        القافلة: 'القافلة',
        'ال قصال': 'ال قصال',
        الموسى: 'الموسى',
        عتود: 'عتود',
        الصقور: 'الصقور',
        'سكن المدينة العسكرية': 'سكن المدينة العسكرية',
        ضمك: 'ضمك',
        الدوحة: 'الدوحة',
        الهميلة: 'الهميلة',
        الرصراص: 'الرصراص',
        ذلالة: 'ذلالة',
        السد: 'السد',
        'طيب الاسم': 'طيب الاسم',
        المثلناة: 'المثلناة',
        'سكن القاعدة الجوية': 'سكن القاعدة الجوية',
        'اللواء الرابع عشر': 'اللواء الرابع عشر',
        المعمورة: 'المعمورة',
        الهرير: 'الهرير',
        'ام سرور': 'ام سرور',
        'مخطط غرب المزارع': 'مخطط غرب المزارع',
        الذهب: 'الذهب',
        الطوية: 'الطوية',
        'عبد اللطيف جميل': 'عبد اللطيف جميل',
        'صناعية العريفي': 'صناعية العريفي',
        الصفاة: 'الصفاة',
        'قلعة مخيط': 'قلعة مخيط',
        البلقاء: 'البلقاء',
        السكب: 'السكب',
        مهزور: 'مهزور',
        'بئر عثمان': 'بئر عثمان',
        الغابة: 'الغابة',
        الغراء: 'الغراء',
        العانبس: 'العانبس',
        الجمعة: 'الجمعة',
        كتانة: 'كتانة',
        'مسجد الدرع': 'مسجد الدرع',
        الشريبات: 'الشريبات',
        وعيرة: 'وعيرة',
        العهن: 'العهن',
        السيح: 'السيح',
        'جبل احد': 'جبل احد',
        العريض: 'العريض',
        ورقان: 'ورقان',
        حثم: 'حثم',
        الدويخلة: 'الدويخلة',
        'عين الخيف': 'عين الخيف',
        'بني حارثة': 'بني حارثة',
        قربان: 'قربان',
        الظاهرة: 'الظاهرة',
        النقس: 'النقس',
        الدويمة: 'الدويمة',
        رهط: 'رهط',
        الصادقية: 'الصادقية',
        الجصة: 'الجصة',
        الوبرة: 'الوبرة',
        الدار: 'الدار',
        'جبل عير': 'جبل عير',
        الرانوناء: 'الرانوناء',
        مذينب: 'مذينب',
        'بني بياضة': 'بني بياضة',
        'ابو كبير': 'ابو كبير',
        الهدراء: 'الهدراء',
        القصواء: 'القصواء',
        شظاة: 'شظاة',
        'بني ظفر': 'بني ظفر',
        'بني معاوية': 'بني معاوية',
        الجماوات: 'الجماوات',
        'ابو بريقاء': 'ابو بريقاء',
        الحديقة: 'الحديقة',
        الخاتم: 'الخاتم',
        العيون: 'العيون',
        السقيا: 'السقيا',
        العصبة: 'العصبة',
        عروة: 'عروة',
        الشهباء: 'الشهباء',
        'السكة الحديدية': 'السكة الحديدية',
        شوران: 'شوران',
        نبلاء: 'نبلاء',
        المبعوث: 'المبعوث',
        خاخ: 'خاخ',
        الجابرة: 'الجابرة',
        الرمانة: 'الرمانة',
        'المنطقة المركزية': 'المنطقة المركزية',
        الفتح: 'الفتح',
        الاصيفرين: 'الاصيفرين',
        المغيسلة: 'المغيسلة',
        القبلتين: 'القبلتين',
        الراية: 'الراية',
        'بني عبدالاشهل': 'بني عبدالاشهل',
        'سيد الشهداء': 'سيد الشهداء',
        'ذو الحليفة': 'ذو الحليفة',
        القلعة: 'القلعة',
        'ام خالد': 'ام خالد',
        'جبل جمة وغرابة': 'جبل جمة وغرابة',
        العاقول: 'العاقول',
        الوردتين: 'الوردتين',
        الخشع: 'الخشع',
        الموظفين: 'الموظفين',
        شمسان: 'شمسان',
        القابل: 'القابل',
        المنسك: 'المنسك',
        الشرف: 'الشرف',
        العرين: 'العرين',
        'الأمير سلطان': 'الأمير سلطان',
        القرير: 'القرير',
        'ام ثلعة': 'ام ثلعة',
        الاصفر: 'الاصفر',
        الرمة: 'الرمة',
        الشيرمي: 'الشيرمي',
        ' العبيلة': ' العبيلة',
        العثمانية: 'العثمانية',
        المستشفى: 'المستشفى',
      },
    },
    services: {
      orders: {
        orderRemoveSuccess: 'Order has been deleted',
        orderMarkAsDeliveredSuccess: 'Order has been marked as Delivered',
        importSuccess: 'Orders has been uploaded',
        details: {
          orderId: 'Order ID',
          lockerId: 'Locker ID',
          cabinet: 'Cabinet',
          status: 'Status',
        },
      },
      locations: {
        createSuccess: 'Location has been created',
        updateSuccess: 'Location has been updated',
      },
      lockers: {
        statusCabinetChangeSuccess: 'Status has been updated successfully',
        openCabinetSuccess: 'Cabinet has been opened successfully',
        openLockerSuccess: 'Locker has been opened successfully',
        depositOrderSuccess: 'Order has been succeeded deposited',
      },
      request: {
        createSuccess: 'Request has been created',
        updateSuccess: 'Request was successfully updated',
        splitSuccess: 'Request has been splitted',
        adjustSuccess: 'Request has been adjusted',
      },
      trips: {
        createSuccess: 'Trip has been created',
        updateSucces: 'Trip was successfully updated',
        completeSuccess: 'Trip has been completed',
        pickupSuccess: 'Order has been picked',
        dropSuccess: 'Order has been dropped',
        issueReported: 'Issue has been reported',
        startedGenerate: 'Generate trips has been started',
        export: 'Export',
        exportDetails: 'Export details',
        details: {
          client: 'Client',
          btnRemove: 'Remove from Trip',
          dialogText: 'Are you really want to remove Order from current trip?',
          btnCancel: 'Cancel',
          btnYes: 'Yes',
        },
      },
      users: {
        updateSucces: 'User has been updated',
        resetSuccess: 'Password has been reseted',
        emailNotify:
          'Message has been sent to your email to reset the password',
      },
      profile: {
        changePasswordSuccess: 'Password has been changed successfully!',
        updateProfileSuccess: 'Profile has been updated successfully!',
      },
    },
    toogleAddressBtn: {
      address: 'Address',
      map: 'MAP',
      autocomplete: 'AUTOCOMPLETE',
    },
    gmap: {
      city: 'City',
      area: 'Area',
      state: 'State',
      address: 'Address',
    },
    loader: {
      text: 'Loading ...',
    },
    lockerDetailInfo: {
      lockerDescriptionTitle: 'Locker Description:',
      lockerPhoto: 'Locker Photo:',
      notFound: 'The information not found',
    },
    reportIssues: {
      orderNotFound: 'Order not Found',
      notDelivered3PL: 'Not Delivered 3PL',
      orderNotReady: 'Order not ready',
      orderCancelledByClient: 'Order cancelled by Client',
      cabinetNotOpen: 'Cabinet Not Open',
    },
    notificationsModal: {
      clear: 'Clear',
      tabs: {
        declinedTrips: 'Declined Trips',
        overdueAcceptenceTrips: 'Not Accepted Trip',
      },
    },
    changeTrackLink: 'Change Track Link',
    update: 'Update',
  },
}
