import React, { useState, useCallback, useRef, useMemo, memo } from 'react'
import { useAsync } from 'react-use'
import { Dialog, Icon } from '@blueprintjs/core'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { TextInput, Button, Loader } from '../'

import { useIntersect } from '../../../hooks/useIntersect'
import { getLogisticsCompanies } from '../../../services/api/settings'

import s from './Clients.module.scss'

export const LogisticsCompanies = memo(
  ({
    value = '',
    className = '',
    onChange = () => {},
    onRemove = () => {},
    labelStyle = {},
    filters,
    withRemoveIcon = false,
  }) => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false)
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const loadItems = async () => {
      const { data } = await getLogisticsCompanies({
        status: 'Active',
      })
      setItems((prevState) => [...prevState, ...data.rows])
    }

    const handleToogleModal = useCallback(
      () => setOpen((prevState) => !prevState),
      [],
    )

    const handleSetItem = useCallback(
      (item) => {
        onChange(item)
        handleToogleModal()
      },
      [handleToogleModal, onChange],
    )

    const myRef = useRef()
    const [ref, entry] = useIntersect({ root: myRef.current })

    useAsync(async () => {
      setIsLoading(true)
      try {
        if (isOpen) {
          await loadItems()
        } else {
          setItems([])
        }
      } catch (error) {}
      setIsLoading(false)
    }, [isOpen])

    const isShowRemoveIcon = useMemo(
      () => withRemoveIcon && !!value,
      [withRemoveIcon, value],
    )

    return (
      <div className={classnames(s.clientSelect, className)}>
        <Button
          text={t('modals').intercityParcels.openModalBtnText}
          onClick={handleToogleModal}
          style={{ width: 200 }}
        />

        <TextInput
          disabled={true}
          label={t('modals').intercityParcels.label}
          placeholder={t('modals').intercityParcels.placeholder}
          style={{ marginTop: 10, ...labelStyle }}
          props={{ value }}
        />

        {isShowRemoveIcon && (
          <Icon
            className={s.removeIcon}
            onClick={onRemove}
            icon='cross'
            iconSize='14'
          />
        )}

        <Dialog isOpen={isOpen} className={s.modal} canOutsideClickClose={true}>
          <header className={s.modalHeader}>
            {t('modals').intercityParcels.header}
          </header>
          <div className={s.itemsList}>
            <Loader showLoader={isLoading} className={s.spinner} size={70} />
            {items.map((item) => (
              <div
                ref={ref}
                key={item._id}
                className={s.listItem}
                onClick={() => handleSetItem(item)}
              >
                {item.name}
              </div>
            ))}
          </div>

          <Button
            text={t('modals').intercityParcels.cancelBtnText}
            onClick={handleToogleModal}
          />
        </Dialog>
      </div>
    )
  },
)
