import React, { useState, useCallback, memo } from 'react'
import { useAsync } from 'react-use'
import { Dialog, Icon } from '@blueprintjs/core'
import GoogleMapReact from 'google-map-react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Button } from '../'

import { getLockers } from '../../../services/api/lockers'
import variables from '../../../styles/variables'
import s from './Clients.module.scss'
import { marker, mapBtn } from './LockerMap.module.scss'

const LockerMarker = ({ quantity, onClick }) => {
  const { t } = useTranslation()
  return (
    <div onClick={onClick} className={marker}>
      <Icon icon='box' color={variables.shuttleWhite} />
      {t('modals').lockersOnMap.quantity.replace('{{quantity}}', quantity)}
    </div>
  )
}

export const LockersOnTheMap = memo(
  ({ onChange = () => {}, setCurrentStep, isOpenOnboarding = false }) => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false)

    const handleToogleModal = useCallback(
      () => setOpen((prevState) => !prevState),
      [],
    )

    const [lockers, setLockers] = useState([])

    const handleClick = async (locker) => {
      onChange([locker])
      handleToogleModal()
      if (isOpenOnboarding) {
        setTimeout(() => {
          setCurrentStep(11)
        }, 400)
      }
    }

    const handleOpenModal = async () => {
      handleToogleModal()
      if (isOpenOnboarding) {
        setTimeout(() => {
          setCurrentStep(10)
        }, 400)
      }
    }
    const handleCancel = () => {
      isOpenOnboarding && setCurrentStep(9)
      handleToogleModal()
    }

    useAsync(async () => {
      if (!isOpen) return
      const retrievedLockers = await getLockers({ status: 'Active' })
      const filteredIsNotUtilizedCompletely = retrievedLockers.rows.filter(
        ({ utilization }) => utilization !== 100,
      )
      const normalized = filteredIsNotUtilizedCompletely.reduce((acc, val) => {
        const locId = val.location._id
        if (locId in acc) {
          acc[locId].quantity += 1
        } else {
          acc[locId] = {
            ...val.location,
            quantity: 1,
          }
        }

        return acc
      }, {})

      const formatingToArray = Object.values(normalized)
      setLockers(formatingToArray)
    }, [isOpen])

    return (
      <div className={classnames(s.clientSelect)}>
        <div className={mapBtn} onClick={handleOpenModal}>
          <Icon icon='send-to-map' color={variables.shuttleWhite} />
        </div>

        <Dialog
          isOpen={isOpen}
          className={`${s.modal} dataTour20`}
          canOutsideClickClose={true}
        >
          <header className={s.modalHeader}>
            {t('modals').lockersOnMap.title}
          </header>
          <div style={{ height: '500px', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
              defaultCenter={{
                lat: 22.848295,
                lng: 45.725533,
              }}
              defaultZoom={5}
            >
              {lockers.length > 0 &&
                lockers.map((locker) => {
                  const { number, lat, lng, quantity } = locker
                  return (
                    <LockerMarker
                      key={number}
                      lat={lat}
                      lng={lng}
                      quantity={quantity}
                      onClick={() => handleClick(locker)}
                    />
                  )
                })}
            </GoogleMapReact>
          </div>

          <Button
            text={t('modals').lockersOnMap.cancelBtn}
            onClick={handleCancel}
          />
        </Dialog>
      </div>
    )
  },
)
