import axios from 'axios'
import i18n from 'i18next'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { showSuccessMessage, showErrorMessage } from '../modules/Toasters'
import { TOKEN_LOCALSTORAGE_KEY } from '../data/constans'

import { authEndpoints } from './api/auth'
import { userEndpoints } from './api/user'
import { settingsEndpoints } from './api/settings'

export const BASE_URL = process.env.REACT_APP_BASE_URL

const SHUTTLE_API_URL = BASE_URL + '/api'

axios.defaults.baseURL = SHUTTLE_API_URL

const tokenExpiredMsg = 'jwt expired'

export const refreshUserToken = async () => {
  try {
    const {
      data: { access_token },
    } = await axios.get('/user/token')
    localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, access_token)
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
  } catch (error) {
    showErrorMessage('Error with refresh token')
    window.location.reload()
  }
}

export const refreshPageIfTokenWasExpired = async (msg) => {
  if (msg === tokenExpiredMsg) {
    localStorage.removeItem(TOKEN_LOCALSTORAGE_KEY)
    document.location.reload()
  }
}

export const signUp = async (data, onSuccess) => {
  try {
    await axios.post('/user', data)
    onSuccess()
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
  }
}

export const forgotPassword = async (email) => {
  try {
    return await axios.post('/user/password/reset', email)
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const checkEmail = async (email) => {
  try {
    return await axios.post('/user/check_email', email)
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

//Current User Profile

export const getCurrentUser = async () => {
  try {
    const { data } = await axios.get('/user')
    return data
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
  }
}

export const changePassword = async (data) => {
  try {
    await axios.post('/user/password', data)
    showSuccessMessage(i18n.t<any>('services').profile.changePasswordSuccess)
    return true
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(
      'Password has not been changed' + error.response.data.message,
    )
    return false
  }
}

export const updateProfile = async (data, onSuccess) => {
  try {
    const { data: newData } = await axios.put('/user', data)
    showSuccessMessage(i18n.t<any>('services').profile.updateProfileSuccess)
    onSuccess(newData)
    return true
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
    return false
  }
}

export const shuttleApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: SHUTTLE_API_URL,
    prepareHeaders: (headers, { getState }) => {
      //const token = (getState() as RootState).auth.token
      const tokenFromLocalStorage = window.localStorage.getItem(
        TOKEN_LOCALSTORAGE_KEY,
      )

      if (
        //token ||
        tokenFromLocalStorage
      ) {
        headers.set(
          'Authorization',
          `Bearer ${
            //token ||
            tokenFromLocalStorage
          }`,
        )
      } else {
      }

      return headers
    },
  }),
  tagTypes: ['Cities'],
  endpoints: (builder) => ({
    ...authEndpoints(builder),
    ...userEndpoints(builder),
    ...settingsEndpoints(builder),
  }),
})

export const {
  useLoginMutation,
  useGetCurrentUserQuery,
  useGetCitiesQuery,
  useGetCitiesForRouteQuery,
  useGetCityByIdQuery,
  useCreateCityMutation,
  useRemoveCityMutation,
  useUpdateCityMutation,
} = shuttleApi
