import React, { memo } from 'react'
import cn from 'classnames'

import ListItem from './ListItem'

import s from './OrderList.module.scss'

export const OrderList = memo(
  ({ items, onRemove, className, children, isOpenOnboarding }) => {
    return (
      <div className={cn(s.ordersList, className)}>
        {children}
        {items.map((item) => (
          <ListItem
            key={item._id}
            {...item}
            onRemove={onRemove}
            isOpenOnboarding={isOpenOnboarding}
          />
        ))}
      </div>
    )
  },
)
