import React, { memo } from 'react'
import i18n from 'i18next'
import { Spinner } from '@blueprintjs/core'
import classnames from 'classnames'

import s from './Loader.module.scss'

export const Loader = memo(
  ({
    showLoader = false,
    showText = true,
    loadingText = i18n.t('loader').text,
    size = 50,
    className = '',
  }) => {
    if (!showLoader) return null
    if (showLoader) {
      return (
        <div className={s.loader}>
          <Spinner className={classnames(s.spinner, className)} size={size} />
          {!!loadingText && showText && (
            <div className={s.loadingText}>{loadingText}</div>
          )}
        </div>
      )
    }
  },
)
