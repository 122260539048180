import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { TextInput } from './TextInput'

export const LocationName = memo(({ register }) => {
  const { t } = useTranslation()
  return (
    <TextInput
      register={register}
      name='name'
      placeholder={t('locationName').placeholder}
      label={t('locationName').label}
    />
  )
})
