import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Select } from './Select'
import { INTEGRATION_SERVICES } from '../../../data/constans'

export const IntegrationService = memo(({ selectRef, value, onChange }) => {
  const { t } = useTranslation()
  const options = useMemo(() => Object.values(INTEGRATION_SERVICES), [])

  return (
    <Select
      selectRef={selectRef}
      value={value}
      options={options}
      placeholder={t('integration').placeholder}
      label={t('integration').label}
      onChange={onChange}
      isRequired
      Option={(props) => {
        if (!props) {
          return null
        }
        const name = props[0]

        return (
          <option value={name} key={name}>
            {name}
          </option>
        )
      }}
    />
  )
})
