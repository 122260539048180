import React from 'react'
import { useTranslation } from 'react-i18next'

import info from '../../../assets/images/Info.svg'
import form from '../../../assets/images/FormIcon.svg'
import variables from '../../../styles/variables'

export const CustomerSupportModal = () => {
  const { t, i18n } = useTranslation()

  const openChatButton = document.querySelector('#slaask-button')

  return (
    <div
      className='modal fade'
      id='exampleModal2'
      style={
        i18n.language === 'ar' ? { direction: 'rtl', textAlign: 'right' } : {}
      }
      tabIndex='-1'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-body mx-auto text-center'>
            <button
              type='button'
              className='close'
              style={coloseIcon}
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
            <img src={info} className='d-block mx-auto mt-2 mb-4' alt='' />
            <h5 className='mb-5' id='exampleModalLabel'>
              {t('trackingPage').homePage.optionCards.customersSupport}
            </h5>
            <p
              className={`mb-4 ${
                i18n.language === 'ar' ? 'text-right' : 'text-left'
              }`}
            >
              {t('trackingPage').homePage.optionCards.customerSupport1}
            </p>
            <div className='d-flex justify-content-around align-items-center mb-5'>
              <button
                type='button'
                className='btn supportBtn'
                onClick={() => openChatButton?.click()}
              >
                <img
                  src={form}
                  className={`${i18n.language === 'ar' ? 'ml-2' : 'mr-2'}`}
                  alt={`${i18n.language === 'ar' ? 'ml-2' : 'mr-2'}`}
                />
                {t('trackingPage').homePage.optionCards.slaaskChatOpen}
              </button>
            </div>

            <p
              className={`mb-4 ${
                i18n.language === 'ar' ? 'text-right' : 'text-left'
              }`}
            >
              {t('trackingPage').homePage.optionCards.customerSupport2}
            </p>
            <div className='d-flex justify-content-around align-items-center mb-2'>
              <a
                href={process.env.REACT_APP_TYPEFORM_ERROR}
                target='_blank'
                rel='noopener noreferrer'
              >
                <button className='btn supportBtn'>
                  <img
                    src={form}
                    className={`${i18n.language === 'ar' ? 'ml-2' : 'mr-2'}`}
                    alt=''
                  />
                  {t('trackingPage').homePage.optionCards.typeForm}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const coloseIcon = {
  position: 'absolute',
  left: '20px',
  top: '20px',
  padding: '0px 5px',
  backgroundColor: variables.background,
  borderRadius: '20px',
}
