import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@blueprintjs/core'
import variables from '../../../styles/variables'
import s from './OrderDetails.module.scss'

export const OrderDetails = ({ order }) => {
  const { t } = useTranslation()

  return (
    <div>
      <ul className={s.list}>
        <li className={s.listItem}>
          <span className={s.itemTitle}>
            {t('services').orders.details.orderId}:
          </span>
          <span>{order.number}</span>
        </li>
        <li className={s.listItem}>
          <span className={s.itemTitle}>
            {t('services').orders.details.lockerId}:
          </span>
          <span>{order.locker ? order.locker : '-'}</span>
        </li>
        <li className={s.listItem}>
          <span className={s.itemTitle}>
            {t('services').orders.details.cabinet}:
          </span>
          <span>{order.cabinet ? order.cabinet : '-'}</span>
        </li>
        <li className={s.listItem}>
          <span className={s.itemTitle}>
            {t('services').orders.details.status}:
          </span>
          <span>{order.status}</span>
        </li>
      </ul>

      <p>
        <Icon icon='person' size={20} color={variables.yellow} />
        <span className={s.contactName}>
          {`(${t('services').trips.details.client}) ${order.client.name} – `}
        </span>
        {order.client.phone && (
          <a href={`tel:${order.client.phone}`}>
            <Icon icon='phone' size={20} color={variables.yellow} />
          </a>
        )}
        {!order.client.phone && '-'}
      </p>
      <p>
        <Icon icon='following' size={30} color={variables.yellow} />
        <span
          className={s.contactName}
        >{`${order.receiverFirstName} ${order.receiverLastName} – `}</span>
        {order.receiverPhone && (
          <a href={`tel:${order.receiverPhone}`}>
            <Icon icon='phone' size={20} color={variables.yellow} />
          </a>
        )}
        {!order.receiverPhone && 'no phone'}
      </p>
    </div>
  )
}
