import React from 'react'
import { Checkbox } from '@blueprintjs/core'
import cn from 'classnames'
import s from './BaseCheckbox.module.scss'

interface BaseCheckboxProps {
  value: boolean
  setValue: React.MouseEventHandler<HTMLElement> | null
  register: any
  name: string
  label: string
  style: any
  disabled: boolean
}

export const BaseCheckbox: React.FC<BaseCheckboxProps> = ({
  value,
  setValue,
  register,
  name,
  label,
  style,
  disabled = false,
}) => {
  return (
    <div style={style}>
      <Checkbox
        checked={value}
        onClick={setValue}
        disabled={disabled}
        className={cn('bp3-large', s.checkBox)}
        {...register}
        {...name}
      >
        <span>{label}</span>
      </Checkbox>
    </div>
  )
}
