import React from 'react'
import { useTranslation } from 'react-i18next'
import { BASE_URL } from '../../../services/api'

export const LockerDetailInfo = ({
  lockerDescription,
  lockerPhoto,
  styles = null,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const languageKey = language === 'ar' ? 'ar' : 'en'

  return (
    <div
      style={{
        ...(languageKey === 'ar'
          ? { direction: 'rtl', textAlign: 'right' }
          : { textAlign: 'left' }),
        ...styles,
      }}
    >
      <h5 style={{ marginBottom: 0 }}>
        {t('lockerDetailInfo').lockerDescriptionTitle}
      </h5>
      <div>
        {lockerDescription ? lockerDescription : t('lockerDetailInfo').notFound}
      </div>
      <h5 style={{ marginBottom: 0 }}>{t('lockerDetailInfo').lockerPhoto}</h5>
      <div>
        {lockerPhoto ? (
          <div style={{ textAlign: 'center' }}>
            <img
              width='270px'
              src={`${BASE_URL}/${lockerPhoto}`}
              alt='locker photo'
            />
          </div>
        ) : (
          t('lockerDetailInfo').notFound
        )}
      </div>
    </div>
  )
}
