import React, { useState, useCallback, useMemo, memo } from 'react'
import { useAsync } from 'react-use'
import { Suggest } from '@blueprintjs/select'
import { MenuItem } from '@blueprintjs/core'

import { Button } from '..'

import { useAuth } from '../../../context/auth'

import s from './SuggestLocation.module.scss'

function areItemsEqual(itemA, itemB, itemKey) {
  // Compare only the titles (ignoring case) just for simplicity.
  return itemA[itemKey].toLowerCase() === itemB[itemKey].toLowerCase()
}

function escapeRegExpChars(text) {
  return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1')
}

function highlightText(text, query) {
  let lastIndex = 0
  const words = query
    .split(/\s+/)
    .filter((word) => word.length > 0)
    .map(escapeRegExpChars)
  if (words.length === 0) {
    return [text]
  }
  const regexp = new RegExp(words.join('|'), 'gi')
  const tokens = []
  while (true) {
    const match = regexp.exec(text)
    if (!match) {
      break
    }
    const length = match[0].length
    const before = text.slice(lastIndex, regexp.lastIndex - length)
    if (before.length > 0) {
      tokens.push(before)
    }
    lastIndex = regexp.lastIndex
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>)
  }
  const rest = text.slice(lastIndex)
  if (rest.length > 0) {
    tokens.push(rest)
  }
  return tokens
}

export const SuggestItems = memo(
  ({ data = [], itemKey = '', onAdd = () => {}, apiGetRuquest = () => {} }) => {
    const [items, setItems] = useState([])
    const [query, setQuery] = useState('')
    const [selectedItem, setItem] = useState('')

    useAsync(async () => {
      if (!query) return null
      const retrievedData = await apiGetRuquest({ [itemKey]: query })
      if (retrievedData) {
        const filterExistingItemInData = retrievedData.filter((el) => {
          const existingItem = data.find((elm) => elm._id === el._id)
          if (!!existingItem) {
            return false
          }
          return el
        })
        setItems(filterExistingItemInData)
      }
    }, [query])

    const handleAdd = useCallback(() => {
      onAdd(selectedItem)
      setQuery('')
      setItem('')
      setItems([])
    }, [onAdd, selectedItem])

    const disableAddButton = useMemo(() => selectedItem === '', [selectedItem])

    return (
      <div className={s.suggestContainer}>
        <Suggest
          className={s.suggest}
          items={items}
          itemsEqual={(itemA, itemB) => areItemsEqual(itemA, itemB, itemKey)}
          inputValueRenderer={({ value }) => value}
          itemRenderer={(item, { handleClick }) => (
            <MenuItem
              onClick={handleClick}
              text={highlightText(item[itemKey], query)}
            />
          )}
          onItemSelect={(item) => {
            setItem(item)
            setQuery(item[itemKey])
          }}
          query={query}
          onQueryChange={setQuery}
          noResults={<MenuItem disabled={true} text='No results.' />}
        />
        <Button
          text='Add'
          disabled={disableAddButton}
          onClick={disableAddButton ? () => {} : handleAdd}
        />
      </div>
    )
  },
)
