import React, { useState, useCallback, useRef, useMemo, memo } from 'react'
import { useAsync } from 'react-use'
import { Dialog, Icon } from '@blueprintjs/core'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import {
  TextInput,
  ButtonsGroup,
  Button,
  Loader,
  BaseCheckbox,
  Search,
} from '../'

import { useIntersect } from '../../../hooks'
import { getClients } from '../../../services/api/clients'

import s from './Clients.module.scss'

export const ClientsMulti = memo(
  ({
    value = [],
    className = '',
    onChange = () => {},
    onRemove = () => {},
    labelStyle = {},
    withRemoveIcon = false,
  }) => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false)
    const [items, setItems] = useState([])
    const [currentSelectedClients, setCurrentSelectedClients] = useState([])
    const [hasNextPage, setHasNextPage] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [searchField, setSearchField] = useState('')

    const loadItems = async () => {
      const { rows, page, totalPages } = await getClients({
        page: pageNumber,
        query: {
          status: 'Active',
          name: { $regex: `.*${searchField}`, $options: 'i' },
        },
      })
      setHasNextPage(page < totalPages)
      setPageNumber(page !== totalPages ? page + 1 : totalPages)
      if (pageNumber === 1) {
        setItems([...rows])
      } else {
        setItems((prevState) => [...prevState, ...rows])
      }
    }

    const handleToogleModal = useCallback(
      () => setOpen((prevState) => !prevState),
      [],
    )

    const handleSearchField = async (value) => {
      setSearchField(value)
      setPageNumber(1)
    }
    const handleConfirmSelect = () => {
      onChange(currentSelectedClients)
      handleToogleModal()
    }
    const handleRemoveClients = () => {
      onRemove()
      setCurrentSelectedClients([])
    }

    const changeSelectClients = (item) => {
      const isAddSelectItem = currentSelectedClients.find(
        (el) => el._id === item._id,
      )

      if (isAddSelectItem) {
        setCurrentSelectedClients((prev) =>
          prev.filter((el) => el._id !== item._id),
        )
      } else {
        setCurrentSelectedClients((prev) => [...prev, item])
      }
    }
    const myRef = useRef()
    const [ref, entry] = useIntersect({ root: myRef.current })

    useAsync(async () => {
      setIsLoading(true)
      try {
        isOpen && hasNextPage && (await loadItems())
      } catch (error) {}
      setIsLoading(false)
    }, [entry, isOpen])

    useAsync(async () => {
      setIsLoading(true)
      try {
        await loadItems()
      } catch (error) {}
      setIsLoading(false)
    }, [searchField])

    const isShowRemoveIcon = useMemo(
      () => withRemoveIcon && !!value,
      [withRemoveIcon, value],
    )

    return (
      <div className={classnames(s.clientSelect, className)}>
        <Button
          text={t('modals').clientsMulti.btnOpenModalLabel}
          onClick={handleToogleModal}
          style={{ padding: '8px 0' }}
        />
        <div className={s.multi}>
          {value.length > 0 ? (
            <>
              {value.length === 1 ? (
                value.map((c) => (
                  <div className={s.multiItem} key={c.email}>
                    <TextInput
                      disabled={true}
                      label={t('modals').clients.label}
                      placeholder={t('modals').clients.placeholder}
                      style={{
                        marginTop: 10,
                        ...labelStyle,
                      }}
                      props={{ value: `${c.name}` }}
                    />
                  </div>
                ))
              ) : (
                <div className={s.multiItem}>
                  <TextInput
                    disabled={true}
                    label={t('modals').clients.label}
                    placeholder={t('modals').clients.placeholder}
                    style={{
                      marginTop: 10,
                      ...labelStyle,
                    }}
                    props={{ value: `${value.length} selected` }}
                  />
                </div>
              )}
              {isShowRemoveIcon && (
                <Icon
                  className={s.removeIcon}
                  onClick={handleRemoveClients}
                  icon='cross'
                  iconSize='14'
                />
              )}
            </>
          ) : (
            <>
              <TextInput
                disabled={true}
                label={t('modals').clients.label}
                placeholder={t('modals').clients.placeholder}
                style={{ marginTop: 10, ...labelStyle }}
              />
            </>
          )}
        </div>

        <Dialog isOpen={isOpen} className={s.modal} canOutsideClickClose={true}>
          <header className={s.modalHeader}>{t('modals').clients.title}</header>
          <Search
            value={searchField}
            onChange={handleSearchField}
            placeholder={t('locationsRoute').filters.searchByNamePlaceholder}
            style={{ width: 200 }}
            autoFocus={true}
          />
          <div className={s.itemsList}>
            <Loader showLoader={isLoading} className={s.spinner} size={70} />
            {items.map((item) => {
              const isAddSelectedClient = currentSelectedClients.find(
                (c) => c._id === item._id,
              )
              return (
                <div ref={ref} key={item._id} className={s.listItem}>
                  <BaseCheckbox
                    value={!!isAddSelectedClient}
                    setValue={() => changeSelectClients(item)}
                  />
                  {item.name}
                </div>
              )
            })}
            {!hasNextPage && (
              <div className={s.fullLoadedLabel}>
                {t('modals').clients.allHitsLoaded}
              </div>
            )}
          </div>

          <ButtonsGroup
            {...{
              dissmissProps: {
                onGoBack: () => handleToogleModal(),
              },
              actionProps: {
                onClick: handleConfirmSelect,
              },
              style: { marginTop: 10, textAlign: 'center' },
            }}
          />
        </Dialog>
      </div>
    )
  },
)
