import { Icon } from '@blueprintjs/core'
import React from 'react'
import variables from '../../../../styles/variables'

interface CircleCheckboxProps {
  checked: boolean
  onClick: React.MouseEventHandler<HTMLElement>
}

export const CircleCheckbox: React.FC<CircleCheckboxProps> = ({
  checked,
  onClick,
}) => {
  return (
    <Icon
      icon={checked ? 'tick-circle' : 'circle'}
      color={checked ? variables.shuttleOrange : variables.background}
      onClick={onClick}
    />
  )
}
