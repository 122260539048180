import React, { lazy } from 'react'
import { ClientRoutes } from './ClientRoutes'
import { AdminRoutes } from './AdminRoutes'
import { TenantRoutes } from './TenantRoutes'
import { useUserRole } from '../../../hooks'

const Header = lazy(() => import('../../Header/Header'))
const SideBar = lazy(() => import('../../NavigationPanel/SideBar'))

export const ApplicationRoute = () => {
  const { isAdmin, isClient, isTenant } = useUserRole()

  return (
    <div>
      <Header />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <SideBar />
        {isClient && <ClientRoutes />}
        {isAdmin && <AdminRoutes />}
        {isTenant && <TenantRoutes />}
      </div>
    </div>
  )
}
