import React, { memo } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Button } from './Button'

import s from './ButtonsGroup.module.scss'

const dismissInitialProps = {
  onGoBack: () => {},
}

const actionInitialProps = {
  disabled: false,
}

export const ButtonsGroup = memo(
  ({
    dissmissProps = dismissInitialProps,
    actionProps = actionInitialProps,
    className = '',
    style = {},
  }) => {
    const { t } = useTranslation()
    const { onGoBack } = dissmissProps
    const { disabled, isShowIcon, icon, text, ...otherProps } = actionProps
    return (
      <div className={classnames(s.buttonsGroup, className)} style={style}>
        <Button
          onClick={onGoBack}
          text={t('buttonsGroup').cancelBtnLabel}
          classNameType='dismiss'
        />
        <Button
          type='submit'
          text={text || t('buttonsGroup').saveBtnLabel}
          classNameType='action'
          disabled={disabled}
          {...otherProps}
        />
      </div>
    )
  },
)
