import { useState } from 'react'
import { useAuth } from '../../../../../../context/auth'

import { ROLE } from '../../../../../../data/constans'
import {
  updateLocation,
  updateAdminLocation,
} from '../../../../../../services/api/locations'

export const useUpdateData = () => {
  const {
    authUser: { role, client },
  } = useAuth()

  const [isUpdatingData, setIsUpdating] = useState(false)

  const update = async ({ data }) => {
    setIsUpdating(true)
    if (role === ROLE.ADMIN) {
      await updateAdminLocation({ data })
    }
    if (role === ROLE.CLIENT) {
      await updateLocation({ data: { ...data, client } })
    }
    setIsUpdating(false)
  }

  return { isUpdatingData, update }
}
