import React, { useState, useCallback, memo } from 'react'
import { Dialog, Icon } from '@blueprintjs/core'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { City, District, ButtonsGroup } from '..'
import { isServedCity, isServedDistrict } from '../../../utils/validators'

import { updateOrderLocation } from '../../../services/api/orders'
import s from './Clients.module.scss'
import variables from '../../../styles/variables'

export const CityDistrict = memo(
  ({
    orderId,
    selectedCity = null,
    selectedDistrict = null,
    className = '',
    isOpenOnboarding = false,
  }) => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false)
    const [city, setCity] = useState(selectedCity)
    const [district, setDistrict] = useState(selectedDistrict)

    const handleToogleModal = useCallback(() => {
      setOpen((prevState) => !prevState)
      setCity(selectedCity)
      setDistrict(selectedDistrict)
    }, [])

    const handleSave = async () => {
      const payload = { receiverCity: city, receiverDistrict: district }
      await updateOrderLocation({ orderId, payload })
    }

    const isNotServedLocation =
      !isServedCity(city) || !isServedDistrict(city, district)

    return (
      <div
        className={classnames(s.clientSelect, className)}
        style={{ display: 'flex' }}
      >
        <Icon
          icon='edit'
          color={
            isOpenOnboarding ? variables.disabled : variables.shuttleOrange
          }
          onClick={!isOpenOnboarding ? handleToogleModal : undefined}
          style={{
            cursor: 'pointer',
          }}
        />

        <Dialog isOpen={isOpen} className={s.modal} canOutsideClickClose={true}>
          <header className={s.modalHeader}>
            {t('modals').cityDistrict.header}
          </header>

          <City
            value={city}
            hideUnvisible={true}
            onChange={(value) => {
              setCity(value)
              setDistrict(null)
            }}
          />

          <District
            selectedCity={city}
            value={district}
            disabled={!city}
            onChange={(value) => setDistrict(value)}
          />

          {isNotServedLocation && (
            <p style={{ color: 'red', marginTop: 20 }}>
              City or District not served
            </p>
          )}

          <ButtonsGroup
            dissmissProps={{ onGoBack: handleToogleModal }}
            actionProps={{
              onClick: handleSave,
              disabled: isNotServedLocation,
            }}
          />
        </Dialog>
      </div>
    )
  },
)
