import i18n from 'i18next'

export const ADMIN_ROUTES = {
  TRIPS: '/trips',
  TRIPS_ADD: '/trips/addtrip',
  TRIPS_EDIT: '/trips/edit',
  TRIP_DETAILS: '/trips/details/:id',
  CLIENTS: '/clients',
  CLIENTS_ADD: '/clients/addclient',
  CLIENTS_EDIT: '/clients/edit',
  ROUTES: '/routes',
  ROUTES_ADD: '/routes/addroute',
  ROUTES_EDIT: '/routes/edit',
  LOCKER_STATISTICS: '/lockers_statistics',
  LOCKER_DETAILS: '/lockers/details/:id',
  LOCKERS_ADD: '/lockers/addlocker',
  LOCKERS_EDIT: '/lockers/edit',
  ORDERS_STATISTICS: '/orders_statistics',
  CYCLE_ANALYSIS: '/cycle_analysis',
  PROVIDER_REPORT: '/provider_report',
  TRIPS_REPORT: '/trips_report',
  HOSTS: '/hosts',
  HOSTS_ADD: '/hosts/addhost',
  HOSTS_EDIT: '/hosts/edit',
  HOSTS_ANALYTICS: '/analytics/hosts',
  INTERCITY_PARCELS: '/intercity_parcels',
  SETTINGS: '/settings',
  SETTINGS_CITY_ADD: '/settings/addCity',
  SETTINGS_CITY_EDIT: '/settings/editCity/:idCity',
}
const COMMON_ROUTES = {
  PROFILE: '/profile',
  REQUESTS: '/requests',
  ORDERS: '/orders',
  ORDERS_ADD: '/orders/addorder',
  ORDERS_EDIT: '/orders/edit',
  PAYMENTS: '/payments',
  USERS: '/users',
  USERS_ADD: '/users/adduser',
  USERS_EDIT: '/users/edit',
  LOCATIONS: '/locations',
  LOCATIONS_ADD: '/locations/addlocation',
  LOCATIONS_EDIT: '/locations/edit',
  LOCKERS: '/lockers',
  AUTH: '/auth',
  PRIVACY_POLICY: '/privacy-policy',
}

export const routes = {
  ...ADMIN_ROUTES,
  ...COMMON_ROUTES,
}

export const SIDEBAR_ROUTES = {
  ADMIN_ROUTES: {
    INTERCITY_PARCELS: {
      path: ADMIN_ROUTES.INTERCITY_PARCELS,
      icon: 'office',
      title: i18n.t('navigationsPanel').intercityParcels,
    },
    HOSTS: {
      path: ADMIN_ROUTES.HOSTS,
      icon: 'layout-auto',
      title: i18n.t('navigationsPanel').hosts,
    },
    TRIPS: {
      path: ADMIN_ROUTES.TRIPS,
      icon: 'drive-time',
      title: i18n.t('navigationsPanel').trips,
    },
    CLIENTS: {
      path: ADMIN_ROUTES.CLIENTS,
      icon: 'people',
      title: i18n.t('navigationsPanel').clients,
    },
    ROUTES: {
      path: ADMIN_ROUTES.ROUTES,
      icon: 'route',
      title: i18n.t('navigationsPanel').routes,
    },
    LOCKER_STATISTICS: {
      path: ADMIN_ROUTES.LOCKER_STATISTICS,
      icon: 'full-stacked-chart',
      title: i18n.t('navigationsPanel').lockersStatistics,
    },
    ORDERS_STATISTICS: {
      path: ADMIN_ROUTES.ORDERS_STATISTICS,
      icon: 'heat-grid',
      title: i18n.t('navigationsPanel').ordersStatistics,
    },
    CYCLE_ANALYSIS: {
      path: ADMIN_ROUTES.CYCLE_ANALYSIS,
      icon: 'panel-table',
      title: i18n.t('navigationsPanel').cycleAnalysis,
    },
    PROVIDER_REPORT: {
      path: ADMIN_ROUTES.PROVIDER_REPORT,
      icon: 'heatmap',
      title: i18n.t('navigationsPanel').providerReport,
    },
    TRIPS_REPORT: {
      path: ADMIN_ROUTES.TRIPS_REPORT,
      icon: 'manually-entered-data',
      title: i18n.t('navigationsPanel').tripsReport,
    },
    HOSTS_ANALYTICS: {
      path: ADMIN_ROUTES.HOSTS_ANALYTICS,
      icon: 'grid-view',
      title: i18n.t('navigationsPanel').hostAnalytics,
    },
    SETTINGS: {
      path: ADMIN_ROUTES.SETTINGS,
      icon: 'cog',
      title: i18n.t('navigationsPanel').settings,
    },
  },
  TENANT_ROUTES: {
    LOCKERS: {
      path: COMMON_ROUTES.LOCKERS,
      icon: 'box',
      title: i18n.t('navigationsPanel').lockers,
    },
    HOSTS_ANALYTICS: {
      path: ADMIN_ROUTES.HOSTS_ANALYTICS,
      icon: 'grid-view',
      title: i18n.t('navigationsPanel').hostAnalytics,
    },
  },
  COMMON_ROUTES: {
    REQUESTS: {
      path: COMMON_ROUTES.REQUESTS,
      icon: 'send-message',
      title: i18n.t('navigationsPanel').requests,
    },
    ORDERS: {
      path: COMMON_ROUTES.ORDERS,
      icon: 'shopping-cart',
      title: i18n.t('navigationsPanel').orders,
    },
    PAYMENTS: {
      path: COMMON_ROUTES.PAYMENTS,
      icon: 'dollar',
      title: i18n.t('navigationsPanel').payments,
    },
    USERS: {
      path: COMMON_ROUTES.USERS,
      icon: 'person',
      title: i18n.t('navigationsPanel').users,
    },
    LOCATIONS: {
      path: COMMON_ROUTES.LOCATIONS,
      icon: 'map-marker',
      title: i18n.t('navigationsPanel').locations,
    },
    LOCKERS: {
      path: COMMON_ROUTES.LOCKERS,
      icon: 'box',
      title: i18n.t('navigationsPanel').lockers,
    },
  },
}
