import React, { useCallback, memo } from 'react'
import { Switch } from '@blueprintjs/core'

import { useLanguage, INTERFACE_LANGUAGES } from '../../../context/language'

import s from './LanguageToogleBtn.module.scss'

const LanguageToggleBtn = () => {
  const [language, toogleLanguage] = useLanguage()

  const toggle = useCallback(
    ({ target }) => {
      const { checked } = target

      const lang = checked
        ? INTERFACE_LANGUAGES.ENGLISH
        : INTERFACE_LANGUAGES.ARABIC
      toogleLanguage(lang)
    },
    [toogleLanguage],
  )

  return (
    <Switch
      className={s.languageToogleBtn}
      checked={language === INTERFACE_LANGUAGES.ENGLISH || language === 'en-US'}
      onChange={toggle}
      innerLabelChecked='EN'
      innerLabel='AR'
    />
  )
}

export default memo(LanguageToggleBtn)
