import React, { useMemo, memo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import s from './Select.module.scss'

export const Select = memo(
  ({
    selectRef = null,
    value = '',
    options = [],
    placeholder = '',
    label = '',
    disabled = false,
    style = {},
    onChange = () => {},
    Option = () => null,
    isRequired = false,
    className,
  }) => {
    const { t } = useTranslation()
    const isShowLabel = useMemo(() => !!label && value, [label, value])
    const isShowPlaceHolder = useMemo(() => !!placeholder, [placeholder])
    const isRenderOptions = useMemo(
      () => options && Array.isArray(options) && options.length > 0,
      [options],
    )
    const isDisabledSelect = useMemo(
      () => options.length === 0 || disabled,
      [disabled, options.length],
    )
    return (
      <div className={cn(s.inputBlock, className)} style={style}>
        <select
          ref={selectRef}
          value={value}
          onChange={({ target }) => onChange(target.value)}
          disabled={isDisabledSelect}
        >
          {isShowPlaceHolder && <option value=''>{placeholder}</option>}
          {isRenderOptions &&
            options.map((...optionProp) => {
              const key = optionProp[0].hasOwnProperty('_id')
                ? optionProp[0]._id
                : optionProp[0].hasOwnProperty('value')
                ? optionProp[0].value
                : optionProp[0].hasOwnProperty('id')
                ? optionProp[0].id
                : optionProp[0]
              return <Option key={key} {...optionProp} />
            })}
        </select>
        {isShowLabel && <label>{label}</label>}
        {isRequired && (
          <span className={s.requiredLabel}>{t('isReuqiredLabel')}</span>
        )}
      </div>
    )
  },
)
