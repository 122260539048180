import axios from 'axios'
import qs from 'qs'

import { showErrorMessage } from '../../modules/Toasters'

import { refreshPageIfTokenWasExpired } from '../api.ts'

export const getRoutes = async ({
  sort = '-_id',
  status,
  page = 1,
  pageSize = 10,
}) => {
  try {
    const { data } = await axios.get(`/routes?page=${page}`, {
      params: {
        query: { $and: [{ status }] },
        sort,
        pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })
    return data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const getRouteById = async ({ id }) => {
  try {
    const { data } = await axios.get(`/routes/${id}`)
    return data
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
  }
}

export const createRoute = async ({ data }) => {
  try {
    const response = await axios.post('/route/admin', data)
    return response.data
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
    return false
  }
}

export const updateRoute = async ({ data }) => {
  try {
    const item = await axios.put('/route/admin', data)
    return item.data
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
    return false
  }
}

export const changeRouteStatus = async ({ data }) => {
  try {
    await axios.put('/route/status', data)
    document.location.reload()
  } catch (error) {
    showErrorMessage(error.response.data.message)
    refreshPageIfTokenWasExpired(error.response.data.data)
  }
}
