import i18n from 'i18next'
// import { LOCATIONS_SERVED } from '../data/locations'
import { showWarningMessage } from '../modules/Toasters'
import { store } from '../redux/store'

export const isServedCity = (c) => {
  const LOCATIONS_SERVED = store.getState().settings.cities

  if (LOCATIONS_SERVED) {
    const citiesEn = LOCATIONS_SERVED.en.map((location) => location.city)
    const citiesAr = LOCATIONS_SERVED.ar.map((location) => location.city)
    const isServedEn = citiesEn.includes(c)
    const isServedAr = citiesAr.includes(c)
    return isServedEn || isServedAr
  }
}

export const isServedDistrict = (c, d) => {
  const LOCATIONS_SERVED = store.getState().settings.cities

  if (LOCATIONS_SERVED) {
    const cityEn = LOCATIONS_SERVED.en.find((location) => location.city === c)
    const cityAr = LOCATIONS_SERVED.ar.find((location) => location.city === c)

    if (!!cityEn) {
      const isServed = cityEn.districts.includes(d)
      return isServed
    }
    if (!!cityAr) {
      const isServedEn = cityEn?.districts.includes(d)
      const isServedAr = cityAr?.districts.includes(d)
      return isServedEn || isServedAr
    }

    return false
  }
}

export const isServedLocation = (c, d) => {
  if (!isServedCity(c)) {
    showWarningMessage(i18n.t('ordersRoute').tooltips.locationIsNotServedText)
    return false
  }

  if (!isServedDistrict(c, d)) {
    showWarningMessage(i18n.t('ordersRoute').tooltips.districtIsNotServedText)
    return false
  }

  return true
}

export const validatePrice = (price, onError) => {
  const numberPrice = Math.floor(Number(price) * 100) / 100
  if (numberPrice >= 0) {
    onError(null)
    return numberPrice
  } else if (numberPrice < 0) {
    onError(i18n.t('usersRoute').formCreation.validate.negativeValue)
    return numberPrice
  } else {
    onError(i18n.t('usersRoute').formCreation.validate.invalid)
    return numberPrice
  }
}
