import React, { useState } from 'react'
import { BackBtn } from '../BackBtn/BackBtn'
import { PickupItem } from '../../../reusableComponents'
import { EnterShipmentAWB } from '../EnterShipmentAWB/EnterShipmentAWB'
import {
  getShipmentByAwb,
  verifyShipment,
  pickupShipment,
} from '../../../../services/api/integrations'
import { openCabinet } from '../../../../services/api/lockers'
import s from './PickupScreen.module.scss'

export const PickupScreen: React.FC = ({ setAction }: any): JSX.Element => {
  const [shipmentAWB, setShipmentAWB] = useState('')
  const [foundExtOrder, setFoundExtOrder] = useState()

  const verifyShipmentAWB = async () => {
    const payload = { awb: shipmentAWB }
    const verifiedData = await verifyShipment(payload)
    if (verifiedData) {
      const extOrder = await getShipmentByAwb({ ...payload, isRto: true })
      setFoundExtOrder(extOrder)
    }
  }

  const handlePickupOrder = async () => {
    const res = await openCabinet({
      data: {
        lockerNumber: foundExtOrder.locker,
        cabinetNumber: foundExtOrder.cabinet,
      },
    })
    if (res) {
      const pickedupShipment = await pickupShipment({ awb: shipmentAWB })
      if (pickedupShipment) {
        setShipmentAWB('')
        setFoundExtOrder(null)
      }
    }
  }

  const handleReportIssue = async () => {}

  return (
    <div className={s.container}>
      <BackBtn setAction={setAction} />
      <h5 className={s.title}>Pickup return shipment</h5>

      {!foundExtOrder ? (
        <EnterShipmentAWB
          onVerifyShipmentAWB={verifyShipmentAWB}
          shipmentAWB={shipmentAWB}
          setShipmentAWB={setShipmentAWB}
        />
      ) : (
        <PickupItem
          key={foundExtOrder._id}
          {...foundExtOrder}
          typeProvider={'integration'}
          number={foundExtOrder.extId}
          onPickup={handlePickupOrder}
          onReportIssue={handleReportIssue}
        />
      )}
    </div>
  )
}
