import { useState } from 'react'
import { useAuth } from '../../../../../../context/auth'

import { ROLE } from '../../../../../../data/constans'
import {
  createLocation,
  createAdminLocation,
} from '../../../../../../services/api/locations'

export const usePostData = () => {
  const {
    authUser: { role, client },
  } = useAuth()

  const [isCreatingData, setIsCreating] = useState(false)

  const create = async ({ data }) => {
    setIsCreating(true)
    if (role === ROLE.ADMIN) {
      await createAdminLocation({ data })
    }
    if (role === ROLE.CLIENT) {
      await createLocation({ data: { ...data, client } })
    }
    setIsCreating(false)
  }

  return { isCreatingData, create }
}
