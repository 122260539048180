import React from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  TrafficLayer,
} from 'react-google-maps'
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel'
import { MapDirectionsRenderer } from '../Map/MapDirectionsRenderer'
import s from './MapWithRoutes.module.scss'

const Map = withScriptjs(
  withGoogleMap((props) => (
    <>
      <GoogleMap
        defaultCenter={props.defaultCenter}
        defaultZoom={props.defaultZoom}
        defaultOptions={{
          fullscreenControl: false,
          scaleControl: false,
        }}
      >
        {props.places.map((marker) => {
          const position =
            marker.lat && marker.lng
              ? { lat: marker.lat, lng: marker.lng }
              : null

          return (
            <>
              {position && (
                <MarkerWithLabel
                  key={marker._id}
                  position={position}
                  labelAnchor={new window.google.maps.Point(0, 0)}
                  labelClass={s.marker}
                >
                  <div className={s.label}>
                    <div className={s.position}>{marker.position}</div>
                    {marker.name}
                  </div>
                </MarkerWithLabel>
              )}
            </>
          )
        })}
        <TrafficLayer autoUpdate />
        {props.places.length > 1 && (
          <MapDirectionsRenderer
            places={props.places}
            travelMode={window.google.maps.TravelMode.DRIVING}
          />
        )}
      </GoogleMap>
    </>
  )),
)

export const MapWithRoutes = ({
  loadingElement,
  containerElement,
  mapElement,
  defaultCenter,
  defaultZoom,
  height = 80,
  places,
}) => {
  return (
    <Map
      googleMapURL={
        'https://maps.googleapis.com/maps/api/js?key=' +
        process.env.REACT_APP_GOOGLE_API_KEY +
        '&libraries=geometry,drawing,places'
      }
      places={places}
      loadingElement={loadingElement || <div style={{ height: `100%` }} />}
      containerElement={
        containerElement || <div style={{ height: `${height}vh` }} />
      }
      mapElement={mapElement || <div style={{ height: `100%` }} />}
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}
    />
  )
}
