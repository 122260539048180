import React, { useState, useCallback, useRef, useMemo, memo } from 'react'
import { useAsync } from 'react-use'
import { Dialog, Icon } from '@blueprintjs/core'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { TextInput, Button, Loader, Search } from '../'

import { useIntersect, useDebounce } from '../../../hooks/'
import { getHosts } from '../../../services/api/hosts.js'

import s from './Clients.module.scss'

export const Hosts = memo(
  ({
    value = '',
    className = '',
    onChange = () => {},
    onRemove = () => {},
    label,
    labelStyle = {},
    filters,
    withRemoveIcon = false,
    Component = null,
  }) => {
    const { t } = useTranslation()

    const [isOpen, setOpen] = useState(false)
    const [items, setItems] = useState([])
    const [hasNextPage, setHasNextPage] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [searchName, setSearchName] = useState('')

    const loadItems = async () => {
      const { rows, page, totalPages } = await getHosts({
        page: pageNumber,
        status: filters?.status || {},
        name: debounceSearchName,
      })
      setHasNextPage(page < totalPages)
      setPageNumber(page !== totalPages ? page + 1 : totalPages)
      setItems((prevState) => [...prevState, ...rows])
    }

    const handleToogleModal = useCallback(() => {
      setOpen((prevState) => !prevState)
      if (!hasNextPage) {
        setHasNextPage(true)
        setItems([])
        setPageNumber(1)
        setSearchName('')
      }
    }, [hasNextPage])

    const handleSetItem = useCallback(
      (item) => {
        onChange(item)
        handleToogleModal()
      },
      [handleToogleModal, onChange],
    )

    const myRef = useRef()
    const [ref, entry] = useIntersect({ root: myRef.current })

    const debounceSearchName = useDebounce(searchName, 500)

    useAsync(async () => {
      setItems([])
      setPageNumber(1)
      setIsLoading(true)

      try {
        const { rows, page, totalPages } = await getHosts({
          page: 1,
          name: debounceSearchName,
        })
        setHasNextPage(page < totalPages)
        setPageNumber(page !== totalPages ? page + 1 : totalPages)
        setItems(rows)
      } catch (error) {}

      setIsLoading(false)
    }, [debounceSearchName])

    useAsync(async () => {
      setIsLoading(true)
      try {
        isOpen && hasNextPage && (await loadItems())
      } catch (error) {}
      setIsLoading(false)
    }, [entry, isOpen])

    const isShowRemoveIcon = useMemo(
      () => withRemoveIcon && !!value,
      [withRemoveIcon, value],
    )

    return (
      <div className={classnames(s.clientSelect, className)}>
        <div style={{ display: 'flex' }}>
          <Button
            text={t('modals').hosts.btnOpenModalLabel}
            onClick={handleToogleModal}
          />
          {Component && <Component />}
        </div>

        <div style={{ display: 'flex', position: 'relative' }}>
          <TextInput
            disabled={true}
            label={t('modals').hosts.label}
            placeholder={t('modals').hosts.placeholder}
            style={{ marginTop: 10, ...labelStyle }}
            props={{ value }}
          />

          {isShowRemoveIcon && (
            <Icon
              className={s.removeIcon}
              onClick={onRemove}
              icon='cross'
              iconSize='14'
            />
          )}
        </div>

        <Dialog isOpen={isOpen} className={s.modal} canOutsideClickClose={true}>
          <header className={s.modalHeader}>{t('modals').hosts.title}</header>
          <Search
            value={searchName}
            onChange={setSearchName}
            placeholder={t('modals').hosts.searchByNamePlaceholder}
            style={{ width: 200 }}
            autoFocus={true}
          />
          <div className={s.itemsList}>
            <Loader showLoader={isLoading} className={s.spinner} size={70} />
            <div
              key='none'
              className={s.listItem}
              onClick={() => handleSetItem('')}
            >
              <div
                style={{ marginRight: 'auto', maxWidth: '50%', width: '100%' }}
              >
                None
              </div>
              <div
                style={{
                  marginLeft: 'auto',
                  maxWidth: '50%',
                  width: '100%',
                  textAlign: 'right',
                }}
              ></div>
            </div>
            {items.map((item) => (
              <div
                ref={ref}
                key={item._id}
                className={s.listItem}
                onClick={() => handleSetItem(item)}
              >
                <div
                  style={{
                    marginRight: 'auto',
                    maxWidth: '50%',
                    width: '100%',
                  }}
                >
                  {item.name}
                </div>
                <div
                  style={{
                    marginLeft: 'auto',
                    maxWidth: '50%',
                    width: '100%',
                    textAlign: 'right',
                  }}
                ></div>
              </div>
            ))}
            {!hasNextPage && (
              <div className={s.fullLoadedLabel}>
                {t('modals').hosts.allHitsLoaded}
              </div>
            )}
          </div>

          <Button
            text={t('modals').hosts.cancelBtnLabel}
            onClick={handleToogleModal}
          />
        </Dialog>
      </div>
    )
  },
)
