const LOCATION_TYPE_CLIENT = ['Warehouse', 'Store']
const LOCATION_TYPE_ADMIN = [
  'Warehouse',
  'Store',
  'Distribution Point',
  'Locker',
]
const LOCATION_OWNER = [
  {
    id: 'Shuttle',
    title: 'Shuttle Locations',
  },
  {
    id: 'Client',
    title: 'Client Locations',
  },
]

export { LOCATION_TYPE_CLIENT, LOCATION_TYPE_ADMIN, LOCATION_OWNER }
