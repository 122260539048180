import { Dialog, Icon } from '@blueprintjs/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonsGroup, Issues } from '../../reusableComponents'

import {
  Order,
  TranslationModals,
  TranslationProviderRoute,
} from '../../NavigationPanel/NavigationRoutes/ProviderRoutes/Trips/Trip/Stops/LocationStops/locationStop.model'
import variables from '../../../styles/variables'
import s from './PickupItem.module.scss'
import { REPORT_ISSUES } from '../../../data/constans'

interface PickupItemProps extends Order {
  onPickup: (orderIds: string[]) => void
  onReportIssue: Function
}

export const PickupItem: React.FC<PickupItemProps> = ({
  _id,
  number,
  cabinet = null,
  locker = null,
  receiverFirstName,
  receiverLastName,
  receiverPhone,
  onPickup,
  client,
  onReportIssue,
  currentRequest,
  typeProvider = null,
}) => {
  const { t } = useTranslation()
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [isShowPickupBtn, setIsShowPickupBtn] = useState(false)
  const [isOpenIssueModal, setIsOpenIssueModal] = useState(false)

  const defaultIssueByTypeLocation =
    !typeProvider &&
    REPORT_ISSUES.find((i) => i.types.includes(currentRequest?.location.type))
      .label

  const [reportIssue, setReportIssue] = useState(defaultIssueByTypeLocation)

  const handleToogleConfirmModal = () => {
    setIsOpenDialog((prevState) => !prevState)
  }

  const handleReportIssue = (orderId) => {
    onReportIssue({ orderId, issue: reportIssue })
    setReportIssue(null)
    setIsOpenIssueModal(false)
  }

  return (
    <div className={s.pickupItem}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <span style={{ color: variables.disabled }}>
            {!typeProvider
              ? t<TranslationProviderRoute>('providerRoute').trip.view.orderId
              : t<TranslationProviderRoute>('providerRoute').trip.view
                  .shipmentAWB}
          </span>{' '}
          {number}
        </div>
        {!typeProvider && (
          <div
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => setIsOpenIssueModal(true)}
          >
            {t<TranslationModals>('modals').reportIssue.label}
          </div>
        )}
      </div>
      {cabinet && (
        <div style={{ margin: '15px 0' }}>
          <div>
            <span style={{ color: variables.disabled }}>
              {t<TranslationProviderRoute>('providerRoute').trip.view.lockerId}
            </span>{' '}
            {locker}
          </div>
          <div>
            <span style={{ color: variables.disabled }}>
              {t<TranslationProviderRoute>('providerRoute').trip.view.cabinet}
            </span>{' '}
            {cabinet}
          </div>
        </div>
      )}
      {!typeProvider && (
        <>
          <div style={{ margin: '15px 0' }}>
            <Icon
              icon='person'
              color={variables.shuttleOrange}
              style={{ marginRight: 5 }}
            />{' '}
            {client.name}
            {' - '}
            <a
              href={client?.phone ? `tel:${client.phone}` : '#'}
              style={{ margin: '15px 0' }}
            >
              <Icon
                icon='phone'
                color={variables.shuttleOrange}
                style={{ marginRight: 5 }}
              />
            </a>
          </div>
          <div style={{ margin: '15px 0' }}>
            <Icon
              icon='following'
              color={variables.shuttleOrange}
              style={{ marginRight: 5 }}
              iconSize={20}
            />{' '}
            {receiverFirstName} {receiverLastName}
            {' - '}
            <a
              href={receiverPhone ? `tel:${receiverPhone}` : '#'}
              style={{ margin: '15px 0' }}
            >
              <Icon
                icon='phone'
                color={variables.shuttleOrange}
                style={{ marginRight: 5 }}
              />
            </a>
          </div>
        </>
      )}

      <div className={s.pickupOrderBtn} onClick={handleToogleConfirmModal}>
        {t<TranslationProviderRoute>('providerRoute').trip.view.pickupBtnText}
      </div>
      <Dialog
        isOpen={isOpenDialog}
        style={{
          padding: 20,
        }}
      >
        <h2 style={{ textAlign: 'center' }}>
          {isShowPickupBtn
            ? t<TranslationProviderRoute>('providerRoute').trip.modals
                .confirmPickup.secondTitlePickup
            : t<TranslationProviderRoute>('providerRoute').trip.modals
                .confirmPickup.firstTitlePickup}
        </h2>
        <div
          style={{
            display: 'flex',
            marginTop: 10,
            justifyContent: 'center',
          }}
        >
          {!isShowPickupBtn && (
            <Button
              {...{
                text: t<TranslationProviderRoute>('providerRoute').trip.modals
                  .confirmPickup.yesBtnText,
                onClick: () => setIsShowPickupBtn(true),
                style: { marginRight: 20 },
              }}
            />
          )}
          {isShowPickupBtn && (
            <Button
              {...{
                text: t<TranslationProviderRoute>('providerRoute').trip.modals
                  .confirmPickup.yesBtnText,
                onClick: () => onPickup([_id]),
                style: { marginRight: 20 },
              }}
            />
          )}
          <Button
            {...{
              classNameType: 'dismiss',
              text: t<TranslationProviderRoute>('providerRoute').trip.modals
                .confirmPickup.notBtnText,
              onClick: () => {
                handleToogleConfirmModal()
                setIsShowPickupBtn(false)
              },
            }}
          />
        </div>
      </Dialog>
      <Dialog
        isOpen={isOpenIssueModal}
        style={{
          padding: 20,
        }}
      >
        <h2 style={{ textAlign: 'center' }}>
          {t<TranslationModals>('modals').reportIssue.title}
        </h2>
        <Issues
          typeLocation={currentRequest?.location.type}
          onChange={setReportIssue}
          value={reportIssue}
        />
        <ButtonsGroup
          {...{
            className: s.btnGroup,
            dissmissProps: {
              onGoBack: () => {
                setReportIssue(defaultIssueByTypeLocation)
                setIsOpenIssueModal(false)
              },
            },
            actionProps: {
              text: t<TranslationModals>('modals').reportIssue.reportBtn,
              onClick: () => handleReportIssue(_id),
            },
          }}
        />
      </Dialog>
    </div>
  )
}
