import variables from '../../styles/variables'

export const ar = {
  translation: {
    authForm: {
      logInPage: {
        title: 'تسجيل الدخول',
        loginPlaceholder: 'البريد الالكتروني',
        PasswordPlaceholder: 'كلمة المرور',
        forgotPasswordLinkLabel: 'نسيت كلمة المرور',
        loginBtn: 'تسجيل الدخول',
        signupLinkLabel: 'ليس لديك حساب ؟ سجل الآن',
        privacyPolicy: 'سياسة خاصة',
        tooltips: {
          email: 'أدخل بريدك الإلكتروني',
          hidePassword: 'اخفاء كلمة المرور',
          showPassword: 'عرض كلمة المرور',
        },
      },
      forgotPasswordPage: {
        title: 'تسجيل الدخول',
        subtitle: 'ادخل بريدك الالكتروني وسيتم ارسال رابط استعادة كلمة المرور',
        successMessage:
          'لقد أرسلنا لك بريدًا إلكترونيًا للتحقق من حسابك. يرجى اتباع التعليمات من هناك لتسجيل الدخول.',
        emailPlaceholder: 'البريد الالكتروني',
        resetPasswordBtn: 'استعادة كلمة المرور',
        loginLinkLabel: 'لديك حساب ؟ سجل دخولك',
        tooltips: {
          email: 'أدخل بريدك الإلكتروني',
        },
      },
      signUpPage: {
        title: 'تسجيل',
        firstNamePlaceholder: 'اسمك الأول',
        lastNamePlaceholder: 'اسمك الاخير',
        emailPlaceholder: 'البريد الإلكتروني لعملك',
        phonePlaceholder: '+966 رقم تليفونك',
        clientNamePlaceholder: 'عنوان الويب لمتجرك',
        citySelectPlaceholder: 'مدينة متجرك',
        districtSelectPlaceholder: 'منطقة متجرك',
        passwordPlaceholder: 'أختر كلمة مرورك',
        confirmPasswordPlaceholder: 'تأكيد كلمة المرور',
        privacyPolicyText: 'بالتسجيل فإنك توافق على Shttle’s',
        privacyPolicyLink: 'بنود الخدمة وخصوصية السياسة.',
        signupBtn: 'إنهاء',
        backStepBtn: 'خلف',
        continueBtn: 'يكمل',
        signupStepBtn: 'ابدأ مجانًا',
        moreAbout: 'أخبرنا المزيد عن عملك لإضفاء الطابع الشخصي على تجربتك',
        loginLinkLabel: 'لديك حساب ؟ سجل دخولك',
        joinBlock: {
          title: 'الانضمام إلى شبكة خزائن شتل.',
          text: 'اسمح لعملائك باستلام طلباتهم من أكثر من 200 خزانة في جميع أنحاء المملكة العربية السعودية.',
          paragraph1: 'إنشاء أوامر الشحن في خطوات قليلة.',
          paragraph2:
            'قم بإيداع الطلبات في أقرب خزانة أو دعنا نستلم الطلبات من متجرك أو مستودعاتك.',
          paragraph3: 'ابدأ بأمرين مجانيين ، وهم علينا.',
        },
        tooltips: {
          hidePassword: 'اخفاء كلمة المرور',
          showPassword: 'عرض كلمة المرور',
        },
        popups: {
          confirmRegistration:
            'لقد أرسلنا لك بريدًا إلكترونيًا للتحقق من حسابك. يرجى اتباع التعليمات من هناك لتسجيل الدخول',
        },
      },
    },
    onboarding: {
      titles: {
        title1: 'Create new order',
        title2: 'Add order information',
        title3: 'Print AWB',
        title4: 'Create a request',
        title5: 'Configure Deposit',
        title6: 'Review',
        title7: 'Complete Deposit',
        title8: 'Select Location',
        title9: 'Select pickup time',
      },
      buttons: {
        acknolwedge: 'Acknolwedge',
        start: 'Start your tour',
        later: 'Take the tour later',
        skip: 'Skip tour',
        end: 'End tour',
        ok: 'OK',
      },
      steps: {
        welcomeStep: {
          title: {
            welcome: 'Welcome',
            shttle: 'to shttle!',
          },
          text: 'Shttle allows you to ship orders to your customers’ nearest lockers. You just need to create an order and deposit it, or ask us to pick it up from your store or warehouse. Let’s create an order together.',
        },
        step1: 'Click on the orders page to check your orders.',
        step2:
          'Every time a customer submits an order to you, you can ask us to handle the shipment by creating a new order.',
        step3:
          'Fill all the required data about the consignee. Finally click on “Save”',
        step4:
          'Your consignee has received an SMS and the order is now in “pending” state. Once your shipment is ready, click on “Actions”.',
        step5:
          'Before doing any action, click on “Print AWB” in order to print your shipping label.',
        step6:
          'Print your AWB, stick it on the parcel, and close the popup window.',
        step7:
          'Once your AWB is attached on the shipment, click on “Actions” again.',
        step8:
          'If you want to deposit the parcel to your nearest locker click on “Deposit”, otherwise if you want to request a pickup from your store, click on “Request pickup”.',
        step9: 'Click on “Select Location”',
        step10:
          'Add a new location or choose an existing one if you have already created one.',
        step11: 'Select a locker and click “Next”',
        step12:
          'Select the cabinet size that suits you order dimensions, then click “Next”.',
        step13: 'Review everything and confirm',
        step14: 'Since you are not at the locker, select “Later”',
        step15:
          'Since you have just created a request, the amount of orders on your account has now decreased.',
        step16:
          'Go to “Requests” in order to complete the deposit at the locker.',
        step17: {
          title: 'When ready to deposit, please',
          item1: 'go to the locker',
          item2:
            'open the Shttle platform again, go to "Requests" and open the “Actions” menu to complete the deposit tour',
        },
        step19: 'Click on “Select Location”',
        step20:
          'Add a new location or choose an existing one if you have already created one.',
        step21:
          'Choose when Shttle can come to your location to pick up the order',
        congratulationsDepositStep: {
          title: 'Congratulations',
          text: 'You have successfully requested a deposit. Now, prepare your order and go to the locker to deposit it.',
        },
        congratulationsPickupStep: {
          title: 'Congratulations',
          text: 'You have successfully requested a pickup. Now make sure your order is ready, while Shttle comes to pickup.',
        },
      },
    },
    profile: {
      header: 'حسابي',
      remainingOrders: 'الطلبات المتبقية:',
      firstNamePlaceholderAndLabel: 'الاسم الأول',
      lastNamePlaceholderAndLabel: 'الاسم الأخير',
      phoneNumberPlaceholderAndLabel: 'رقم الجوال',
      changePasswordText: 'تغيير كلمة المرور',
      oldPasswordPlaceholderAndLabel: 'كلمة المرور القديمة',
      newPasswordPlaceholderAndLabel: 'كلمة المرور الجديدة',
      newConfirmPasswordPlaceholderAndLabel: 'تأكيد كلمة المرور',
      resetPasswordBtnText: 'إعادة تعيين كلمة المرور',
      popups: {
        largeImageError: 'حجم الصورة أكبر من 15 ميغا بايت',
      },
    },
    header: {
      remainingOrders: 'الطلبات المتبقية',
      onboarding: {
        button: 'Onboarding Tour',
        confirmText:
          'Are you sure you want to start the tour? You will exit any current activity.',
      },
      userLogoPopover: {
        myAccount: 'حسابي',
        editLogo: 'تحرير الشعار',
        editPassword: 'تحرير كلمة المرور',
        logout: 'تسجيل خروج',
      },
    },
    navigationsPanel: {
      hosts: 'المضيفون',
      requests: 'الأوامر',
      orders: 'الطلبات',
      payments: 'المدفوعات',
      users: 'المستخدمين',
      locations: 'المواقع',
      trips: 'الرحلات',
      clients: 'العملاء',
      routes: 'المسارات',
      lockers: 'الخزنات',
      lockersStatistics: 'إحصائيات الخزائن',
      ordersStatistics: 'إحصائيات الطلبات',
      cycleAnalysis: 'تحليل الدورة',
      providerReport: 'تقرير الموفر',
      tripsReport: 'تقرير الرحلات',
      settings: 'إعدادات',
      hostAnalytics: 'يستضيف التحليلات',
      intercityParcels: 'الطرود بين المدن',
    },
    providerRoute: {
      assigned: {
        title: 'الرحلات المخصصة',
        emptyMessage: 'لا توجد رحلات مخصصة',
      },
      inProgressTrips: {
        title: 'رحلات جارية',
        emptyMessage: 'لا توجد رحلات جارية',
      },
      upcomingTrips: {
        title: 'رحلات قادمة',
        emptyMessage: 'لا توجد رحلات مجدولة',
      },
      trip: {
        detailsBtnText: 'عرض التفاصيل',
        view: {
          backToListBtnText: 'العودة إلى القائمة',
          pausedByAdmin: 'الرحلة متوقفة مؤقتًا ، لاستئناف الرحلة اتصل بالمسؤول',
          paused: 'الرحلة متوقفة مؤقتًا ، لمتابعة الرحلة اضغط على زر استئناف',
          goBackToStopsBtnText: 'العودة إلى محطات التوقف',
          goBackBtnText: 'رجوع',
          stopsText: 'توقف',
          pickupText: 'استلام',
          dropText: 'انزال',
          startTripBtnText: 'بداية الرحلة',
          viewTripBtnText: 'عرض الرحلة',
          endTripBtnText: 'نهاية الرحلة',
          noOrdersToPickup: 'لا توجد طلبات للاستلام',
          setOrderToService: 'حدد طلبًا للخدمة',
          orderId: 'رقم الطلب',
          shipmentAWB: 'شحنة AWB',
          lockerId: 'رقم الخزنة',
          cabinet: 'الكبينة',
          openBtnText: 'فتح',
          pickupBtnText: 'استلام',
          dropOrdersAsText: 'انزال الطلبات بشكل',
          multy: 'مجموعة',
          single: 'فردي',
          noOrdersToDrop: 'لا توجد طلبات للإنزال',
          orders: 'الطلبات',
          client: 'العميل',
          selectCabinetBtnText: 'اختر كبينة',
          dropBtnText: 'انزال',
          selectLockerText: 'اختر خزنة',
          sizes: 'الأحجام',
          locker: 'الخزنة',
          viewCabinetsBtnText: 'عرض الخزنات',
          avaliableCabinetsText: 'الكبائن المتوفرة',
          size: 'حجم',
          width: 'عرض',
          height: 'ارتفاع',
          parcel: 'قطعة',
        },
        modals: {
          confirmPickup: {
            firstTitleOpen: 'هل تؤكد فتح الخزنة ؟',
            secondTitleOpen: 'هل توافق على أنك استلمت الطلب ؟',
            firstTitlePickup: 'هل تؤكد استلام الطلب ؟',
            secondTitlePickup: 'هل توافق على أنك استلمت الطلب ؟',
            yesBtnText: 'نعم',
            notBtnText: 'لا',
          },
          confrmDrop: {
            title: 'هل تريد انزال الطلب (الطلبات) ؟',
            yesBtnText: 'نعم',
            notBtnText: 'لا',
          },
          cofirmDropSingle: {
            firstTitle: 'هل تريد انزال الطلب ؟',
            secondTitle: 'هل تؤكد انزال هذا الطلب ؟',
            yesBtnText: 'نعم',
            notBtnText: 'لا',
          },
          confirmDropLastStep: {
            firstTitle: 'هل تريد فتح الخزنة ؟',
            secondTitle: 'هل تؤكد أن الطلب (الطلبات) في الكبينة؟',
            yesBtnText: 'نعم',
            notBtnText: 'لا',
            dropBtnText: 'انزال',
          },
          cofirmAcceptTripTitle: 'هل تؤكد قبولك للرحلة؟',
          cofirmDeclineTripTitle: 'هل تؤكد أنك رفضت الرحلة؟',
        },
      },
      emptyMessage: 'لا رحلات',
    },
    requestRoute: {
      header: 'الأوامر',
      tabs: {
        Unassigned: 'غير مسند',
        Assigned: 'مسند',
        Completed: 'مكتمل',
        Cancelled: 'ملغى',
        countLabel: 'الأوامر',
      },
      filters: {
        searchByIdPlaceholder: 'ابحث حسب الرقم',
        filterByLabel: ':تصنيف حسب',
      },
      columnsHeaders: {
        requestId: 'رقم الأمر',
        location: 'الموقع',
        locker: 'الخزنة',
        orders: 'الطلبات',
        date: 'التاريخ',
        noOfOrders: 'عدد الطلبات',
        type: 'النوع',
        preferredTime: 'الوقت المفضل',
        types: {
          Deposit: 'إيداع',
          Pickup: 'استلام',
        },
        client: 'العميل',
        actions: 'إجراء',
      },
      actionPopover: {
        deposit: 'إيداع',
        editRequest: 'تعديل أمر',
        cancelRequest: 'إلغاء الأمر',
      },
      notFoundText: 'لم يتم العثور على أوامر',
    },
    paymentsRoute: {
      header: 'المدفوعات عند التحصيل',
      btnAsAvailable: 'وضع علامة كمتوفر',
      btnAsRemitted: 'وضع علامة محولة',
      total: 'المجموع',
      remittanceIdLabel: 'معرف التحويل',
      notFoundText: 'اه اوه! لم يتم العثور على مدفوعات.',
      tabs: {
        'To Pay': 'للدفع',
        Processing: 'يعالج',
        Available: 'متوفرة',
        Remitted: 'المحولة',
        countLabel: 'المدفوعات',
      },
      warnings: {
        admin: {
          'To Pay': 'المدفوعات التي لم يتم الحصول عليها بعد من المرسل إليهم.',
          Processing:
            'المدفوعات التي تم الحصول عليها بالفعل من المرسل إليهم وتتم معالجتها بواسطة مزود خدمة الدفع.',
          Available:
            'المدفوعات المتاحة حاليًا على حساب Shttle ليتم تحويلها إلى العملاء وفقًا للاتفاقيات التعاقدية.',
          Remitted: 'المبالغ المحولة بالفعل للعملاء',
        },
        client: {
          'To Pay': 'المدفوعات التي لم يتم الحصول عليها بعد من المرسل إليهم.',
          Processing:
            'المدفوعات التي تم الحصول عليها بالفعل من المرسل إليهم وتتم معالجتها بواسطة مزود خدمة الدفع.',
          Available:
            'المدفوعات المتاحة حاليا على حساب Shttle. سيتم تحويل المبلغ الإجمالي إليك وفقًا للاتفاقيات التعاقدية.',
          Remitted:
            'إجمالي المبالغ المحولة بالفعل. قد يستغرق الأمر من يوم إلى ثلاثة أيام عمل حتى تظهر الدفعة في بيان معاملاتك المصرفية.',
        },
      },
      columnsHeaders: {
        remittanceId: 'معرف التحويل',
        totalRemittedAmount: 'إجمالي المبلغ المحول (SAR)',
        remittedOn: 'المحولة في',
        orderId: 'رقم التعريف الخاص بالطلب',
        client: 'عميل',
        status: 'حالة الطلب',
        lastChanged: 'تم التغيير الأخير في',
        requestId: 'طلب معرف',
        paymentAmount: 'مبلغ الدفع (SAR)',
        paidOn: 'المدفوعة على',
        paidWith: 'مدفوعة',
        remittance: 'كمية التحويلات (SAR)',
        availableSince: 'متاح منذ ذلك الحين',
      },
      modals: {
        confirmMarkAsAvailable: {
          title: 'تأكيد',
          text: 'هل أنت متأكد من أنك تريد تعليم هذه المبالغ على أنها متوفرة الآن في حساب Shttle المصرفي؟',
          text2: 'هذا العمل لا رجوع فيه',
        },
        confirmMarkAsRemitted: {
          title: 'تأكيد',
          text: 'سيتم وضع علامة على المبالغ المحددة على أنها محولة إلى العملاء.',
          text2: 'هذا العمل لا رجوع فيه. هل تؤكد؟',
        },
      },
    },
    ordersRoute: {
      header: 'الطلبات',
      newOrder: 'طلب جديد',
      filters: {
        label: 'ترتيب حسب',
        options: {
          updatedAt: 'تم التحديث في',
          createdAt: 'أنشئت في',
        },
        searchId: 'البحث عن طريق المعرف',
      },
      tabs: {
        Pending: 'قيد الانتظار',
        Scheduled: 'المجدولة',
        'Returns Ready for Pickup': 'مرتجعات جاهزة للاستلام',
        Delivered: 'تم التوصيل',
        'Under investigation': 'قيد التحقيق',
        Processed: 'معالجتها',
        Cancelled: 'ألغيت',
        'Locker SLA Broken': 'الخزانة SLA مكسورة',
        countLabel: 'الطلبات',
        btnsGroup: {
          upload: 'رفع',
          download: 'تحميل',
          printAWBS: 'طباعة AWBS',
          pickup: 'طلب استلام',
          deposit: 'إيداع',
        },
      },
      columnsHeaders: {
        orderId: 'رقم الطلب',
        orderNumber: 'رقم الطلب المخصص',
        originOrderId: 'معرّف طلب المنشأ',
        returnOrderId: 'معرّف طلب الإرجاع',
        extId: 'المعرف الخارجي',
        receiver: 'اسم المستلم',
        phone: 'هاتف',
        location: 'الموقع',
        lockerInfo: 'معلومات الخزنة',
        status: 'الحالة',
        lockerTiming: 'توقيت الخزنة',
        requestId: 'رقم الأمر',
        client: 'العميل',
        type: 'النوع',
        types: {
          Deposit: 'إيداع',
          Pickup: 'استلام',
        },
        issueType: 'نوع القضية',
        actions: 'إجراء',
      },
      actionPopover: {
        markAsDelivered: 'وضع علامة "تم التسليم"',
        markAsDeliveredText: 'هل تريد حقًا وضع علامة "تم التسليم" على الطلب؟',
        edit: 'تعديل',
        track: 'تتبع',
        cancel: 'يلغي',
        markAsLost: 'وضع علامة على أنه مفقود',
        delete: 'حذف',
        putIntoLocker: 'إيداع في الخزنة',
        resendSms: 'إعادة إرسال الرسال النصية',
        deposit: 'إيداع',
        depositBySender: 'الإيداع (عن طريق المرسل)',
        pickup: 'طلب استلام',
        printAWB: 'AWB طباعة',
        trackLinkCopy: 'رابط المسار (نسخ)',
        depositLinkCopy: 'رابط الإيداع (نسخة)',
        return: 'أمر الإرجاع',
        markAsLostText: 'هل تريد حقًا وضع علامة على النظام المفقود؟',
        cancelText: 'هل تريد حقًا إلغاء الطلب؟',
        deleteText: 'هل تريد حقا حذف الطلب؟',
        returnText:
          'سيتم تعيين جميع الطلبات في الخزانة على أنها لم يتم إلغاؤها',
        returnDeliverdText: 'هل تريد حقًا طلب أمر الإرجاع؟',
      },
      formCreation: {
        titleCreate: 'إضافة طلب',
        titleEdit: 'تعديل الطلب',
        senderBlockTitle: 'معلومات المرسل',
        trackingLinkLabel: 'افتح صفحة التتبع',
        receiverAddress: 'عنوان المستلم',
        customOrderNumber: 'رقم الطلب المخصص',
        loadText: 'جارٍ تحميل بيانات الطلب ...',
        extId: 'معرف خارجي',
        errorClientMessage: 'من فضلك ، حدد العميل',
        address: 'العنوان',
        paymentLabel: 'الدفع عند التحصيل',
        paymentText: 'دع العميل يدفع عند استلام الطلب',
        amountToCollect: 'المبلغ المطلوب تحصيله بالريال السعودي',
        amountToCollectHelperText: 'على سبيل المثال ، 1.00',
        validateAmountToCollect:
          'يجب أن تكون القيمة رقمية بحد أقصى رقمين عشريين',
        next: 'التالي',
      },
      tooltips: {
        locationIsNotServedText: 'لا يتم تقديم الموقع!',
        cityIsNotServedText: 'المدينة لا تخدم!',
        districtIsNotServedText: 'لا يخدم المنطقة!',
        returnedOrder: 'الأمر الذي تم إرجاعه',
      },
      popups: {
        refillBalanceError: 'يرجى إعادة تعبئة الرصيد أولاً',
        copiedLinkSuccess: 'نسخ!',
      },
      notFoundText: 'لا يوجد أي طلب حتى الآن',
    },
    usersRoute: {
      header: 'المستخدمون',
      newUser: 'إضافة مستخدم',
      deactivateAll: 'قم بإلغاء تنشيط الكل',
      tabs: {
        Active: 'فعال',
        Inactive: 'معطل',
        countLabel: 'المستخدمين',
      },
      columnsHeaders: {
        name: 'الاسم',
        type: 'النوع',
        client: 'العميل',
        host: 'مضيف',
        email: 'البريد الإلكتروني',
        actions: 'إجراء',
        externalCourier: {
          externalCourier: 'ساعي خارجي',
          courierCompany: 'شركة البريد السريع',
          yesText: 'نعم',
          noText: 'رقم',
        },
      },
      actionPopover: {
        edit: 'تعديل',
        active: 'تفعيل',
        deactive: 'تعطيل',
        resetPassword: 'أعد ارسال كلمة المرور',
        verifyEmail: 'التحقق من البريد الإلكتروني',
      },
      formCreation: {
        titleCreate: 'إضافة مستخدم',
        titleEdit: 'تعديل المستخدم',
        loadText: 'جارٍ تحميل بيانات المستخدم ...',
        providersId: 'معرف الموفر',
        providersDate: 'تاريخ الانضمام',
        fixedAmount: 'مبلغ ثابت',
        tripRate: 'معدل الرحلة',
        externalCourier: {
          externalCourier: 'ساعي خارجي',
          courierCompany: 'شركة البريد السريع',
          courierCompanyToken: 'رمز شركة البريد السريع',
        },
        integration: {
          label: 'اندماج',
          checkbox: 'تمكين / تعطيل التكامل مع الخدمات',
        },
        validate: {
          invalid: 'قيمة غير صالحة',
          negativeValue:
            'القيمة السلبية غير مسموح بها. الرجاء إدخال 0.00 أو رقم أكبر.',
        },
      },
      popups: {
        refillBalanceError: 'يرجى إعادة تعبئة الرصيد أولاً',
      },
      searchByEmailPlaceholder: 'ابحث عن البريد الألكتروني',
      notFoundText: '!لم يتم العثور على مستخدمين',
      emailNotVerified: 'لم يتم التحقق منه',
    },
    locationsRoute: {
      header: 'المواقع',
      newLocation: 'موقع جديد',
      tabs: {
        Active: 'فعال',
        Inactive: 'معطل',
        countLabel: 'المواقع',
      },
      filters: {
        filterByText: 'تصنيف حسب',
        searchByNamePlaceholder: 'بحث',
      },
      columnsHeaders: {
        name: 'الاسم',
        type: 'النوع',
        address: 'العنوان',
        location: 'الموقع',
        zone: 'المنطقة الأولية',
        client: 'العميل',
        logisticsCompany: 'شركة التخطيط والتنفيذ',
        actions: 'إجراء',
      },
      actionPopover: {
        edit: 'تعديل',
        Activate: 'تفعيل',
        Deactivate: 'تعطيل',
        qrcode: 'رمز الاستجابة السريعة',
      },
      formCreation: {
        titleCreate: 'إضافة موقع',
        titleEdit: 'تعديل الموقع',
        loadText: 'جارٍ تحميل بيانات الموقع ...',
        zoneLimits: 'يجب أن تكون منطقة واحدة على الأقل',
        addressInputPlaceholder: 'اكتب العنوان ...',
        noOptionsMessage: 'لا يوجد خيارات',
        clients: {
          label: 'هذا هو موقع العميل؟',
          innerLabel: 'رقم',
          innerLabelChecked: 'نعم',
        },
        logisticCompany: {
          placeholder: 'حدد شركة لوجستية',
          label: 'شركة لوجستية',
        },
        qr: {
          label: 'قبل فتح الخزانة',
          yes: 'امسح كود QR',
          no: 'لا تقم بمسح QR',
        },
      },
      popups: {
        zoneInListWarning: 'المنطقة موجودة بالفعل',
      },
      notFoundText: 'لم يتم  العثور على مواقع',
    },
    tripsRoute: {
      header: 'الرحلات',
      newTrip: 'رحلة جديدة',
      generateTrips: {
        generateTripsButton: 'توليد الرحلات',
        approveGenerateTripTitle: 'هل تريد حقًا بدء إنشاء الرحلات؟',
        startGenerate: 'يبدأ',
        selectLogisticsCompany: {
          title: 'حدد شركة لوجستية للطلبات بين المدن',
          or: 'أو',
          subtitle: 'حدد نقاط التوزيع لتسليم الطلبات بين المدن في مدن المنشأ',
          orderId: 'رقم التعريف الخاص بالطلب',
          orders: 'الطلبات',
          btnApply: 'تنطبق على جميع مدن الوجهة',
          monoSelectOriginDP: 'حدد نقطة التوزيع الأصلية',
          multySelectOriginDP: 'تم اختيار شركات مختلفة',
        },
        btnProceed: 'المضي قدما في إنشاء الرحلات',
        ordersNotFound: 'الطلبات غير موجودة',
        intercityOrdersNotFound:
          'لم يتم العثور على الطلبات بين المدن. يمكنك متابعة توليد الرحلات للأوامر المحلية',
      },
      generateTripsHistory: {
        columnsHeadersRouting: {
          startTime: 'وقت البدء',
          finishTime: 'وقت الانتهاء',
          status: 'حالة',
          cancelBtn: 'يلغي',
          created: 'خلقت',
        },
        generateTripsHistoryNotFound: 'لم يتم العثور على',
        generateTripsHistoryButton: 'إنشاء سجل الرحلات',
        currentGenerateTripsTitle: 'إنشاء الرحلات الحالية',
      },
      tabs: {
        Unassigned: 'غير معين',
        All: 'الكل',
        Upcoming: 'القادمة',
        'In-Progress': 'قيد التنفيذ',
        'Waiting Response': 'انتظار الرد',
        Completed: 'تم تنفيذها',
        countLabel: 'الرحلات',
      },
      filters: {
        searchByIdPlaceholder: 'ابحث حسب الرقم',
      },
      columnsHeaders: {
        tripId: 'رقم الرحلة',
        map: 'خريطة',
        startLocation: 'موقع البداية',
        endLocation: 'موقع النهاية',
        scheduledDate: 'التاريخ المقرر',
        startTime: 'موعد البداية',
        endTime: 'وقت النهاية',
        duration: 'ساعات المدة',
        paused: 'متوقف مؤقتًا',
        assignedProvider: 'المندوب المسند إليه',
        status: 'الحالة',
        actions: 'إجراء',
      },
      columnsHeadersDnd: {
        stop: 'محطة توقف',
        location: 'الموقع',
        distance: 'مسافه: بعد',
        type: 'النوع',
        pickups: 'الاستلام',
        drops: 'الانزال',
        lockers: 'خزائن',
        avaliableLockers: 'الخزائن المتوفرة',
        actions: 'إجراء',
      },
      actionPopover: {
        edit: 'تعديل',
        assignProvider: 'تعيين مندوب',
        details: 'تفاصيل',
        updateProvider: 'تغيير المندوب',
        delete: 'حذف',
        pauseTrip: 'توقف الرحلة',
        resumeTrip: 'استئناف الرحلة',
      },
      formCreation: {
        titleCreate: 'إضافة موقع',
        titleEdit: 'تعديل الموقع',
        scheduledLabel: 'مجدولة في',
        loadText: 'جارٍ تحميل بيانات الموقع ...',
        locationsLimits: 'أضف موقعين على الأقل لإنشاء رحلة',
      },
      detailsPage: {
        backBtnText: 'رجوع',
        tripId: 'رقم الرحلة',
        assignedProvider: 'المندوب المسند إليه',
        startTime: 'موعد البداية',
        endTime: 'وقت النهاية',
        duration: 'ساعات المدة',
        assignmentHistory: 'تاريخ التعيين',
        columns: {
          stop: 'محطة توقف',
          time: 'زمن',
          location: 'الموقع',
          type: 'النوع',
          pickup: 'استلام الطلبات',
          drop: 'تسليم الطلبات',
          cabinet: 'الكبينة',
          orders: 'الطلبات',
          action: 'عمل',
          date: 'تاريخ',
          provider: 'مزود',
          author: 'مؤلف',
        },
      },
      popups: {
        locationInTripError: 'الموقع موجود بالفعل في الرحلات',
        emptyDropsOrPickupsWarning: 'ليس لديك مواقع بها أوامر استلام وانزال',
      },
      notFoundText: 'لم يتم العثور على رحلات',
    },
    intercityParcelsRoute: {
      header: 'الطرود بين المدن',
      tabs: {
        'Dropped at the Origin DP': 'تم إسقاطه في Origin DP',
        'In Shipment': 'في الشحنt',
        'Arrived at Destination DP': 'وصل إلى الوجهة DP',
        Completed: 'مكتمل',
        countLabel: 'الطرود بين المدن',
      },
      filters: {
        filterByLabel: 'مصنف بواسطة:',
        searchByExterIDPlaceholder: 'البحث عن طريق تحويلة. بطاقة تعريف',
        searchByCompanyPlaceholder: 'البحث عن طريق الشركة',
        markBtn: 'وضع علامة وصلت إلى الوجهة DP',
        confirm: {
          askAboutAllIntercityParcels:
            'هل أنت متأكد من أنك تريد وضع علامة على الطرود المحددة بين المدن في هذه الصفحة على أنها وصلت إلى الوجهة DP؟',
        },
      },
      columnsHeaders: {
        externalID: 'معرف خارجي',
        originDP: 'الأصل موانئ دبي',
        destinationDP: 'الوجهة موانئ دبي',
        date: 'تاريخ',
        masterAWBCode: 'كود AWB الرئيسي',
        logisticsCompany: 'شركة التخطيط والتنفيذ',
        received3pl: 'تلقت بواسطة 3PL',
        actions: 'أجراءات',
      },
      actionPopover: {
        details: 'تفاصيل',
        markAsArrivedAtDestinationDP: 'وضع علامة وصلت إلى الوجهة DP',
        markOrdersAsArrivedAtDestinationDP:
          'وضع علامة على الطلبات على أنها وصلت إلى الوجهة DP',
        printMasterAWBCode: 'طباعة ماستر AWB',
      },
      intercityParcelDetails: {
        title: 'تفاصيل الطرد بين المدن',
        requestID: 'طلب معرف',
        externalNumber: 'رقم خارجي',
        logisticsCompany: 'شركة التخطيط والتنفيذ',
        pickupLocation: 'اختر موقعا',
        columnsHeaders: {
          orderID: 'رقم التعريف الخاص بالطلب',
          orderNumber: 'رقم الأمر',
          extID: 'معرف التحويلة',
          client: 'عميل',
          consignee: 'المرسل إليه',
        },
      },
      notFoundText: 'اه اوه! لم يتم العثور على طرود بين المدن.',
    },
    hostsRoute: {
      header: 'المضيفون',
      newHost: 'مضيف جديد',
      tabs: {
        Active: 'فعال',
        Inactive: 'معطل',
        countLabel: '(مضيف (ق',
      },
      columnsHeaders: {
        name: 'اسم',
        status: 'الحالة',
        actions: 'إجراء',
      },
      formCreation: {
        titleCreate: 'إنشاء مضيف',
        titleEdit: 'تحرير المضيف',
        nameLabel: 'اسم',
        loadText: 'تحميل بيانات المضيف ...',
      },
      actionPopover: {
        edit: 'تعديل',
        Activate: 'تفعيل',
        Deactivate: 'تعطيل',
      },
      notFoundText: 'اه اوه! لم يتم العثور على مضيفين.',
    },
    settingsRoute: {
      header: 'إعدادات',
      tabs: {
        Cities: 'مدن',
        'Logistics Companies': 'شركات الخدمات اللوجستية',
        Application: 'تطبيق',
        'Generate Trips': 'توليد الرحلات',
        'Providers Settings': 'إعدادات الموفرين',
      },
      logistics: {
        title: 'شركات الخدمات اللوجستية',
        newLogCompany: 'شركة لوجستية جديدة',
        formCreationLogisticsCompany: {
          titleCreate: 'إنشاء شركة لوجستية',
          titleEdit: 'تحرير شركة النقل والإمداد',
          logisticsCompanyName: 'اسم شركة الخدمات اللوجستية',
          loadText: 'تحميل بيانات شركة الخدمات اللوجستية',
        },
        columnsHeaders: {
          name: 'اسم',
          status: 'الحالة',
          actions: 'إجراء',
        },
        actionPopover: {
          edit: 'تعديل',
          Activate: 'تفعيل',
          Deactivate: 'تعطيل',
        },
        tabs: {
          Active: 'فعال',
          Inactive: 'معطل',
          countLabel: 'شركة (شركات) الخدمات اللوجستية',
        },
        notFoundText: 'اه اوه! لم يتم العثور على شركات لوجستية.',
      },
      cities: {
        title: 'مدن',
        subtitle: 'المناطق',
        newCity: 'مدينة جديدة',
        newDistrict: 'حي جديد',
        tabs: {
          Active: 'فعال',
          Inactive: 'معطل',
        },
        columnsHeaders: {
          number: 'عدد',
          nameEnglish: 'الاسم الانجليزي',
          nameArabic: 'الاسم العربي',
          districts: 'المناطق',
          actions: 'إجراء',
        },
        filtersCities: 'البحث عن طريق الرقم',
        filtersDistricts: 'البحث عن طريق الرقم',
        formCreationCity: {
          titleCreate: 'إنشاء مدينة',
          titleEdit: 'تحرير المدينة',
          numberCityLabel: 'رقم المدينة',
          englishCityName: 'اسم المدينة الانجليزية',
          arabicCityName: 'اسم المدينة بالعربية',
          regionCode: 'رمز المنطقة',
          helperTextForRegionCode: 'بحد أقصى 5 أحرف',
          addDistrictBtn: 'أضف منطقة',
          titleCreateDistrict: 'حي جديد',
          numberDistrictLabel: 'رقم المنطقة',
          englishDistrictName: 'اسم الحي الانجليزي',
          arabicDistrictName: 'اسم المنطقة العربية',
          statusDistrictLabel: 'حالة المقاطعة',
          editBtn: 'يحرر',
          deleteBtn: 'حذف',
          deactivateBtn: 'تعطيل',
          activateBtn: 'تفعيل',
          saveDistrictBtn: 'يحفظ',
          cancelDistrictBtn: 'يلغي',
          visible: 'مرئي في الطلبات',
          loadText: '...تحميل بيانات المدينة',
        },
        actionPopover: {
          edit: 'يحرر',
          delete: 'حذف',
        },
        notFoundCities: 'اه اوه! لم يتم العثور على مدن',
        notFoundDistricts: 'اه اوه! لم يتم العثور على مناطق',
      },
      application: {
        title: 'التطبيقات',
        newApplication: 'تطبيق جديد',
        emptyList: 'قائمة التطبيقات فارغة',
        columnsHeaders: {
          name: 'اسم',
          token: 'رمز',
        },
        tabs: {
          Active: 'فعال',
          Inactive: 'معطل',
        },
        actionPopover: {
          copyToken: 'نسخ الرمز',
          update: 'تحديث',
          Activate: 'تفعيل',
          Deactivate: 'تعطيل',
        },
        applicationDialog: {
          createNewApp: 'إنشاء تطبيق جديد وإنشاء رمز مميز',
          change: 'يتغيرون',
          set: 'جلس',
          applicationName: 'اسم التطبيق',
          attention:
            'انتباه! سيتم تغيير الرمز. سوف تحتاج إلى تقديم رمز جديد للتطبيق.',
          tokenCreated: 'تم إنشاء الرمز المميز',
          update: 'تحديث',
          create: 'خلق',
          copyToken: 'نسخ الرمز',
        },
        notFoundText: 'اه اوه! لم يتم العثور على تطبيقات.',
      },
      generateTrips: {
        title: 'تكوين رحلات الجيل',
        info: {
          mode: 'الوضع:',
          logisticsCompany: 'شركة التخطيط والتنفيذ:',
          days: 'أيام:',
          time: 'زمن:',
        },
        mode: {
          placeholderText: 'حدد إنشاء وضع الرحلات',
          labelText: 'إنشاء وضع الرحلات',
          types: {
            Manual: 'يدوي',
            Automated: 'الآلي',
          },
        },
        week: {
          title: 'حدد الأيام لبدء إنشاء الرحلات تلقائيًا',
          Sunday: 'الأحد',
          Monday: 'الاثنين',
          Tuesday: 'يوم الثلاثاء',
          Wednesday: 'الأربعاء',
          Thursday: 'يوم الخميس',
          Friday: 'جمعة',
          Saturday: 'السبت',
        },
        time: {
          title: 'حدد الوقت لبدء إنشاء الرحلات تلقائيًا',
        },
        warning:
          'لم يتم العثور على نقاط توزيع لـ logisticsCompanyName للمدن المدرجة أدناه: listCities. ستفشل عملية إنشاء الرحلات في حالة العثور على أوامر بين المدن لهذه المدن.',
        submit: 'يُقدِّم',
      },
      providers: {
        title: 'وقت القبول المعلق (بالدقائق)',
        save: 'يحفظ',
      },
    },
    clientsRoute: {
      header: 'العملاء',
      newClient: 'عميل جديد',
      tabs: {
        Active: 'فعال',
        Inactive: 'معطل',
        countLabel: 'العملاء',
      },
      columnsHeaders: {
        name: 'اسم',
        remainingOrders: 'الطلبات المتبقية',
        location: 'الموقع',
        actions: 'إجراء',
      },
      formCreation: {
        titleCreate: 'إنشاء عميل',
        titleEdit: 'تحرير العميل',
        nameLabel: 'الاسم',
        loadText: 'جارٍ تحميل بيانات العميل ...',
        warning: 'معلومات الاتصال مطلوبة',
        paymentLabel: 'الدفع عند التحصيل',
        paymentText: 'تمكين / تعطيل هذا العميل لإنشاء أوامر بالدفع عند التحصيل',
      },
      actionPopover: {
        edit: 'تعديل',
        Activate: 'تفعيل',
        Deactivate: 'تعطيل',
        refillBalance: 'إعادة تعبئة الرصيد',
      },
      notFoundText: 'لا يوجد عملاء',
    },
    routesRoute: {
      header: 'المسارات',
      newRoute: 'إضافة مسار',
      tabs: {
        Active: 'فعال',
        Inactive: 'معطل',
        countLabel: 'المسارات',
      },
      filters: {
        searchByNamePlaceholder: 'ابحث عن مسار',
      },
      columnsHeaders: {
        routeId: 'رقم المسار',
        routeName: 'اسم المسار',
        startLocation: 'موقع البداية',
        endLocation: 'موقع النهاية',
        noOfStops: 'عدد محطات التوقف',
        actions: 'إجراء',
      },
      columnsHeadersRoute: {
        stop: 'محطة توقف',
        location: 'الموقع',
        distance: 'المسافة',
        actions: 'إجراء',
      },
      formCreation: {
        titleCreate: 'إضافة رحلة',
        titleEdit: 'تعديل المسار',
        nameLabel: 'اسم المسار',
        addBtnText: 'إضافة',
        loadText: 'تحميل بيانات المسار ...',
      },
      actionPopover: {
        edit: 'تعديل',
        Activate: 'تفعيل',
        Deactivate: 'تعطيل',
        createTrip: 'إضافة رحلة',
      },
      popups: {
        locationLimitsWarining: 'يجب أن يكون موقعًا واحدًا على الأقل في المسار',
      },
      notFoundText: 'لم يتم العثور على مسار',
    },
    lockersRoute: {
      header: 'الخزنات',
      newLocker: 'خزانة جديدة',
      toggleBtn: {
        all: 'الكل',
        onlyOpened: 'مفتوح فقط',
      },
      tabs: {
        Active: 'فعال',
        Inactive: 'معطل',
        countLabel: 'الخزنة (الخزنات)',
      },
      columnsHeaders: {
        visible: 'مرئي',
        number: 'رقم',
        name: 'اسم',
        locationName: 'اسم الموقع',
        address: 'عنوان',
        location: 'موقع',
        host: 'مضيف',
        utilization: 'استغلال',
        sizes: 'الأحجام',
        actions: 'إجراء',
        lockerTiming: 'توقيت الخزنة',
        cabinetNumber: 'رقم الخزانة',
        state: 'حالة',
        lastStatusRequestDate: 'طلب آخر حالة',
        lastStatusResponseDate: 'آخر استجابة للحالة',
        dateTime: 'التاريخ / الوقت',
        action: 'عمل',
        actor: 'الفاعل',
      },
      formCreation: {
        titleCreate: 'إنشاء خزنة',
        titleEdit: 'تحرير الخزنة',
        cabinetsNumberingDirection: 'اتجاه ترقيم الخزانات',
        numerationDirectionLTR: 'من اليسار إلى اليمين (صف)',
        numerationDirectionTTB: 'من أعلى إلى أسفل (عمود)',
        visible: 'متاح للعملاء',
        notVisible: 'غير متوفر للعملاء',
        scan: 'امسح رمز الاستجابة السريعة ضوئيًا قبل فتح الخزانة',
        notScan: 'لا تقم بمسح رمز الاستجابة السريعة ضوئيًا قبل فتح الخزانة',
        nameLabel: 'اسم الخزنة',
        numberLabel: 'رقم الخزنة',
        rowsNumberLabel: 'عدد الصفوف',
        columnsNumberLabel: 'عدد الأعمدة',
        lockerDescriptionLabel: 'وصف الخزانة',
        lockerPhotoBtnText: 'تصفح الصور',
        nextBtnText: 'التالى',
        cancelBtnText: 'إلغاء',
        backBtnText: 'رجوع',
        positionText: 'موضع',
        rowText: 'صف',
        columnText: 'عمود',
        numberText: 'رقم',
        sizeText: 'حجم',
        widthText: 'عرض',
        heightText: 'ارتفاع',
        loadText: 'جارٍ تحميل بيانات الخزنة ...',
      },
      detailsPage: {
        backBtnText: 'رجوع',
        lockerInfo: {
          visible: 'مرئي',
          number: 'رقم',
          rowsNumber: 'عدد الصفوف',
          columnsNumber: 'عدد الأعمدة',
          sizes: 'الأحجام',
          location: 'موقع',
          host: 'مضيف',
          ltd: 'خط العرض',
          lng: 'خط الطول',
          utilization: 'استغلال',
          description: 'وصف',
          photo: 'صورة فوتوغرافية',
          downloadQR: 'تنزيل رمز الاستجابة السريعة للخزانة',
          printQR: 'طباعة رمز الاستجابة السريعة للخزانة',
        },
        cabinetsGrid: {
          title: 'مجموعة الكبائن',
          cabinetInfo: {
            status: 'الحالة',
            state: 'حالة',
            number: 'رقم',
            size: 'حجم',
            width: 'عرض',
            height: 'ارتفاع',
            row: 'صف',
            column: 'عمود',
            hideBtnsText: 'إخفاء معلومات الخزنة',
            openCabinetBtnText: 'كبينة مفتوحة',
            openAllCabinetsBtnText: 'افتح كل الخزائن',
          },
          columnsHeaders: {
            number: 'رقم',
            receiver: 'اسم المرسل إليه',
            phone: 'الجوال',
            client: 'عميل',
          },
          confirmOpenCabinetModal: {
            title: '؟ {{number}} هل أنت متأكد من فتح الخزنة',
            notBtnText: 'لا',
            yesBtnText: 'نعم',
          },
          confirmOpenAllCabinetsModal: {
            title: 'هل أنت متأكد من فتح كل الخزائن؟',
            notBtnText: 'لا',
            yesBtnText: 'نعم',
            openingCabinets: 'فتح الخزانات ...',
          },
          confirmStatusChangeModal: {
            title: 'انتباه! سوف يمسح الأوامر المعينة! هل أنت متأكد؟',
            notBtnText: 'لا',
            yesBtnText: 'نعم',
          },
        },
        loadText: 'جارٍ تحميل بيانات الخزنة ...',
      },
      actionPopover: {
        edit: 'تعديل',
        details: 'تفاصيل',
        Activate: 'تفعيل',
        Deactivate: 'تعطيل',
        qrcode: 'رمز الاستجابة السريعة',
      },
      // popups: {
      //     locationLimitsWarining: 'At least should be 1 location in the Route',
      // },
      notFoundText: '! لم يتم العثور على خزنات',
    },
    lockersStatisticsRoute: {
      header: 'إحصائيات الخزائن',
      stuckLockerLabel: 'عرض الخزائن التي لا تستجيب',
      cabinetHistoryHeader: 'تاريخ مجلس الوزراء',
      lockerResponseFailedText: 'الصيانة مطلوبة',
      exportCabinetsStatus: 'حالة خزانة التصدير',
    },
    ordersStatisticsRoute: {
      header: 'مجلة إحصائيات الطلبات',
      filters: {
        requestTypes: {
          All: 'الجميع',
          Deposit: 'إيداع',
          Pickup: 'استلام',
        },
        exportBtnLabel: 'يصدر',
        exportPausesBtnLabel: 'توقف التصدير',
      },
      columnsHeaders: {
        orderId: 'ID ترتيب',
        createdAt: 'مخلوق',
        deliveryLocker: 'خزانة التسليم',
        deliveryTime: 'موعد التسليم',
        status: 'حالة',
        client: 'عميل',
        requestId: 'ID طلب',
        requestType: 'نوع الطلب',
      },
    },
    operationCycleAnalysisRoute: {
      header: 'تحليل دورة التشغيل',
      filters: {
        exportBtnLabel: 'يصدر',
      },
      columnsHeaders: {
        orderId: 'ID ترتيب',
        storeName: 'اسم المتجر',
        origin: 'أصل',
        destinationCity: 'المدينة الوجهة',
        pickupType: 'نوع الالتقاط',
        orderType: 'نوع الأمر',
        lockerNumber: 'رقم الخزانة',
        cabinetNumber: 'رقم الخزانة',
        awb: 'AWB',
      },
    },
    reportProviderRoute: {
      header: 'تقرير الموفر',
    },
    reportTripsRoute: {
      header: 'تقرير الرحلات',
    },
    hostsAnalyticsRoute: {
      header: 'يستضيف التحليلات',
      columnsHeaders: {
        number: 'رقم',
        name: 'اسم',
        locationName: 'موقع',
        hostName: 'يستضيف',
        status: 'حالة',
        deposited: 'المودعة',
        retrieved: 'المودعةالمودعة',
      },
      selectHost: 'من فضلك ، حدد مضيف',
      notFoundText: 'اه اوه! لا يوجد.',
      filters: {
        exportBtnLabel: 'يصدر',
      },
    },
    depositOrderInstructionsForConsignee: {
      title: 'تعليمات إيداع الطلب',
      text1: `يرجى إيداع <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">طلبك #orderNumber</span> في الكابينة المحجوزة.`,
      text2: `الحد الزمني لحجز الكابينة واستلام طلبك <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;"> هو 24 ساعة</span>`,
      text3: 'للحصول على التعليمات ، اقرأ أدناه',
      openCabinetText: `بالنقر فوق <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">"فتح"</span>، سيتم فتح الكابينة المخصصة <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">لطلبك</span> على <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;"> الفور</span>. يرجى التأكد من <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;"> إغلاق باب الكابينة</span> بمجرد <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">الفور.</span> <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">إيداع الطلب</span>`,
      errorPage: {
        notFound: 'لم يتم العثور على!',
        notFoundList: {
          title:
            'يبدو أنك تواجه مشكلة في إيداع طلبك ، قد تكون المشكلة واحدة مما يلي:',
          subTitle: 'يرجى الاتصال بنا إذا كنت بحاجة إلى أي مساعدة',
          first: '1. انتهت فترة إيداع الأمر',
          second: '2. حدثت مشكلة في فتح الخزانة ',
        },
        customersSupport: 'دعم العملاء',
      },
      successPage: {
        thankYou: 'شكرا',
        successText: `تم <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">إيداع</span> طلبك #orderNumber <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;"> بنجاح</span> شكرا لاستخدامكم شتل.`,
        rateText: 'الرجاء تقييم تجربتك معنا',
        commentText: 'ساعدنا لتحسين خدماتنا! أخبرنا عن رأيك',
        submitText: 'إرسال',
        feedbackSent: 'شكرا لك ، تم إرسال ملاحظاتك!',
        serveyText: 'اضغط هنا لتقديم تقييم كامل',
      },
    },
    trackingPage: {
      homePage: {
        title: 'تتبع الطلب',
        orderOnWayText:
          'سيتم توصيل طلبك إلى أقرب خزنة ذكية منك، ستكون حالة الطلب "جاهز للاستلام" عند وصول طلبك الى الخزنة الذكية.',
        readyForPickupText1: 'طلبك جاهز للاستلام من الخزنة الذكية',
        readyForPickupText2:
          'قم بالضغط على "استلم الطلب بنفسك" للحصول على تعليمات استلام الطلب.',
        customerDetailsBlock: {
          origin: 'الأصل',
          store: 'المتجر',
          destination: 'الوجهة',
          smartLocker: 'الخزنة الذكية',
          nearestSmartLocker: 'أقرب خزنة ذكية',
          orderStatus: 'حالة الطلب',
          willBeReady: 'سوف يكون جاهز للاستلام في',
          willBeDelivered: 'سيتم تسليمها بواسطة',
          deliveryRequested: 'طلب التسليم',
        },
        optionCards: {
          useShuttle1: 'كيفية استخدام شتل',
          useShuttle2: '',
          orderPickup1: 'استلام الطلب',
          orderPickup2: 'تعليمات',
          orderTracking1: 'تتبع الطلب',
          orderTracking2: '',
          customersSupport1: 'خدمة العملاء',
          customersSupport2: '',
          deliveryNow1: 'اطلب توصيله الى',
          deliveryNow3: 'باب المنزل',
          pickupYourself1: 'استلم الطلب بنفسك',
          pickupYourself2: 'من الخزنة الذكية',
          useShuttleModal: 'كيف تستخدم شتل',
          close: 'إغلاق',
          customerSupport1:
            'بإمكانكم التواصل معنا علماً بأن اوقات العمل من الساعة 9 صباحاً حتى الساعة 5 مساءً',
          customerSupport2:
            'في حال عدم الاستجابة، الرجاء رفع استفساركم هنا وسيتم التواصل معكم في اقرب وقت ممكن',
          phoneCall: 'اتصال',
          whatsUp: 'واتس اب',
          typeForm: 'تعبئة النموذج',
          request: 'طلب',
          shgardiNow: 'الآن Shgardi',
          openCabinet: 'فتح',
          back: 'عودة',
          dropIntoCabinet: 'اسقط في الخزانة',
          slaaskChatOpen: 'يرجى الاتصال بنا من خلال الدردشة الحية للمساعدة',
        },
      },
      trackPage: {
        title: 'تتبع الطلب',
        text1:
          'سوف تتمكن من استلام طلبك orderNumber# من الخزنة، حينما تكون حالة الطلب جاهز للاستلام. سوف يكون لديك 48 ساعة لاستلام طلبك',
        text2:
          'طلبك orderNumber# جاهز للاستلام. لديك 48 ساعه لاستلام طلبك لتجنب ارجاعه. يرجى اتباع تعليمات الاستلام والذهاب لموقع الخزنة',
        text1Ups:
          'سوف تتمكن من استلام طلبك orderNumber# من الخزنة، حينما تكون حالة الطلب جاهز للاستلام. سوف يكون لديك 5 أيام لاستلام طلبك',
        text2Ups:
          'طلبك orderNumber# جاهز للاستلام. لديك 5 أيام لاستلام طلبك لتجنب ارجاعه. يرجى اتباع تعليمات الاستلام والذهاب لموقع الخزنة',
        initiated: 'تم الإنشاء',
        initiatedSubText: 'طلبك قيد التجهيز من قبل المتجر',
        onProgress: 'تحت الإجراء',
        onProgressSubText: 'طلبك قيد التنفيذ من قبل شتل',
        onWayToLocker: 'في الطريق إلى الخزنة',
        onWayToLockerSubText:
          'تم استلام طلبك من المتجر، ومتوجه نحو اقرب خزنة ذكية',
        readyForPickup: 'جاهز للاستلام',
        readyForPickupSubText: 'يرجى استلام طلبك من الخزنة الذكية لتجنب ارجاعه',
        waitForPickupByDeliveryCompany: 'انتظر الاستلام',
        delivering: 'التسليم من قبل',
        delivered: 'تم التوصيل',
        willBeDeliveryAt: 'سيتم تسليمها في',
      },
      orderPage: {
        pickUpInstructions: 'تعليمات الاستلام',
        text1: 'طلبك orderNumber# جاهز للاستلام من الخزنة الذكية',
        text2: 'قم باستلام طلبك من الخزنة خلال 48 ساعة تفادياً للإرجاع',
        text2Ups: 'قم باستلام طلبك من الخزنة خلال 5 أيام تفادياً للإرجاع',
        text3: 'لكيفية استلام طلبك اتبع الخطوات التالية',
        infoAboutPayment: 'يرجى دفع ثمن طلبك الآن لفتح الخزانة واستلام طلبك',
        pay: 'يدفع',
        paySuccessful: 'تم الدفع الخاص بك بنجاح!',
        payUnsuccessful:
          'لسوء الحظ ، لم تنجح عملية الدفع الخاصة بك ، يرجى دفع ثمن طلبك مرة أخرى من أجل فتح الخزانة واستلام طلبك',

        getDirections: 'أحصل على الاتجاهات',
        lockerLocation: 'اضغط هنا للحصول على إتجاهات موقع الخزنة',
        lockerLocationText:
          'بعد فتح الخريطة والذهاب الى الموقع، يرجى فتح صفحة تعليمات الاستلام مرة أخرى لتفعيل الخطوة التالية',
        scanQR: 'امسح رمز QR',
        scanQRDescription: `امسح رمز QR الموجود على الخزانة باستخدام <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">كاميرا هاتفك المحمول</span> لتنشيط زر <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">"فتح الكابينة"</span>`,
        scanQRBtn: 'إضغط هنا لمسح رمز QR',
        scanQRWarning:
          'اذا لم تعمل معك الكاميرا، نرجوا منك التأكد من فتح الرابط في متصفح الجوال، سفاري او قوقل كروم',
        scanQRText: 'ضع رمز الاستجابة السريعة داخل الإطار للمسح الضوئي من فضلك',
        scanQRLoader: 'مسح الرمز ...',
        scanQRSuccessful: 'تم مسح رمز الاستجابة السريعة الخاص بك بنجاح!',
        scanQRUnsuccessful:
          'للأسف ، قمت بمسح رمز الاستجابة السريعة الخاطئ ضوئيًا. يرجى مراجعة قسم الحصول على الاتجاهات للحصول على معلومات الخزانة الصحيحة',
        disclaimer: 'التأكيد',
        disclaimerText:
          'الرجاء الضغط على الزر لتأكيد تواجدك أمام الكابينة ولتنشيط زر فتح الكابينة',
        youHaveApproved: 'موافق',
        yourApproval: 'قبول الاقرار',
        disclaimerOpenCabinetText: `من أجل أمانك، فقط <span style="text-shadow: 0px 0px 0.5px ${variables.shuttleBlack}; font-weight: bold;">"افتح الكابينة"</span> بمجرد أن تكون أمام الخزانة فعليًا`,
        openCabinet: 'فتح الكابينة',
        openCabinetText:
          'بالضغط على فتح، سوف تفتح الكبينة مباشرة ! يرجى إغلاق الكبينة بعد استلام طلبك',
        open: 'فتح الكابينة',
        close: 'إغلاق',
        goToLocation: 'الذهاب للموقع',
        approve: 'قبول',
        popupTexts: {
          errorOpenCabinet: 'لم يتم فتح الخزانة',
        },
        notFound: 'لم يتم العثور على المعلومات',
      },
      deliveryPage: {
        header: 'املأ الاستمارة الخاصة بـ Shgardi',
        description: '',
        pickupDate: 'اختر تاريخا',
        deliveryDate: 'تاريخ التسليم او الوصول',
        recipientName: 'اسم المستلم',
        recipientMobile: 'جوال المستلم',
        recipientAddress: 'عنوان المستلم',
        recipientLatitude: 'خط العرض المستلم',
        recipientLongitude: 'خط الطول المستلم',
        selectFromMap: 'حدد العنوان من خريطة جوجل',
        selectFromField: 'حدد العنوان من حقل البحث',
        p1Shgardi: 'سيتم توصيل طلبك إلى موقعك في أسرع وقت عن طريق مندوب شقردي',
        p2Shgardi: ' من فضلك قم بتحديد موقعك على الخريطة',
        create: 'إرسال',
      },
      errorPage: {
        notFound: 'غير موجود!',
        notFoundList: {
          title:
            'يبدو أنك تواجه مشكلة في استلام طلبك، قد تكون المشكلة واحدة من التالي:',
          subTitle: 'يرجى التواصل معنا اذا احتجت الى المساعدة',
          first: '1. تم استلام الطلب بالفعل',
          second: '2. انتهاء مدة استلام الطلب',
          third: '3. وجود خلل في فتح الكبينة',
        },
        customersSupport: 'خدمة العملاء',
      },
      successPage: {
        thankYou: 'شكرا',
        successText: 'تم استلام طلبك orderNumber# بنجاح. شكرا لاختيارك شتل',
        rateText: 'يرجى تقييم تجربتك',
        commentText: 'هل لديك اي تعليق ؟',
        submitText: 'إرسال',
        feedbackSent: 'شكرا، لقد تم ارسال تقييمك!',
        serveyText: 'اضغط هنا لتقديم تقييم كامل',
      },
      dropViaSmartLinkPage: {
        successDrop: 'تم إسقاط الطلب بنجاح',
        origin: 'الأصل',
        destination: 'الوجهة',
      },
      orderStatus: {
        Initiated: 'تم الإنشاء',
        'Assigned to request': 'تم الإسناد',
        'Pick-up scheduled': 'تم جدولة الاستلام',
        'On way to origin locker': 'متوجه إلى خزنة الأصل',
        'Deposited at origin locker': 'تم الإيداع في خزنة الأصل',
        'Picked-up at origin locker': 'تم الاستلام من خزنة الأصل',
        'On way to intermediate locker': 'متوجه إلى خزنة في المنتصف',
        'Deposited at intermediate locker': 'تم الإيداع في خزنة في المنتصف',
        'On way to destination locker': 'متوجه إلى خزنة الوجهة',
        'On way to origin DP': 'متوجه إلى نقطة التوزيع (الأصل)',
        'Arrived at origin DP': 'تم الوصول إلى نقطة التوزيع (الأصل)',
        'On way to destination DP': 'متوجه إلى نقطة التوزيع (الوجهة)',
        'Arrived at destination DP': 'تم الوصول إلى نقطة التوزيع (الوجهة)',
        'Deposited at destination locker': 'تم الإيداع في خزنة الوجهة',
        'Ready for pick-up': 'جاهز للاستلام',
        'Delivery requested by Consignee': 'طلب استلام من العميل النهائي',
        'Picked-up at destination locker': 'تم الاستلام من خزنة الوجهة',
        'Out for delivery': 'خارج التوصيل',
        Delivered: 'تم التوصيل',
        'Delivery requested"': 'طلب التسليم',
        'Picked up by delivery company': 'التقطت من قبل شركة التوصيل',
        'Not delivered': 'لم يتم تسليمها',
      },
    },
    shgardiDriverTackPage: {
      receiver: 'المتلقي',
      address: 'عنوان',
      lockerDetails: 'تفاصيل الخزانة',
      locker: 'خزانة',
      cabinet: 'خزانة',
      receiverTitle: 'المتلقي',
      popupTexts: {
        successfullOpendedCabinet: 'تم فتح الخزانة',
        errorPickupedOrder: 'لم يتم انتقاء الطلب',
      },
      infoStep: {
        title: 'أهلا بالشقردي،',
        subTitle: 'لقد قمت بقبول توصيل الطلب، وستقوم بتوصيله الى العميل',
        listHeader: 'معلومات مهمة',
        item1: '1- لن تقوم بإٍستلام الطلب من متجر أو شخص عند موقع الاستلام',
        item2: '2- ستقوم بإستلام الطلب من خزنة ذكية عند موقع التسليم',
        item3: '3- شكل الخزنة التي ستقوم بإستلام الطلب منها:',
        agree: 'يوافق على',
      },
      mainPage: {
        title1: 'طريقة الاستلام والتوصيل:',
        item1: '1- قم بالتوجه الى موقع الاستلام الموضح لديك في تطبيق شقردي.',
        item2: '2- عند وصولك لموقع التسليم ستجد خزنة ذكية في الموقع.',
        item3: '3- إليك معلومات الخزنة + زر فتح الكبينة',
        lockerInfoTitle: 'رقم الخزنة:',
        lockerNumber: 'رقم الخزنة:',
        cabinetNumber: 'رقم الكبينة:',
        address: 'عنوان الخزنة:',
        direction: 'اتجاهات الخزنة:',
        lockerDescription: 'وصف مكان الخزنة:',
        item4: '4- عند وقوفك أمام الخزنة، قم بالضغط على فتح الكبينة',
        item5: '5- قم بإستلام الطلب من الكبينة',
        item6: '6- اذا واجهت مشكلة مع فتح الكبينة قم بالتواصل مع الدعم الفني',
        item7:
          '7- بعد استلام الطلب ستقوم بتوصيله الى العميل، معلومات العميل موضحه لديك في تطبيق شقردي.',
        item8: '8- قم بالتوجه الى موقع التسليم الموضح لديك في تطبيق شقردي.',
        item9: '9- قم بتوصيل الطلب الى المستلم',
        modalHeader: 'هل التقطت الطلب؟',
        confirmBtnYes: 'نعم',
        confirmBtnNot: 'لا',
      },
    },
    deliveryPartnersPage: {
      title: 'خيارات التوصيل',
      descriptionP1: 'خدمة التوصيل الى باب البيت تتطلب رسوم توصيل إضافية.',
      descriptionP2: 'تختلف الأسعار بناء على مزود الخدمة',
      select: 'اختيار',
    },
    smartRoutePages: {
      consigneePage: {
        title: 'بدأنا في التخطيط لتسليم طلبك.',
        subTitle: 'الرجاء تحديد الموقع الذي تريد توصيل الطلب فيه.',
        confirmLocationText: 'هل تؤكد تحديد موقع {locationName}؟',
        successText: 'سيتم تسليم طلبك إلى الخزانة: سيتم توصيل الطلب إلى موقعك!',
      },
    },
    modals: {
      warning: {
        title: 'تحذير',
        yes: 'نعم',
      },
      createLocation: {
        btnOpenModalLabel: 'أضف موقعًا جديدًا',
      },
      confirm: {
        title: 'هل تؤكد العمل؟',
        cancel: 'يلغي',
        yes: 'نعم',
      },
      locations: {
        btnOpenModalLabel: 'اختر موقع',
        label: 'موقع',
        placeholder: 'اضغط لاختيار الموقع',
        title: 'قائمة المواقع',
        allHitsLoaded: 'تم تحميل المواقع بالكامل',
        cancelBtnLabel: 'إلغاء',
        notFound: 'لم يتم العثور على',
      },
      hosts: {
        btnOpenModalLabel: 'حدد المضيف',
        label: 'مضيف',
        placeholder: 'انقر لاختيار المضيف',
        title: 'قائمة المضيفين',
        searchByNamePlaceholder: 'البحث عن طريق الإسم',
        allHitsLoaded: 'يتم تحميل كافة المضيفين',
        cancelBtnLabel: 'إلغاء',
      },
      clientsMulti: {
        btnOpenModalLabel: 'حدد العملاء',
      },
      clients: {
        btnOpenModalLabel: 'اختر عميل',
        label: 'عميل',
        placeholder: 'اضغط لاختيار العميل',
        title: 'قائمة العملاء',
        allHitsLoaded: 'تم تحميل العملاء بالكامل',
        cancelBtnLabel: 'إلغاء',
      },
      orders: {
        btnOpenModalLabel: 'اختر الطلبات',
        selectAll: 'اختر الكل',
        label: 'الطلبات',
        placeholder: 'اضغط لاختيار الطلب',
        title: 'قائمة الطلبات',
        allHitsLoaded: 'تم تحميل الطلبات بالكامل',
        cancelBtnLabel: 'إلغاء',
        okBtnLabel: 'حسنا',
      },
      pickups: {
        title: 'طلب استلام',
        locationsPlaceholder: 'من أين تريد استلام الطلبات ؟',
        ordersWarningMessage: 'يجب أن يكون على الأقل طلب واحد.',
        preferredTimePlaceholder: 'اختر الوقت المناسب',
      },
      depositSplitting: {
        title: 'إيداع الطلبات',
        ordersList: {
          orderLabel: 'طلب:',
          nameLabel: 'اسم:',
        },
        openBtn: 'فتح',
        cabinetNoLabel: 'رقم الخزنة:',
        cabinetText: 'خزنة:',
        instructionsList: {
          first: '1 أضف أكبر عدد ممكن من الطلبات',
          second:
            '2 ثم حدد حجز خزنة أخرى إذا كنت بحاجة إلى إضافة المزيد من الطلبات',
        },
        anotherBtn: 'كبينة أخرى',
        finishDepositBtn: 'إنهاء الإيداع',
        confirmFinishModal: {
          title: 'هل تؤكد إنهاء الإيداع؟',
          yes: 'نعم',
          not: 'لا',
        },
        depositHasBeenFinished: 'تم الإيداع',
        popups: {
          disableOpenAndSplitWarning:
            'اخترت جميع الطلبات. يجب ان يكون على الأقل طلب واحد غير مختار',
          refillBalanceError: '{{name}} رجاء قم تعبئة الرصيد أولا لـ',
          finishDepositSuccess: 'تم الإيداع',
          someOrdersNotDepositerError: 'لم يتم إيداع بعض الطلبات',
          allOrdersInCabinetsWarning: 'يبدو أن جميع الطلبات وضعت في الكبائن',
          notEnoughFreeCabinetsWarning: 'لا يوجد كبائن فارغه',
          depositCancelError: 'لم يتم الغاء الإيداع',
          atLeastNeedOneOrderToFinishDepositWarning:
            'رجاء قم بوضع طلب واحد على الأقل ليتم الإيداع',
        },
      },
      upload: {
        title: 'رفع مجموعة طلبات',
        brief: 'تحميل نموذج رفع مجموعة الطلبات',
        label: 'اختر ملف',
        placeholder: 'اختر ملف...',
        selectFileBtnText: 'تصفح',
        uploadBtnText: 'رفع الملف',
      },
      deposit: {
        title: 'إيداع الطلبات',
        stepsLabels: {
          time: 'ضبط الوقت',
          locker: 'اختر خزنة',
          cabinet: 'اختر كبينة',
          confirm: 'مراجعة وتأكيد',
        },
        step1: {
          depositTimeLabel: 'وقت الإيداع',
          depositTimePlaceholder: 'متى تريد الإيداع ؟',
          preferredTimeLabel: 'وقت الإيداع المفضل',
          dateLabel: 'تاريخ',
          popups: {
            refillBalanceError: '{{name}} يرجى إعادة تعبئة الرصيد أولاً لـ',
          },
        },
        step2: {
          notLockerFoundMsg:
            'عذراً لم يتم العثور على خزنة، فضلاً اختر موقع آخر',
          avaliableLockerTitle: 'الخزنات المتوفرة! الرجاء تحديد واحد منهم.',
        },
        step3: {
          title: '{{name}} # خزنة',
          cabinetSizeTitle: 'اختر حجم الكبينة',
          notAvaliableMsg: '(غير متوفر)',
          cabinetLabel: 'خزنة #',
          heightLabel: 'ارتفاع',
          widthLabel: 'عرض',
          selectCabinetMsg: 'من فضلك ، حدد حجم الخزنة',
        },
        step4: {
          title: `#{{number}} كبينة #{{name}} خزنة`,
          ordersToDeposit: 'الطلبات {{count}} إيداع',
        },
        warnings: {
          differenteLocations:
            'موقع الإيداع هو المكان المقصود للأوامر [listOrders]. يرجى إيداع هذه الطلبات بشكل منفصل.',
          differentReceivers: 'لا يُسمح بإيداع الطلبات معًا لمستلمين مختلفين',
        },
        footerBtns: {
          cancel: 'إلغاء',
          back: 'رجوع',
          next: 'التالى',
          confirm: 'تؤكد',
          save: 'حفظ',
        },
        confirmDeposit: {
          title: 'متى تريد الإيداع ؟',
          nowBtnText: 'الآن',
          laterBtnText: 'في وقت لاحق',
        },
      },
      lockersOnMap: {
        title: 'الخزنات',
        quantity: '{{quantity}} كمية',
        cancelBtn: 'إلغاء',
      },
      tripsMap: {
        title: 'طريق الرحلة',
        cancelBtn: 'إلغاء',
        error: 'لسوء الحظ ، لم نتمكن من الحصول على الطريق',
      },
      zone: {
        openModalBtn: 'أضف منطقة',
        title: 'منطقة',
        closeBtnText: 'الغاء',
        addBtnText: 'إضافة',
      },
      qrcode: {
        titleLocation: 'رمز الاستجابة السريعة للموقع locationName',
        titleLocker: 'رمز الاستجابة السريعة للخزانة lockerName',
      },
      routes: {
        openModalBtnText: 'اختر الطريق',
        label: 'طريق',
        placeholder: 'اضغط لاختيار الطريق',
        header: 'قائمة الطرق',
        loadCompleteMsg: 'تم تحميل مسارات بالكامل',
        cancelBtnText: 'الغاء',
      },
      providers: {
        openModalBtnText: 'اختر المندوب',
        label: 'مندوب',
        placeholder: 'اضغط لاختيار المندوب',
        header: 'قائمة المناديب',
        loadCompleteMsg: 'تم تحميل المناديب بالكامل',
        cancelBtnText: 'الغاء',
      },
      intercityParcels: {
        openModalBtnText: 'حدد شركة لوجستية',
        label: 'شركة التخطيط والتنفيذ',
        placeholder: 'انقر لاختيار شركة الخدمات اللوجستية',
        header: 'قائمة شركات الخدمات اللوجستية',
        loadCompleteMsg: 'يتم تحميل شركات الخدمات اللوجستية الكاملة',
        cancelBtnText: 'يلغي',
      },
      awbPrinting: {
        title: 'تسمية AWB',
        note: 'ملاحظة: يرجى ضبط حجم الصفحة A5 للطباعة',
        hideList: 'إخفاء القائمة',
        showList: 'عرض قائمة',
      },
      reportIssue: {
        label: 'قضية تقرير',
        title: 'من فضلك ، حدد المشكلة',
        reportBtnConfirm: 'نقل',
      },
      cityDistrict: {
        header: 'موقع الطلب',
      },
    },
    reactTable: {
      previousText: 'السابق',
      nextText: 'التالي',
      pageText: 'الصفحة',
      ofText: 'من',
    },
    buttonsGroup: {
      cancelBtnLabel: 'إلغاء',
      saveBtnLabel: 'حفظ',
    },
    pickupTime: {
      label: 'وقت الاستلام',
      placeholder: 'موعد الاستلام المفضل ؟',
      selectOptions: {
        today: 'اليوم',
        tomorrow: 'غداً',
        later: 'يوم آخر',
      },
    },
    date: {
      label: 'التاريخ',
    },
    preferredTime: {
      label: 'الوقت المفضل',
    },
    firstName: {
      placeholder: 'الاسم الأول',
      label: 'الاسم الأول',
    },
    lastName: {
      placeholder: 'الاسم الأخير',
      label: 'الاسم الأخير',
    },
    phoneNumber: {
      placeholder: '(+9665xxxxxxxx) رقم الجوال',
      label: 'رقم الجوال',
    },
    email: {
      placeholder: 'البريد الإلكتروني',
      label: 'البريد الإلكتروني',
    },
    photo: {
      placeholder: 'انقر لاختيار الصورة',
      label: 'صورة فوتوغرافية',
    },
    city: {
      placeholder: 'اختر مدينة',
      label: 'المدينة',
    },
    district: {
      placeholder: 'اختر حي',
      label: 'الحي',
    },
    integration: {
      placeholder: 'حدد الخدمة',
      label: 'خدمة',
    },
    logisticsCompany: {
      placeholder: {
        mono: 'حدد شركة لوجستية',
        multy: 'تم اختيار شركات مختلفة',
      },
      label: 'شركة التخطيط والتنفيذ',
    },
    role: {
      placeholder: 'اختر الدور',
      label: 'الأدوار',
    },
    orderType: {
      placeholder: 'حدد نوع الأمر',
      label: 'يكتب',
    },
    locationTypes: {
      placeholder: 'حدد نوع الموقع',
      label: 'نوع الموقع',
    },
    userTypes: {
      placeholder: 'حدد نوع المستخدم',
      label: 'نوع المستخدم',
    },
    locationName: {
      placeholder: 'اسم الموقع',
      label: 'اسم الموقع',
    },
    datePicker: {
      todayText: 'اليوم',
    },
    isReuqiredLabel: 'مطلوب',
    loadMoreBtn: {
      label: 'تحميل المزيد',
    },
    locations: {
      cities: {
        Riyadh: 'الرياض',
        Dammam: 'الدمام',
        Jeddah: 'جدة',
        Kharj: 'الخرج',
        Abha: 'أبها',
        'Al-Badaya': 'البدائع',
        Buraydah: 'بريدة',
        Hofuf: 'الهفوف',
        Jubail: 'الجبيل',
        'Khamis Mushait': 'خميس مشيط',
        Medinah: 'المدينة المنورة',
        Taif: 'الطائف',
        Unaizah: 'عنيزة',
        الرياض: 'الرياض',
        الدمام: 'الدمام',
        جدة: 'جدة',
        الخرج: 'الخرج',
        الطائف: 'الطائف',
        الهفوف: 'الهفوف',
        بريدة: 'بريدة',
        عنيزة: 'عنيزة',
        'خميس مشيط': 'خميس مشيط',
        الجبيل: 'الجبيل',
        'المدينة المنورة': 'المدينة المنورة',
        أبها: 'أبها',
        البدائع: 'البدائع',
      },
      districts: {
        'Ibn Khaldun': 'ابن خلدون',
        Uhud: 'احد',
        'Al Itisalat': 'الاتصالات',
        'Al Iskan': 'الاسكان',
        'Al Iskan (Dammam)': 'الإسكان (الدمام)',
        'Al Iskan (Khobar)': 'الاسكان (الخبر)',
        'Al Amir Muhammed Ibn Saud': 'الامير محمد بن سعود',
        'Al Athir': 'الأثير',
        'Al Amanah': 'الأمانة',
        'Al Amal': 'العمل',
        'Al Anwar': 'الأنوار',
        'Al Badiyah': 'البادية',
        'Al Buhayrah (Dammam)': 'البحيرة (الدمام)',
        'Al Buhayrah (Khobar)': 'البحيرة (الخبر)',
        'Al Badi': 'البديع',
        'Al Basatin': 'البساتين',
        'Al Jamiyin': 'الجامعيين',
        'Al Jalawiyah': 'الجلوية',
        'Al Jawharah': 'الجوهرة',
        'Al Jawharah (Dammam)': 'الجوهرة (الدمام)',
        'Al Jawharah (Khobar)': 'الجوهرة (الخبر)',
        'Al Hussam': 'الحسام',
        'Al Hamra': 'الحمراء',
        'Al Hamra (Dammam)': 'الحمراء (الدمام)',
        'Al Hamra (Khobar)': 'الحمراء (الخبر)',
        'Al Khalidiyah Al Janubiyah': 'الخالدية الجنوبية',
        'Al Khalidiyah Ash Shamaliyah': 'الخالدية الشمالية',
        'Al Kuthriah': 'الخضرية',
        'Al Khalij': 'الخليج',
        'Ad Danah': 'الدانة',
        'Ad Dawasir': 'الدواسر',
        'Ar Rabiyah': 'الرابية',
        'Ar Rakah Ash Shamaliyah': 'الراكة الشمالية',
        'Ar Rabi': 'الربيع',
        'Ar Rawdah': 'الروضة',
        'Ar Rayyan': 'الريان',
        'Az Zuhur': 'الزهور',
        'As Salam': 'السلام',
        'As Suq': 'السوق',
        'As Saif': 'السيف',
        'Ash Shati Ash Sharqi': 'الشاطئ الشرقي',
        'Ash Shati Al Gharbi': 'الشاطئ الغربي',
        'Ash Sharq': 'الشرق',
        'Ash Shulah': 'الشعلة',
        'Ash Shifa': 'الشفا',
        'As Sadafah': 'الصدفة',
        'As Safa': 'الصفا',
        Industrial: 'الصناعية',
        'Industrial Area No1': 'الصناعية الاولى',
        'Ad Dabab': 'الضباب',
        'At Tubayshi': 'الطبيشي',
        'Al Adamah': 'العدامة',
        'Al Aziziyah': 'العزيزية',
        'Al Amamrah': 'العمامرة',
        'Al Anud': 'العنود',
        'Al Firdaws': 'الفردوس',
        'Al Fursan': 'الفرسان',
        'Al Fanar': 'الفنار',
        'Al Faiha': 'الفيحاء',
        'Al Faisaliyah': 'الفيصلية',
        'Al Qadisiyah': 'القادسية',
        'Al Qazaz': 'القزاز',
        'Al Muhammadiyah': 'المحمدية',
        'Industrial City 2': 'المدينة الصناعية الثانية',
        'Al Muraikabat': 'المريكبات',
        'Al Mazruiyah': 'المزروعية',
        'Al Matar': 'المطار',
        'Al Manar': 'المنار',
        'Al Muntazah': 'المنتزه',
        'Al Maha (Dammam)': 'المها (الدمام)',
        'Al Maha (Khobar)': 'المها (الخبر)',
        'An Nasriyah': 'الناصرية',
        'An Nakhil': 'النخيل',
        'An Nada': 'الندى',
        'An Nuzhah': 'النزهة',
        'An Nasim': 'النسيم',
        'An Nahdah': 'النهضة',
        'An Nur': 'النور',
        'An Nawras': 'النورس',
        'Al Hadabah': 'الهضبة',
        'Al Wahah': 'الواحة',
        Badr: 'بدر',
        'Dahiyat Al Malik Fahd': 'ضاحية الملك فهد',
        Taybah: 'طيبة',
        Ghirnatah: 'غرناطة',
        'Qasr Al Khalij': 'قصر الخليج',
        'Madinat Al Umal (Dammam)': 'مدينة العمال (الدمام)',
        'Madinat Al Ummal (Khobar)': 'مدينة العمال(الخبر)',
        'King Faysal University': 'مدينة الملك فيصل الجامعية',
        'King Fahd International Airport': 'مطار الملك فهد الدولي',
        'King Abdul Aziz Seaport': 'ميناء الملك عبدالعزيز',
        'Ibn Sina': 'ابن سيناء',
        Ishbiliya: 'اشبيليا',
        'Al Amwaj': 'الامواج',
        'Al Andalus': 'الاندلس',
        'Al Bahar': 'البحر',
        'Al Bustan': 'البستان',
        'Al Bandariyah': 'البندرية',
        'At Tahliyah': 'التحلية',
        'At Taawn': 'التعاون',
        'Ath Thuqbah': 'الثقبة',
        'Al Jisr': 'الجسر',
        'Al Hizam Al Akhdar': 'الحزام الاخضر',
        'Al Hizam Adh Dhahabi': 'الحزام الذهبي',
        'Al Khubar Al Janubiyah': 'الخبر الجنوبية',
        'Al Khubar Ash Shamaliyah': 'الخبر الشمالية',
        'Al Khuzama': 'الخزامى',
        'Al Khawr': 'الخور',
        'Ar Rakah Al Janubiyah': 'الراكة الجنوبية',
        'Ar Raja': 'الرجاء',
        'Ar Rawabi': 'الروابي',
        'As Sahil': 'الساحل',
        'As Sufun': 'السفن',
        'Ash Sheraa': 'الشراع',
        'As Sawari': 'الصواري',
        'Al Aqrabiyah': 'العقربية',
        'Al Aqiq': 'العقيق',
        'Al Ulaya': 'العليا',
        'Al Kawthar': 'الكوثر',
        'Al Kurnaish': 'الكورنيش',
        'Al Lulu': 'اللؤلؤ',
        'Sporting City': 'المدينة الرياضية',
        'Al Murjan': 'المرجان',
        'Al Hada': 'الهدا',
        'Al Yarmok': 'اليرموك',
        'Sinaiyah Ath Thuqbah': 'صناعية الثقبة',
        'Sinaiyah Al Foaziah': 'صناعية الفوازية',
        Qurtubah: 'قرطبة',
        'Ad Dawhah Al Janubiyah': 'الدوحة الجنوبية',
        'Ad Danah Ash Shamaliyah': 'الدانة الشمالية',
        'Al Qusur': 'القصور',
        'Ad Dawhah Ash Shamaliyah': 'الدوحة الشمالية',
        'Ad Danah Al Janubiyah': 'الدانة الجنوبية',
        'Al Jamiah': 'الجامعة',
        'Al Haras Al Watani': 'الحرس الوطني',
        Tihamah: 'تهامة',
        'Al Qashlah': 'القشلة',
        Hajr: 'هجر',
        'Gharb Adh Dhahran': 'غرب الظهران',
        Kfupm: 'جامعة الملك فهد للبترول والمعادن',
        'Az Zomorod': 'الزمرد',
        'Al Loaloa': 'اللؤلؤ',
        'Al Yaqoot': 'الياقوت',
        'As Swaryee': 'الصوارى',
        'Al Ferdous': 'الفردوس',
        'Al Falah': 'الفلاح',
        'Al Asalah': 'الاصالة',
        Mraykh: 'مريخ',
        'Ash Shrouk': 'الشروق',
        'Al Amir Fawaz Ash Shamaly': 'الامير فواز الشمالى',
        'Ar Riyadh': 'الرياض',
        'Al Frosyah': 'الفروسية',
        'Ar Rahmanyah': 'الرحمانية',
        'As Salhiyah': 'الصالحية',
        'Al Hamadaniyah': 'الحمدانية',
        'Ar Rayaan': 'الريان',
        'Um Hableen': 'ام حبلين',
        Bryman: 'بريمان',
        'Al Montazah': 'المنتزة',
        'Ath Thaalibah': 'الثعالبة',
        'Al Balad': 'البلد',
        'Al Farouk': 'الفاروق',
        'Al Adel': 'العدل',
        'Al Hindawiyah': 'الهنداوية',
        'Al Mahjar': 'المحجر',
        'Al Khalidiyah': 'الخالدية',
        'King Abdul Aziz University': 'جامعة الملك عبدالعزيز',
        'Al Baghdadiyah Ash Sharqiyah': 'البغدادية الشرقية',
        'An Nazlah Ash Sharqiyah': 'النزلة الشرقية',
        'Al Bawadi': 'البوادي',
        'As Salamah': 'السلامة',
        'Ath Thaghr': 'الثغر',
        'Ash Sharafiyah': 'الشرفية',
        'King Faisal Naval Base': 'قاعدة الملك فيصل البحرية',
        'Ash Shefaa': 'الشفا',
        'As Sanabel': 'السنابل',
        'At Tadamon': 'التضامن',
        'Al Karamah': 'الكرامة',
        'Ar Rahmah': 'الرحمة',
        'Al Barakah': 'البركة',
        'Al Masarah': 'المسرة',
        'Al Moulysaa': 'المليساء',
        'Al Qouzeen': 'القوزين',
        'Al Wadi': 'الوادي',
        'Al Fadeylah': 'الفضيلة',
        'At Taawon': 'التعاون',
        'As Sarawat': 'السروات',
        'Al Khomrah': 'الخمرة',
        'Ad Dahiah': 'الضاحية',
        'Al Qryniah': 'القرينية',
        'An Nazlah Al Yamaniyah': 'النزلة اليمانية',
        'Al Quraiyat': 'القريات',
        Ghulail: 'غليل',
        'Al Kandarah': 'الكندرة',
        'Al Ammariyah': 'العمارية',
        'As Sahifah': 'الصحيفة',
        'As Sabil': 'السبيل',
        'Bani Malik': 'بنى مالك',
        'Ar Rihab': 'الرحاب',
        Mishrifah: 'مشرفة',
        'Al Wurud': 'الورود',
        'Abruq Ar Rughamah': 'ابرق الرغامة',
        'As Sulimaniyah': 'السليمانية',
        'Al Bashaer': 'البشائر',
        Taibah: 'طيبة',
        'An Naim': 'النعيم',
        'Ar Rabwah': 'الربوة',
        'Al Marwah': 'المروة',
        'As Samir': 'السامر',
        'Al Ajwad': 'الاجواد',
        'King Abdul Aziz International Airport': 'مطار الملك عبدالعزيز الدولي',
        'Industrial Area': 'المنطقة الصناعية',
        'Al Amir Abdoulmajed': 'الامير عبدالمجيد',
        'Al Waziriyah': 'الوزيريه',
        'Al Amir Fawaz Al Janouby': 'الامير فواز الجنوبى',
        'Al Mutanazahat': 'المتنزهات',
        'Al Fadel': 'الفضل',
        'Um Asalam': 'ام السلم',
        'King Abdul Aziz Medical City': 'مدينة الملك عبدالعزيز الطبية',
        'Al Baghdadiyah Al Gharbiyah': 'البغدادية الغربية',
        'Abhur Ash Shamaliyah': 'ابحر الشمالية',
        'Ar Ruwais': 'الرويس',
        Petromin: 'بترومين',
        'Abhur Al Janubiyah': 'ابحر الجنوبية',
        'As Sororyah': 'السرورية',
        'Al Ajaweed': 'الاجاويد',
        'Ash Shati': 'الشاطئ',
        'Madain Al Fahd': 'مدائن الفهد',
        'Az Zahra': 'الزهراء',
        Bahrah: 'بحرة',
        'Jeddah Eslamic Seaport': 'ميناء جدة الاسلامى',
        'An Namudhajiyah': 'النموذجية',
        'Al Jaradiyah': 'الجرادية',
        'As Sinaiyah': 'الصناعية',
        'Manfuhah Al Jadidah': 'منفوحة الجديدة',
        'Al Fakhriyah': 'الفاخرية',
        'Ad Dirah': 'الديرة',
        'Umm Al Hamam Ash Sharqi': 'ام الحمام الشرقي',
        'Al Madhar Ash Shamali': 'المعذر الشمالي',
        'Umm Al Hamam Al Gharbi': 'ام الحمام الغربي',
        'Ar Rahmaniyah': 'الرحمانية',
        Laban: 'لبن',
        'Ar Rafiah': 'الرفيعة',
        'Al Shohada': 'الشهداء',
        'King Fahd': 'الملك فهد',
        'As Suwaidi': 'السويدي',
        'Al Hazm': 'الحزم',
        Utaiqah: 'عتيقة',
        'Al Murabba': 'المربع',
        'Al Mursalat': 'المرسلات',
        'King Faisal': 'الملك فيصل',
        'New Industrial Area': 'المدينة الصناعية الجديدة',
        'Al Mansurah': 'المنصورة',
        Ghubairah: 'غبيرة',
        'Al Faruq': 'الفاروق',
        'Al Jazirah': 'الجزيرة',
        'As Saadah': 'السعادة',
        'Al Manakh': 'المناخ',
        'Ad Difa': 'الدفاع',
        'King Abdullah': 'الملك عبدالله',
        'Salah Ad Din': 'صلاح الدين',
        'King Abdul Aziz': 'الملك عبدالعزيز',
        'Al Wazarat': 'الوزارات',
        Skirinah: 'سكيرينة',
        Jarir: 'جرير',
        'Al Madhar': 'المعذر',
        'Al Malaz': 'الملز',
        Manfuhah: 'منفوحة',
        Ilaishah: 'عليشة',
        'Adh Dhubbat': 'الضباط',
        'As Suwaidi Al Gharbi': 'السويدي الغربي',
        Dirab: 'ديراب',
        Namar: 'نمار',
        Uqaz: 'عكاظ',
        Shubra: 'شبرا',
        'Az Zahrah': 'الزهرة',
        Siyah: 'صياح',
        Sultanah: 'سلطانة',
        'Al Yamamah': 'اليمامة',
        'Al Badiah': 'البديعة',
        'Al Masani': 'المصانع',
        'Ad Duraihimiyah': 'الدريهمية',
        'An Nasim Ash Sharqi': 'النسيم الشرقي',
        'Al Quds': 'القدس',
        'An Nafl': 'النفل',
        'Al Masif': 'المصيف',
        'Al Izdihar': 'الازدهار',
        'Ar Rayan': 'الريان',
        'Dhahrat Al Badiah': 'ظهرة البديعة',
        'An Nazim': 'النظيم',
        'Ar Rimayah': 'الرماية',
        'Al Bariah': 'البرية',
        Mansuriyah: 'المنصورية',
        'Dahiat Namar': 'ضاحية نمار',
        'Al Misfat': 'المصفاة',
        'As Safarat': 'السفارات',
        'Khashm Al An': 'خشم العان',
        Tuwaiq: 'طويق',
        'Dhahrat Namar': 'ظهرة نمار',
        'Al Mughrizat': 'المغرزات',
        'As Sulai': 'السلي',
        'Al Ghadir': 'الغدير',
        'Al Muruj': 'المروج',
        'Al Ud': 'العود',
        Thulaim: 'ثليم',
        'Ash Shimaisi': 'الشميسي',
        'Al Wisham': 'الوشام',
        'Ad Dubiyah': 'الدوبية',
        'Al Mikal': 'المعكال',
        Jabrah: 'جبرة',
        'Al Qura': 'القرى',
        'Al Marqab': 'المرقب',
        'Al Futah': 'الفوطة',
        'Umm Salim': 'ام سليم',
        'As Sahafah': 'الصحافة',
        'Ar Raid': 'الرائد',
        'Al Uraija Al Gharbiyah': 'العريجاء الغربية',
        'Al Uraija': 'العريجاء',
        'Al Uraija Al Wusta': 'العريجاء الوسطى',
        'Ad Dar Al Baida': 'الدار البيضاء',
        'Al Batha': 'البطحاء',
        'Al Mutamarat': 'المؤتمرات',
        'Al Wusayta': 'الوسيطاء',
        'Al Janadriyah': 'الجنادرية',
        Ishbiliyah: 'اشبيلية',
        'Al Maizilah': 'المعيزلة',
        'Al Yarmuk': 'اليرموك',
        'Al Munsiyah': 'المونسية',
        Irqah: 'عرقة',
        'Dhahrat Laban': 'ظهرة لبن',
        Hittin: 'حطين',
        'Al Malqa': 'الملقا',
        'Al Qirawan': 'القيروان',
        'Al Yasmin': 'الياسمين',
        'Al Arid': 'العارض',
        'King Khalid Airport': 'مطار الملك خالد',
        'An Narjis': 'النرجس',
        'Al Imam University': 'جامعة الامام',
        Banban: 'بنبان',
        'Ar Rimal': 'الرمال',
        'Al Dho': 'الدحو',
        'Al Umjiah': 'العماجية',
        Hyt: 'هيت',
        'Al Haeer': 'الحائر',
        'Um Alshal': 'ام الشعال',
        'Al Ghnamiah': ' الغنامية',
        Areed: 'عريض',
        'Al Mahdiyah': 'المهدية',
        'King Saud University': 'جامعة الملك سعود',
        'An Nasim Al Gharbi': 'النسيم الغربي',
        'Al Mishal': 'المشاعل',
        'ابن خلدون': 'ابن خلدون',
        احد: 'احد',
        الاتصالات: 'الاتصالات',
        الاسكان: 'الاسكان',
        'الإسكان (الدمام)': 'الإسكان (الدمام)',
        'الاسكان (الخبر)': 'الاسكان (الخبر)',
        'الامير محمد بن سعود': 'الامير محمد بن سعود',
        الأثير: 'الأثير',
        الأمانة: 'الأمانة',
        العمل: 'العمل',
        الأنوار: 'الأنوار',
        البادية: 'البادية',
        'البحيرة (الدمام)': 'البحيرة (الدمام)',
        'البحيرة (الخبر)': 'البحيرة (الخبر)',
        البديع: 'البديع',
        البساتين: 'البساتين',
        الجامعيين: 'الجامعيين',
        الجلوية: 'الجلوية',
        الجوهرة: 'الجوهرة',
        'الجوهرة (الدمام)': 'الجوهرة (الدمام)',
        'الجوهرة (الخبر)': 'الجوهرة (الخبر)',
        الحسام: 'الحسام',
        الحمراء: 'الحمراء',
        'الحمراء (الدمام)': 'الحمراء (الدمام)',
        'الحمراء (الخبر)': 'الحمراء (الخبر)',
        'الخالدية الجنوبية': 'الخالدية الجنوبية',
        'الخالدية الشمالية': 'الخالدية الشمالية',
        الخضرية: 'الخضرية',
        الخليج: 'الخليج',
        الدانة: 'الدانة',
        الدواسر: 'الدواسر',
        الرابية: 'الرابية',
        'الراكة الشمالية': 'الراكة الشمالية',
        الربيع: 'الربيع',
        الروضة: 'الروضة',
        الريان: 'الريان',
        الزهور: 'الزهور',
        السلام: 'السلام',
        السوق: 'السوق',
        السيف: 'السيف',
        'الشاطئ الشرقي': 'الشاطئ الشرقي',
        'الشاطئ الغربي': 'الشاطئ الغربي',
        الشرق: 'الشرق',
        الشعلة: 'الشعلة',
        الشفا: 'الشفا',
        الصدفة: 'الصدفة',
        الصفا: 'الصفا',
        الصناعية: 'الصناعية',
        'الصناعية الاولى': 'الصناعية الاولى',
        الضباب: 'الضباب',
        الطبيشي: 'الطبيشي',
        العدامة: 'العدامة',
        العزيزية: 'العزيزية',
        العمامرة: 'العمامرة',
        العنود: 'العنود',
        الفردوس: 'الفردوس',
        الفرسان: 'الفرسان',
        الفنار: 'الفنار',
        الفيحاء: 'الفيحاء',
        الفيصلية: 'الفيصلية',
        القادسية: 'القادسية',
        القزاز: 'القزاز',
        المحمدية: 'المحمدية',
        'المدينة الصناعية الثانية': 'المدينة الصناعية الثانية',
        المريكبات: 'المريكبات',
        المزروعية: 'المزروعية',
        المطار: 'المطار',
        المنار: 'المنار',
        المنتزه: 'المنتزه',
        'المها (الدمام)': 'المها (الدمام)',
        'المها (الخبر)': 'المها (الخبر)',
        الناصرية: 'الناصرية',
        النخيل: 'النخيل',
        الندى: 'الندى',
        النزهة: 'النزهة',
        النسيم: 'النسيم',
        النهضة: 'النهضة',
        النور: 'النور',
        النورس: 'النورس',
        الهضبة: 'الهضبة',
        الواحة: 'الواحة',
        بدر: 'بدر',
        'ضاحية الملك فهد': 'ضاحية الملك فهد',
        طيبة: 'طيبة',
        غرناطة: 'غرناطة',
        'قصر الخليج': 'قصر الخليج',
        'مدينة العمال (الدمام)': 'مدينة العمال (الدمام)',
        'مدينة العمال(الخبر)': 'مدينة العمال(الخبر)',
        'مدينة الملك فيصل الجامعية': 'مدينة الملك فيصل الجامعية',
        'مطار الملك فهد الدولي': 'مطار الملك فهد الدولي',
        'ميناء الملك عبدالعزيز': 'ميناء الملك عبدالعزيز',
        'ابن سيناء': 'ابن سيناء',
        اشبيليا: 'اشبيليا',
        الامواج: 'الامواج',
        الاندلس: 'الاندلس',
        البحر: 'البحر',
        البستان: 'البستان',
        البندرية: 'البندرية',
        التحلية: 'التحلية',
        التعاون: 'التعاون',
        الثقبة: 'الثقبة',
        الجسر: 'الجسر',
        'الحزام الاخضر': 'الحزام الاخضر',
        'الحزام الذهبي': 'الحزام الذهبي',
        'الخبر الجنوبية': 'الخبر الجنوبية',
        'الخبر الشمالية': 'الخبر الشمالية',
        الخزامى: 'الخزامى',
        الخور: 'الخور',
        'الراكة الجنوبية': 'الراكة الجنوبية',
        الرجاء: 'الرجاء',
        الروابي: 'الروابي',
        الساحل: 'الساحل',
        السفن: 'السفن',
        الشراع: 'الشراع',
        الصواري: 'الصواري',
        العقربية: 'العقربية',
        العقيق: 'العقيق',
        العليا: 'العليا',
        الكوثر: 'الكوثر',
        الكورنيش: 'الكورنيش',
        اللؤلؤ: 'اللؤلؤ',
        'المدينة الرياضية': 'المدينة الرياضية',
        المرجان: 'المرجان',
        الهدا: 'الهدا',
        اليرموك: 'اليرموك',
        'صناعية الثقبة': 'صناعية الثقبة',
        'صناعية الفوازية': 'صناعية الفوازية',
        قرطبة: 'قرطبة',
        'الدوحة الجنوبية': 'الدوحة الجنوبية',
        'الدانة الشمالية': 'الدانة الشمالية',
        القصور: 'القصور',
        'الدوحة الشمالية': 'الدوحة الشمالية',
        'الدانة الجنوبية': 'الدانة الجنوبية',
        الجامعة: 'الجامعة',
        'الحرس الوطني': 'الحرس الوطني',
        تهامة: 'تهامة',
        القشلة: 'القشلة',
        هجر: 'هجر',
        'غرب الظهران': 'غرب الظهران',
        'جامعة الملك فهد للبترول والمعادن': 'جامعة الملك فهد للبترول والمعادن',
        الزمرد: 'الزمرد',
        الياقوت: 'الياقوت',
        الصوارى: 'الصوارى',
        الفلاح: 'الفلاح',
        الاصالة: 'الاصالة',
        مريخ: 'مريخ',
        الشروق: 'الشروق',
        'الامير فواز الشمالى': 'الامير فواز الشمالى',
        الرياض: 'الرياض',
        الفروسية: 'الفروسية',
        الرحمانية: 'الرحمانية',
        الصالحية: 'الصالحية',
        الحمدانية: 'الحمدانية',
        'ام حبلين': 'ام حبلين',
        بريمان: 'بريمان',
        المنتزة: 'المنتزة',
        الثعالبة: 'الثعالبة',
        البلد: 'البلد',
        الفاروق: 'الفاروق',
        العدل: 'العدل',
        الهنداوية: 'الهنداوية',
        المحجر: 'المحجر',
        الخالدية: 'الخالدية',
        'جامعة الملك عبدالعزيز': 'جامعة الملك عبدالعزيز',
        'البغدادية الشرقية': 'البغدادية الشرقية',
        'النزلة الشرقية': 'النزلة الشرقية',
        البوادي: 'البوادي',
        السلامة: 'السلامة',
        الثغر: 'الثغر',
        الشرفية: 'الشرفية',
        'قاعدة الملك فيصل البحرية': 'قاعدة الملك فيصل البحرية',
        السنابل: 'السنابل',
        التضامن: 'التضامن',
        الكرامة: 'الكرامة',
        الرحمة: 'الرحمة',
        البركة: 'البركة',
        المسرة: 'المسرة',
        المليساء: 'المليساء',
        القوزين: 'القوزين',
        الوادي: 'الوادي',
        الفضيلة: 'الفضيلة',
        السروات: 'السروات',
        الخمرة: 'الخمرة',
        الضاحية: 'الضاحية',
        القرينية: 'القرينية',
        'النزلة اليمانية': 'النزلة اليمانية',
        القريات: 'القريات',
        غليل: 'غليل',
        الكندرة: 'الكندرة',
        العمارية: 'العمارية',
        الصحيفة: 'الصحيفة',
        السبيل: 'السبيل',
        'بنى مالك': 'بنى مالك',
        الرحاب: 'الرحاب',
        مشرفة: 'مشرفة',
        الورود: 'الورود',
        'ابرق الرغامة': 'ابرق الرغامة',
        السليمانية: 'السليمانية',
        البشائر: 'البشائر',
        النعيم: 'النعيم',
        الربوة: 'الربوة',
        المروة: 'المروة',
        السامر: 'السامر',
        الاجواد: 'الاجواد',
        'مطار الملك عبدالعزيز الدولي': 'مطار الملك عبدالعزيز الدولي',
        'المنطقة الصناعية': 'المنطقة الصناعية',
        'الامير عبدالمجيد': 'الامير عبدالمجيد',
        الوزيريه: 'الوزيريه',
        'الامير فواز الجنوبى': 'الامير فواز الجنوبى',
        المتنزهات: 'المتنزهات',
        الفضل: 'الفضل',
        'ام السلم': 'ام السلم',
        'مدينة الملك عبدالعزيز الطبية': 'مدينة الملك عبدالعزيز الطبية',
        'البغدادية الغربية': 'البغدادية الغربية',
        'ابحر الشمالية': 'ابحر الشمالية',
        الرويس: 'الرويس',
        بترومين: 'بترومين',
        'ابحر الجنوبية': 'ابحر الجنوبية',
        السرورية: 'السرورية',
        الاجاويد: 'الاجاويد',
        الشاطئ: 'الشاطئ',
        'مدائن الفهد': 'مدائن الفهد',
        الزهراء: 'الزهراء',
        بحرة: 'بحرة',
        'ميناء جدة الاسلامى': 'ميناء جدة الاسلامى',
        النموذجية: 'النموذجية',
        الجرادية: 'الجرادية',
        'منفوحة الجديدة': 'منفوحة الجديدة',
        الفاخرية: 'الفاخرية',
        الديرة: 'الديرة',
        'ام الحمام الشرقي': 'ام الحمام الشرقي',
        'المعذر الشمالي': 'المعذر الشمالي',
        'ام الحمام الغربي': 'ام الحمام الغربي',
        لبن: 'لبن',
        الرفيعة: 'الرفيعة',
        الشهداء: 'الشهداء',
        'الملك فهد': 'الملك فهد',
        السويدي: 'السويدي',
        الحزم: 'الحزم',
        عتيقة: 'عتيقة',
        المربع: 'المربع',
        المرسلات: 'المرسلات',
        'الملك فيصل': 'الملك فيصل',
        'المدينة الصناعية الجديدة': 'المدينة الصناعية الجديدة',
        المنصورة: 'المنصورة',
        غبيرة: 'غبيرة',
        الجزيرة: 'الجزيرة',
        السعادة: 'السعادة',
        المناخ: 'المناخ',
        الدفاع: 'الدفاع',
        'الملك عبدالله': 'الملك عبدالله',
        'صلاح الدين': 'صلاح الدين',
        'الملك عبدالعزيز': 'الملك عبدالعزيز',
        الوزارات: 'الوزارات',
        سكيرينة: 'سكيرينة',
        جرير: 'جرير',
        المعذر: 'المعذر',
        الملز: 'الملز',
        منفوحة: 'منفوحة',
        عليشة: 'عليشة',
        الضباط: 'الضباط',
        'السويدي الغربي': 'السويدي الغربي',
        ديراب: 'ديراب',
        نمار: 'نمار',
        عكاظ: 'عكاظ',
        شبرا: 'شبرا',
        الزهرة: 'الزهرة',
        صياح: 'صياح',
        سلطانة: 'سلطانة',
        اليمامة: 'اليمامة',
        البديعة: 'البديعة',
        المصانع: 'المصانع',
        الدريهمية: 'الدريهمية',
        'النسيم الشرقي': 'النسيم الشرقي',
        القدس: 'القدس',
        النفل: 'النفل',
        المصيف: 'المصيف',
        الازدهار: 'الازدهار',
        'ظهرة البديعة': 'ظهرة البديعة',
        النظيم: 'النظيم',
        الرماية: 'الرماية',
        البرية: 'البرية',
        المنصورية: 'المنصورية',
        'ضاحية نمار': 'ضاحية نمار',
        المصفاة: 'المصفاة',
        السفارات: 'السفارات',
        'خشم العان': 'خشم العان',
        طويق: 'طويق',
        'ظهرة نمار': 'ظهرة نمار',
        المغرزات: 'المغرزات',
        السلي: 'السلي',
        الغدير: 'الغدير',
        المروج: 'المروج',
        العود: 'العود',
        ثليم: 'ثليم',
        الشميسي: 'الشميسي',
        الوشام: 'الوشام',
        الدوبية: 'الدوبية',
        المعكال: 'المعكال',
        جبرة: 'جبرة',
        القرى: 'القرى',
        المرقب: 'المرقب',
        الفوطة: 'الفوطة',
        'ام سليم': 'ام سليم',
        الصحافة: 'الصحافة',
        الرائد: 'الرائد',
        'العريجاء الغربية': 'العريجاء الغربية',
        العريجاء: 'العريجاء',
        'العريجاء الوسطى': 'العريجاء الوسطى',
        'الدار البيضاء': 'الدار البيضاء',
        البطحاء: 'البطحاء',
        المؤتمرات: 'المؤتمرات',
        الوسيطاء: 'الوسيطاء',
        الجنادرية: 'الجنادرية',
        اشبيلية: 'اشبيلية',
        المعيزلة: 'المعيزلة',
        المونسية: 'المونسية',
        عرقة: 'عرقة',
        'ظهرة لبن': 'ظهرة لبن',
        حطين: 'حطين',
        الملقا: 'الملقا',
        القيروان: 'القيروان',
        الياسمين: 'الياسمين',
        العارض: 'العارض',
        'مطار الملك خالد': 'مطار الملك خالد',
        النرجس: 'النرجس',
        'جامعة الامام': 'جامعة الامام',
        بنبان: 'بنبان',
        الرمال: 'الرمال',
        الدحو: 'الدحو',
        العماجية: 'العماجية',
        هيت: 'هيت',
        الحائر: 'الحائر',
        'ام الشعال': 'ام الشعال',
        ' الغنامية': ' الغنامية',
        عريض: 'عريض',
        المهدية: 'المهدية',
        'جامعة الملك سعود': 'جامعة الملك سعود',
        'النسيم الغربي': 'النسيم الغربي',
        المشاعل: 'المشاعل',
        القطبية: 'القطبية',
        'ام العراد': 'ام العراد',
        الوكف: 'الوكف',
        نخب: 'نخب',
        الشرقية: 'الشرقية',
        البيعة: 'البيعة',
        الدهاس: 'الدهاس',
        'الامام تركي بن عبدالله': 'الامام تركي بن عبدالله',
        جليل: 'جليل',
        قروى: 'قروى',
        الوسام: 'الوسام',
        الجال: 'الجال',
        الرميدة: 'الرميدة',
        'مخطط البيعة': 'مخطط البيعة',
        حوايا: 'حوايا',
        'مخطط البساتين': 'مخطط البساتين',
        لقطبية: 'لقطبية',
        الوكيف: 'الوكيف',
        'الشهداء الجنوبية': 'الشهداء الجنوبية',
        الوشحاء: 'الوشحاء',
        شهار: 'شهار',
        معشي: 'معشي',
        'مخطط بن باين': 'مخطط بن باين',
        'المهلب بن صفرة': 'المهلب بن صفرة',
        الطويرات: 'الطويرات',
        السداد: 'السداد',
        مسره: 'مسره',
        المثناه: 'المثناه',
        عودة: 'عودة',
        الوهط: 'الوهط',
        القمرية: 'القمرية',
        'مخطط بدر': 'مخطط بدر',
        'بن باين': 'بن باين',
        'مخطط الدهاس': 'مخطط الدهاس',
        'وادي ديرة': 'وادي ديرة',
        الحمادين: 'الحمادين',
        الشهابية: 'الشهابية',
        المربدية: 'المربدية',
        'منسوبي التعليم': 'منسوبي التعليم',
        جوبا: 'جوبا',
        'المعلمين الشرقية': 'المعلمين الشرقية',
        النايفية: 'النايفية',
        'مجمع الدوائر الحكومية': 'مجمع الدوائر الحكومية',
        البصيرة: 'البصيرة',
        المثلث: 'المثلث',
        الحوراء: 'الحوراء',
        المرقاب: 'المرقاب',
        السلمانية: 'السلمانية',
        المهندسين: 'المهندسين',
        المباركية: 'المباركية',
        الجامعين: 'الجامعين',
        الحفيرة: 'الحفيرة',
        الهدى: 'الهدى',
        السنيدية: 'السنيدية',
        المزروع: 'المزروع',
        'الإسكان الأول': 'الإسكان الأول',
        الصيهد: 'الصيهد',
        الكوت: 'الكوت',
        'عين موسى': 'عين موسى',
        الثليثية: 'الثليثية',
        الفاضلية: 'الفاضلية',
        النعاثل: 'النعاثل',
        العسيلة: 'العسيلة',
        الرفعة: 'الرفعة',
        العويمرية: 'العويمرية',
        'السلمانية القديمة': 'السلمانية القديمة',
        'عين علي': 'عين علي',
        الرقيقة: 'الرقيقة',
        الأندلس: 'الأندلس',
        خضراء: 'خضراء',
        الجنوب: 'الجنوب',
        السادة: 'السادة',
        الجردة: 'الجردة',
        'خب الثنيان': 'خب الثنيان',
        الخبيب: 'الخبيب',
        الزرقاء: 'الزرقاء',
        'القاع البارد': 'القاع البارد',
        'الشقة السفلى': 'الشقة السفلى',
        الافق: 'الافق',
        النقل: 'النقل',
        المرواج: 'المرواج',
        الخضر: 'الخضر',
        السالمية: 'السالمية',
        الصباخ: 'الصباخ',
        الهلال: 'الهلال',
        الشماس: 'الشماس',
        الصفراء: 'الصفراء',
        الروضه: 'الروضه',
        رواق: 'رواق',
        اللسيب: 'اللسيب',
        واسط: 'واسط',
        الضاحي: 'الضاحي',
        العجيبة: 'العجيبة',
        الموطاء: 'الموطاء',
        المريدسية: 'المريدسية',
        الشفق: 'الشفق',
        الفايزية: 'الفايزية',
        الإسكان: 'الإسكان',
        الخزامي: 'الخزامي',
        القضيعة: 'القضيعة',
        حويلان: 'حويلان',
        'خب البريدي': 'خب البريدي',
        العريضي: 'العريضي',
        التعليم: 'التعليم',
        الصفاء: 'الصفاء',
        الحمر: 'الحمر',
        الوسيطي: 'الوسيطي',
        النقع: 'النقع',
        الأخضر: 'الأخضر',
        الاشرفية: 'الاشرفية',
        الروغاني: 'الروغاني',
        السمية: 'السمية',
        الشريمية: 'الشريمية',
        الصالة: 'الصالة',
        الضليعة: 'الضليعة',
        العونية: 'العونية',
        الفيضة: 'الفيضة',
        الملكخالد: 'الملكخالد',
        المنطقةالصناعية: 'المنطقةالصناعية',
        الوسطى: 'الوسطى',
        شيخة: 'شيخة',
        غربالديرة: 'غربالديرة',
        مشرف: 'مشرف',
        مليحة: 'مليحة',
        هلالة: 'هلالة',
        الراقي: 'الراقي',
        شباعة: 'شباعة',
        شكر: 'شكر',
        المعزاب: 'المعزاب',
        الشفاء: 'الشفاء',
        القافلة: 'القافلة',
        'ال قصال': 'ال قصال',
        الموسى: 'الموسى',
        عتود: 'عتود',
        الصقور: 'الصقور',
        'سكن المدينة العسكرية': 'سكن المدينة العسكرية',
        ضمك: 'ضمك',
        الدوحة: 'الدوحة',
        الهميلة: 'الهميلة',
        الرصراص: 'الرصراص',
        ذلالة: 'ذلالة',
        السد: 'السد',
        'طيب الاسم': 'طيب الاسم',
        المثلناة: 'المثلناة',
        'سكن القاعدة الجوية': 'سكن القاعدة الجوية',
        'اللواء الرابع عشر': 'اللواء الرابع عشر',
        المعمورة: 'المعمورة',
        الهرير: 'الهرير',
        'ام سرور': 'ام سرور',
        'مخطط غرب المزارع': 'مخطط غرب المزارع',
        الذهب: 'الذهب',
        الطوية: 'الطوية',
        'عبد اللطيف جميل': 'عبد اللطيف جميل',
        'صناعية العريفي': 'صناعية العريفي',
        الصفاة: 'الصفاة',
        'قلعة مخيط': 'قلعة مخيط',
        البلقاء: 'البلقاء',
        السكب: 'السكب',
        مهزور: 'مهزور',
        'بئر عثمان': 'بئر عثمان',
        الغابة: 'الغابة',
        الغراء: 'الغراء',
        العانبس: 'العانبس',
        الجمعة: 'الجمعة',
        كتانة: 'كتانة',
        'مسجد الدرع': 'مسجد الدرع',
        الشريبات: 'الشريبات',
        وعيرة: 'وعيرة',
        العهن: 'العهن',
        السيح: 'السيح',
        'جبل احد': 'جبل احد',
        العريض: 'العريض',
        ورقان: 'ورقان',
        حثم: 'حثم',
        الدويخلة: 'الدويخلة',
        'عين الخيف': 'عين الخيف',
        'بني حارثة': 'بني حارثة',
        قربان: 'قربان',
        الظاهرة: 'الظاهرة',
        النقس: 'النقس',
        الدويمة: 'الدويمة',
        رهط: 'رهط',
        الصادقية: 'الصادقية',
        الجصة: 'الجصة',
        الوبرة: 'الوبرة',
        الدار: 'الدار',
        'جبل عير': 'جبل عير',
        الرانوناء: 'الرانوناء',
        مذينب: 'مذينب',
        'بني بياضة': 'بني بياضة',
        'ابو كبير': 'ابو كبير',
        الهدراء: 'الهدراء',
        القصواء: 'القصواء',
        شظاة: 'شظاة',
        'بني ظفر': 'بني ظفر',
        'بني معاوية': 'بني معاوية',
        الجماوات: 'الجماوات',
        'ابو بريقاء': 'ابو بريقاء',
        الحديقة: 'الحديقة',
        الخاتم: 'الخاتم',
        العيون: 'العيون',
        السقيا: 'السقيا',
        العصبة: 'العصبة',
        عروة: 'عروة',
        الشهباء: 'الشهباء',
        'السكة الحديدية': 'السكة الحديدية',
        شوران: 'شوران',
        نبلاء: 'نبلاء',
        المبعوث: 'المبعوث',
        خاخ: 'خاخ',
        الجابرة: 'الجابرة',
        الرمانة: 'الرمانة',
        'المنطقة المركزية': 'المنطقة المركزية',
        الفتح: 'الفتح',
        الاصيفرين: 'الاصيفرين',
        المغيسلة: 'المغيسلة',
        القبلتين: 'القبلتين',
        الراية: 'الراية',
        'بني عبدالاشهل': 'بني عبدالاشهل',
        'سيد الشهداء': 'سيد الشهداء',
        'ذو الحليفة': 'ذو الحليفة',
        القلعة: 'القلعة',
        'ام خالد': 'ام خالد',
        'جبل جمة وغرابة': 'جبل جمة وغرابة',
        العاقول: 'العاقول',
        الوردتين: 'الوردتين',
        الخشع: 'الخشع',
        الموظفين: 'الموظفين',
        شمسان: 'شمسان',
        القابل: 'القابل',
        المنسك: 'المنسك',
        الشرف: 'الشرف',
        العرين: 'العرين',
        'الأمير سلطان': 'الأمير سلطان',
        القرير: 'القرير',
        'ام ثلعة': 'ام ثلعة',
        الاصفر: 'الاصفر',
        الرمة: 'الرمة',
        الشيرمي: 'الشيرمي',
        ' العبيلة': ' العبيلة',
        العثمانية: 'العثمانية',
        المستشفى: 'المستشفى',
      },
    },
    services: {
      orders: {
        orderRemoveSuccess: 'تم حذف الطلب',
        orderMarkAsDeliveredSuccess:
          'تم وضع علامة على الطلب على أنه تم التسليم',
        importSuccess: 'تم تحميل الطلبات',
        details: {
          orderId: 'رقم التعريف الخاص بالطلب',
          lockerId: 'معرف الخزانة',
          cabinet: 'خزانة',
          status: 'الحالة',
        },
      },
      locations: {
        createSuccess: 'تم إنشاء الموقع',
        updateSuccess: 'تم تحديث الموقع',
      },
      lockers: {
        statusCabinetChangeSuccess: 'تم تحديث الحالة بنجاح',
        openCabinetSuccess: 'تم فتح الخزانة بنجاح',
        openLockerSuccess: 'تم فتح الخزانة بنجاح',
        depositOrderSuccess: 'تم إيداع الطلب بنجاح',
      },
      request: {
        createSuccess: 'تم إنشاء الطلب',
        updateSuccess: 'تم تحديث الطلب بنجاح',
        splitSuccess: 'تم تقسيم الطلب',
        adjustSuccess: 'تم تعديل الطلب',
      },
      trips: {
        createSuccess: 'تم إنشاء الرحلة',
        updateSucces: 'تم تحديث الرحلة بنجاح',
        completeSuccess: 'اكتملت الرحلة',
        pickupSuccess: 'تم اختيار الطلب',
        dropSuccess: 'تم إسقاط الطلب',
        issueReported: 'تم الإبلاغ عن المشكلة',
        startedGenerate: 'تم بدء إنشاء الرحلات',
        export: 'يصدر',
        exportDetails: 'تفاصيل التصدير',
        details: {
          client: 'عميل',
          btnRemove: 'إزالة من الرحلة',
          dialogText: 'هل تريد حقًا إزالة الطلب من الرحلة الحالية؟',
          btnCancel: 'يلغي',
          btnYes: 'نعم',
        },
      },
      users: {
        updateSucces: 'تم تحديث المستخدم',
        resetSuccess: 'تم إعادة تعيين كلمة المرور',
        emailNotify:
          'تم إرسال رسالة إلى بريدك الإلكتروني لإعادة تعيين كلمة المرور',
      },
      profile: {
        changePasswordSuccess: 'تم تغيير كلمة المرور بنجاح!',
        updateProfileSuccess: 'تم تحديث الملف الشخصي بنجاح!',
      },
    },
    toogleAddressBtn: {
      address: 'عنوان',
      map: 'خريطة',
      autocomplete: 'الإكمال التلقائي',
    },
    gmap: {
      city: 'مدينة',
      area: 'منطقة',
      state: 'حالة',
      address: 'عنوان',
    },
    loader: {
      text: 'جار التحميل ...',
    },
    lockerDetailInfo: {
      lockerDescriptionTitle: 'وصف الخزنة',
      lockerPhoto: 'صورة الخزنة',
      notFound: 'لم يتم العثور على المعلومات',
    },
    reportIssues: {
      orderNotFound: 'الطلب غير موجود',
      notDelivered3PL: 'لم يتم تسليم 3PL',
      orderNotReady: 'الطلب غير جاهز',
      orderCancelledByClient: 'تم إلغاء الطلب من قبل العميل',
      cabinetNotOpen: 'الخزانة غير مفتوحة',
    },
    notificationsModal: {
      clear: 'صافي',
      tabs: {
        declinedTrips: 'الرحلات المرفوضة',
        overdueAcceptenceTrips: 'رحلة غير مقبولة',
      },
    },
    changeTrackLink: 'Change Track Link',
    update: 'Update',
  },
}
