import React, { useMemo, memo } from 'react'
import { Icon } from '@blueprintjs/core'

import { LoadMoreBtn } from '../'
import variables from '../../../styles/variables'
import s from './MobileList.module.scss'

export const MobileList = memo(
  ({
    items = [],
    fieldsToShow = [],
    ActionPopover = () => null,
    hasNextPage = false,
    onLoadMore = () => {},
  }) => {
    const isShowLoadMoreBtn = useMemo(() => hasNextPage, [hasNextPage])
    return (
      <div className={s.container}>
        <div className={s.list}>
          {items.map(({ ...props }) => {
            let showActions = true
            if (props.orders) {
              showActions = !props.orders.some(
                ({ status }) =>
                  status === 'Deposited at origin locker' ||
                  status === 'Assigned',
              )
            }

            return (
              <div key={props._id} className={s.listItem}>
                <div className={s.infoSide}>
                  {fieldsToShow.map(({ iconName, fieldName }) => {
                    let label = ''
                    let MapBtn = null
                    if (fieldName !== 'geoCoord') {
                      fieldName.split(',').forEach((field) => {
                        label += `${props[field]} `.trim()
                      })
                    }
                    if (fieldName === 'geoCoord') {
                      MapBtn = () => {
                        return (
                          <div
                            key={iconName}
                            className={s.mapBtn}
                            onClick={() =>
                              window.open(
                                `https://www.google.com/maps/search/?api=1&query=${props[fieldName].lat},${props[fieldName].lng}`,
                                '_blank',
                              )
                            }
                          >
                            <Icon
                              icon='send-to-map'
                              color={variables.shuttleWhite}
                            />
                          </div>
                        )
                      }
                    }
                    return (
                      <div key={iconName} className={s.itemRow}>
                        <Icon icon={iconName} />
                        <span className={s.itemLabel}>
                          {label}
                          {MapBtn && <MapBtn />}
                        </span>
                      </div>
                    )
                  })}
                </div>
                {showActions && ActionPopover && (
                  <div className={s.actionsSide}>
                    <ActionPopover {...props} />
                  </div>
                )}
              </div>
            )
          })}
        </div>
        {isShowLoadMoreBtn && <LoadMoreBtn onClick={onLoadMore} />}
      </div>
    )
  },
)
