import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Select } from './Select'

import { USER_TYPES } from '../../../data/constans'

export const UserType = memo(({ selectRef, value, onChange }) => {
  const { t } = useTranslation()

  return (
    <Select
      selectRef={selectRef}
      value={value}
      options={USER_TYPES}
      placeholder={t('userTypes').placeholder}
      label={t('userTypes').label}
      onChange={onChange}
      Option={(props) => {
        const name = props[0]
        return <option value={name}>{name}</option>
      }}
    />
  )
})
