import React, { useState, lazy, Suspense, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import axios from 'axios'
import jwt from 'jwt-decode'
import { useAuth } from '../hooks/useAuth.ts'
import io from 'socket.io-client'

import { ROLE } from '../data/constans'
import { AuthContext } from '../context/auth'
import {
  useGetCurrentUserQuery,
  useGetCitiesQuery,
  useGetCitiesForRouteQuery,
} from '../services/api.ts'
import { authSlice } from '../redux/reducers/auth-reducer'
import { citiesSlice } from '../redux/reducers/settings-reducer/cities-reducer'
import {
  notificationsSlice,
  notificationType,
} from '../redux/reducers/notification-reducer'
import SiteLogo from '../modules/SiteLogo/SiteLogo'

import { ApplicationRoute, ProviderRoute } from './Routes'
import TrackingRoutes from './TrackingRoutes'

import { useLocalStorage } from '../hooks'
import { refreshUserToken } from '../services/api.ts'
import { routes } from '../routes'
import { TOKEN_LOCALSTORAGE_KEY } from '../data/constans'

import s from './App.module.css'

import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '@blueprintjs/core/lib/css/blueprint.css'

import './reset.css'
import 'materialize-css'
import '../styles/main.scss'
import { useAppDispatch } from '../redux/store'
import { ProviderExternalRoute } from './Routes/ProviderExternalRoute/ProviderExternalRoute.js'

const Auth = lazy(() =>
  import(
    './NavigationPanel/NavigationRoutes/CommonsRoutes/Login/Auth' /* webpackChunkName: "Auth" */
  ),
)
const PrivacyPolicy = lazy(() =>
  import(
    './NavigationPanel/NavigationRoutes/CommonsRoutes/PrivacyPolicy' /* webpackChunkName: "Privacy Policy" */
  ),
)

const ShgardiDriverRouteLazy = lazy(() =>
  import('./ShgardiDriverRoute/ShgardiDriverRoute'),
)
const ShgardiDriverRouteErrorLazy = lazy(() =>
  import('./TrackingRoutes/ErrorRoute'),
)

const DepositSmartLinkRouteLazy = lazy(() =>
  import('./Routes/SmartPagesRoutes/DepositSmartLinkRoute'),
)
const DepositSmartLinkRouteErrorLazy = lazy(() =>
  import('./TrackingRoutes/ErrorRoute'),
)
const DepositSmartLinkForConsigneeRouteLazy = lazy(() =>
  import(
    './Routes/SmartPagesRoutes/Consignee/DepositSmartLinkForConsigneeRoute'
  ),
)
const DepositSmartLinkForConsigneeRouteSuccessLazy = lazy(() =>
  import(
    './Routes/SmartPagesRoutes/Consignee/DepositSmartLinkForConsigneeRoute/SuccessRoute'
  ),
)
const DepositSmartLinkForConsigneeRouteErrorLazy = lazy(() =>
  import(
    './Routes/SmartPagesRoutes/Consignee/DepositSmartLinkForConsigneeRoute/ErrorRoute'
  ),
)
const ConsigneeSmartPageRouteLazy = lazy(
  () =>
    import(
      './Routes/SmartPagesRoutes/Consignee/ConsigneeSmartPage.tsx'
    ) /*webpackChunkName: "Consignee Smart Page" */,
)
const PrintInfoParcelRouteLazy = lazy(
  () =>
    import(
      './Routes/SmartPagesRoutes/Driver/PrintInfoParcelRoute'
    ) /*webpackChunkName: "Driver Smart Page" */,
)
export const checkIsExpiredToken = (access_token) => {
  const { exp } = jwt(access_token)
  const isExpired = exp * 1000 - Date.now() <= 0
  if (isExpired) {
    window.localStorage.removeItem(TOKEN_LOCALSTORAGE_KEY)
  }
  return isExpired
}

function App() {
  const [token, setToken, removeToken] = useLocalStorage(TOKEN_LOCALSTORAGE_KEY)
  const [authToken, setAuthToken] = useState(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      return token
    } else {
      return token
    }
  })

  useEffect(() => {
    if (authToken) {
      const interval = setInterval(() => {
        if (
          authToken &&
          !window.location.pathname.includes('/orders/track') &&
          !window.location.pathname.includes('/orders/delivery')
        ) {
          refreshUserToken()
        } else {
          clearInterval(interval)
        }
      }, 60000)
      return () => clearInterval(interval)
    }
  }, [authToken])

  const [authUser, setAuthUser] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    image: '',
    email: '',
    client: '',
    role: '',
  })

  const handleChangeToken = (token) => {
    setToken(token)
    setAuthToken(token)
  }

  const setUser = (user) => {
    let image = user.image
    if (user.image) {
      image = user.image + `?${new Date().getTime()}`
    }

    setAuthUser({ ...user, image })
  }

  const onHandleSignOut = () => {
    removeToken(TOKEN_LOCALSTORAGE_KEY)
    setAuthToken(null)
    setAuthUser(null)
    dispatch(setCurrentUser({ token: null, user: null }))
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  const { setCurrentUser } = authSlice.actions
  const { selectCities, selectVisibleCities, selectCitiesRoute } =
    citiesSlice.actions
  const { updateNotification } = notificationsSlice.actions

  const dispatch = useAppDispatch()

  const { user, token: RTK_token } = useAuth()

  const isExpiredToken = authToken && checkIsExpiredToken(authToken)

  const isSkipToGetCurrentUser = !!(
    (!(authToken && user) &&
      window.location.pathname.includes('/orders/track')) ||
    isExpiredToken
  )

  const { data: currentUser } = useGetCurrentUserQuery('', {
    skip: isSkipToGetCurrentUser,
  })

  const { data: dataCities } = useGetCitiesQuery('', {
    skip: isExpiredToken,
  })

  if (!!dataCities) {
    dispatch(selectCities(dataCities))
  }

  const { data: dataCitiesRoute, isSuccess } = useGetCitiesForRouteQuery()
  if (!!dataCitiesRoute) {
    dispatch(selectCitiesRoute(dataCitiesRoute))
  }

  if (!user && currentUser && !isSuccess) {
    setUser(currentUser)
    dispatch(setCurrentUser({ token, user: currentUser }))
  }

  const isShowAppRoute =
    !!RTK_token &&
    (user.role === ROLE.ADMIN ||
      user.role === ROLE.CLIENT ||
      user.role === 'Tenant')
  const isShowProviderRoute =
    !!authToken &&
    authUser.role === ROLE.PROVIDER &&
    !authUser?.externalProviderCompany
  const isShowExternalProviderRoute =
    !!authToken &&
    authUser.role === ROLE.PROVIDER &&
    authUser.externalProviderCompany
  const isShowAuthRoute = !authToken || isExpiredToken
  const isShowPrivacyPolicyRoute = !authToken

  useEffect(() => {
    if (!authUser.firstName) return
    const socket = io(process.env.REACT_APP_BASE_URL, {
      reconnectionDelay: 300,
      reconnectionDelayMax: 300,
    })

    socket.on('connect', function () {
      console.log('Connection established!')
    })

    socket.emit(
      'createRoom',
      authUser.role === 'Admin' ? 'ADMIN' : authUser._id,
    )

    if (authUser.role === 'Admin') {
      socket.on('DECLINED_TRIP', (data) => {
        dispatch(
          updateNotification({
            notificationType: notificationType.DECLINED_TRIPS,
            entity: data,
          }),
        )
      })

      socket.on('PROVIDER_OVERDUE_ACCEPT_TRIP', (data) => {
        dispatch(
          updateNotification({
            notificationType: notificationType.OVERDUE_ACCEPTANCE_TRIPS,
            entity: data,
          }),
        )
      })
    }

    if (authUser.role === 'Provider' && !authUser?.externalProviderCompany) {
      socket.on('TRIP_ASSIGNED', (data) => {
        dispatch(
          updateNotification({
            notificationType: notificationType.ASSIGNED_TRIPS,
            entity: data,
          }),
        )
      })
    }

    return () => {
      socket.on('disconnect', () => {
        console.log('DISCONNECT')
      })
    }
  }, [authUser])

  return (
    <Suspense fallback={<FallBack />}>
      <AuthContext.Provider
        value={{
          authUser,
          setAuthUser: setUser,
          setAuthToken: handleChangeToken,
          onSignOut: onHandleSignOut,
        }}
      >
        <Router>
          <Switch>
            <Route path='/orders/track/:guid' component={TrackingRoutes} />
            <Route
              path='/orders/delivery/track/:id/error'
              exact
              render={() => <ShgardiDriverRouteErrorLazy />}
            />
            <Route
              path='/orders/delivery/track/:id/:pin'
              exact
              render={() => <ShgardiDriverRouteLazy />}
            />

            <Route
              path='/orders/delivery/track/:id/:pin'
              exact
              render={() => <ShgardiDriverRouteLazy />}
            />
            <Route
              path='/orders/delivery/:id/error'
              exact
              render={() => <DepositSmartLinkRouteErrorLazy />}
            />
            <Route
              path='/orders/delivery/:id'
              exact
              render={() => <DepositSmartLinkRouteLazy />}
            />
            <Route
              path='/orders/consignee/deposit/:trackingId'
              exact
              render={() => <DepositSmartLinkForConsigneeRouteLazy />}
            />
            <Route
              path='/orders/consignee/deposit/:trackingId/success'
              exact
              render={() => <DepositSmartLinkForConsigneeRouteSuccessLazy />}
            />
            <Route
              path='/orders/consignee/deposit/:trackingId/error'
              exact
              render={() => <DepositSmartLinkForConsigneeRouteErrorLazy />}
            />
            <Route
              path='/order/external/initiate/:accessKey'
              exact
              render={() => <ConsigneeSmartPageRouteLazy />}
            />
            <Route
              path='/intercity_parcel/:intercityParcelGroupTrackingId'
              render={() => <PrintInfoParcelRouteLazy />}
            />
            {isShowProviderRoute && <ProviderRoute />}
            {isShowExternalProviderRoute && <ProviderExternalRoute />}
            {isShowAppRoute && <ApplicationRoute />}
            {isShowAuthRoute && (
              <Route path={routes.AUTH} exact render={() => <Auth />} />
            )}
            {isShowPrivacyPolicyRoute && (
              <Route
                path={routes.PRIVACY_POLICY}
                exact
                render={() => <PrivacyPolicy />}
              />
            )}
            {isShowAuthRoute && <Redirect to={routes.AUTH} />}
          </Switch>
        </Router>
      </AuthContext.Provider>
    </Suspense>
  )
}

export default App

function FallBack() {
  return (
    <div className={s.fallback}>
      <SiteLogo />
    </div>
  )
}
