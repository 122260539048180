import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { TextInput } from './TextInput'

export const LastName = memo(({ register, name, isRequired }) => {
  const { t } = useTranslation()

  return (
    <TextInput
      register={register}
      name={name}
      placeholder={t('lastName').placeholder}
      label={t('lastName').label}
      isRequired={isRequired}
    />
  )
})
