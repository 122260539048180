import React, { useMemo, memo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import s from './TextArea.module.scss'

export const TextArea = memo(
  ({
    name = '',
    type = 'text',
    disabled = false,
    autoFocus = false,
    register = null,
    label = '',
    placeholder = '',
    style = {},
    styleLabel = {},
    props = {},
    isRequired = false,
    subInput = false,
  }) => {
    const { t } = useTranslation()
    const isShowLabel = useMemo(() => !!label && !!props.value, [label, props])

    return (
      <div
        className={cn(s.inputBlock, subInput && s.subInputBlock)}
        style={style}
      >
        <textarea
          name={name}
          ref={register}
          type={type}
          disabled={disabled}
          autoFocus={autoFocus}
          placeholder={!!placeholder && !props.value ? placeholder : ''}
          {...props}
        />
        {isShowLabel && <label style={styleLabel}>{label}</label>}
        {isRequired && (
          <span className={s.requiredLabel}>{t('isReuqiredLabel')}</span>
        )}
      </div>
    )
  },
)
