import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { ar, en } from './dictionary'
import { LANGUAGE_STORAGE_KEY } from '../context/language'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    detection: {
      lookupLocalStorage: LANGUAGE_STORAGE_KEY,
    },
    resources: { ar, en },
    fallbackLng: 'ar',

    debug: false,
    returnObjects: true,
    whitelist: ['ar', 'en'],
    // have a common namespace used around the full app

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },

    react: {
      wait: true,
    },
  })

export default i18n
