import React from 'react'
import { Icon } from '@blueprintjs/core'
import variables from '../../../../styles/variables'
import s from './MapButton.module.scss'

export const MapButton = ({ lat, lng }) => {
  return (
    <div
      className={s.mapBtn}
      onClick={() =>
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
          '_blank',
        )
      }
    >
      <Icon icon='send-to-map' color={variables.shuttleWhite} />
    </div>
  )
}
