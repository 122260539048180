import { useState, useRef } from 'react'
import { useAsync } from 'react-use'
import { useIntersect, useDebounce } from './'

export const useDialogService = (getItemsCallback, buildPayalod) => {
  const [isOpen, setOpen] = useState(false)
  const [items, setItems] = useState([])
  const [hasNextPage, setHasNextPage] = useState(true)
  const [pageNumber, setPageNumber] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [searchField, setSearchField] = useState('')

  const myRef = useRef()
  const [ref, entry] = useIntersect({ root: myRef.current })
  const debounceSearchField = useDebounce(searchField, 500)

  const resetStates = () => {
    setHasNextPage(true)
    setItems([])
    setPageNumber(1)
    setSearchField('')
  }

  const handleToogleModal = () => setOpen((prevState) => !prevState)

  const getItems = async (payload, resetState = false) => {
    try {
      setIsLoading(true)
      const { rows, page, totalPages } = await getItemsCallback(payload)
      setHasNextPage(page < totalPages)
      setItems((prevState) => (resetState ? rows : [...prevState, ...rows]))
      if (page !== totalPages) {
        setPageNumber(page + 1)
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useAsync(async () => {
    if (!isOpen) return
    const payload = buildPayalod()
    payload.page = 1
    await getItems(payload, true)
  }, [debounceSearchField])

  useAsync(async () => {
    if (isOpen && hasNextPage) {
      const payload = buildPayalod()
      await getItems(payload)
    }
    if (!isOpen) {
      resetStates()
    }
  }, [entry, isOpen])

  return {
    isOpen,
    items,
    hasNextPage,
    pageNumber,
    isLoading,
    ref,
    getItems,
    searchField,
    debounceSearchField,
    setSearchField,
    toggleModal: handleToogleModal,
  }
}
