import React from 'react'
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
} from 'react-google-maps'

const AsyncMap = ({
  address,
  google,
  zoom,
  lat,
  lng,
  markerPosition,
  onCloseInfo,
  onMarkerDragEnd,
}) => {
  return (
    <GoogleMap google={google} defaultZoom={zoom} defaultCenter={{ lat, lng }}>
      <Marker
        google={google}
        name={'Dolores park'}
        draggable={true}
        onDragEnd={onMarkerDragEnd}
        position={{ lat: markerPosition.lat, lng: markerPosition.lng }}
      />

      {/* InfoWindow on top of marker */}
      <InfoWindow
        onClose={onCloseInfo}
        position={{ lat: lat + 0.0018, lng: lng }}
      >
        <div>
          <span style={{ padding: 0, margin: 0 }}>{address}</span>
        </div>
      </InfoWindow>
    </GoogleMap>
  )
}

export default withScriptjs(
  withGoogleMap(({ asyncMapProps }) => <AsyncMap {...asyncMapProps} />),
)
