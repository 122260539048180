import React, { useState, useCallback, memo } from 'react'
import { Dialog } from '@blueprintjs/core'
import { generate } from 'shortid'
import { useTranslation } from 'react-i18next'

import { City, District, Button } from '../../reusableComponents'
import { useUserRole } from '../../../hooks'

import s from './Zone.module.scss'

const INITIAL_ZONE = { city: '', district: '' }

export const Zone = memo(
  ({ selectedZonesAmount = null, maxLimits = 1, onChange = () => {} }) => {
    const { t } = useTranslation()
    const { isClient } = useUserRole()

    const [isOpen, setOpen] = useState(false)
    const [zone, setZone] = useState(INITIAL_ZONE)

    const handleChangeZone = (name, value) => {
      setZone((prevState) => ({ ...prevState, [name]: value }))
    }

    const handleToogleModal = useCallback(() => {
      if (isClient && !isOpen && selectedZonesAmount === maxLimits) {
        return null
      }
      setOpen((prevState) => !prevState)
      setZone(INITIAL_ZONE)
    }, [selectedZonesAmount, maxLimits, isOpen, isClient])

    const handleAddZone = () => {
      onChange({ ...zone, zoneId: generate() })
      setZone(INITIAL_ZONE)
      handleToogleModal()
    }

    const disableAddZoneBtn = isClient && selectedZonesAmount === maxLimits

    return (
      <div className={s.container}>
        <Button
          text={t('modals').zone.openModalBtn}
          onClick={handleToogleModal}
          disabled={disableAddZoneBtn}
        />

        <Dialog isOpen={isOpen} className={s.modal} canOutsideClickClose={true}>
          <header className={s.modalHeader}>{t('modals').zone.title}</header>
          <City
            onChange={(v) => handleChangeZone('city', v)}
            value={zone.city}
          />
          <District
            selectedCity={zone.city}
            disabled={!zone.city}
            onChange={(v) => handleChangeZone('district', v)}
            value={zone.district}
          />

          <div className={s.btnGroup}>
            <Button
              text={t('modals').zone.closeBtnText}
              classNameType='dismiss'
              onClick={handleToogleModal}
            />
            <Button
              text={t('modals').zone.addBtnText}
              disabled={!zone.city || !zone.district}
              onClick={handleAddZone}
            />
          </div>
        </Dialog>
      </div>
    )
  },
)
