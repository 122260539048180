import React from 'react'
import { Link } from 'react-router-dom'
import { useTour } from '@reactour/tour'
import s from './HeaderRoute.module.scss'

const RouteHeader = ({
  title,
  route = false,
  children = null,
  style = null,
}) => {
  const isShowRoute = !!route
  const { isOpen: isOpenOnboarding } = useTour()

  return (
    <div className={s.headerBlock}>
      <header className={s.header} style={style}>
        {title} {children}
      </header>
      {isShowRoute && (
        <Link
          to={route.path}
          className={s.routeBtn}
          data-tour={isOpenOnboarding && '2'}
        >
          <div>{route.title}</div>
        </Link>
      )}
    </div>
  )
}

export default RouteHeader
