import React, { useState, memo } from 'react'
import { Dialog } from '@blueprintjs/core'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Button } from '..'
import { LocationForm } from '../../reusableComponents'

import s from './CreateLocation.module.scss'

export const CreateLocation = memo(({ className = '', getItems }) => {
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(false)

  const handleClickButton = async () => {
    setOpen(true)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleAddLocation = () => {
    handleCancel()
    getItems()
  }

  return (
    <div className={classnames(className)}>
      <div style={{ display: 'flex' }}>
        <Button
          text={t('modals').createLocation.btnOpenModalLabel}
          onClick={handleClickButton}
          iconProps={{
            icon: 'plus',
            iconSize: 20,
          }}
          withIcon={true}
          className={s.btnOpenModal}
        />
      </div>

      <Dialog
        isOpen={isOpen}
        className={`${s.modal}`}
        canOutsideClickClose={true}
      >
        <LocationForm goBack={handleCancel} onSubmit={handleAddLocation} />
      </Dialog>
    </div>
  )
})
