import axios from 'axios'
import qs from 'qs'
import i18n from 'i18next'

import { showSuccessMessage, showErrorMessage } from '../../modules/Toasters'

import { refreshPageIfTokenWasExpired } from '../api.ts'

export const getLocationsForDashboard = async ({
  status = 'Active',
  filters = {},
  page = 1,
  isAdmin,
  client,
  pageSize = 10,
}) => {
  const initialQueries = {
    $and: [{ status }],
  }
  let query
  if (!!filters.length && Array.isArray(filters)) {
    query = {
      $and: [
        ...initialQueries.$and,
        ...filters
          .filter((el) => {
            if ('client' in el) {
              !isAdmin && delete el.client
              return el
            }
            return el
          })
          .filter((el) => {
            if ('name' in el) {
              return false
            } else {
              return true
            }
          }),
      ],
    }
    const isNameProp = filters.filter((filter) => 'name' in filter).length > 0
    if (isNameProp) {
      const { name } = filters.find((filter) => 'name' in filter)
      query.$and[0].name = {
        $regex: `.*${name}`,
        $options: 'i',
      }
    }

    const isExistCityAndDistrict =
      filters.length !== 0 &&
      filters.filter((filter) => 'city' in filter || 'district' in filter)
        .length === 1
    if (isExistCityAndDistrict) {
      const c = filters.find((filter) => 'city' in filter)
      const d = filters.find((filter) => 'district' in filter)
      const obj = {}
      if (c) {
        obj.city = c.city
      }
      if (d) {
        obj.district = d.district
      }
      query.$and.push({
        zones: {
          $elemMatch: obj,
        },
      })
    }
    query.$and.forEach((q, i) => {
      if ('city' in q || 'district' in q) {
        delete query.$and[i].city
        delete query.$and[i].district
      }
    })
  } else {
    query = initialQueries
  }

  if (!isAdmin) {
    query.client = client._id
  }

  try {
    const { data } = await axios.get(`/locations?page=${page}`, {
      params: { query, sort: '-_id', pageSize },

      paramsSerializer: (params) => {
        return qs
          .stringify(params)
          .replace('nin%5D%5B0%5D=', 'nin%5D%5B0%5D=%00')
      },
    })
    return data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const getLocations = async ({ query, ...params }) => {
  try {
    const { data } = await axios.get('/locations', {
      params: {
        query,
        ...params,
        sort: params.sort || '-_id',
        includeLockers: params?.includeLockers || false,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })

    return data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const getLocationById = async ({ id }) => {
  try {
    const { data } = await axios.get(`/locations/${id}`)
    return data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const createLocation = async ({ data }) => {
  try {
    const response = await axios.post('/location', data)
    showSuccessMessage(i18n.t('services').locations.createSuccess)
    return response.data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const createAdminLocation = async ({ data }) => {
  try {
    const response = await axios.post('/location/admin', data)
    showSuccessMessage(i18n.t('services').locations.createSuccess)
    return response.data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const updateLocation = async ({ data }) => {
  try {
    const item = await axios.put('/location', data)
    showSuccessMessage(i18n.t('services').locations.updateSuccess)
    return item.data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const updateAdminLocation = async ({ data }) => {
  try {
    const item = await axios.put('/location/admin', data)
    showSuccessMessage(i18n.t('services').locations.updateSuccess)
    return item.data
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}

export const changeLocationStatus = async ({ data }) => {
  try {
    await axios.put('/location/status', data)
    document.location.reload()
  } catch (error) {
    refreshPageIfTokenWasExpired(error.response.data.data)
    showErrorMessage(error.response.data.message)
  }
}
