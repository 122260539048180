import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from '../../reusableComponents'
import { REPORT_ISSUES } from '../../../data/constans'
import s from './Select.module.scss'

export const Issues = ({ styles, typeLocation, onChange, value }) => {
  const { t } = useTranslation()

  return (
    <Select
      {...{
        className: s.reportIssueSelect,
        options: REPORT_ISSUES.filter((i) => i.types.includes(typeLocation)),
        onChange,
        value,
        Option: (props) => {
          const { id, label } = props[0]
          return <option value={label}>{t('reportIssues')[id] || label}</option>
        },
      }}
    />
  )
}
