import React from 'react'
import openLocker from '../../../../assets/images/OpenLocker.svg'
import { useTranslation } from 'react-i18next'

import s from './styles.module.scss'

export const OpenCabinet = ({ onOpen }) => {
  const { t, i18n } = useTranslation()
  return (
    <div
      className={s.cards}
      onClick={onOpen}
      style={{ minWidth: 170, height: 60 }}
    >
      <img className='ml-1' src={openLocker} alt='' />
      <div
        className='my-auto ml-2'
        style={
          i18n.language === 'ar'
            ? { marginRight: '4px', marginLeft: '0px' }
            : {}
        }
      >
        <p>{t('trackingPage').homePage.optionCards.openCabinet}</p>
      </div>
    </div>
  )
}
