import React from 'react'
import { Dialog, Icon } from '@blueprintjs/core'
import { useTranslation } from 'react-i18next'
import info from '../../../assets/images/Info.svg'
import s from './SmartModals.module.scss'

export const OpenCabinetDialog = ({
  onOpenCabinet,
  onCloseDialog,
  html = false,
  text,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  return (
    <Dialog isOpen={true} canOutsideClickClose={true} className={s.modal}>
      <Icon
        className={s.closeBtn}
        icon='cross'
        iconSize={23}
        onClick={onCloseDialog}
      />
      <img src={info} className='d-block mx-auto mt-2 mb-4' alt='' />

      <h5 className={s.title}>{t('trackingPage').orderPage.openCabinet}</h5>
      {html ? (
        <p
          className={s.text}
          style={
            language === 'ar' ? { direction: 'rtl', textAlign: 'right' } : {}
          }
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        ></p>
      ) : (
        <p className={s.text}>{text}</p>
      )}
      {/* <p className={s.text}>{t('trackingPage').orderPage.openCabinetText}</p> */}
      <div className='d-flex justify-content-around align-items-center'>
        <button
          type='button'
          className={`${s.btn} ${s.btnSecondary}`}
          onClick={onCloseDialog}
        >
          {t('trackingPage').orderPage.close}
        </button>
        <button
          type='button'
          className={`${s.btn} ${s.btnPrimary}`}
          onClick={onOpenCabinet}
        >
          {t('trackingPage').orderPage.open}
        </button>
      </div>
    </Dialog>
  )
}
