import React, { useState, useEffect } from 'react'
import { DirectionsRenderer } from 'react-google-maps'
import { useTranslation } from 'react-i18next'
import s from './MapWithRoutes.module.scss'

export const MapDirectionsRenderer = (props) => {
  const { t } = useTranslation()
  const [directions, setDirections] = useState(null)
  const [error, setError] = useState(null)
  const { places, travelMode } = props

  useEffect(() => {
    const waypoints = places.map((p) => ({
      location: { lat: p.lat, lng: p.lng },
      stopover: true,
    }))
    const origin = waypoints.shift().location
    const destination = waypoints.pop().location

    const directionsService = new window.google.maps.DirectionsService()
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: travelMode,
        waypoints: waypoints,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result)
        } else {
          setError(result)
        }
      },
    )
  }, [places, travelMode])

  if (directions) {
    return <DirectionsRenderer directions={directions} />
  } else {
    return <h3 className={s.error}>{t('modals').tripsMap.error}</h3>
  }
}
